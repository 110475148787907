import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography } from '@mui/material';
import gettingReadyPageThumb from '@sharedImages/gettingReadyPage--thumb.svg';
import gettingReadyPageTakeTest from '@sharedImages/gettingReadyPage--take-test.svg';
import gettingReadyPageAfterTest from '@sharedImages/gettingReadyPage--after-test.svg';
import { GettingReadyPageBoxWithIcon } from '@barracuda/shared/src/components/GettingReadyPageBoxWithIcon/GettingReadyPageBoxWithIcon';
import { scrollToElement } from '@barracuda/shared/src/utils/utils';
import { container, firstItem, secondItem, thirdItem } from './GetStartedResource.styles';
import { RegularTextColor, H3 } from '../GettingReadyPage/GettingReadyPage.styles';

export const GetStartedResource: FC = () => {
  const { t } = useTranslation();

  const getStarted1Points: string[] = Object.values(
    t('gettingReadyPage.getStarted1Points', { returnObjects: true })
  );
  const getStarted2Points: string[] = Object.values(
    t('gettingReadyPage.getStarted2Points', { returnObjects: true })
  );
  const getStarted3Points: string[] = Object.values(
    t('gettingReadyPage.getStarted3Points', { returnObjects: true })
  );

  const linkAction = (value: string) => {
    switch (value) {
      case t('gettingReadyPage.getStarted1Link'): {
        scrollToElement('prepare-for-your-test', 100);
        break;
      }
      case t('gettingReadyPage.getStarted2Link'): {
        scrollToElement('check-system-requirements', 100);
        break;
      }
      case t('gettingReadyPage.getStarted3Link'): {
        scrollToElement('check-and-share-your-results', 100);
        break;
      }
    }
  };

  return (
    <Box sx={container} id='how-to-get-started'>
      <Grid item xs={12} sx={{ mb: '30px' }}>
        <Typography component='h2' sx={[H3, { marginBottom: 0 }]}>
          {t('gettingReadyPage.getStartedHeader')}
        </Typography>
        <Typography sx={RegularTextColor}>{t('gettingReadyPage.getStartedText')}</Typography>
      </Grid>
      <Grid item sm={6} md={4} sx={firstItem}>
        <GettingReadyPageBoxWithIcon
          linkAction={linkAction}
          key='resources-item-1'
          icon={gettingReadyPageThumb}
          title={t('gettingReadyPage.getStarted1Title')}
          bottomLink={{ name: t('gettingReadyPage.getStarted1Link') }}
          points={getStarted1Points}
        />
      </Grid>
      <Grid item sm={6} md={4} sx={secondItem}>
        <GettingReadyPageBoxWithIcon
          linkAction={linkAction}
          key='resources-item-2'
          icon={gettingReadyPageTakeTest}
          title={t('gettingReadyPage.getStarted2Title')}
          bottomLink={{ name: t('gettingReadyPage.getStarted2Link') }}
          points={getStarted2Points}
        />
      </Grid>
      <Grid item sm={6} md={4} sx={thirdItem}>
        <GettingReadyPageBoxWithIcon
          linkAction={linkAction}
          key='resources-item-3'
          icon={gettingReadyPageAfterTest}
          title={t('gettingReadyPage.getStarted3Title')}
          bottomLink={{ name: t('gettingReadyPage.getStarted3Link') }}
          points={getStarted3Points}
        />
      </Grid>
    </Box>
  );
};
