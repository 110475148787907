import { theme } from '../../styles/theme';

export const MobileTestTileContainer = {
  padding: '10px 20px 10px 20px'
};
export const MobileTestTileName = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  fontWeight: 400
};
export const MobileTestTileTestInfo = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};
export const MobileTestTileScore = {
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
  color: theme.palette.common.darkGrey3
};
