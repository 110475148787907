import { FC, PropsWithChildren, useEffect } from 'react';
import { Box, Dialog, Grid, Tooltip, Typography } from '@mui/material';
import {
  CenterAlign,
  CloseIconStyle,
  DialogStyles,
  DialogWrapperContainer,
  DialogWrapperSubtitle,
  DialogWrapperTitle
} from './DialogWrapper.styles';
import closeIcon from '../../assets/images/close-small.svg';
import InfoIcon from '../../assets/images/info-icon.svg';
import { Img } from '../Primitive';
import { purpleTooltip } from '../../styles/tooltip';

export interface DialogWrapperProps {
  open: boolean;
  handleClose?: (_: any, reason: 'backdropClick' | 'escapeKeyDown') => void;
  subtitle?: string;
  title: string;
  closeable?: boolean;
  clean?: boolean;
  tooltipAfterQuestion?: string;
  allowBackdrop?: boolean;
  scrollable?: boolean;
  videoWrapper?: boolean;
  isMobile?: boolean;
  subtitleColor?: string;
  gridModifications?: { [p: string]: string | { [p: string]: any } };
  paperRootModifications?: { [p: string]: string | { [p: string]: any } };
}

export const DialogWrapper: FC<PropsWithChildren<DialogWrapperProps>> = ({
  open,
  handleClose,
  title,
  subtitle,
  clean,
  children,
  closeable,
  tooltipAfterQuestion,
  allowBackdrop,
  scrollable,
  videoWrapper,
  isMobile,
  subtitleColor,
  gridModifications,
  paperRootModifications
}) => {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden !important' : 'auto !important';
  }, [open]);

  return (
    <Dialog
      onBackdropClick={() => (allowBackdrop ? handleClose?.(null, 'backdropClick') : null)}
      open={open}
      onClose={handleClose}
      maxWidth='lg'
      sx={
        isMobile && videoWrapper
          ? DialogStyles
          : paperRootModifications
          ? paperRootModifications
          : {}
      }
      disableEscapeKeyDown={!clean}
    >
      <Grid
        container
        spacing={0}
        sx={[
          DialogWrapperContainer,
          gridModifications ? gridModifications : {},
          { padding: clean ? '0' : '40px', overflow: !scrollable ? 'hidden' : 'auto' }
        ]}
      >
        {closeable && (
          <Img
            onClick={() => handleClose?.(undefined, 'escapeKeyDown')}
            sx={CloseIconStyle}
            width={50}
            src={closeIcon}
          />
        )}
        {!clean && subtitle && (
          <Grid item xs={12}>
            <Typography sx={[DialogWrapperSubtitle, subtitleColor ? { color: subtitleColor } : {}]}>
              {subtitle}
            </Typography>
          </Grid>
        )}
        {!clean && (
          <Grid item xs={12}>
            <Box sx={CenterAlign}>
              <Typography sx={DialogWrapperTitle} component='h3'>
                {title}
              </Typography>
              {tooltipAfterQuestion && (
                <Tooltip
                  componentsProps={{ tooltip: { sx: purpleTooltip } }}
                  placement='right'
                  arrow
                  title={tooltipAfterQuestion}
                >
                  <img style={{ marginBottom: '10px' }} src={InfoIcon} alt='info' />
                </Tooltip>
              )}
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sx={{ flex: '1 !important' }}>
          {children}
        </Grid>
      </Grid>
    </Dialog>
  );
};
