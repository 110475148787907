import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { License, Score } from '../../interface/licenses.interfaces';
import {
  MobileTestTileContainer,
  MobileTestTileName,
  MobileTestTileScore,
  MobileTestTileTestInfo
} from './MobileTestTile.style';
import testIcon from '../../assets/images/test--icon.svg';
import { StatusBadge } from '../StatusBadge/StatusBadge';
import { SkillTypes, SkillType } from '../../index';
import { theme } from '../../styles/theme';
import { Img } from '../Primitive';

export interface MobileTestTileProps {
  license: License;
  practice: boolean;
  handleRowClick?: (t: License) => void;
  getLabelForSkill?: (name: string, scale: string, scores: Score[]) => string | null;
}

const findScoreByName = (name: SkillType, scale: string, scores: Score[]): string | null => {
  if (typeof scores === undefined) return null;
  const score = scores.find((el) => el.component === name && el.scale === scale);
  if (!score) {
    return null;
  }

  return score.score;
};

export const MobileTestTile: FC<MobileTestTileProps> = ({
  license,
  practice,
  handleRowClick,
  getLabelForSkill
}) => {
  return (
    <Stack onClick={() => handleRowClick?.(license)} sx={MobileTestTileContainer}>
      <Box sx={MobileTestTileTestInfo}>
        <Box sx={MobileTestTileName}>
          <Img src={testIcon} />
          <Box sx={{ marginLeft: '10px' }}>{license.product.name}</Box>
        </Box>
        {!practice && (
          <Box sx={MobileTestTileScore}>
            {findScoreByName(SkillTypes.OVERALL_SCORE, !practice ? 'GSE' : 'LEVEL', license.scores)}
          </Box>
        )}
      </Box>
      <Box sx={{ marginLeft: '46px' }}>
        {!practice && <StatusBadge type={license.status} />}
        {practice && (
          <Box sx={{ ...MobileTestTileScore, color: theme.palette.common.darkBlue2 }}>
            {findScoreByName(SkillTypes.OVERALL_SCORE, !practice ? 'GSE' : 'LEVEL', license.scores)}
            {getLabelForSkill?.(SkillTypes.OVERALL_SCORE, 'LEVEL', license.scores)}
          </Box>
        )}
      </Box>
    </Stack>
  );
};
