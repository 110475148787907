import {
  sopClient,
  ReasonSurveyExtended,
  ScaleSurvey,
  StarRatingSurvey
} from '@barracuda/shared/src';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/App.context';

export type ResultsSurveyVariant = 'certified' | 'incomplete' | 'uncertified' | 'firstPurchase';

export const certifiedSurveyKey = 'RESULTS_DETAILS_CERTIFIED_TEST_USER_FEEDBACK';
export const incompleteSurveyKey = 'RESULTS_DETAILS_INCOMPLETE_TEST_USER_FEEDBACK';
export const uncertifiedSurveyKey = 'RESULTS_DETAILS_UNCERTIFIED_TEST_USER_FEEDBACK';

interface ResultsSurveyProps {
  readonly isOpen: boolean;
  readonly variant: ResultsSurveyVariant;
  readonly licenseId: string | undefined;
  readonly onSubmitted: () => void;
}

export const ResultsSurvey: FC<ResultsSurveyProps> = ({
  isOpen,
  variant,
  licenseId,
  onSubmitted
}) => {
  const { t } = useTranslation();
  const { setUser, user } = useContext(AppContext);

  // We only want to collect user opinion once per survey type per license id
  const updateMetadataForKey = (_licenseId: string, surveyKey: string) => {
    if (user && surveyKey in user.profile) {
      const data = JSON.parse(user.profile[surveyKey]);
      data.push(_licenseId);
      const dataJSON = JSON.stringify(data);
      sopClient
        .addMetadataToProfile({
          [surveyKey]: dataJSON
        })
        .then((response) => {
          const { data } = response;
          setUser(data);
        });
    } else {
      const data = [_licenseId];
      const dataJSON = JSON.stringify(data);
      sopClient
        .addMetadataToProfile({
          [surveyKey]: dataJSON
        })
        .then((response) => {
          const { data } = response;
          setUser(data);
        });
    }
  };

  const onCertifiedSubmit = (rating: string, comment: string) => {
    sopClient.uploadSurvey(licenseId, {
      metadata: {
        RESULTS_DETAILS_CERTIFIED_TEST_USER_FEEDBACK_RATING: rating,
        RESULTS_DETAILS_CERTIFIED_TEST_USER_FEEDBACK_COMMENT: comment
      }
    });
    licenseId && updateMetadataForKey(licenseId, certifiedSurveyKey);
    onSubmitted();
  };

  const onIncompleteSubmit = (question1: string, comment: string, question3: string) => {
    sopClient.uploadSurvey(licenseId, {
      metadata: {
        RESULTS_DETAILS_INCOMPLETE_TEST_USER_FEEDBACK_WHAT_HAPPENED: question1,
        RESULTS_DETAILS_INCOMPLETE_TEST_USER_FEEDBACK_RETAKE: question3,
        RESULTS_DETAILS_INCOMPLETE_TEST_USER_FEEDBACK_QUESTION_COMMENT: comment
      }
    });
    licenseId && updateMetadataForKey(licenseId, incompleteSurveyKey);
    onSubmitted();
  };

  const onUncertifiedSubmit = (
    radioValue: string,
    radio2Value: string,
    radio3Value: string,
    comment: string
  ) => {
    sopClient.uploadSurvey(licenseId, {
      metadata: {
        RESULTS_DETAILS_UNCERTIFIED_TEST_USER_FEEDBACK_UNDERSTAND: radioValue,
        RESULTS_DETAILS_UNCERTIFIED_TEST_USER_FEEDBACK_KNOW_HOW_IMPROVE: radio2Value,
        RESULTS_DETAILS_UNCERTIFIED_TEST_USER_FEEDBACK_PLAN_RETAKE: radio3Value,
        RESULTS_DETAILS_UNCERTIFIED_TEST_USER_FEEDBACK_COMMENT: comment
      }
    });
    licenseId && updateMetadataForKey(licenseId, uncertifiedSurveyKey);
    onSubmitted();
  };
  const onFirstPurchaseSubmit = (rating: string, comment: string) => {
    sopClient
      .addMetadataToProfile({
        RESULTS_DETAILS_CERTIFIED_TEST_USER_FIRST_PURCHASE_FEEDBACK_RATING: rating,
        RESULTS_DETAILS_CERTIFIED_TEST_USER_FIRST_PURCHASE_FEEDBACK_COMMENT: comment,
        RESULTS_DETAILS_CERTIFIED_TEST_USER_FIRST_PURCHASE: 'false'
      })
      .then((response) => {
        const { data } = response;
        setUser(data);
      });
    onSubmitted();
  };

  switch (variant) {
    case 'certified':
      return (
        <StarRatingSurvey
          isOpen={isOpen}
          onSubmit={onCertifiedSubmit}
          skipSubmittedState
          upperTitle={t('webResultsSurvey.certified.upperTitle')}
          title={t('webResultsSurvey.certified.title')}
          subTitle={t('webResultsSurvey.certified.subTitle', {
            productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
          })}
        />
      );
    case 'incomplete':
      return <ReasonSurveyExtended isOpen={isOpen} onSubmit={onIncompleteSubmit} />;
    case 'uncertified':
      return <ScaleSurvey isOpen={isOpen} onSubmit={onUncertifiedSubmit} />;
    case 'firstPurchase':
      return (
        <StarRatingSurvey
          isOpen={isOpen}
          onSubmit={onFirstPurchaseSubmit}
          skipSubmittedState
          upperTitle={t('webResultsSurvey.certified.upperTitle')}
          title={t('webResultsSurvey.firstPurchase.title')}
          subTitle={t('webResultsSurvey.firstPurchase.subtitle')}
        />
      );
  }
};
