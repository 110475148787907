import { theme } from '../../styles/theme';

export const dialogContainer = {
  backgroundColor: theme.palette.common.lightGrey6,
  padding: '41px 54px',
  position: 'relative',
  '@media screen and (max-width: 740px)': {
    padding: '41px 30px'
  }
};

export const closeButton = {
  position: 'absolute',
  top: '35px',
  right: '55px',
  '&:hover': {
    cursor: 'pointer'
  },
  '@media screen and (max-width: 740px)': {
    right: '21px'
  }
};

export const headerText = {
  color: theme.palette.common.darkBlue7,
  letterSpacing: '2.67361px',
  lineHeight: '29px',
  fontSize: '14.2593px',
  fontWeight: 400
};

export const headerText2 = {
  color: theme.palette.common.darkGrey1,
  letterSpacing: '-0.891204px',
  lineHeight: '43px',
  fontSize: '36px',
  fontWeight: 800,
  fontFamily: 'Noto Sans'
};

export const subheaderText = {
  color: theme.palette.common.darkGrey1,
  lineHeight: 1.5,
  fontSize: '16px',
  fontWeight: 400,
  marginTop: '15px'
};

export const contentWrapper = {
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'space-between',
  '@media screen and (max-width: 740px)': {
    flexDirection: 'column'
  }
};

export const contentHeader = {
  color: theme.palette.common.darkGrey1,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.5,
  marginBottom: '20px'
};

export const itemContainer = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '22px',
  maxWidth: '440px'
};

export const itemText = {
  color: theme.palette.common.darkBlue2,
  fontSize: '14px',
  lineHeight: 1.5
};

export const contentButton = {
  color: theme.palette.common.lightGrey6,
  backgroundColor: theme.palette.common.darkGrey2,
  padding: '12px 20px',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: 1.5,
  textTransform: 'none',
  borderRadius: '40px',
  border: `1px solid ${theme.palette.common.black}`,
  marginTop: '40px',
  textDecoration: 'none',
  display: 'block',
  width: 'fit-content',
  '&:hover': {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.black}`,
    textDecoration: 'none'
  }
};

export const badgeContainer = {
  padding: '24px 15px',
  borderRadius: '4px',
  height: 'fit-content',
  width: 'fit-content',
  alignSelf: 'flex-start',
  '@media screen and (max-width: 740px)': {
    marginTop: '40px',
    alignSelf: 'center'
  }
};

export const badgeImage = {
  width: '190px',
  height: '190px'
};
