import { Box, Button, Link, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  BaseSyntheticEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  AlignedBox,
  AllResultsLink,
  DoneButtonAlign,
  EmailSentParagraph,
  EmailSentStack,
  EmailSentTitle,
  FlexCenter,
  FormDescription,
  FormInputLabel,
  FormInputs,
  FormInputTextField,
  FormTitle,
  FormValidationMessage,
  ForwardIcon,
  PreviewLinkStyle,
  ResultDetailsLink,
  SamplesBox,
  SendButtonStyle,
  ShareDetailsBottomLink,
  ShareResultsContainer,
  ShareResultsForm,
  ShareResultsFormStack,
  ShareResultsTitle
} from './ShareResults.style';
import { useLocation, useParams } from 'react-router-dom';
import CertificateIcon from '../../assets/images/certificate-copy-icon.svg';
import CopyIcon from '../../assets/images/copy-icon.svg';
import EmailSentIcon from '../../assets/images/dancing-people.svg';
import { useForm } from 'react-hook-form';
import { CreateShare, EMAIL_REGEXP_PATTERN, sopClient } from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { SignInButton } from '@barracuda/shared/src/components/SignIn/SignInStyles';
import { useTranslation } from 'react-i18next';
import { Img } from '@barracuda/shared/src/components/Primitive';
import LinkArrow from '../../assets/images/link-arrow.svg';
import { useLocalizedNavigate } from 'src/hooks/useLocalizedNavigate';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

interface EndCustomerOption {
  id: number | null;
  name: string;
}

export const ShareResults: FC = () => {
  const OTHER_OPTION = 'Other';
  const { setError, emailPreviewData, setEmailPreviewData } = useContext(AppContext);
  const navigate = useLocalizedNavigate();
  const { pathname } = useLocation();
  const [emailSent, setEmailSent] = useState(false);
  const [organizations, setOrganizations] = useState<EndCustomerOption[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<
    EndCustomerOption | null | undefined
  >();
  const { id } = useParams();
  const { t } = useTranslation();

  const form = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
    trigger
  } = form;

  useEffect(() => {
    fetchOptions('').then(() => {
      if (emailPreviewData && emailPreviewData.company?.length) {
        setSelectedOrganization({ id: null, name: OTHER_OPTION });
        setValue('anotherOrganization', emailPreviewData.company);
        setValue('email', emailPreviewData.email);
      }
    });
  }, []);

  useEffect(() => {
    if (emailPreviewData && emailPreviewData.company?.length) {
      setValue('organizationName', OTHER_OPTION);
    }
  }, [selectedOrganization]);

  const fetchOptionsWithDelay = useMemo(
    () =>
      debounce(async (event: BaseSyntheticEvent) => {
        const value = event?.target.value;
        await fetchOptions(value?.length ? value : '');
      }, 1000),
    []
  );

  const fetchOptions = useCallback(async (search: string) => {
    const response = await sopClient.getSharesOrganizations(search);
    const { data } = response;
    const otherOption: EndCustomerOption = { id: null, name: OTHER_OPTION };
    setOrganizations([otherOption, ...data]);
  }, []);

  const goToPreviousRoute = () => {
    const routePart = pathname.includes('my-top-score')
      ? `${pathname.split('my-top-score')[0]}my-top-score`
      : pathname.split('share')[0];
    navigate(routePart, { replace: true });
  };
  const sendEmail = async (data: Partial<CreateShare>) => {
    try {
      if (data.organizationName === OTHER_OPTION) {
        data.organizationName = data.anotherOrganization;
      }
      data.sk3CompanyId = selectedOrganization?.id;
      isMyTopScore()
        ? await sopClient.shareMyTopScore(data, id)
        : await sopClient.sendShareEmail(data, id);
      await sopClient.addMetadataToProfile({ SCORE_SHARED: 'true' });
      setEmailSent(true);
    } catch (error: any) {
      const {
        response: {
          data: { message },
          status
        }
      } = error;
      setError({ open: status !== 401, message });
      setEmailSent(false);
    }
  };
  const emailPreviewClicked = () => {
    trigger('email').then(() => {
      if (!isValid) return;
      const values = form.getValues();
      setEmailPreviewData({
        company: values.anotherOrganization,
        email: values.email
      });
      navigate('email-preview', { state: form.getValues() });
    });
  };

  const isMyTopScore = () => !!pathname.includes('my-top-score');

  return (
    <>
      <Box sx={AlignedBox}>
        <Link onClick={() => navigate('/dashboard/results')} sx={AllResultsLink}>
          {t('results.allResults')}
        </Link>
        <ArrowForwardIosIcon sx={ForwardIcon} />
        <Link onClick={() => goToPreviousRoute()} sx={ResultDetailsLink}>
          {t('results.resultDetails')}
        </Link>
        <ArrowForwardIosIcon sx={ForwardIcon} />
        <Typography sx={{ fontWeight: 600 }}>{t('results.shareResults')}</Typography>
      </Box>
      <Box sx={ShareResultsContainer}>
        <Stack>
          <Stack justifyContent='center' alignItems='center'>
            <Typography sx={ShareResultsTitle} component='h1'>
              {t('results.shareYourResults')}
            </Typography>
            <Box component='form' sx={ShareResultsForm} onSubmit={handleSubmit(sendEmail)}>
              {!emailSent && (
                <Stack sx={ShareResultsFormStack}>
                  <Typography sx={FormTitle}>{t('results.shareYourResultsHeader')}</Typography>
                  <Typography sx={FormDescription}>
                    {t('results.shareYourResultsDescription')}
                  </Typography>
                  <Typography sx={SamplesBox}>
                    <img src={CertificateIcon} style={{ marginRight: '10px' }} />
                    {t('results.shareYourResultsCopyOfCertificate')}
                  </Typography>
                  <Typography sx={SamplesBox}>
                    <img src={CopyIcon} style={{ marginRight: '16px' }} />
                    {t('results.shareYourResultsSamples')}
                  </Typography>
                  <Stack sx={FormInputs}>
                    <Typography sx={FormInputLabel}>{t('results.organizationName')}</Typography>
                    <Autocomplete
                      disablePortal
                      id='organization-name'
                      options={organizations}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...register('organizationName', {})}
                          sx={FormInputTextField}
                        />
                      )}
                      onInputChange={(event) => fetchOptionsWithDelay(event)}
                      onChange={(_: BaseSyntheticEvent, value: EndCustomerOption | null) => {
                        setSelectedOrganization(value);
                        setEmailPreviewData({});
                      }}
                      renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.name, inputValue, { insideWords: true });
                        const parts = parse(option.name, matches);
                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        );
                      }}
                    />
                    {selectedOrganization?.name === OTHER_OPTION && (
                      <>
                        <Typography sx={FormInputLabel}>
                          {t('results.specifyOrganization')}
                        </Typography>
                        <TextField
                          sx={FormInputTextField}
                          {...register('anotherOrganization', { required: true })}
                        />
                        <Typography sx={FormInputLabel}>{t('results.emailAddress')}</Typography>
                        <Box sx={{ position: 'relative' }}>
                          <TextField
                            sx={FormInputTextField}
                            {...register('email', {
                              required: true,
                              pattern: {
                                value: EMAIL_REGEXP_PATTERN,
                                message: t('results.invalidEmailAddress')
                              }
                            })}
                          />
                          {errors?.email?.type === 'required' && (
                            <Typography sx={FormValidationMessage}>
                              {t('fieldIsRequired')}
                            </Typography>
                          )}
                          {errors?.email?.type === 'pattern' && (
                            <Typography sx={FormValidationMessage}>
                              {t('thisShouldBeAValidEmailAddress')}
                            </Typography>
                          )}
                        </Box>
                      </>
                    )}
                  </Stack>
                  <Box sx={SendButtonStyle}>
                    <Button
                      disabled={!isValid || !selectedOrganization}
                      type='submit'
                      sx={{ ...SignInButton, marginBottom: 0 }}
                    >
                      {t('send')}
                    </Button>
                    {selectedOrganization?.name === OTHER_OPTION && (
                      <Link onClick={() => emailPreviewClicked()} sx={ShareDetailsBottomLink}>
                        <Typography component='span'>{t('results.previewEmail')}</Typography>
                        <Img src={LinkArrow} />
                      </Link>
                    )}
                  </Box>
                </Stack>
              )}
              {emailSent && (
                <Stack sx={{ ...FlexCenter, ...EmailSentStack }}>
                  <Img sx={{ maxWidth: '245px' }} src={EmailSentIcon} alt='Email Sent Icon' />
                  <Typography sx={EmailSentTitle}>
                    {t('results.shareResultsEmailSentSuccess')}
                  </Typography>
                  {selectedOrganization?.name === OTHER_OPTION ? (
                    <>
                      <Typography sx={EmailSentParagraph}>
                        {t('results.yourScoreHasBeenSent')}
                      </Typography>
                      <Typography sx={EmailSentParagraph}>
                        {t('results.previewTheEmailLinkDescription')}{' '}
                        <Link
                          sx={PreviewLinkStyle}
                          onClick={() => navigate('email-preview', { state: form.getValues() })}
                        >
                          {t('here')}
                        </Link>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography sx={EmailSentParagraph}>
                        {t('results.yourScoreHasBeenSentToEndCustomer')}
                        <b>{selectedOrganization?.name}</b>
                      </Typography>
                    </>
                  )}
                  <Button
                    type='submit'
                    onClick={() => goToPreviousRoute()}
                    sx={[SignInButton, DoneButtonAlign]}
                  >
                    {t('done')}
                  </Button>
                </Stack>
              )}
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
