import { useCallback, useContext } from 'react';
import { Cart, countryISO3ToISO2, User } from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { ELLCommerce, ProductVariantQuantity } from '@pearson-ell/commerce-sdk';
import { useTranslation } from 'react-i18next';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { ellCommerceInstanceConfig } from '../../utils/ell-commerce';

export const useAddCartItem = () => {
  const { setLoading, user, setCart, eCommerceCountryISO2, setError } = useContext(AppContext);
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();

  const createNewCart = async (
    items: ProductVariantQuantity[],
    language: string,
    cartUser: User
  ) => {
    const eCommerce = ELLCommerce.getInstance({
      ...ellCommerceInstanceConfig,
      defaultCountryISO2: language
    });
    await eCommerce
      .CartService()
      .createCart(cartUser?.externalId || '', items, language)
      .then((r) => {
        setCart(r);
        setLoading?.(false);
      })
      .catch(() => {
        setLoading?.(false);
      });
  };

  const addCartItem = useCallback(
    (pId: string, _user?: User, signUp = false, countryISO2?: string) => {
      const cartUser = _user ? _user : user;
      let country: string | undefined;
      if (cartUser?.countryOfResidence?.isoCode) {
        country = countryISO3ToISO2(cartUser.countryOfResidence.isoCode);
      } else {
        country = countryISO2 ? countryISO2 : eCommerceCountryISO2;
      }
      if (cartUser) {
        setLoading?.(true);
        if (signUp) {
          createNewCart(
            [
              {
                sku: pId,
                quantity: 1,
                variantId: 0
              }
            ],
            country || 'US',
            cartUser
          ).then(() => navigate(`/dashboard/shop`));
        } else {
          const eCommerce = ELLCommerce.getInstance({
            ...ellCommerceInstanceConfig,
            defaultCountryISO2: country || 'US'
          });
          eCommerce
            .ProductService()
            .getProductBySku(pId, country)
            .then(() => {
              eCommerce
                .CartService()
                .getCartByUser(cartUser?.externalId || '', country)
                .then((cartResponse) => {
                  if (cartResponse) {
                    const cartItemFound = cartResponse.items.find((i) => i.sku === pId);
                    if (cartItemFound) {
                      eCommerce
                        .CartService()
                        .updateCartItem(cartResponse.id, cartItemFound.id, {
                          productId: cartItemFound.productId,
                          variantId: cartItemFound.variantId,
                          quantity: cartItemFound.quantity + 1
                        })
                        .then((updatedCartResponse: Cart) => {
                          setCart(updatedCartResponse);
                          setLoading?.(false);
                          navigate(`/dashboard/shop`);
                        });
                    } else {
                      eCommerce
                        .CartService()
                        .addCartItem(cartResponse.id, {
                          sku: pId,
                          variantId: 0,
                          quantity: 1
                        })
                        .then((updatedCartResponse: Cart) => {
                          setCart(updatedCartResponse);
                          setLoading?.(false);
                          navigate(`/dashboard/shop`);
                        })
                        .catch(() => {
                          setLoading?.(false);
                          setError({ open: true, message: t('toast.somethingWentWrong') });
                        });
                    }
                  } else {
                    createNewCart(
                      [
                        {
                          sku: pId,
                          quantity: 1,
                          variantId: 0
                        }
                      ],
                      country || 'US',
                      cartUser
                    ).then(() => navigate(`/dashboard/shop`));
                  }
                });
            });
        }
      } else {
        navigate('/login', { state: { cartItemId: pId } });
      }
    },
    []
  );

  return addCartItem;
};
