import { theme } from '../../styles/theme';

export const errorSnackbarStyle = {
  backgroundColor: theme.palette.common.darkGrey2,
  color: theme.palette.common.lightGrey6,
  '.MuiAlert-action': {
    alignSelf: 'center',
    padding: '0 15px 0 50px',
    cursor: 'pointer'
  }
};
