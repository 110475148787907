import { theme } from '../../styles/theme';

export const DashboardTestDetailsShareLink = {
  color: theme.palette.common.black,
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  marginRight: '20px',
  cursor: 'pointer',
  '> svg': {
    height: '15px'
  },
  textTransform: 'capitalize',
  '@media screen and (max-width: 600px)': {
    marginRight: 0
  }
};
