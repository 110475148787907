import { FC, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
  Badge,
  LatestTestTile,
  License,
  MyTopScore,
  MyTopScoreTile,
  PageableResponse,
  sopClient
} from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { TilesContainer } from './DashboardResults.styles';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';

export const DashboardResultsTiles: FC = () => {
  const navigate = useLocalizedNavigate();
  const [badge, setBadge] = useState<Badge>();
  const [license, setLicense] = useState<License>();
  const [myTopScore, setMyTopScore] = useState<MyTopScore>();
  const { setEmailPreviewData } = useContext(AppContext);

  useEffect(() => {
    setEmailPreviewData({
      company: '',
      email: ''
    });
    fetchLatestLicense();
    fetchMyTopScore();
  }, []);

  useEffect(() => {
    sopClient
      .getUserBadges()
      .then((result) => (result.data ? setBadge(result.data) : setBadge(undefined)));
  }, []);

  const fetchMyTopScore = async () => {
    sopClient
      .getMyTopScore(process.env.REACT_APP_MTS_CODE || '')
      .then((response) => {
        if (response?.data && typeof response.data === 'object') {
          //Temporary fix until SOP response change, to not load plain response text for success status.
          setMyTopScore(response.data);
        }
      })
      .catch((error) => console.error('There was an error during getting MyTopScore.', error));
  };

  const fetchLatestLicense = async () => {
    const response = await sopClient.getLatestLicense();
    const data = response.data as PageableResponse<License>;
    const startedLicense = data.results.filter((license) => license.status === 'STARTED')[0];
    setLicense(
      startedLicense
        ? startedLicense
        : data.results.filter(
            (license) => license.status !== 'VALIDATED' && license.status !== 'NEW'
          )[0]
    );
  };

  return (
    <>
      <Box sx={TilesContainer}>
        {myTopScore && <MyTopScoreTile badge={badge} myTopScore={myTopScore} navigate={navigate} />}
        <LatestTestTile license={license} navigate={navigate} />
      </Box>
    </>
  );
};
