import { theme } from '../../styles/theme';

export const BannerContainer = {
  width: '100%',
  background: theme.palette.common.lightGrey6,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  display: 'flex',
  '@media screen and (max-width: 920px)': {
    flexFlow: 'column',
    alignItems: 'flex-start'
  },
  alignItems: 'center',
  gap: '40px',
  '@media screen and (max-width: 1440px)': {
    gap: '10px'
  },
  borderLeft: `${theme.palette.common.violet} solid 10px`,
  '> p': {
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '16px',
    padding: '40px',
    color: theme.palette.common.darkBlue2
  }
};

export const OrderDetails = {
  display: 'flex',
  gap: '30px',
  background: theme.palette.common.lightGrey5,
  border: `1px solid ${theme.palette.common.lightGrey2}`,
  borderRadius: '4px',
  padding: '10px 50px',
  '@media screen and (max-width: 1440px)': {
    padding: '10px 20px'
  },
  '> p > span': {
    fontWeight: 700
  },
  '@media screen and (max-width: 920px)': {
    marginBottom: '30px',
    marginLeft: '40px'
  },
  '@media screen and (max-width: 630px)': {
    flexFlow: 'column'
  }
};

export const DescriptionStyle = {
  '@media screen and (max-width: 1350px)': {
    width: '50%'
  },
  '@media screen and (max-width: 1200px)': {
    width: '40%'
  },
  '@media screen and (max-width: 920px)': {
    width: '100%',
    paddingBottom: '15px'
  }
};
