import { BaseSyntheticEvent, FC, useCallback, useEffect, useState } from 'react';
import { Box, Button, Link, Stack, TextField, Typography } from '@mui/material';
import {
  ConsentsContainer,
  DontHaveAccountBox,
  SignInButton,
  SignInButtonContainer,
  SignInDivider,
  SignInLoginWithGoogleButton,
  SignInTextField,
  SignInTextFieldContainer,
  SignUpLink
} from '../SignIn/SignInStyles';
import googleLogo from '../../assets/images/google-logo.svg';
import {
  ConsentLinkStyle,
  PasswordVisibilityIcon,
  SignUpErrorMessage,
  SignUpHintContainer,
  SignUpPasswordLength,
  SignUpTextField,
  SignUpTextFieldContainer
} from './SignUp.styles';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash';
import { PasswordHintColor, SetPasswordHints } from '../SetPassword/SetPassword.styles';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation, Trans } from 'react-i18next';
import { RecaptchaComponent } from '../../interface/recaptcha.interface';
import { EMAIL_REGEXP_PATTERN } from '../../interface/validations';
import { Img } from '../Primitive';
import { ConsentsBox } from '../ConsentsBox/ConsentsBox';
import { ConsentData, ConsentDataContainer } from '../ConsentsBox/ConsentsBox.style';
import { theme } from '../../styles/theme';
import { Language } from '../../interface/language.interface';

const captchaId = 'captchaResult';

interface SignUpProps {
  signUpSuccess?: () => void;
  handleSSO?: (mode: 'join' | 'signin') => void;
  handleSignUp?: (
    email: string,
    password: string,
    emailReceiveConsent: boolean,
    captchaResult?: string,
    korConsents?: { [p: string]: string }
  ) => void;
  readonly handlePasswordValidation?: (password: string) => Promise<string[]>;
  readonly handleNavigate?: (url: string) => void;
  readonly signUpEmail?: string;
  readonly recaptchaComponent: RecaptchaComponent;
  readonly ipCountry?: string;
  readonly onGoogleClick: () => void;
  readonly onConsentsStatusChange: (
    accepted: boolean,
    consentsDto: { [p: string]: boolean }
  ) => void;
  readonly onCheckboxChanged: (consentsDto: { [p: string]: boolean }) => void;
  readonly consentsDto?: { [p: string]: boolean };
  readonly consentsAccepted?: boolean;
  readonly language?: Language;
}

export const SignUp: FC<SignUpProps> = ({
  signUpSuccess,
  handleSignUp,
  handlePasswordValidation,
  handleNavigate,
  signUpEmail,
  recaptchaComponent,
  ipCountry,
  onGoogleClick,
  onConsentsStatusChange,
  onCheckboxChanged,
  consentsDto,
  consentsAccepted,
  language
}) => {
  const [password, setPassword] = useState('');
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { t } = useTranslation();

  const {
    register,
    setValue,
    trigger,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });

  useEffect(() => {
    register(captchaId, {
      required: true,
      value: ''
    });
  });

  const checkPassword = useCallback(
    debounce(async (event: BaseSyntheticEvent) => {
      const value = event?.target.value;
      if (value) {
        const errors = await handlePasswordValidation?.(value);
        setPasswordErrors(errors || []);
      }
    }, 300),
    []
  );

  const handlePasswordChange = (event: BaseSyntheticEvent) => {
    setPassword(event?.target?.value);
    setValue('password', event?.target?.value);
    trigger('password');
    checkPassword(event);
  };

  const handleEmailChange = (event: BaseSyntheticEvent) => {
    setValue('email', event.target.value);
    trigger('email');
  };

  const signUpHandler = (korConsents?: { [p: string]: boolean }) => {
    const metadata: { [p: string]: string } = {};

    if (korConsents) {
      Object.keys(korConsents).forEach((item) => {
        metadata[item] = korConsents[item].toString();
      });
    }

    if (!handleSignUp) return;
    handleSignUp(
      getValues('email'),
      getValues('password'),
      korConsents ? korConsents['emailReceiveConsent'] : false,
      getValues('captchaResult'),
      metadata
    );
  };

  const recaptchaChange = (value: string | null) => {
    setValue(captchaId, value);
    trigger(captchaId);
  };

  return (
    <>
      <Box>
        <Box sx={SignInButtonContainer}>
          <Button onClick={onGoogleClick} sx={[SignInLoginWithGoogleButton]}>
            <Img src={googleLogo} />
            {t('signUp.withGoogle')}
          </Button>
        </Box>
        <Box sx={[SignInDivider]}>
          <span>{t('signUp.orSignUpWith')}</span>
        </Box>
        <Box sx={[SignInTextFieldContainer]}>
          <TextField
            {...register('email', {
              required: t('thisIsRequired') as string,
              pattern: {
                value: EMAIL_REGEXP_PATTERN,
                message: t('invalidEmail') as string
              }
            })}
            defaultValue={signUpEmail}
            label={t('email')}
            autoComplete='off'
            onChange={handleEmailChange}
            id='email'
            sx={SignInTextField}
            variant='outlined'
          />
          {errors?.email?.type === 'required' && (
            <Typography sx={SignUpErrorMessage}>{t('fieldIsRequired')}</Typography>
          )}
          {errors?.email?.type === 'pattern' && (
            <Typography sx={SignUpErrorMessage}>{t('signUp.shouldBeValidEmail')}</Typography>
          )}
        </Box>
        <Box sx={[SignUpTextFieldContainer, { marginBottom: '5px', position: 'relative' }]}>
          <TextField
            {...register('password', {
              required: t('fieldIsRequired') as string
            })}
            type={passwordVisible ? 'text' : 'password'}
            onChange={handlePasswordChange}
            label={t('signUp.password')}
            id='password'
            sx={SignUpTextField}
            variant='outlined'
          />
          {passwordVisible && (
            <VisibilityOffIcon
              sx={PasswordVisibilityIcon}
              onClick={() => setPasswordVisible(false)}
            />
          )}
          {!passwordVisible && (
            <VisibilityIcon sx={PasswordVisibilityIcon} onClick={() => setPasswordVisible(true)} />
          )}
          <Typography sx={{ ...SignUpPasswordLength(password) }}>{password.length}/8</Typography>
        </Box>
        <Box sx={SignUpHintContainer}>
          <Typography sx={[SetPasswordHints, { textAlign: 'left' }]}>
            {t('atLeast')}
            <Typography
              sx={[
                SetPasswordHints,
                PasswordHintColor(password, passwordErrors, 'NOT_LONG_ENOUGH')
              ]}
              component='span'
            >
              {' '}
              {t('signUp.charactersLength')}
            </Typography>
            ,
            <Typography
              sx={[
                SetPasswordHints,
                PasswordHintColor(password, passwordErrors, 'NO_LOWERCASE_LETTER')
              ]}
              component='span'
            >
              {' '}
              {t('oneLowercaseLetter')}
            </Typography>
            ,
            <Typography
              sx={[
                SetPasswordHints,
                PasswordHintColor(password, passwordErrors, 'NO_UPPERCASE_CHARACTER')
              ]}
              component='span'
            >
              {' '}
              {t('oneUppercaseLetter')}
            </Typography>
            ,
            <Typography
              sx={[
                SetPasswordHints,
                PasswordHintColor(password, passwordErrors, 'NO_DIGIT_CHARACTER')
              ]}
              component='span'
            >
              {' '}
              {t('oneNumber')}
            </Typography>{' '}
            {t('and')}
            <Typography
              sx={[
                SetPasswordHints,
                PasswordHintColor(password, passwordErrors, 'NO_SPECIAL_CHARACTER')
              ]}
              component='span'
            >
              {' '}
              {t('specialCharacter')}
            </Typography>
          </Typography>
        </Box>
        <Stack sx={[ConsentData, ConsentDataContainer]}>
          <ConsentsBox
            language={language}
            handleNavigate={handleNavigate}
            type={ipCountry === 'KR' ? 'sk' : 'default'}
            onConsentsStatusChange={onConsentsStatusChange}
            onCheckboxChanged={onCheckboxChanged}
          />
        </Stack>
        <Stack sx={{ marginTop: '20px' }} alignItems='center' justifyContent='center'>
          {recaptchaComponent(recaptchaChange)}
        </Stack>
        <Box sx={{ ...SignInButtonContainer, marginTop: '40px' }}>
          <Button
            type='submit'
            onClick={() => signUpHandler(consentsDto)}
            disabled={
              Object.keys(errors).length > 0 ||
              !!passwordErrors.length ||
              !isValid ||
              !consentsAccepted
            }
            sx={{ ...SignInButton, marginBottom: '15px' }}
          >
            {t('signUpText')}
          </Button>
        </Box>
        <Box sx={ConsentsContainer}>
          <Box>
            {t('signUp.agreeingText')}{' '}
            <Link onClick={() => handleNavigate?.('privacy-policy')} sx={ConsentLinkStyle}>
              {t('signUp.privacyPolicy')}
            </Link>
            ,{' '}
            <Link onClick={() => handleNavigate?.('terms-of-use')} sx={ConsentLinkStyle}>
              {t('signUp.termsOfUse')}
            </Link>
            , &{' '}
            <Box style={{ color: theme.palette.common.darkGrey2 }} component='span'>
              <Trans
                components={{
                  a: (
                    <Link onClick={() => handleNavigate?.('cookie-policy')} sx={ConsentLinkStyle}>
                      {' '}
                    </Link>
                  )
                }}
                i18nKey='signUp.cookiePolicy'
              />
            </Box>
            .{' '}
          </Box>
        </Box>
        <Box sx={[SignInButtonContainer, DontHaveAccountBox]}>
          {t('signUp.alreadyHaveAccount')}
          <Typography
            onClick={signUpSuccess}
            component='a'
            sx={[SignUpLink, { marginLeft: '10px' }]}
          >
            {t('signUp.signIn')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
