import {
  MyTopScore,
  Score,
  SharedScoresTable,
  ShareTableRow,
  sopClient
} from '@barracuda/shared/src';
import { Box, Stack } from '@mui/material';
import { BaseSyntheticEvent, FC, useCallback, useEffect, useState } from 'react';
import { SharedScoreContainer, SharedScoreWrapper } from './AccountSharedScoresWrapper.styles';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

export const AccountSharedScoresWrapper: FC<{ myTopScore?: MyTopScore }> = ({ myTopScore }) => {
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [reload, setReload] = useState(true);
  const { t } = useTranslation();

  const accordionHeaders: string[] = Object.values(
    t('profilePage.scoresTableHeaders', { returnObjects: true })
  );

  const tableHeaders = Object.values(
    t('profilePage.allSentScoresTableHeaders', { returnObjects: true })
  ).map((header) => header as string);

  const [licensesWithShares, setLicensesWithShares] = useState<ShareTableRow[]>([]);

  useEffect(() => {
    reloadLicensesWithShares(page, searchQuery);
  }, []);

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
    reloadLicensesWithShares(page, query);
  };
  const handleInputChange = useCallback(
    debounce((event: BaseSyntheticEvent) => {
      const value = event?.target.value;
      handleSearchChange(value);
    }, 300),
    []
  );

  const handleChangePage = async (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    reloadLicensesWithShares(newPage, searchQuery);
  };

  const updateShares = (value: ShareTableRow[]) => {
    setLicensesWithShares([...value]);
  };

  const reloadLicensesWithShares = (page: number, query: string) => {
    sopClient.getShareableLicenses(page, query).then((response) => {
      const { data } = response;
      const mappedResults: ShareTableRow[] = data?.results
        .map((item: ShareTableRow) => {
          return {
            ...item,
            selected: false,
            anyChildSelected: false,
            allChildrenSelected: false,
            selectedIds: [],
            sharedWith: 0
          };
        })
        .filter(
          (item: any) => item.product.code !== process.env.REACT_APP_MTS_CODE
        ) as ShareTableRow[];
      if (myTopScore) {
        mappedResults.unshift({
          id: myTopScore.id,
          name: 'MyTopScore',
          testCode: myTopScore.code,
          startTime: myTopScore.issuedAt,
          score: myTopScore.score,
          selected: false,
          anyChildSelected: false,
          allChildrenSelected: false,
          selectedIds: [],
          sharedWith: 0
        });
      }
      setLicensesWithShares(mappedResults);
      setTotal(mappedResults.length);
      setReload(true);
    });
  };
  const findScoreByName = (name: string, scale: string, scores: Score[]): string | null => {
    if (!scores || typeof scores === undefined) return null;
    const score = scores.find((el) => el?.component === name && el?.scale === scale);
    if (!score) {
      return null;
    }

    return score.score;
  };

  const generateRevokeSharesDto = () => {
    return { shareIds: [...licensesWithShares.flatMap((i) => i.selectedIds)] };
  };
  const triggerStopSharing = () => {
    sopClient.revokeMultipleShares(generateRevokeSharesDto()).then(() => {
      reloadLicensesWithShares(page, '');
    });
  };

  return (
    <Box sx={SharedScoreWrapper}>
      <Stack sx={SharedScoreContainer}>
        <SharedScoresTable
          reload={reload}
          setReload={setReload}
          handleInputChange={handleInputChange}
          triggerStopSharing={triggerStopSharing}
          title={t('allScoresYouShared')}
          findScoreByName={findScoreByName}
          tableHeaders={tableHeaders}
          accordionHeaders={accordionHeaders}
          page={page}
          setLicensesWithShares={updateShares}
          shares={licensesWithShares}
          total={total}
          handleChangePage={handleChangePage}
          setPage={setPage}
        />
      </Stack>
    </Box>
  );
};
