import {
  Box,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery
} from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { ShareResult } from '../../interface/score-share.interface';
import { sopClient } from '../../index';
import { CheckboxStyle } from '../SharedScoresTable/SharedScoresTable.style';
import { useTranslation } from 'react-i18next';
import visible from '../../assets/images/visible.svg';
import hidden from '../../assets/images/hidden.svg';
import {
  sharedScoresDetailedTableEmail,
  sharedScoresDetailedTableFooterStyle,
  sharedScoresDetailedTableOffCircle,
  sharedScoresDetailedTableOnCircle,
  sharedScoresDetailedTableOrderHistoryAccordionTableContainer,
  sharedScoresDetailedTableOrderHistoryAccordionTableTheadCell,
  sharedScoresDetailedTableRow,
  sharedScoresDetailedTableSharingCell
} from './SharedScoresDetailedTable.styles';
import { DateFormat, formatDate } from '../../utils/utils';
import { Img } from '../Primitive';
import { TESTS_TABLE_PAGE_SIZE } from '@barracuda/sop-api-client';
import { AutomaticShareTooltip } from './AutomaticShareTooltip';

export interface SharedScoresDetailedTableProps {
  readonly resourceId: number;
  readonly resourceType: 'testTakerTopScoreId' | 'licenseId';
  readonly allSelected: boolean;
  readonly reload: boolean;
  readonly setReload: Dispatch<SetStateAction<boolean>>;
  readonly updateParentCheckbox: (
    parentId: number,
    value: boolean,
    anyChildSelected: boolean,
    selectedIds: number[],
    mainCheckboxDisabled?: boolean,
    sharedWith?: number
  ) => void;
}

export const SharedScoresDetailedTable: FC<SharedScoresDetailedTableProps> = ({
  resourceId,
  resourceType,
  allSelected,
  updateParentCheckbox,
  reload,
  setReload
}) => {
  const { t } = useTranslation();
  const [shares, setShares] = useState<(ShareResult & { selected: boolean })[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const tableHeaders: string[] = Object.values(
    t('sharedScoresDetailedTable.headers', { returnObjects: true })
  );
  const matches1024px = useMediaQuery('(max-width: 1024px)');
  const columnMatchesMobile = (_: unknown, index: number) =>
    matches1024px ? index === 0 || index === 4 : true;

  useEffect(() => {
    if (reload) {
      loadShares(page, '');
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if (!allSelected && shares.every((i) => i.selected)) {
      selectUnselectAllShares(allSelected);
    } else if (allSelected) {
      selectUnselectAllShares(allSelected);
    }
  }, [allSelected]);

  const loadShares = (page: number, query?: string) => {
    sopClient.getSharesById(page, resourceId, resourceType, query).then((response) => {
      const { data } = response;
      const mappedResults = data?.results.map((item: ShareResult) => {
        return { ...item, selected: false };
      });
      setShares(mappedResults);
      setTotal(data.total);
      updateParentCheckbox(
        resourceId,
        false,
        false,
        [],
        allLoadedSharesRevoked(mappedResults),
        data.total
      );
    });
  };

  const selectUnselectAllShares = (select: boolean) => {
    const updatedShares = shares.map((item) => ({ ...item, selected: select }));
    setShares(updatedShares);
    updateParentCheckbox(
      resourceId,
      select,
      select,
      select ? shares.filter((i) => !i.automaticShare).map((i) => i.id) : []
    );
  };

  const handleChangePage = async (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    loadShares(newPage, '');
  };

  const handleCheckboxChange = (value: boolean, index: number) => {
    const modifiedShares = shares.map((item, i) => ({
      ...item,
      selected: i === index ? value : item.selected
    }));
    setShares(modifiedShares);
    const selectedIds = modifiedShares
      .filter((i) => i.selected && !i.automaticShare)
      .map((i) => i.id);
    if (modifiedShares.every((i) => i.selected))
      updateParentCheckbox(resourceId, true, true, selectedIds);
    if (modifiedShares.some((i) => !i.selected))
      updateParentCheckbox(resourceId, false, true, selectedIds);
    if (modifiedShares.every((i) => !i.selected))
      updateParentCheckbox(resourceId, false, false, selectedIds);
  };

  const allLoadedSharesRevoked = (list: (ShareResult & { selected: boolean })[]) => {
    return list.every((i) => i.revoked);
  };

  const renderIsViewed = useCallback((item: ShareResult) => {
    const organization = item.organization;
    const viewed = item.viewed || item.automaticShare || !organization?.email;
    return (
      <Box sx={sharedScoresDetailedTableSharingCell}>
        <Img src={viewed ? visible : hidden} />
        <Typography>{viewed ? t('profilePage.viewed') : t('profilePage.notViewed')}</Typography>
      </Box>
    );
  }, []);

  return (
    <Box sx={sharedScoresDetailedTableOrderHistoryAccordionTableContainer}>
      <TableContainer sx={{ maxHeight: '300px' }}>
        <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              {tableHeaders.filter(columnMatchesMobile).map((header, index) => (
                <TableCell
                  key={`${header}${index}`}
                  sx={[
                    sharedScoresDetailedTableOrderHistoryAccordionTableTheadCell,
                    { width: matches1024px && index === 0 ? '75%' : 'unset' }
                  ]}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={sharedScoresDetailedTableRow}>
            {shares?.map((item, index) => (
              <TableRow key={`${item}${index}`} sx={sharedScoresDetailedTableRow}>
                <TableCell>
                  <Stack direction='row'>
                    <Checkbox
                      sx={CheckboxStyle}
                      checked={item.selected && !item?.revoked && !item?.automaticShare}
                      onChange={(e) => handleCheckboxChange(e.target.checked, index)}
                      disabled={item?.revoked || item?.automaticShare}
                      size='medium'
                      color='default'
                    />
                    <Typography component='span' sx={{ fontWeight: 700 }}>
                      {item.organization?.name}
                    </Typography>
                  </Stack>
                  {matches1024px && (
                    <Stack sx={{ marginLeft: '42px', '> *': { fontSize: '14px' } }}>
                      <Typography sx={sharedScoresDetailedTableEmail}>
                        {item.organization?.email}
                      </Typography>
                      <Typography>{formatDate(item?.createdAt, DateFormat.DEFAULT)}</Typography>
                      {renderIsViewed(item)}
                    </Stack>
                  )}
                </TableCell>
                {!matches1024px && (
                  <>
                    <TableCell>
                      <Typography sx={sharedScoresDetailedTableEmail}>
                        {!item.automaticShare && item.organization?.email}
                      </Typography>
                    </TableCell>
                    <TableCell>{formatDate(item?.createdAt, DateFormat.DEFAULT)}</TableCell>
                    <TableCell>{renderIsViewed(item)}</TableCell>
                  </>
                )}
                <TableCell>
                  <Box sx={sharedScoresDetailedTableSharingCell}>
                    <Box
                      sx={
                        !item.revoked
                          ? sharedScoresDetailedTableOnCircle
                          : sharedScoresDetailedTableOffCircle
                      }
                    />
                    <Typography>{!item.revoked ? t('account.on') : t('account.off')}</Typography>
                    {item.automaticShare && <AutomaticShareTooltip />}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter sx={sharedScoresDetailedTableFooterStyle}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[1]}
            rowsPerPage={TESTS_TABLE_PAGE_SIZE}
            colSpan={9}
            count={total}
            page={page}
            onPageChange={handleChangePage}
          />
        </TableRow>
      </TableFooter>
    </Box>
  );
};
