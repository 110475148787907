import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  LabelDisplayedRowsArgs,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery
} from '@mui/material';

import {
  CheckboxStyle,
  LowResolutionAppear,
  SearcherBox,
  sharedScoresTableAccordionRow,
  SharedScoresTableContainer,
  sharedScoresTableExpandIcon,
  SharedScoresTableHeader,
  SharedScoresTableMainHeader,
  sharedScoresTableMobileRow,
  SharedScoresTableTHead,
  SharedScoresTableTHeadCell,
  StopSharingButton,
  TableFooterStyle
} from './SharedScoresTable.style';
import { TESTS_TABLE_PAGE_SIZE } from '@barracuda/sop-api-client';
import { Score } from '../../interface/licenses.interfaces';
import { useTranslation } from 'react-i18next';
import { SearchBar } from '../SearchBar/SearchBar';
import { SignInButton } from '../SignIn/SignInStyles';
import { RevokeSharingPopup } from '../RevokeSharingPopup/RevokeSharingPopup';
import { PurpleCircle, SearchBarSearchIcon } from '../SearchBar/SearchBar.styles';
import SearchIcon from '@mui/icons-material/Search';
import { SkillTypes } from '../../interface/common.interfaces';
import { DateFormat, formatDate } from '../../utils/utils';
import { SharedScoresDetailedTable } from '../SharedScoresDetailedTable/SharedScoresDetailedTable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ShareTableRow } from '../../interface/score-share.interface';
import EmptyStateImage from '../../assets/images/empty-table-image.svg';
import { Img } from '../Primitive';

interface SharedScoresTableProps {
  title: string;
  query?: string;
  setPage: (value: number) => void;
  page: number;
  total: number;
  tableHeaders: string[];
  accordionHeaders?: string[];
  accordionSummaryClick?: (id: number) => void;
  expandAccordionClick?: (id: number) => void;
  handleChangePage: (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  shares: ShareTableRow[];
  findScoreByName: (name: string, scale: string, scores: Score[]) => string | null;
  handleInputChange?: Function;
  setLicensesWithShares: (value: ShareTableRow[]) => void;
  triggerStopSharing: () => void;
  reload: boolean;
  setReload: Dispatch<SetStateAction<boolean>>;
}

export const SharedScoresTable: FC<SharedScoresTableProps> = ({
  title,
  page,
  total,
  tableHeaders,
  handleChangePage,
  shares,
  // handleInputChange,
  triggerStopSharing,
  setLicensesWithShares,
  findScoreByName,
  reload,
  setReload
}) => {
  const { t } = useTranslation();
  const [anyCheckboxSelected, setAnyCheckboxSelected] = useState(false);
  const [allCheckboxSelected, setAllCheckboxSelected] = useState(false);
  const [stopSharingOpened, setStopSharingOpened] = useState(false);
  const matches1024px = useMediaQuery('(max-width: 1024px)');
  const columnMatchesMobile = (_: unknown, index: number) =>
    matches1024px ? index === 0 || index === 2 : true;

  useEffect(() => {
    const availableCheckboxes = shares?.filter((item) => !item.mainCheckboxDisabled);
    const allSelected =
      !!availableCheckboxes.every((item) => item.selected) && availableCheckboxes.length > 0;
    setAllCheckboxSelected(allSelected);
    const anySelected = !!shares?.find((item) => item.anyChildSelected);
    setAnyCheckboxSelected(anySelected);
  }, [shares]);

  const computeTableCellWidth = (el: string) => {
    if (el === t('testName')) return matches1024px ? '70%' : '30%';
    if (el === t('testID') || el === t('shareDetails.sharedWith')) return '20%';
    if (el === tableHeaders[2]) return matches1024px ? '30%' : '15%';
    return '15%';
  };

  const handleCheckboxChange = (
    mode: 'all' | 'single' = 'single',
    value: boolean,
    index?: number
  ) => {
    let mapped;
    if (mode === 'all') {
      mapped = [
        ...shares.map((item) => {
          return {
            ...item,
            selected: item.mainCheckboxDisabled ? false : value,
            anyChildSelected: value
          };
        })
      ];
      setLicensesWithShares(mapped);
    } else if (mode === 'single') {
      mapped = [...shares];
      if (index !== undefined) {
        mapped[index].selected = value;
        setLicensesWithShares([...mapped]);
      }
    }
    const anySelected = !!mapped?.find((item) => item.selected || item.anyChildSelected);
    setAnyCheckboxSelected(anySelected);
    const allSelected = !!mapped
      ?.filter((item) => !item.mainCheckboxDisabled)
      .every((item) => item.selected);
    setAllCheckboxSelected(allSelected);
  };

  const renderTableHeaderRegular = useMemo(() => {
    return (
      <TableHead sx={SharedScoresTableTHead}>
        <TableRow>
          {tableHeaders.filter(columnMatchesMobile).map((el, index) => (
            <TableCell
              width={computeTableCellWidth(el)}
              key={`${el}${index}`}
              sx={SharedScoresTableTHeadCell}
            >
              {index === 0 && (
                <>
                  <Checkbox
                    defaultChecked={false}
                    sx={CheckboxStyle}
                    checked={allCheckboxSelected}
                    disabled={!shares.length}
                    onChange={(e) => handleCheckboxChange('all', e.target.checked)}
                    size='medium'
                    color='default'
                  />
                  {el}
                </>
              )}
              {index !== 0 && <>{el}</>}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }, [tableHeaders]);

  const renderTableHeader = useMemo(() => {
    return renderTableHeaderRegular;
  }, [renderTableHeaderRegular]);

  const labelDisplayedRows = (props: LabelDisplayedRowsArgs): string => {
    return `${props.from}–${props.to} ${t('pagination.of')} ${props.count}`;
  };

  const updateParentCheckbox = (
    parentId: number,
    value: boolean,
    anyChildSelected: boolean,
    selectedIds: number[],
    mainCheckboxDisabled?: boolean,
    sharedWith?: number
  ) => {
    const sharePosition = shares.find((i) => i.id === parentId);
    if (sharePosition) {
      const indexOfShare = shares.indexOf(sharePosition);
      sharePosition.selected = value;
      sharePosition.anyChildSelected = anyChildSelected;
      sharePosition.selectedIds = selectedIds;
      sharePosition.mainCheckboxDisabled = mainCheckboxDisabled;
      if (sharedWith) {
        sharePosition.sharedWith = sharedWith;
      }
      shares[indexOfShare] = sharePosition;
      setLicensesWithShares(shares);
    }
  };

  return (
    <>
      <Box sx={SharedScoresTableContainer}>
        <Box sx={SharedScoresTableMainHeader}>
          <Box sx={[PurpleCircle, LowResolutionAppear]}>
            <SearchIcon sx={SearchBarSearchIcon} />
          </Box>
          <Typography sx={SharedScoresTableHeader} component='h2'>
            {title}
          </Typography>
          <Box sx={SearcherBox}>
            <Button
              onClick={() => setStopSharingOpened(true)}
              type='submit'
              disabled={!anyCheckboxSelected}
              sx={[SignInButton, StopSharingButton]}
            >
              {t('stopSharing')}
            </Button>
            <SearchBar handleInputChange={() => null} />
          </Box>
        </Box>
        <TableContainer>
          <Table>{renderTableHeader}</Table>
        </TableContainer>
        {!shares.length && (
          <Stack
            sx={{ width: '100%', minHeight: '395px' }}
            alignItems='center'
            justifyContent='center'
          >
            <Img src={EmptyStateImage} />
            <Typography sx={{ textAlign: 'center' }}>
              {t('account.dontHaveAnyTestsWithShares')}
            </Typography>
          </Stack>
        )}
        {!!shares.length && (
          <>
            {shares.map((item, i) => (
              <Accordion key={`shares-accordion-${i}`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ pointerEvents: 'auto' }} />}
                  sx={sharedScoresTableExpandIcon}
                >
                  <Stack direction='row' sx={sharedScoresTableAccordionRow}>
                    <Stack direction='row' sx={{ width: matches1024px ? '70%' : '30%' }}>
                      <Checkbox
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleCheckboxChange('single', !shares[i].selected, i);
                        }}
                        sx={CheckboxStyle}
                        checked={item.selected}
                        disabled={item.mainCheckboxDisabled}
                        size='medium'
                        color='default'
                      />
                      <Typography component='span' sx={{ fontWeight: 700 }}>
                        {'product' in item ? item?.product?.name : item?.name}
                      </Typography>
                    </Stack>
                    {!matches1024px && (
                      <Typography sx={{ width: '20%' }}>{item?.testCode}</Typography>
                    )}
                    <Typography sx={{ fontWeight: 700, width: matches1024px ? '30%' : '15%' }}>
                      {'scores' in item
                        ? findScoreByName(SkillTypes.OVERALL_SCORE, 'GSE', item?.scores)
                        : item?.score}
                    </Typography>
                    {!matches1024px && (
                      <>
                        <Typography sx={{ width: '15%' }}>
                          {formatDate(item.startTime, DateFormat.DEFAULT)}
                        </Typography>
                        <Typography sx={{ width: '20%' }}>{`${item.sharedWith} ${t(
                          'account.accounts'
                        ).toLowerCase()}`}</Typography>
                      </>
                    )}
                  </Stack>
                  {matches1024px && (
                    <Stack sx={{ marginLeft: '60px' }}>
                      <Box sx={sharedScoresTableMobileRow}>
                        <Typography>{tableHeaders[0]}:</Typography>
                        <Typography>{item.testCode}</Typography>
                      </Box>
                      <Box sx={sharedScoresTableMobileRow}>
                        <Typography>{t('date')}:</Typography>
                        <Typography>{formatDate(item.startTime, DateFormat.DEFAULT)}</Typography>
                      </Box>
                      <Box sx={sharedScoresTableMobileRow}>
                        <Typography>{tableHeaders[3]}:</Typography>
                        <Typography>{`${item.sharedWith} ${t(
                          'account.accounts'
                        ).toLowerCase()}`}</Typography>
                      </Box>
                    </Stack>
                  )}
                </AccordionSummary>
                <AccordionDetails sx={{ padding: !matches1024px ? '0 45px 50px' : '15px' }}>
                  <SharedScoresDetailedTable
                    resourceId={item.id}
                    setReload={setReload}
                    allSelected={item.selected}
                    resourceType={'name' in item ? 'testTakerTopScoreId' : 'licenseId'}
                    updateParentCheckbox={updateParentCheckbox}
                    reload={reload}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
        {!!shares.length && (
          <Table>
            <TableFooter sx={TableFooterStyle}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[1]}
                  rowsPerPage={TESTS_TABLE_PAGE_SIZE}
                  labelDisplayedRows={labelDisplayedRows}
                  colSpan={9}
                  count={total}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': t('rowsPerPage') as string
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Box>
      <RevokeSharingPopup
        triggerStopSharing={triggerStopSharing}
        open={stopSharingOpened}
        handleClose={() => setStopSharingOpened(!stopSharingOpened)}
      />
    </>
  );
};
