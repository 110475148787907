import { FC, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import {
  HelpCenterArticleAccordion,
  HelpCenterArticleContent,
  HelpCenterArticleLink,
  HelpCenterArticlesContainer,
  HelpCenterArticleTitle,
  HelpCenterCategoriesContainer,
  HelpCenterCategoriesHeader,
  HelpCenterCategoriesMobileContainer,
  HelpCenterCategoryItem,
  HelpCenterCategoryItemActive,
  HelpCenterCategoryTitle,
  HelpCenterContactUsContainer,
  HelpCenterContactUsLink,
  HelpCenterHeaderContainer,
  HelpCenterHeaderParagraph,
  HelpCenterHeaderTitle,
  HelpCenterListHeader,
  TabletCategoriesBox
} from './HelpCenter.styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HelpCenterContent, HelpCenterItem } from '../../interface/help-center-content.interface';
import { Trans, useTranslation } from 'react-i18next';
import { ScalableWrapper } from '../ScalableWrapper/ScalableWrapper';
import i18n from 'i18next';

interface HelpCenterProps {
  content: HelpCenterContent[];
  handleContactUs: () => void;
  handleNavigate: (url: string) => void;
  desktop?: boolean;
  isLoggedIn?: boolean;
}

export const HelpCenter: FC<HelpCenterProps> = ({
  content,
  handleContactUs,
  handleNavigate,
  desktop,
  isLoggedIn
}) => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState<string[]>([]);
  const [category, setCategory] = useState<string>(t('helpCenterPage.activeCategory') as string);
  const [currentArticles, setCurrentArticles] = useState<HelpCenterContent[]>([]);

  const generateCategories = (): string[] => {
    return [
      ...new Set(
        content.map((el: HelpCenterContent) => {
          return el.category as string;
        })
      )
    ];
  };

  const removeTargetFromString = (item: string): string => {
    return item.replaceAll(`target='_blank'`, '');
  };

  useEffect(() => {
    setCategory(t('helpCenterPage.activeCategory') as string);
    setCategories(generateCategories());
    findArticlesByCategory(category);
  }, [content, i18n.language]);

  useEffect(() => {
    findArticlesByCategory(category);
  }, [category]);

  const generateCategoryItems = () => {
    return categories.map((el, index) => (
      <Typography
        onClick={() => setCategory(el)}
        sx={[HelpCenterCategoryItem, category === el && HelpCenterCategoryItemActive]}
        key={`category${index}`}
      >
        {el}
      </Typography>
    ));
  };

  const findArticlesByCategory = (categoryName: string) => {
    const articles = content.filter((el: HelpCenterContent) => el.category === categoryName);
    setCurrentArticles(articles);
  };

  const navigate = (el: HelpCenterItem) => {
    if (!el.href) return;
    if (el.blank) {
      handleContactUs();
    } else {
      handleNavigate(el.href);
    }
  };

  const setContent = (el: HelpCenterItem, title: string, index: number) => {
    switch (el.type) {
      case 'link': {
        return (
          <Box
            sx={[HelpCenterArticleContent, HelpCenterArticleLink, { marginBottom: '15px' }]}
            key={`${el.type}_${index}_${el.content}`}
            component='a'
            onClick={() => navigate(el)}
          >
            {el.content}
          </Box>
        );
      }
      case 'paragraph': {
        // todo quickfix before launch, whole component needs to be refactored to our normal translation standards
        const content = el.content as string;
        const url = '{{ url }}';
        const newContent = content.includes(url)
          ? content.replace(url, process.env.REACT_APP_PESC_URL as string)
          : content;

        return (
          <Typography
            sx={[HelpCenterArticleContent, { marginBottom: '15px' }]}
            key={`${el.type}_${index}_${el.content}`}
            dangerouslySetInnerHTML={{
              __html: !desktop ? newContent : removeTargetFromString(newContent)
            }}
          />
        );
      }
      case 'list': {
        return generateList(el, title, false, index);
      }
      case 'orderedList': {
        return generateList(el, title, true, index);
      }
      default: {
        return '';
      }
    }
  };

  const generateList = (el: HelpCenterItem, title: string, ordered = false, index?: number) => {
    return (
      <Box key={`list-container-${index}`}>
        {el.listTitle && (
          <Typography sx={[HelpCenterArticleContent, HelpCenterListHeader]}>
            {el.listTitle}
          </Typography>
        )}
        <Box component={ordered ? 'ol' : 'ul'} sx={{ paddingLeft: '20px' }}>
          {el.listItems?.map((el: string, index: number) => (
            <Box
              sx={[HelpCenterArticleContent, { marginBottom: '5px', display: 'list-item' }]}
              key={title + '_' + index + '_' + el}
              component='li'
              dangerouslySetInnerHTML={{ __html: el as string }}
            />
          ))}
        </Box>
      </Box>
    );
  };

  const generateArticlesAccordion = () => {
    return currentArticles.map((element, index) => {
      return (
        <Accordion
          sx={HelpCenterArticleAccordion}
          key={`${category}_${index}_${element.title}_accordion`}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            key={`${category}_${index}_${element.title}_summary`}
          >
            <Typography sx={HelpCenterArticleTitle}>{element.title}</Typography>
          </AccordionSummary>
          <AccordionDetails key={`${category}_${index}_${element.title}_details`}>
            {element.items &&
              element.items.length &&
              element.items.map((el: HelpCenterItem, index: number) =>
                setContent(el, element.title, index)
              )}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <ScalableWrapper isLoggedIn={isLoggedIn}>
      <Box sx={{ background: '#F9F9F9' }}>
        <Grid container>
          <Grid item xs={12} sx={HelpCenterHeaderContainer}>
            <Typography sx={HelpCenterHeaderTitle} component='h1'>
              {t('helpCenterPage.header')}
            </Typography>
            <Typography sx={HelpCenterHeaderParagraph}>
              {t('helpCenterPage.headerText', {
                productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
              })}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container sx={[HelpCenterHeaderContainer, { paddingTop: '40px' }]}>
        <Grid item xs={12} sx={HelpCenterCategoriesMobileContainer}>
          <Typography>{t('helpCenterPage.categories')}</Typography>
          <Select value={category} onChange={(event) => setCategory(event.target.value)}>
            {categories.map((category, index) => (
              <MenuItem key={'selectCategory' + index} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3} sx={[HelpCenterCategoriesContainer, TabletCategoriesBox]}>
          <Typography sx={HelpCenterCategoriesHeader}>{t('helpCenterPage.categories')}</Typography>
          {categories && categories.length && generateCategoryItems()}
          <Box sx={HelpCenterContactUsContainer}>
            <Trans
              i18nKey='helpCenterPage.sidebarText'
              components={{
                a: (
                  <Link
                    onClick={() => handleContactUs()}
                    sx={HelpCenterContactUsLink}
                    component='span'
                  />
                )
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={8} sx={[{ paddingLeft: '80px' }, HelpCenterArticlesContainer]}>
          <Typography sx={HelpCenterCategoryTitle} component='h2'>
            {category}
          </Typography>
          {currentArticles && currentArticles.length && generateArticlesAccordion()}
        </Grid>
      </Grid>
    </ScalableWrapper>
  );
};
