import { Box, Tab, Tabs, Typography } from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import {
  AccountDataContainer,
  AccountName,
  AccountSingleTab,
  AccountTabs,
  AccountTabsScaled,
  FlexCenter,
  HeaderMainContainer
} from './DashboardAccountsHeader.styles';
import { User } from '../../interface/user.interface';
import { AccountPhoto } from '../AccountPhoto';
import { TooltipWrapper } from '../TooltipWrapper/TooltipWrapper';
import { useTranslation } from 'react-i18next';

interface DashboardHeaderAccountsProps {
  activeTab: number;
  handleTabChange: (_: SyntheticEvent, newValue: number) => void;
  user: User | undefined;
}

export const DashboardAccountsHeader: FC<DashboardHeaderAccountsProps> = ({
  activeTab,
  handleTabChange,
  user
}) => {
  const username =
    user?.firstName && user?.lastName
      ? `${user?.firstName} ${user?.lastName}`
      : user?.preferredName;
  const { t } = useTranslation();

  const userAgent = navigator.userAgent;
  const isWindows = /Win/i.test(userAgent);

  const AccountTabsStyles = isWindows ? AccountTabsScaled : AccountTabs;

  return (
    <>
      <Box sx={{ ...HeaderMainContainer, ...FlexCenter, flexFlow: 'column' }}>
        <Box sx={{ ...AccountDataContainer, ...FlexCenter, flexFlow: 'column' }}>
          <Box sx={{ position: 'relative' }}>
            <AccountPhoto imageUri={user?.imageUri} width='128px' height='128px' />
          </Box>
          <TooltipWrapper sx={{ marginTop: '30px', maxWidth: '100%' }}>
            <Typography sx={AccountName}>{username}</Typography>
          </TooltipWrapper>
        </Box>
        <Tabs
          sx={{ ...AccountTabsStyles, width: '100%', marginTop: '35px' }}
          value={activeTab}
          onChange={handleTabChange}
        >
          <Tab sx={AccountSingleTab} label={t('profilePage.profileTab')} />
          <Tab sx={AccountSingleTab} label={t('profilePage.orderHistoryTab')} />
          <Tab sx={AccountSingleTab} label={t('profilePage.sharedScoresTab')} />
        </Tabs>
      </Box>
    </>
  );
};
