import { ActivationCodeStatus } from '../../interface/activation-code.interface';
import { theme } from '../../styles/theme';

export const activationCodePopupBoxStyle = {
  display: 'flex',
  marginTop: '36px',
  gap: '13px',
  width: '100%'
};

export const activationCodePopupInputStyle = (status: ActivationCodeStatus) => ({
  width: '100%',
  '> .MuiInputBase-root': {
    borderRadius: '4px',
    border: status === 'INVALID' ? `1px solid ${theme.palette.common.darkRed}` : 'unset'
  }
});

export const activationCodePopupValidationResultStyle = (status: ActivationCodeStatus) => ({
  position: 'absolute',
  color: status === 'VALID' ? theme.palette.common.darkGreen : theme.palette.common.darkRed,
  marginTop: '5px'
});

export const activationCodePopupSkipButtonStyle = {
  textAlign: { xs: 'center', sm: 'right' },
  width: '100%',
  paddingBottom: '20px',
  fontWeight: 600,
  '&:hover': {
    cursor: 'pointer'
  }
};

export const activationCodePopupSmallScreenStyles = {
  alignSelf: 'start',
  marginTop: '56px'
};

export const activationCodePopupInputBoxStyle = {
  position: 'relative',
  width: '100%',
  maxWidth: { sm: '370px' }
};

export const activationCodePopupImageStyle = {
  maxWidth: '165px',
  marginTop: '56px',
  paddingBottom: '68px'
};
