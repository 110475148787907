import { theme } from '@theme';

export const DashboardResultsContainer = {
  padding: '80px 50px',
  '@media screen and (max-width: 900px)': {
    padding: '80px 30px'
  },
  '@media screen and (max-width: 765px)': {
    padding: '80px 15px'
  },
  background: theme.palette.common.lightGrey5,
  display: 'flex',
  flexFlow: 'column',
  '> h1': {
    fontWeight: 700,
    fontSize: '25px',
    lineHeight: 1.2,
    color: theme.palette.common.darkBlue1,
    marginBottom: '20px'
  },
  '@media screen and (max-width: 1199px)': {
    marginTop: '50px'
  }
};

export const DashboardResultsTabLabel = {
  borderBottom: 0,
  maxWidth: '220px',
  textTransform: 'initial',
  fontSize: '16px'
};

export const TilesContainer = {
  display: 'flex',
  gap: '30px',
  '@media screen and (max-width: 805px)': {
    gap: '10px'
  },
  '@media screen and (max-width: 680px)': {
    flexFlow: 'column'
  }
};
