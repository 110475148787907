import { theme } from '../../styles/theme';

export const RectangleContainer = {
  width: '370px',
  height: '470px',
  backgroundColor: theme.palette.common.lightGrey6,
  border: `0 solid ${theme.palette.common.violet}`,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  padding: '26px 30px 26px 30px',
  position: 'relative',
  '@media screen and (max-width: 450px)': {
    height: '370px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90%'
  }
};
export const RectangleTitle = {
  marginTop: '20px',
  fontSize: '22px',
  fontWeight: 700
};
export const RectangleText = {
  fontSize: '16px',
  fontWeight: 400,
  '@media screen and (max-width: 450px)': {
    display: 'none'
  }
};

export const ButtonContainer = {
  position: 'absolute',
  bottom: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  width: 'calc(100% - 60px)'
};
export const RectangleContainerImage = {
  width: '100%',
  height: 'auto'
};
