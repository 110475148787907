import { Language } from '@barracuda/shared/src';

enum oneTrustGroups {
  PERFORMANCE_COOKIES = 'C0002'
}

export const useOneTrust = (): {
  mapOTLangCode: (lang: Language) => string;
  oneTrustGroups: typeof oneTrustGroups;
  reloadOTBanner: () => void;
  hasActiveOneTrustGroup: (group: oneTrustGroups) => boolean;
} => {
  const hasActiveOneTrustGroup = (group: oneTrustGroups): boolean =>
    !!window?.OnetrustActiveGroups?.includes(group);

  // OT is basing on HTML lang attribute, it has to be exactly as valid OT lang code
  const mapOTLangCode = (lang: Language) => {
    if (lang === 'JPN') return 'JA';

    return 'EN'; // default language fallback
  };

  const reloadOTBanner = () => {
    const otInitialized = document.getElementById('onetrust-consent-sdk') instanceof HTMLElement;
    const notAccepted = !hasActiveOneTrustGroup(oneTrustGroups.PERFORMANCE_COOKIES);
    if (otInitialized && notAccepted) {
      window.location.reload();
    }
  };

  return {
    oneTrustGroups,
    hasActiveOneTrustGroup,
    mapOTLangCode,
    reloadOTBanner
  };
};
