import { theme } from '../../styles/theme';

export const SkillsTileContainer = {
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '40px 50px 50px 50px',
  height: '100%',
  background: theme.palette.common.lightGrey4,
  border: '3px solid #F0F0F0'
};

export const SkillsTileTitle = {
  paddingBottom: '50px',
  fontSize: '25px',
  fontWeight: 700
};

export const NoScoresStack = {
  justifyContent: 'center',
  alignItems: 'center',
  gap: '30px'
};
