export const container = {
  display: {
    sm: 'flex'
  },
  flexWrap: 'wrap'
};

export const firstItem = {
  mb: {
    xs: '-1px',
    md: 0
  }
};

export const secondItem = {
  mb: {
    xs: '-1px',
    md: 0
  },
  ml: { sm: '-1px' },
  //  specific max-width and flex-basis values to achieve border-collapse
  maxWidth: { sm: 'calc(50% + 1px)', md: 'calc(33.333333% + 1px)' },
  flexBasis: { sm: 'calc(50% + 1px)', md: 'calc(33.333333% + 1px)' }
};

export const thirdItem = {
  ml: { md: '-1px' },
  //  specific max-width and flex-basis values to achieve border-collapse
  maxWidth: { sm: 'calc(50% + 1px)', md: 'calc(33.333333% + 1px)' },
  flexBasis: { sm: 'calc(50% + 1px)', md: 'calc(33.333333% + 1px)' }
};
