import { SkillType } from './common.interfaces';
import type { ProductCode } from './products.interface';

export type Scale = 'CEFR' | 'VERSANT' | 'GSE';

export type Status =
  | 'NEW'
  | 'VALIDATED'
  | 'SCORED'
  | 'CERTIFICATION_FAILED'
  | 'APPEALED'
  | 'CERTIFIED'
  | 'SPEAKING_UNSCORBALE'
  | 'LATE_HANGUP'
  | 'IN_PROGRESS'
  | 'STARTED'
  | 'ERROR'
  | 'NOT_SCORABLE'
  | 'PARTIALLY_SCORED'
  | 'COMPLETED'
  | 'UPLOADING'
  | 'ABORTED';

export interface Score {
  scale: Scale;
  component: SkillType;
  score: string;
  color?: string;
  exceptionCode?: number;
  exceptionMessage?: string;
  tags?: any;
  description?: string;
  label?: string;
}

export interface Product {
  active: boolean;
  application: {
    id: number;
    code: string;
    name: string;
  };
  code: ProductCode;
  id: number;
  name: string;
  price: number;
  repositoryId: number;
  practice?: boolean;
  essId: number;
  metadata: {
    TEST_DURATION: string;
  };
}

export interface License {
  createdAt: string;
  endTime: string;
  errorCode: string;
  errorReason: string;
  id: number;
  product: Product;
  scores: Score[];
  startTime: string;
  status: Status;
  testCode: string;
  canBeRetaken: boolean;
  retakeRedeemed: boolean;
  retake: boolean;
  issued: Date;
  retakeExpiresAt: Date;
}

export interface SkillScore {
  description: string;
  label: string;
  name: string;
  score: string;
  value: string;
}

export interface Skill {
  component: string;
  definition: string;
  description: string;
  scores: SkillScore[];
  tags: string[];
  tips: string[];
  exceptionCode: number;
  exceptionMessage: string;
}

export interface LicenseResolution {
  id: number;
  version: number;
  ticketType: string;
  value: string;
}

export interface FailedCertificationData {
  id: number;
  canBeRetaken?: boolean;
  retake: boolean;
}
export const statusesWithoutDetailsPage = ['NEW', 'STARTED', 'COMPLETED', 'VALIDATED'];
export const statusesWithoutNewAndValidated = [
  'STARTED',
  'COMPLETED',
  'LATE_HANGUP',
  'ERROR',
  'NOT_SCORABLE',
  'PARTIALLY_SCORED',
  'SCORED',
  'CERTIFICATION_FAILED',
  'CERTIFIED',
  'ABORTED'
];

export interface TestsLimitReached {
  testLimitReached: boolean;
  message: string;
}
