import { FC, PropsWithChildren } from 'react';
import { Button, Dialog, Grid, Typography } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AreYouSureImg from '../../assets/images/are-you-sure.svg';
import {
  CancelButtonAlign,
  DialogWrapperContainer,
  DialogWrapperText,
  DialogWrapperTitle,
  FlexCenter,
  RevokeSharingButtonMobile
} from './RevokeSharingPopup.style';
import { SignInButton } from '../SignIn/SignInStyles';
import { Trans, useTranslation } from 'react-i18next';
import { buttonOutlineDark } from '../../styles/button';

interface DialogWrapperProps {
  open: boolean;
  handleClose: () => void;
  triggerStopSharing: () => void;
}

export const RevokeSharingPopup: FC<PropsWithChildren<DialogWrapperProps>> = ({
  open,
  handleClose,
  triggerStopSharing
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md' disableEscapeKeyDown>
      <Grid container spacing={0} sx={DialogWrapperContainer}>
        <Grid item xs={12} sx={FlexCenter}>
          <img width='120' src={AreYouSureImg} />
        </Grid>
        <Grid item xs={12} sx={{ ...FlexCenter, marginTop: '25px' }}>
          <Typography sx={DialogWrapperTitle}>{t('account.areYouSure')}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ ...FlexCenter, marginTop: '10px' }}>
          <Typography sx={{ display: 'inline', ...DialogWrapperText }}>
            <Trans i18nKey='doYouWantStopSharing' components={{ bold: <strong /> }} />
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ ...FlexCenter, marginTop: '25px' }}>
          <Button
            onClick={() => {
              triggerStopSharing();
              handleClose();
            }}
            tabIndex={0}
            type='submit'
            sx={{ ...buttonOutlineDark, marginBottom: 0, ...RevokeSharingButtonMobile }}
          >
            {t('stopSharing')}
          </Button>
          <Button
            tabIndex={0}
            type='submit'
            sx={[SignInButton, CancelButtonAlign, RevokeSharingButtonMobile]}
            onClick={() => handleClose()}
          >
            {t('account.cancel')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
