import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Img from '../Primitive/Img';
import { useTranslation, Trans } from 'react-i18next';
import { imageStyles, leftColumn, rightColumn, SubtitleStyles } from './CallToActionTop.styles';

export interface CallToActionTopProps {
  readonly textI18nKey: string;
  readonly smallTitle?: boolean;
  readonly subtitle?: string;
  readonly describeI18nKey?: string;
  readonly image: string;
  readonly invert?: boolean;
  readonly children?: React.ReactNode;
  readonly columnGap?: number | string | Record<string, string>; // It's not using proper MUI breakpoint interface
}

export const CallToActionTop: FC<CallToActionTopProps> = ({
  textI18nKey,
  describeI18nKey,
  image,
  children,
  subtitle,
  invert,
  smallTitle,
  columnGap = '50px'
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{
        flexDirection: invert ? 'row-reverse' : 'row',
        flexWrap: { md: 'nowrap' }
      }}
      columnGap={columnGap}
    >
      <Grid item xs={12} md={5} sx={leftColumn} justifyContent='center'>
        <Box
          sx={{
            textAlign: 'left'
          }}
        >
          <Typography
            component='h1'
            sx={{
              fontSize: smallTitle ? '35px' : '50px',
              fontWeight: 700,
              lineHeight: 1.3,
              paddingBlock: '0.5rem',
              letterSpacing: 0
            }}
          >
            {t(textI18nKey)}
          </Typography>
          {subtitle && (
            <Typography component='h2' sx={SubtitleStyles}>
              {subtitle}
            </Typography>
          )}
          <Typography
            component='div'
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: 1.5,
              paddingTop: '0.5rem'
            }}
          >
            <Trans
              i18nKey={describeI18nKey}
              components={{
                br: <br />,
                margin: <Box sx={{ marginBottom: '1rem' }} />,
                italic: <i />,
                bold: <b />,
                p: <p />
              }}
            />
          </Typography>
          <Box
            sx={{
              marginTop: '24px'
            }}
          >
            {children ?? ''}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={rightColumn}
        alignItems='center'
        justifyContent={{ xs: 'center', md: invert ? 'start' : 'end' }}
      >
        <Img sx={imageStyles} src={image} />
      </Grid>
    </Grid>
  );
};
