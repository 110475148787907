import { theme } from '@theme';

export const ShareResultsTitle = {
  fontWeight: 700,
  fontSize: '25px',
  lineHeight: 1.2,
  color: theme.palette.common.darkBlue1,
  padding: '30px 0 50px 0',
  '@media only screen and (max-width: 743px)': {
    display: 'none'
  },
  '@media only screen and (min-width: 1513px)': {
    width: '943px'
  },
  '@media only screen and (min-width: 743px) and (max-width: 1513px)': {
    width: '722px'
  }
};

export const ShareResultsContainer = {
  padding: '40px 90px 90px 90px',
  background: theme.palette.common.lightGrey5,
  minHeight: '100vh',
  '@media only screen and (max-width: 1200px)': {
    padding: '26px 60px'
  },
  '@media only screen and (max-width: 833px)': {
    padding: '26px 40px'
  }
};

export const EmailSentStack = {
  padding: '0 80px 30px 80px',
  '& > img': {
    width: '367px',
    marginTop: '74px',
    '@media only screen and (max-width: 650px)': {
      width: '198px'
    }
  },
  '@media only screen and (max-width: 650px)': {
    padding: '0 20px 20px 20px'
  }
};

export const FlexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export const ShareResultsForm = {
  '@media only screen and (min-width: 743px)': {
    width: '722px'
  },
  backgroundColor: theme.palette.common.white,
  borderRadius: '4px',
  '@media only screen and (min-width: 1513px)': {
    width: '943px'
  },
  '@media only screen and (max-width: 743px)': {
    marginTop: '30px'
  }
};

export const ShareResultsFormStack = {
  padding: '60px',
  '@media only screen and (max-width: 428px)': {
    padding: '40px 20px'
  }
};

export const FormTitle = {
  fontWeight: 700,
  fontSize: '25px',
  lineHeight: 1.2,
  color: theme.palette.common.darkGrey3,
  paddingBottom: '15px'
};

export const FormInputs = {
  marginTop: '50px'
};

export const FormInputLabel = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: 1.5,
  color: theme.palette.common.darkGrey3,
  paddingBottom: '5px'
};

export const FormInputTextField = {
  width: '70%',
  '@media screen and (max-width: 500px)': {
    width: '100%'
  },
  paddingBottom: '8px',
  '> div': {
    height: '48px'
  }
};

export const EmailSentTitle = {
  fontWeight: 700,
  fontSize: '25px',
  lineHeight: 1,
  textAlign: 'center',
  marginTop: '66px'
};

export const EmailSentParagraph = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.5,
  textAlign: 'center',
  color: theme.palette.common.darkBlue1,
  marginTop: '12px'
};

export const FormDescription = {
  paddingBottom: '20px',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.6,
  color: theme.palette.common.darkGrey2
};

export const AlignedBox = {
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  fontSize: '14px',
  marginTop: '90px',
  background: '#E1E9FB',
  width: '100%',
  paddingLeft: '55px',
  '@media screen and (min-width: 1199px)': {
    marginTop: 0
  },
  '@media screen and (max-width: 540px)': {
    paddingLeft: '25px'
  },
  '@media screen and (max-width: 400px)': {
    paddingLeft: '5px'
  }
};

export const AllResultsLink = {
  textDecoration: 'none',
  color: theme.palette.common.darkBlue1,
  cursor: 'pointer'
};

export const ForwardIcon = {
  width: '14px',
  '@media screen and (max-width: 400px)': {
    width: '6px'
  },
  margin: '0 7px 0 7px'
};

export const ResultDetailsLink = {
  textDecoration: 'underline',
  color: theme.palette.common.darkBlue1,
  cursor: 'pointer',
  '@media screen and (max-width: 400px)': {
    fontSize: '13px'
  }
};

export const SamplesBox = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px'
};

export const SendButtonStyle = {
  alignItems: 'center',
  marginTop: '40px',
  display: 'flex'
};

export const PreviewLinkStyle = {
  color: theme.palette.common.violet,
  fontWeight: 700,
  textDecorationColor: theme.palette.common.violet,
  cursor: 'pointer'
};

export const DoneButtonAlign = {
  marginBottom: 0,
  marginTop: '40px'
};

export const FormValidationMessage = {
  position: 'absolute',
  bottom: '-20px',
  fontSize: '12px',
  color: 'red',
  left: 0
};

export const ShareDetailsBottomLink = {
  color: theme.palette.common.darkBlue7,
  cursor: 'pointer',
  textDecoration: 'none',
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  '> span': {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 1.6,
    marginLeft: '30px'
  }
};
