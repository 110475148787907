import { theme } from '../../styles/theme';

export const FormInputContainer = {
  paddingBottom: '23px',
  position: 'relative'
};
export const InputLabel = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: 1.5,
  color: theme.palette.common.darkGrey3,
  paddingBottom: '8px'
};
export const FormInput = {
  borderRadius: '4px',
  border: '1px solid #91919',
  width: '90%',
  '.MuiInputBase-input': {
    height: '15px'
  }
};
export const DoneButton = {
  marginBottom: 0,
  marginTop: '70px',
  '@media screen and (max-width: 600px)': {
    marginTop: 0
  }
};
