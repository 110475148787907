import { theme } from '../../styles/theme';

export const StepperStyles = {
  background: 'inherit',
  justifyContent: 'center',
  button: {
    color: theme.palette.common.darkGrey3
  },
  '.MuiMobileStepper-dot': {
    width: '15px',
    height: '15px',
    margin: '0 5px'
  },
  '.MuiMobileStepper-dotActive': {
    backgroundColor: theme.palette.common.violet
  }
};
