import { theme } from '@theme';

export const HeaderContainer = {
  backgroundColor: theme.palette.common.lightGrey5,
  paddingLeft: '30px',
  paddingRight: '30px',
  '@media screen and (max-width: 720px)': {
    paddingBottom: '50px'
  }
};
export const HeaderTitle = {
  fontSize: '42px',
  fontWeight: 600,
  maxWidth: '500px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  marginBottom: '20px'
};
export const HeaderSubtitle = {
  fontSize: '16px',
  fontWeight: 400,
  paddingBottom: '12px',
  '@media screen and (max-width: 900px)': {
    width: '80%'
  },
  '@media screen and (max-width: 870px)': {
    width: '65%'
  },
  '@media screen and (max-width: 720px)': {
    width: '100%'
  }
};
export const ImgPosition = {
  position: 'absolute',
  right: '105%',
  top: '50%',
  '@media screen and (max-width: 980px)': {
    width: '80%',
    top: '65%'
  },
  '@media screen and (max-width: 720px)': {
    width: 'unset',
    top: '-20%',
    left: '30%'
  },
  '@media screen and (max-width: 500px)': {
    width: 'unset',
    top: '-20%',
    left: '20%'
  }
};
export const GridBlueJourney = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media screen and (max-width: 720px)': {
    flexBasis: '100%',
    maxWidth: '100%'
  }
};

export const HeaderTitleUsername = {
  fontSize: '42px',
  fontWeight: 600,
  color: theme.palette.common.darkBlue4
};

export const WelcomeBackMobile = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media only screen and (max-width: 720px)': {
    marginTop: '110px',
    maxWidth: '100%',
    flexBasis: '100%'
  }
};

export const GridContainer = {
  height: '442px',
  '@media only screen and (min-width: 720px) and (max-width: 1199px)': {
    height: '545px'
  },
  '@media only screen and (max-width: 720px)': {
    height: 'unset !important'
  },
  justifyContent: 'space-between',
  maxWidth: '1192px',
  margin: 'auto'
};
