import { FC, FormEvent } from 'react';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { SignInButton } from '../SignIn/SignInStyles';
import { FormContainer, NextButtonAlign } from './SoftProfileSurveyForm.styles';
import { useTranslation } from 'react-i18next';

interface SoftProfileSurveyFormProps {
  handleSubmit: (event: FormEvent) => void;
  setRadioValue: (value: string) => void;
  radioValue: string | null;
  setOther: (value: string) => void;
  other: string;
}

export const SoftProfileSurveyForm: FC<SoftProfileSurveyFormProps> = ({
  other,
  radioValue,
  setRadioValue,
  handleSubmit
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container component='form' onSubmit={handleSubmit}>
        <Grid item xs={12} sx={FormContainer}>
          <FormControl>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
              value={radioValue}
              onChange={(_event, value) => setRadioValue(value)}
            >
              <FormControlLabel
                value={t('tellUsMoreForm.iAmSecondaryStudent')}
                control={<Radio />}
                label={t('tellUsMoreForm.iAmSecondaryStudent')}
              />
              <FormControlLabel
                value={t('tellUsMoreForm.iAmStudent')}
                control={<Radio />}
                label={t('tellUsMoreForm.iAmStudent')}
              />
              <FormControlLabel
                value={t('tellUsMoreForm.iAmGraduate')}
                control={<Radio />}
                label={t('tellUsMoreForm.iAmGraduate')}
              />
              <FormControlLabel
                value={t('tellUsMoreForm.iAmYoungProfessional')}
                control={<Radio />}
                label={t('tellUsMoreForm.iAmYoungProfessional')}
              />
              <FormControlLabel
                value={t('tellUsMoreForm.iAmProfessional')}
                control={<Radio />}
                label={t('tellUsMoreForm.iAmProfessional')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Button
          type='submit'
          disabled={!radioValue || (radioValue === 'other' && !other)}
          sx={[SignInButton, NextButtonAlign]}
        >
          {t('tellUsMoreForm.next')}
        </Button>
      </Grid>
    </>
  );
};
