import { Img } from '../Primitive';
import ErrorIconSvg from '../../assets/images/error-icon.svg';
import { FC } from 'react';
import { Alert, Snackbar } from '@mui/material';
import WhiteCloseSvg from '../../assets/images/white-close.svg';
import { errorSnackbarStyle } from './ErrorSnackbar.style';
import { Trans } from 'react-i18next';
import { theme } from '../../styles/theme';

interface ErrorSnackbarProps {
  error: { open: boolean; message?: string };
  handleClose: () => void;
}
export const ErrorSnackbar: FC<ErrorSnackbarProps> = ({ error, handleClose }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={error.open}
      onClose={handleClose}
      autoHideDuration={5000}
    >
      <Alert
        action={<Img src={WhiteCloseSvg} onClick={handleClose} />}
        icon={<Img src={ErrorIconSvg} />}
        sx={errorSnackbarStyle}
      >
        <Trans sx={{ color: theme.palette.common.lightGrey6 }} i18nKey='toast.somethingWentWrong' />
      </Alert>
    </Snackbar>
  );
};
