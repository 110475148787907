import { theme } from '@theme';

export const ErrorContainer = {
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '556px',
  marginInline: 'auto'
};
export const ErrorTitle = {
  fontSize: '28px',
  fontWeight: 700,
  marginTop: '30px',
  color: theme.palette.common.darkBlue1
};
export const ErrorDescription = {
  marginTop: '10px',
  color: theme.palette.common.darkGrey1,
  textAlign: 'center'
};

export const qrCode = {
  position: 'absolute',
  left: '33%',
  width: '30.35%',
  top: '27.4%'
} as const;
