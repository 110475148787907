import { theme } from '@theme';

export const emailPreviewContainer = {
  background: theme.palette.common.lightGrey5,
  minHeight: '100vh',
  '@media only screen and (max-width: 800px)': {
    table: {
      width: 'unset !important',
      maxWidth: '580px'
    }
  }
};

export const emailPreviewDisplayBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@media only screen and (max-width: 600px)': {
    margin: '30px 10px',
    backgroundColor: theme.palette.common.lightGrey2
  }
};

export const emailPreviewTitleAndBox = {
  padding: '90px',
  '@media only screen and (min-width: 600px) and (max-width: 743px)': {
    marginTop: '30px'
  },
  '@media only screen and (max-width: 600px)': {
    padding: '42px 0'
  },
  '@media only screen and (max-width: 1199px)': {
    padding: '96px 60px',
    marginTop: '50px'
  },
  '@media only screen and (max-width: 427px)': {
    padding: '42px 0'
  }
};

export const emailPreviewTemplateContainer = {
  background: theme.palette.common.white,
  boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
  borderRadius: '8px'
};

export const emailPreviewActiveLink = {
  fontWeight: 600,
  '@media screen and (max-width: 400px)': {
    fontSize: '13px'
  }
};
