import { theme } from '@theme';

export const SectionTitle = {
  fontSize: '24px',
  color: theme.palette.common.darkGrey2,
  fontWeight: 600,
  marginBottom: '13px'
};

export const SectionBoxes = {
  display: 'flex',
  marginTop: '30px'
};

export const SectionBox = {
  border: '1px solid #727272',
  borderRadius: '6px',
  padding: '45px'
};

export const FlexCenter = {
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '20px'
};

export const GrayCircle = {
  width: '40px',
  height: '40px',
  borderRadius: '100px',
  background: '#F7F7F7',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  '> img': {
    maxWidth: '18px'
  }
};

export const BoxTitle = {
  fontSize: '24px',
  fontWeight: 700,
  paddingBottom: '30px'
};

export const firstItem = {
  ...SectionBox,
  mb: {
    xs: '-1px',
    md: 0
  }
};

export const secondItem = {
  ...SectionBox,
  mb: {
    xs: '-1px',
    md: 0
  },
  ml: { sm: '-1px' },
  //  specific max-width and flex-basis values to achieve border-collapse
  maxWidth: { sm: 'calc(50% + 1px)', md: 'calc(33.333333% + 1px)' },
  flexBasis: { sm: 'calc(50% + 1px)', md: 'calc(33.333333% + 1px)' }
};

export const thirdItem = {
  ...SectionBox,
  ml: { md: '-1px' },
  //  specific max-width and flex-basis values to achieve border-collapse
  maxWidth: { sm: 'calc(50% + 1px)', md: 'calc(33.333333% + 1px)' },
  flexBasis: { sm: 'calc(50% + 1px)', md: 'calc(33.333333% + 1px)' }
};
