import { useContext, useEffect, useState } from 'react';
import { Box, Button, Drawer, List, ListItem, Stack, Typography } from '@mui/material';
import {
  DashboardList,
  DashboardListItem,
  DashboardNavLink,
  DashboardNavLinkActive,
  DashboardOutlet,
  DashboardSidebar,
  DashboardSidebarScaled
} from './Dashboard.styles';
import { Outlet, Route, Routes } from 'react-router-dom';
import { DashboardHome } from '../DashboardHome/DashboardHome';
import { Head } from '../Head/Head';
import { AccountPhoto } from '@barracuda/shared/src/components/AccountPhoto';
import {
  countryISO3ToISO2,
  DialogWrapper,
  ELLCommerce,
  ScalableWrapper,
  sopClient,
  sopUtils
} from '@barracuda/shared/src';
import { DashboardResults } from '../DashboardResults/DashboardResults';
import { DashboardTestDetails } from '../DashboardTestDetails/DashboardTestDetails';
import { DashboardAccounts } from '../DashboardAccounts/DashboardAccounts';
import { ShareResults } from '../ShareResults/ShareResults';
import { EmailPreview } from '../EmailPreview/EmailPreview';
import { AppContext } from '../../context/App.context';
import { GettingReadyPageWrapper } from '../GettingReadyPageWrapper/GettingReadyPageWrapper';
import { HelpCenterWrapper } from '../HelpCenterWrapper/HelpCenterWrapper';
import { PrivacyPolicy } from '../PrivacyPolicy/PrivacyPolicy';
import { TermsOfUse } from '../TermsOfUse/TermsOfUse';
import { CookiePolicy } from '../CookiePolicy/CookiePolicy';
import { ShopWrapper } from '../ShopWrapper/ShopWrapper';
import { ExploreTestsWrapper } from '../ExploreTests/ExploreTestsWrapper';
import { useTranslation } from 'react-i18next';
import OrderWrapper from '../OrderWrapper/OrderWrapper';
import { DashboardMyTopScore } from '../DashboardMyTopScore/DashboardMyTopScore';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { LocalizedNavLink } from '../LocalizedNavLink/LocalizedNavLink';
import { ConsentsBox } from '@barracuda//shared/src/components/ConsentsBox/ConsentsBox';
import { buttonDark } from '@barracuda//shared/src/styles/button';
import { consentsSignUpButtons } from '@barracuda/shared/src/components/JoinWeb/JoinWeb.styles';
import { hasValidConsents } from '@barracuda//shared/src/components/ConsentsBox/utils';
import { ellCommerceInstanceConfig } from '../../utils/ell-commerce';

export const Dashboard = () => {
  const { user, setLoading, setCart, eCommerceCountryISO2, setUser, language } =
    useContext(AppContext);
  const userAgent = navigator.userAgent;
  const isWindows = /Win/i.test(userAgent);
  const [needsKoConsents, setNeedsKoConsents] = useState(false);
  const [consentsAccepted, setConsentsAccepted] = useState<boolean>(false);
  const [consentsDto, setConsentsDto] = useState<{ [p: string]: boolean }>();

  const onConsentsStatusChange = (accepted: boolean, consentsDto: { [p: string]: boolean }) => {
    if (consentsAccepted !== accepted) {
      setConsentsAccepted(accepted);
    }
    setConsentsDto(consentsDto);
  };

  const onCheckboxChanged = (consentsDto: { [p: string]: boolean }) => {
    setConsentsDto(consentsDto);
  };
  const DashboardSidebarStyles = isWindows ? DashboardSidebarScaled : DashboardSidebar;
  const { t } = useTranslation();
  const dashboardItems = [
    { link: '/dashboard', icon: 'dashboard--icon.svg', label: t('menuLabels.dashboard') },
    { link: '/dashboard/results', icon: 'results--icon.svg', label: t('menuLabels.results') },
    {
      link: '/dashboard/account',
      profile: true,
      icon: 'account--icon.svg',
      label: t('menuLabels.account')
    }
  ];

  const getTokenFunction = () => {
    return `Bearer ${sopUtils.getSopToken()}`;
  };

  const addConsentsToProfile = async () => {
    if (consentsDto) {
      const metadata: { [p: string]: string } = {};
      Object.keys(consentsDto).forEach((item) => {
        metadata[item] = consentsDto[item].toString();
      });
      sopClient.addMetadataToProfile(metadata).then((response) => {
        const { data } = response;
        setUser(data);
        setNeedsKoConsents(false);
      });
    }
  };

  useEffect(() => {
    if (user) {
      if (!hasValidConsents(user)) {
        setNeedsKoConsents(true);
      }
      const country =
        countryISO3ToISO2(user.countryOfResidence?.isoCode) || eCommerceCountryISO2 || 'US';
      const eCommerce = ELLCommerce.getInstance({
        ...ellCommerceInstanceConfig,
        defaultCountryISO2: country,
        getTokenFunction: getTokenFunction
      });
      eCommerce
        .CartService()
        .getCartByUser(user?.externalId || '', country)
        .then(
          (r) => {
            r ? setCart(r) : setCart(null);
            setLoading?.(false);
          },
          () => setCart(null)
        )
        .catch((error) => console.log('There was an error during getting cart.', error));
    }
  }, [user]);

  const createListItems = () => {
    return dashboardItems.map((el, index) => {
      return (
        <LocalizedNavLink
          style={({ isActive }) =>
            isActive ? { ...DashboardNavLinkActive, ...DashboardNavLink } : { ...DashboardNavLink }
          }
          end
          to={el.link}
          key={'navLink' + index}
        >
          <ListItem sx={DashboardListItem} key={el.label + index}>
            {!el.profile && <Img src={require(`../../assets/images/${el.icon}`)} />}
            {el.profile && <AccountPhoto imageUri={user?.imageUri} width='30px' height='30px' />}
            <Typography>{el.label}</Typography>
          </ListItem>
        </LocalizedNavLink>
      );
    });
  };
  return (
    <>
      {user && (
        <>
          <Head title='VEC - Dashboard' />
          <Box component='section'>
            <Drawer sx={DashboardSidebarStyles} open={false} variant='permanent'>
              <List sx={DashboardList}>{createListItems()}</List>
            </Drawer>
            <Box sx={DashboardOutlet}>
              <ScalableWrapper>
                <Routes>
                  <Route path='/' element={<DashboardHome />} />
                  <Route path='/results' element={<DashboardResults />} />
                  <Route path='/results/test/:id' element={<DashboardTestDetails />} />
                  <Route path='/results/my-top-score' element={<DashboardMyTopScore />} />
                  <Route path='/results/test/:id/share' element={<ShareResults />} />
                  <Route path='/results/test/:id/share/email-preview' element={<EmailPreview />} />
                  <Route path='/results/my-top-score/:id/share' element={<ShareResults />} />
                  <Route
                    path='/results/my-top-score/:id/share/email-preview'
                    element={<EmailPreview />}
                  />
                  <Route path='/learning' />
                  <Route path='/account' element={<DashboardAccounts />} />
                  <Route path='/help' />
                  <Route path='/explore-tests' element={<ExploreTestsWrapper />} />
                  <Route path='/getting-ready' element={<GettingReadyPageWrapper />} />
                  <Route path='/help-center' element={<HelpCenterWrapper />} />
                  <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                  <Route path='/terms-of-use' element={<TermsOfUse />} />
                  <Route path='/cookie-policy' element={<CookiePolicy />} />
                  <Route path='/shop' element={<ShopWrapper />} />
                  <Route path='/order' element={<OrderWrapper />} />
                </Routes>
              </ScalableWrapper>
              <Outlet />
            </Box>
          </Box>
          {needsKoConsents && (
            <DialogWrapper
              key='koConsentsPopup'
              open
              scrollable
              subtitle={t('signUp.countrySpecificPolicies').toUpperCase()}
              title={t('signUp.beforeProceed')}
            >
              <Stack>
                <Typography sx={{ paddingBottom: '15px' }}>
                  {t('signUp.differentCountryDetected')}
                </Typography>
                <ConsentsBox
                  language={language}
                  onCheckboxChanged={onCheckboxChanged}
                  type='sk'
                  onConsentsStatusChange={onConsentsStatusChange}
                />
                <Box sx={consentsSignUpButtons}>
                  <Button
                    disabled={!consentsAccepted}
                    onClick={addConsentsToProfile}
                    sx={buttonDark}
                  >
                    {t('dashboardPage.SoftProfileAllDoneButton')}
                  </Button>
                </Box>
              </Stack>
            </DialogWrapper>
          )}
        </>
      )}
    </>
  );
};
