import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { AppContext } from 'src/context/App.context';

export const ProtectedRoute: ({ children }: { children: JSX.Element }) => JSX.Element = ({
  children
}) => {
  const { hash } = useLocation();
  const { isLoggedIn } = useContext(AppContext);

  if (!isLoggedIn && hash) {
    return <Navigate state={{ hash: hash }} to='/login' />;
  }
  if (!isLoggedIn) {
    return <Navigate to='/' replace />;
  }
  return children;
};
