import moment from 'moment';
import type { CurrencyInfo } from '../interface/transaction-product.interface';
import { User } from '../interface/user.interface';
import { V2CertificateSkill } from '../interface/mts-report.interface';
import { size, isNil } from 'lodash';

type EnvironmentVariables = keyof typeof CONFIG;
export const CONFIG = {
  ID_VERIFICATION_MIN_HEIGHT: process.env.REACT_APP_ID_VERIFICATION_MIN_HEIGHT,
  ID_VERIFICATION_MIN_WIDTH: process.env.REACT_APP_ID_VERIFICATION_MIN_WIDTH
} as const;

export const isStartTestReady = (user?: User): boolean => {
  if (!user) return false;
  const requiredKeys = ['firstName', 'lastName', 'email', 'countryOfCitizenship'];
  let flag = false;

  for (const key of requiredKeys) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!user[key]) {
      flag = false;
      break;
    } else {
      flag = true;
    }
  }

  return flag;
};

export function isMac(): boolean {
  return navigator.platform.indexOf('Mac') > -1;
}

export const countryISO3ToISO2 = (iso3Country: string) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const getCountryISO2 = require('country-iso-3-to-2');
  return iso3Country?.length === 3 ? getCountryISO2(iso3Country) : iso3Country;
};

export const formatCurrency = (
  amount: number,
  currency: string | CurrencyInfo = 'USD',
  fractionDigits = 0
) => {
  const effectiveCurrencyCode = typeof currency === 'string' ? currency : currency.code;
  const effectiveFractionDigits =
    fractionDigits || typeof currency === 'string' ? fractionDigits : currency.fractionDigits;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: effectiveCurrencyCode
  }).format(amount / 10 ** effectiveFractionDigits);
};

export const formatDate = (date: Date | number | string | undefined, format: string) => {
  return moment(date).isValid() ? moment(date).format(format) : '';
};

export const scrollToElement = (id: string, offset: number = 0) => {
  const element = document.getElementById(id);
  if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};
const getOrDefaultNumber = (environmentVariable: EnvironmentVariables, defaultValue: number) => {
  const readValue = CONFIG[environmentVariable];
  const value = Number(readValue);
  return Number.isNaN(value) ? defaultValue : value;
};

export const idVerificationMinWidth = getOrDefaultNumber('ID_VERIFICATION_MIN_WIDTH', 320);
export const idVerificationMinHeight = getOrDefaultNumber('ID_VERIFICATION_MIN_HEIGHT', 240);

export const hasSkills = (skills: Record<string, V2CertificateSkill>) =>
  !isNil(skills) && size(skills) > 0;

export enum DateFormat {
  DEFAULT = 'DD MMMM yyyy'
}
