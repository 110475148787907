import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  SkillScoreDetailsContainer,
  SkillScoreDetailsHeader,
  SkillScoreDetailsLi,
  SkillScoreDetailsTipsContainer,
  SkillScoreDetailsUl
} from './SkillScoreDetails.styles';
import { SkillScoreGraph } from '../SkillScoreGraph/SkillScoreGraph';
import { useTranslation } from 'react-i18next';
import { V2CertificateSkill } from '../../interface/mts-report.interface';

interface SkillScoreDetailsProps {
  score: string;
  skill: V2CertificateSkill | undefined;
}

export const SkillScoreDetails: FC<SkillScoreDetailsProps> = ({ score, skill }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid sx={SkillScoreDetailsContainer} container spacing={0}>
        <Grid item md={6} xs={12}>
          <Typography sx={SkillScoreDetailsHeader} component='h4'>
            {t('results.skillOverwiev')}
          </Typography>
          <Typography>{skill && skill.description}</Typography>
          <SkillScoreGraph min={10} max={90} score={+score} />
        </Grid>
        <Grid item md={6} xs={12} sx={SkillScoreDetailsTipsContainer}>
          <Typography sx={SkillScoreDetailsHeader} component='h4'>
            {t('results.tipsAndRecommendations')}
          </Typography>
          <Box component='ul' sx={SkillScoreDetailsUl}>
            {skill &&
              skill.tips?.map((el: string, index: number) => (
                <Box key={`${el}${index}`} sx={SkillScoreDetailsLi} component='li'>
                  {el}
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
