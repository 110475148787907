import { FC, useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  AcceptCredlyBadge,
  DialogWrapper,
  IRoute,
  Language,
  MyTopScore,
  MyTopScoreDetailsTile,
  MyTopScoreTable,
  SignInStyles,
  sopClient,
  V2Certificate
} from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { Trans, useTranslation } from 'react-i18next';
import {
  NavbarRouteBoxContainer,
  NavbarRouteBoxLinks,
  NavbarRouteBoxStyle,
  RouteLink
} from '../HeaderNavbar/HeaderNavbar.style';
import ArrowRight from '../../assets/images/arrow-right.svg';
import {
  ResultsModifications,
  ScaleStyles
} from '../DashboardTestDetails/DashboardTestDetails.styles';
import {
  DashboardTestDetailsContainer,
  DashboardTestDetailsSkillScoresTableMobileContainer,
  DownloadButtonAlignMobile
} from '@barracuda/shared/src/components/DashboardTestDetails/DashboardTestDetails.styles';
import { uniqBy } from 'lodash';
import { changeLanguage } from '@i18n';
import {
  DashboardHeaderLeft,
  DashboardHeaderRight,
  DetailsHeader
} from './DashboardMyTopScore.style';
import { useLocalizedNavigate } from 'src/hooks/useLocalizedNavigate';
import { theme } from '@theme';
import { DownloadCertificatePopup } from '../DownloadCertificatePopup/DownloadCertificatePopup';
import { saveAxiosResponseFile } from '../../app.utils';

export const DashboardMyTopScore: FC = () => {
  const [myTopScore, setMyTopScore] = useState<MyTopScore>();
  const [mtsDetails, setMtsDetails] = useState<V2Certificate | null>(null);
  const [downloadCertificateOpened, setDownloadCertificateOpened] = useState(false);
  const navigate = useLocalizedNavigate();
  const { setError, language } = useContext(AppContext);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchMyTopScore();
  }, [language]);

  const fetchMyTopScore = async () => {
    sopClient
      .getMyTopScore(process.env.REACT_APP_MTS_CODE || '')
      .then((response) => {
        if (response?.data) {
          response.data.licenses = uniqBy(response.data.licenses, 'id');
          setMyTopScore(response.data);
          getMtsDetails(i18n.language.toUpperCase() as Language, response.data.id);
        }
      })
      .catch((error) => console.error('There was an error during getting MyTopScore.', error));
  };

  const getMtsDetails = async (lang: Language = 'ENG', id: number) => {
    try {
      const response = await sopClient.getMyTopScoreDetails(id, lang);
      await changeLanguage(lang.toLowerCase());
      const { data }: { data: V2Certificate } = response;
      setMtsDetails(data);
    } catch (e: any) {
      setMtsDetails(null);
    }
  };

  const handleDownloadCertificate = async (lang: Language) => {
    try {
      if (myTopScore?.id) {
        const response = await sopClient.downloadMyTopScoreCertificate(myTopScore.id, lang);
        saveAxiosResponseFile(response);
        setDownloadCertificateOpened(false);
      }
    } catch (e: any) {
      if (e.response.status === 403) {
        const message = <Trans i18nKey='toast.refreshMTSError' components={{ a: <a href='' /> }} />;
        setError({ open: true, message });
        return;
      }
      const responseAsText = await e.response.data.text();
      const { message } = JSON.parse(responseAsText);
      setError({ open: e.response?.status !== 401, message });
    }
  };

  const defaultRoutes: IRoute[] = [
    { label: t('results.allResults'), main: false, redirectTo: `../results` },
    { label: t('results.resultDetails'), main: true, redirectTo: `` }
  ];

  return (
    <>
      <Box sx={[NavbarRouteBoxStyle, ResultsModifications]}>
        <Box sx={NavbarRouteBoxContainer}>
          {defaultRoutes.map((item, index) => {
            return (
              <Box sx={NavbarRouteBoxLinks} key={`routeLink-${index}`}>
                <Typography
                  key={index}
                  onClick={() => navigate?.(item.redirectTo)}
                  sx={{
                    ...RouteLink,
                    fontWeight: item.main ? '600' : '400',
                    cursor: 'pointer',
                    textDecoration: index === 0 ? 'underline' : 'none'
                  }}
                >
                  {item.label}
                </Typography>
                {index !== defaultRoutes.length - 1 && <img width={7} src={ArrowRight} />}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box sx={{ background: theme.palette.common.lightGrey4 }}>
        <Box sx={DetailsHeader}>
          <Box sx={DashboardHeaderLeft}>
            <MyTopScoreDetailsTile
              handleDownloadCertificate={() => setDownloadCertificateOpened(true)}
              myTopScore={myTopScore}
              navigate={navigate}
              mtsDetails={mtsDetails}
            />
          </Box>
          <Box sx={DashboardHeaderRight}>
            <AcceptCredlyBadge anyTestScored />
          </Box>
        </Box>
        <Box
          sx={[
            DashboardTestDetailsContainer,
            DashboardTestDetailsSkillScoresTableMobileContainer,
            { paddingTop: 0 }
          ]}
        >
          <MyTopScoreTable navigate={navigate} myTopScore={myTopScore} />
        </Box>
        <Box
          onClick={() => setDownloadCertificateOpened(true)}
          sx={[SignInStyles.SignInButton, DownloadButtonAlignMobile]}
        >
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M16.5 11.75C16.9142 11.75 17.25 12.0858 17.25 12.5V15.5C17.25 16.7426 16.2426 17.75 15 17.75H3C1.75736 17.75 0.75 16.7426 0.75 15.5V12.5C0.75 12.0858 1.08579 11.75 1.5 11.75C1.91421 11.75 2.25 12.0858 2.25 12.5V15.5C2.25 15.9142 2.58579 16.25 3 16.25H15C15.4142 16.25 15.75 15.9142 15.75 15.5V12.5C15.75 12.0858 16.0858 11.75 16.5 11.75ZM9 0.5C9.41421 0.5 9.75 0.835786 9.75 1.25V9.93934L12.2197 7.46967C12.5126 7.17678 12.9874 7.17678 13.2803 7.46967C13.5732 7.76256 13.5732 8.23744 13.2803 8.53033L9.53033 12.2803C9.38388 12.4268 9.19194 12.5 9 12.5C8.80806 12.5 8.61612 12.4268 8.46967 12.2803L4.71967 8.53033C4.42678 8.23744 4.42678 7.76256 4.71967 7.46967C5.01256 7.17678 5.48744 7.17678 5.78033 7.46967L8.25 9.93934V1.25C8.25 0.835786 8.58579 0.5 9 0.5Z'
              fill={theme.palette.common.lightBlue00}
            />
          </svg>
        </Box>
      </Box>
      <DialogWrapper
        key='downloadCertificatePopup'
        title={t('downloadCertificatePopup.wrapperTitle')}
        subtitle={t('downloadCertificatePopup.myTopScore')}
        open={downloadCertificateOpened}
        handleClose={() => setDownloadCertificateOpened(false)}
        paperRootModifications={ScaleStyles}
        closeable
      >
        <DownloadCertificatePopup handleDownload={handleDownloadCertificate} />
      </DialogWrapper>
    </>
  );
};
