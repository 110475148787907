import { FC, PropsWithChildren } from 'react';
import { Autocomplete, Box, Stack, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  userDataFormContentLabelWithTooltip,
  userDataFormContentTooltip,
  userDataFormContentCountryInputWrapper,
  userDataFormContentError,
  userDataFormContentInputWrapper,
  userDataFormContentInput
} from './UserDataFormContent.styles';
import { User } from '../../interface/user.interface';
import InfoIcon from '../../assets/images/info-icon.svg';
import CalendarImg from '../../assets/images/account-userDataFormContent__calendar.svg';
import { InputLabel } from '../DemographicInfoPopup/DemographicInfoPopup.style';
import { genderOptions } from '../../interface/gender.interfaces';
import { useTranslation } from 'react-i18next';
import { Img } from '../Primitive';
import { UseUserDataFormReturn } from './useUserDataForm';
import { purpleTooltip } from '../../styles/tooltip';
import { EMAIL_REGEXP_PATTERN } from '../../interface/validations';
const nonLetters = /[^\p{L} ]/u;
const lettersOnly = /\p{L}/u;

interface UserDataFormProps extends UseUserDataFormReturn {
  readonly user: User;
  readonly readonly?: boolean;
  readonly testTaken?: boolean;
  readonly invokedFromAccount?: boolean;
}

export const UserDataFormContent: FC<PropsWithChildren<UserDataFormProps>> = ({
  readonly = false,
  testTaken,
  invokedFromAccount,
  date,
  name,
  setName,
  surname,
  setSurname,
  country,
  genderValue,
  countryOfCitizenship,
  countries,
  register,
  formState,
  onSubmit,
  handleDatepickerChange,
  handleGenderAutocompleteChange,
  handleGenderInputChange,
  handleCitizenshipAutocompleteChange,
  handleCitizenshipInputChange,
  handleDatepickerError,
  children
}) => {
  const { t } = useTranslation();

  const CalendarIcon = () => <Img src={CalendarImg} />;

  const citizenshipOptions = countryOfCitizenship ? [countryOfCitizenship] : [];
  const countryOptions = countries.length ? countries : citizenshipOptions;

  return (
    <Grid component='form' container spacing={0} sx={{ flexGrow: 1 }} onSubmit={onSubmit}>
      <Grid item sm={6} xs={12} sx={userDataFormContentInputWrapper}>
        <Box sx={userDataFormContentLabelWithTooltip}>
          <Typography sx={InputLabel}>{t('profilePage.editProfileFormGivenName')}</Typography>
          <Tooltip
            componentsProps={{ tooltip: { sx: purpleTooltip } }}
            placement='right'
            arrow
            title={t('profilePage.editProfileFormGivenNameTooltip')}
          >
            <Img sx={userDataFormContentTooltip} src={InfoIcon} />
          </Tooltip>
        </Box>
        <TextField
          {...register('firstName', {
            required: !invokedFromAccount,
            pattern: lettersOnly
          })}
          inputProps={{ maxLength: 35 }}
          type='text'
          value={name}
          onChange={(e) => setName(e.target.value.replace(nonLetters, ''))}
          disabled={readonly || testTaken}
          id='firstName'
          variant='outlined'
          sx={userDataFormContentInput}
        />
        {formState.errors?.firstName?.type === 'required' && (
          <Typography sx={userDataFormContentError}>{t('fieldIsRequired')}</Typography>
        )}
      </Grid>
      <Grid item sm={6} xs={12} sx={userDataFormContentInputWrapper}>
        <Box sx={userDataFormContentLabelWithTooltip}>
          <Typography sx={InputLabel}>{t('profilePage.editProfileFormSurname')}</Typography>
          <Tooltip
            componentsProps={{ tooltip: { sx: purpleTooltip } }}
            placement='right'
            arrow
            title={t('profilePage.editProfileFormSurnameTooltip')}
          >
            <Img sx={userDataFormContentTooltip} src={InfoIcon} alt='info icon' />
          </Tooltip>
        </Box>
        <TextField
          {...register('lastName', {
            required: !invokedFromAccount,
            pattern: lettersOnly
          })}
          value={surname}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => setSurname(e.target.value.replace(nonLetters, ''))}
          type='text'
          disabled={readonly || testTaken}
          id='lastName'
          variant='outlined'
          sx={userDataFormContentInput}
        />
        {formState.errors?.lastName?.type === 'required' && (
          <Typography sx={userDataFormContentError}>{t('fieldIsRequired')}</Typography>
        )}
      </Grid>
      <Grid item sm={6} xs={12} sx={userDataFormContentInputWrapper}>
        <Typography sx={InputLabel}>{t('profilePage.editProfileFormEmail')}</Typography>
        <TextField
          {...register('email', {
            required: true,
            pattern: {
              value: EMAIL_REGEXP_PATTERN,
              message: t('invalidEmail')
            }
          })}
          type='email'
          disabled
          id='email'
          variant='outlined'
          sx={userDataFormContentInput}
        />
        {formState.errors?.email?.type === 'required' && (
          <Typography sx={userDataFormContentError}>{t('thisIsRequired')}</Typography>
        )}
        {formState.errors?.email?.type === 'pattern' && (
          <Typography sx={userDataFormContentError}>
            {t('thisShouldBeAValidEmailAddress')}
          </Typography>
        )}
      </Grid>
      <Grid item sm={6} xs={12} sx={userDataFormContentInputWrapper}>
        <Typography sx={InputLabel}>{t('profilePage.dateOfBirth')}</Typography>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={date}
            components={{ OpenPickerIcon: CalendarIcon }}
            disabled={readonly || testTaken}
            disableFuture
            onError={handleDatepickerError}
            onChange={handleDatepickerChange}
            renderInput={(params) => (
              <Stack direction='row'>
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                    placeholder: 'Pick a date ...'
                  }}
                  sx={[userDataFormContentInput, { marginBottom: 0 }]}
                  {...register('dateOfBirth')}
                />
              </Stack>
            )}
          />
          {formState.errors?.dateOfBirth?.type === 'required' && (
            <Typography sx={userDataFormContentError}>{t('fieldIsRequired')}</Typography>
          )}
          {formState.errors?.dateOfBirth?.types?.minDate && (
            <Typography sx={userDataFormContentError}>
              {formState.errors?.dateOfBirth?.types?.minDate}
            </Typography>
          )}
          {formState.errors?.dateOfBirth?.types?.invalidDate && (
            <Typography sx={userDataFormContentError}>
              {formState.errors?.dateOfBirth?.types?.invalidDate}
            </Typography>
          )}
          {formState.errors?.dateOfBirth?.types?.maxDate && (
            <Typography sx={userDataFormContentError}>
              {formState.errors?.dateOfBirth?.types?.maxDate}
            </Typography>
          )}
        </LocalizationProvider>
      </Grid>
      <Grid item sm={6} xs={12} sx={userDataFormContentCountryInputWrapper}>
        {!invokedFromAccount && (
          <>
            <Box sx={userDataFormContentLabelWithTooltip}>
              <Typography sx={InputLabel}>{t('profilePage.countryOfCitizenship')}</Typography>
              <Tooltip
                componentsProps={{ tooltip: { sx: purpleTooltip } }}
                placement='right'
                arrow
                title={t('profilePage.countryOfCitizenshipTooltip')}
              >
                <Img sx={userDataFormContentTooltip} src={InfoIcon} alt='info icon' />
              </Tooltip>
            </Box>
            <Autocomplete
              disablePortal={false}
              forcePopupIcon={false}
              id='countryInput'
              options={countryOptions}
              getOptionLabel={(option) => option.name}
              disabled={readonly}
              onInputChange={handleCitizenshipInputChange}
              onChange={handleCitizenshipAutocompleteChange}
              value={country}
              renderInput={(params) => (
                <>
                  <TextField
                    sx={userDataFormContentInput}
                    {...register('citizenship', {
                      value: countryOfCitizenship?.name || undefined,
                      required: true
                    })}
                    {...params}
                    disabled={readonly}
                    placeholder={t('profilePage.startTypingPlaceholder')}
                  />
                </>
              )}
            />
            {formState.errors?.citizenship?.type === 'required' && (
              <Typography sx={userDataFormContentError}>{t('fieldIsRequired')}</Typography>
            )}
          </>
        )}
        {invokedFromAccount && (
          <>
            <Typography sx={InputLabel}>{t('profilePage.gender')}</Typography>
            <Autocomplete
              {...register('gender', {
                value: genderValue?.id
              })}
              disablePortal={false}
              disabled={readonly}
              id='genderInput'
              options={genderOptions}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.name === value.id}
              sx={userDataFormContentInput}
              onInputChange={handleGenderInputChange}
              onChange={handleGenderAutocompleteChange}
              value={genderValue || null}
              renderInput={(params) => <TextField {...params} />}
            />
          </>
        )}
      </Grid>
      {children}
    </Grid>
  );
};
