import { FC } from 'react';
import {
  LatestTestDetailsTile,
  License,
  SkillsTile,
  SkillType,
  V2CertificateSkill
} from '../../index';
import { Box, Grid, Stack, Typography } from '@mui/material';
import {
  DashboardTestDetailsContainer,
  DashboardTestDetailsReasonContainer,
  ReasonBackground,
  ReasonBox,
  ReasonSectionTitle
} from '../DashboardTestDetails/DashboardTestDetails.styles';
import { NavigateFunction } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IncompleteTileImg from '../../assets/images/incomplete-tile.svg';
import RuleViolationTileImg from '../../assets/images/rule-violation-tile.svg';
import ErrorTileImg from '../../assets/images/error-tile.svg';
import {
  DetailsHeader,
  SkillsTileVisibility,
  TestTileResponsive
} from './DashboardTestDetailsCertificationFailed.styles';
import { theme } from '../../styles/theme';

interface DashboardTestDetailsCertificationFailedProps {
  navigate: NavigateFunction;
  license: License;
  skills: Record<string, V2CertificateSkill>;
  findSkillByName?: (name: SkillType) => V2CertificateSkill | undefined;
  retakeTest: () => void;
  daysToExpire: number | undefined;
  resolutionsList: JSX.Element[];
  openScorePopup: () => void;
}

export const DashboardTestDetailsCertificationFailed: FC<
  DashboardTestDetailsCertificationFailedProps
> = ({
  navigate,
  license,
  skills,
  retakeTest,
  daysToExpire,
  resolutionsList,
  openScorePopup,
  findSkillByName
}) => {
  const { t } = useTranslation();
  const imagesMap = new Map([
    ['LATE_HANGUP', IncompleteTileImg],
    ['ABORTED', RuleViolationTileImg],
    ['ERROR', ErrorTileImg]
  ]);
  const imgOnly = Array.from(imagesMap.keys()).includes(license?.status);

  return (
    <Stack sx={{ background: theme.palette.common.lightGrey4 }}>
      <Box sx={DetailsHeader}>
        <Box sx={TestTileResponsive}>
          <LatestTestDetailsTile
            license={license}
            navigate={navigate}
            skills={skills}
            findSkillByName={findSkillByName}
            retakeTest={retakeTest}
            daysToExpire={daysToExpire}
          />
        </Box>
        <Box sx={SkillsTileVisibility}>
          <SkillsTile
            image={imagesMap.get(license?.status)}
            mode={imgOnly ? 'IMG_ONLY' : 'NO_SCORES'}
            openScorePopup={openScorePopup}
          />
        </Box>
      </Box>
      {license.status !== 'NOT_SCORABLE' && resolutionsList.length > 0 && (
        <Grid
          container
          spacing={12}
          sx={[DashboardTestDetailsContainer, ReasonBackground, { marginTop: 0 }]}
        >
          <Grid item md={6} sm={12} xs={12} sx={DashboardTestDetailsReasonContainer}>
            <Stack>
              <Typography sx={ReasonSectionTitle}>
                {t('results.reasonsforDeniedCertification')}
              </Typography>
              <Stack sx={ReasonBox}>
                {resolutionsList && resolutionsList.length > 0 && resolutionsList}
                {!resolutionsList || (resolutionsList.length === 0 && 'No reasons found.')}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};
