import { theme } from '@theme';

export const SharedScoreWrapper = {
  padding: '50px',
  backgroundColor: theme.palette.common.lightGrey4,
  minHeight: 'calc(100vh - 350px)',
  '@media screen and (max-width: 1200px) and (min-width: 1025px)': {
    padding: '46px'
  },
  '@media screen and (max-width: 1024px) and (min-width: 745px)': {
    padding: '40px'
  },
  '@media screen and (max-width: 744px) and (min-width: 431px)': {
    padding: '30px'
  },
  '@media screen and (max-width: 430px) and (min-width: 400px)': {
    padding: '20px'
  },
  '@media screen and (max-width: 399px)': {
    padding: '25px 10px 10px 10px'
  }
};

export const SharedScoreContainer = {
  maxWidth: '1315px',
  margin: 'auto'
};
