import { FC, useState } from 'react';
import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import emojiFrown from '../../assets/images/bi_emoji-frown.svg';
import emojiLaughing from '../../assets/images/bi_emoji-laughing.svg';
import emojiNeutral from '../../assets/images/bi_emoji-neutral.svg';
import emojiSmile from '../../assets/images/bi_emoji-smile.svg';
import emojiHeartEyes from '../../assets/images/bi_emoji-heart-eyes.svg';
import star from '../../assets/images/star.png';
import { useTranslation } from 'react-i18next';
import {
  buttonStyle,
  headerCloseIcon,
  headerStyle,
  modalStyle,
  ratingCaption,
  ratingContainer,
  ratingWrapper,
  subHeader2Style,
  subHeaderStyle,
  textFieldLabel
} from './FeedbackSurveyModal.styles';
import { Img } from '../Primitive';
import CloseIcon from '@mui/icons-material/Close';

const emojiMap = {
  1: emojiFrown,
  2: emojiNeutral,
  3: emojiSmile,
  4: emojiLaughing,
  5: emojiHeartEyes
};

interface StarRatingSurveyProps {
  readonly isOpen: boolean;
  readonly onSubmitted?: () => void;
  readonly onSubmit: (rating: string, comment: string) => void;
  readonly skipSubmittedState?: boolean;
  readonly upperTitle: string;
  readonly title: string;
  readonly subTitle: string;
}

export const StarRatingSurvey: FC<StarRatingSurveyProps> = ({
  isOpen,
  onSubmitted,
  onSubmit,
  skipSubmittedState = false,
  upperTitle,
  title,
  subTitle
}) => {
  const [rating, setRating] = useState(0);
  const [tempRating, setTempRating] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [comment, setComment] = useState('');

  const { t } = useTranslation();

  const handleMouseEnter = (num: number) => {
    setTempRating(num);
    setIsHover(true);
  };

  const isStarActive = (orderNumber: number, _rating: number) => {
    if (orderNumber <= _rating) {
      return 'unset';
    }
    return 'luminosity';
  };

  const handleSubmit = () => {
    onSubmit(rating.toString(), comment);
    skipSubmittedState ? onSubmitted?.() : setSubmitted(true);
  };

  const handleClose = () => {
    onSubmit(rating.toString(), comment);
    setSubmitted(true);
    onSubmitted?.();
  };

  const showingAnyRating = (isHover && tempRating !== 0) || rating !== 0;

  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={modalStyle}>
        <IconButton aria-label='close' onClick={handleClose} sx={headerCloseIcon}>
          <CloseIcon />
        </IconButton>
        <Typography sx={subHeaderStyle} id='modal-modal-description'>
          {upperTitle}
        </Typography>
        <Typography sx={headerStyle} id='modal-modal-title'>
          {submitted ? t('survey.headerAlternative') : title}
        </Typography>
        {!submitted && <Typography sx={subHeader2Style}>{subTitle}</Typography>}
        <Box sx={{ maxWidth: '370px', margin: 'auto' }}>
          <Box sx={ratingWrapper}>
            <Box>
              {showingAnyRating ? (
                <Img
                  src={
                    emojiMap[
                      isHover
                        ? (tempRating as keyof typeof emojiMap)
                        : (rating as keyof typeof emojiMap)
                    ]
                  }
                  sx={
                    submitted
                      ? { width: '87px', height: '87px', marginBottom: '20px', marginTop: '70px' }
                      : {}
                  }
                />
              ) : (
                <Box sx={{ height: '64px' }} />
              )}
            </Box>
            <Box sx={ratingContainer}>
              {Object.entries(emojiMap).map(([key]) => (
                <Img
                  id={`survey-rating-star-${key}`}
                  key={key}
                  src={star}
                  onClick={() => !submitted && setRating(Number(key))}
                  onMouseEnter={() => !submitted && handleMouseEnter(Number(key))}
                  onMouseLeave={() => !submitted && setIsHover(false)}
                  sx={{
                    mixBlendMode: isStarActive(Number(key), isHover ? tempRating : rating),
                    padding: '2.5px',
                    '&:hover': !submitted
                      ? {
                          cursor: 'pointer'
                        }
                      : {}
                  }}
                />
              ))}
            </Box>
            {showingAnyRating ? (
              <Typography sx={submitted ? { ...ratingCaption, marginTop: '35px' } : ratingCaption}>
                {t(
                  `survey.caption${
                    isHover
                      ? (tempRating as keyof typeof emojiMap)
                      : (rating as keyof typeof emojiMap)
                  }`
                )}
              </Typography>
            ) : (
              <Box sx={{ height: '32px' }} />
            )}
          </Box>
          <Box>
            {!submitted && (
              <>
                <Typography sx={textFieldLabel}>{t('survey.textFieldLabel')}</Typography>
                <TextField
                  sx={{ marginTop: '8px', marginBottom: '40px' }}
                  fullWidth
                  minRows={3}
                  multiline
                  maxRows={3}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </>
            )}
            <Box
              sx={{ display: 'flex', flexDirection: 'row', marginTop: submitted ? '63px' : 'none' }}
            >
              <Button
                id='survey-submit'
                sx={buttonStyle}
                disabled={rating === 0}
                onClick={submitted ? onSubmitted : handleSubmit}
              >
                {submitted ? t('survey.buttonAlternative') : t('survey.button')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
