import { FC, useContext } from 'react';
import { AppContext } from '../../context/App.context';
import { H3 } from '../GettingReadyPage/GettingReadyPage.styles';
import { Slider } from '@barracuda/shared/src/components/Slider/Slider';
import { GettingReadyPageVideoBox } from '@barracuda/shared/src/components/GettingReadyPageVideoBox/GettingReadyPageVideoBox';
import gettingReadyForTestCover from '@sharedImages/getting-ready-for-test-cover.png';
import gettingReadyForTestCoverJpn from '@sharedImages/getting-ready-for-test-cover-jpn.png';
import gettingReadyForTestCoverKor from '@sharedImages/getting-ready-for-test-cover-kor.png';
import takingATestCover from '@sharedImages/taking-a-test-cover.png';
import takingATestCoverJpn from '@sharedImages/taking-a-test-cover-jpn.png';
import takingATestCoverKor from '@sharedImages/taking-a-test-cover-kor.png';
import understandingResultsCover from '@sharedImages/understanding-results-cover.png';
import understandingResultsCoverJpn from '@sharedImages/understanding-results-cover-jpn.png';
import understandingResultsCoverKor from '@sharedImages/understanding-results-cover-kor.png';
import sharingResultsCover from '@sharedImages/sharing-results-cover.png';
import sharingResultsCoverJpn from '@sharedImages/sharing-results-cover-jpn.png';
import sharingResultsCoverKor from '@sharedImages/sharing-results-cover-kor.png';
import { Grid, Typography, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { slider } from './HowToGuidesResource.styles';

export interface HowToGuidesResourceProps {
  readonly sx?: SxProps<Theme>;
  readonly openVideoModal: (groupName: string, index: number) => void;
}

export const HowToGuidesResource: FC<HowToGuidesResourceProps> = ({ sx, openVideoModal }) => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);

  const chooseElementByLanguage = (eng: string, jpn: string, kor: string) => {
    switch (language) {
      case 'JPN':
        return jpn;

      case 'KOR':
        return kor;
    }

    return eng;
  };

  return (
    <Grid container sx={sx}>
      <Grid item xs={12} id='how-to-guides'>
        <Typography component='h2' sx={H3}>
          {t('gettingReadyPage.howToHeader')}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ position: 'relative' }}>
        <Slider
          shadowColor='4px 0 20px 0 rgba(0, 0, 0, 0.05);'
          sx={slider}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 15
            },
            600: {
              slidesPerView: 1.2,
              spaceBetween: 15
            },
            900: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            1200: {
              slidesPerView: 3.5,
              spaceBetween: 20
            }
          }}
          items={[
            <GettingReadyPageVideoBox
              image={chooseElementByLanguage(
                gettingReadyForTestCover,
                gettingReadyForTestCoverJpn,
                gettingReadyForTestCoverKor
              )}
              title={t('gettingReadyPage.howToVideo1Title') as string}
              subtitle={t('gettingReadyPage.howToVideo1Subtitle') as string}
              handleClick={() => openVideoModal('howToGuides', 0)}
            />,
            <GettingReadyPageVideoBox
              image={chooseElementByLanguage(
                takingATestCover,
                takingATestCoverJpn,
                takingATestCoverKor
              )}
              title={t('gettingReadyPage.howToVideo2Title') as string}
              subtitle={t('gettingReadyPage.howToVideo2Subtitle') as string}
              handleClick={() => openVideoModal('howToGuides', 1)}
            />,
            <GettingReadyPageVideoBox
              image={chooseElementByLanguage(
                understandingResultsCover,
                understandingResultsCoverJpn,
                understandingResultsCoverKor
              )}
              title={t('gettingReadyPage.howToVideo3Title') as string}
              subtitle={t('gettingReadyPage.howToVideo3Subtitle') as string}
              handleClick={() => openVideoModal('howToGuides', 2)}
            />,
            <GettingReadyPageVideoBox
              image={chooseElementByLanguage(
                sharingResultsCover,
                sharingResultsCoverJpn,
                sharingResultsCoverKor
              )}
              title={t('gettingReadyPage.howToVideo4Title') as string}
              subtitle={t('gettingReadyPage.howToVideo4Subtitle') as string}
              handleClick={() => openVideoModal('howToGuides', 3)}
            />
          ]}
        />
      </Grid>
    </Grid>
  );
};
