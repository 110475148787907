import { theme } from '../../styles/theme';

export const AgeRestrictionContainer = {
  '*': {
    color: theme.palette.common.darkGrey1
  }
};
export const AgeRestrictionTitle = {
  fontSize: '36px',
  fontWeight: 800,
  paddingBottom: '15px'
};
export const AgeRestrictionDescription = {
  fontSize: '16px',
  fontWeight: 400,
  paddingBottom: '25px'
};
export const RestrictionNote = {
  fontWeight: 600,
  paddingBottom: '22px'
};
export const ImageContainer = {
  display: 'flex',
  justifyContent: 'center'
};
