import { FC, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import unableToCertifyIcon from '../../assets/images/unable-to-certify--icon.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  accordionMatchingRow,
  exceptionIcon,
  ScoreContainer,
  scoreTextStyle,
  SkillScoresTableAccordion,
  SkillScoresTableAccordionSummary,
  SkillScoresTableContainer,
  TestIdStyles
} from './SkillScoresTable.styles';
import { TestsTableTHead, TestsTableTHeadCell } from '../TestsTable/TestsTable.styles';
import { SkillScoreDetails } from '../SkillScoreDetails/SkillScoreDetails';
import moment from 'moment';
import { License } from '../../interface/licenses.interfaces';
import { useTranslation } from 'react-i18next';
import { V2CertificateSkill } from '../../interface/mts-report.interface';
import { entries, map, omit, values } from 'lodash';
import { SkillScoreTable } from '../SkillScoreTable/SkillScoreTable';
import { Img } from '../Primitive';
import { theme } from '../../styles/theme';

interface ISkillScoresTableProps {
  license: License;
  skills: Record<string, V2CertificateSkill>;
  findExceptionByName?: (name: string) => string;
  insidePopup?: boolean;
  findCefrScoreBySkill: (skillName: string) => string | undefined;
}

export const SkillScoresTable: FC<ISkillScoresTableProps> = ({
  license,
  skills,
  findExceptionByName,
  insidePopup,
  findCefrScoreBySkill
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const matchesMidWidth = useMediaQuery('(max-width:1024px)');
  const tableHeaders: string[] = values(
    t('results.skillScoresTableHeaders', { returnObjects: true })
  );
  const isNotDateHeader = (item: string) => item !== tableHeaders[2];
  const isNotIdHeader = (item: string) => item !== tableHeaders[1];

  const renderTableHeader = useMemo(() => {
    return matchesMidWidth || insidePopup
      ? tableHeaders.filter((i) => isNotDateHeader(i) && isNotIdHeader(i))
      : tableHeaders;
  }, [matchesMidWidth, tableHeaders]);

  const skillsFiltered: Record<string, V2CertificateSkill> = omit(skills, 'Overall Score');

  const findSkillByName = (name: string): V2CertificateSkill | undefined => {
    return skills[name];
  };

  return (
    <>
      {license && skills && (
        <Box className='scores-container' sx={SkillScoresTableContainer(insidePopup)}>
          <SkillScoreTable insidePopup open={open} setOpen={setOpen} />
          {matchesMidWidth && !insidePopup && (
            <Typography sx={TestIdStyles}>
              {t('testID')}: <Typography component='span'>{license.testCode}</Typography>
            </Typography>
          )}
          <TableContainer>
            <Table>
              <TableHead sx={TestsTableTHead}>
                <TableRow>
                  {renderTableHeader.map((el, index) => (
                    <TableCell key={`${el}${index}`} sx={TestsTableTHeadCell}>
                      {el as string}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {!matchesMidWidth && !insidePopup && (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ borderBottom: 0, fontWeight: 600 }}>
                      {license.product.name}
                    </TableCell>
                    {!matchesMidWidth && (
                      <>
                        <TableCell sx={{ borderBottom: 0 }}>{license.testCode}</TableCell>
                        <TableCell sx={{ borderBottom: 0 }}>
                          {moment(license.endTime).format('MMMM DD, yyyy')}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box sx={{ marginTop: '10px' }}>
            {map(entries(skillsFiltered), ([skillKey, skill], index) => {
              return (
                <Accordion sx={SkillScoresTableAccordion} key={`${skill.component}${index}`}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={SkillScoresTableAccordionSummary}
                    id={skill.component}
                  >
                    <Box sx={ScoreContainer}>
                      <Box sx={accordionMatchingRow}>
                        {skill.label}
                        {license.status === 'PARTIALLY_SCORED' &&
                          findExceptionByName?.(skillKey) && (
                            <Tooltip
                              placement='right'
                              arrow
                              title={findExceptionByName?.(skillKey) || ''}
                            >
                              <Img style={exceptionIcon} src={unableToCertifyIcon} />
                            </Tooltip>
                          )}
                      </Box>
                      {!matchesMidWidth && (
                        <>
                          <Box sx={accordionMatchingRow}></Box>
                          <Box sx={accordionMatchingRow}></Box>
                        </>
                      )}

                      <Box sx={accordionMatchingRow}>
                        <Typography sx={scoreTextStyle(!!findExceptionByName?.(skillKey))}>
                          {skill.score}
                        </Typography>
                      </Box>
                      <Box sx={accordionMatchingRow}>
                        <Typography sx={{ fontWeight: 700 }}>
                          {findCefrScoreBySkill(skill.label)}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ border: `2px solid ${theme.palette.common.lightBlue0}` }}>
                    <SkillScoreDetails
                      score={skill.score?.toString() || ''}
                      skill={findSkillByName(skillKey)}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};
