export const DetailsHeader = {
  padding: '70px',
  display: 'flex',
  gap: '25px',
  justifyContent: 'center',
  '@media screen and (max-width: 1240px)': {
    padding: '70px 30px'
  },
  '@media screen and (max-width: 745px)': {
    paddingBottom: '10px'
  },
  '@media screen and (max-width: 430px)': {
    paddingBottom: '10px !important',
    padding: '70px 10px'
  }
};
export const SkillsTileVisibility = {
  width: '45%',
  maxWidth: '542px',
  '@media screen and (max-width: 1024px)': {
    display: 'none'
  }
};
export const SkillsTileVisibilityMobile = {
  width: '50%',
  maxWidth: '536px',
  '@media screen and (min-width: 1025px)': {
    display: 'none'
  },
  '@media screen and (max-width: 745px)': {
    width: '100%',
    maxWidth: 'unset'
  },
  '@media screen and (max-width: 600px)': {
    width: '100%'
  }
};
export const TestTileResponsive = {
  width: '55%',
  maxWidth: '757px',
  '@media screen and (max-width: 1024px)': {
    width: '100%',
    maxWidth: 'unset'
  }
};
