import { FC } from 'react';
import { Box, Link, Typography } from '@mui/material';
import {
  BorderLight,
  FW600,
  MB10,
  MB20,
  MB40,
  Padding20,
  PrivacyPolicyContentHeader,
  PrivacyPolicyContentParagraph,
  PrivacyPolicyItalic
} from './PrivacyPolicyContent.styles';
import { theme } from '../../styles/theme';

interface PrivacyPolicyContentProps {
  t: (key: string) => string;
  language: string;
}

export const PrivacyPolicyContent: FC<PrivacyPolicyContentProps> = ({ t, language }) => {
  return (
    <>
      <Box
        sx={{
          fontFamily:
            language === 'jpn'
              ? '"Noto Sans JP", sans-serif !important'
              : '"Noto Sans", sans-serif !important',
          '> *': {
            fontFamily:
              language === 'jpn'
                ? '"Noto Sans JP", sans-serif !important'
                : '"Noto Sans", sans-serif !important'
          }
        }}
      >
        <Typography sx={PrivacyPolicyContentHeader} component='h2'>
          {t('privacyPolicy.content.header')}
        </Typography>
        <Typography
          sx={[PrivacyPolicyContentParagraph, MB40]}
          dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.description') }}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, FW600, MB10]} component='h3'>
          {t('privacyPolicy.content.1.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.1.paragraph1')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, FW600]}>
          {t('privacyPolicy.content.1.paragraph2')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('privacyPolicy.content.1.paragraph3')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph]}>
          {t('privacyPolicy.content.1.paragraph4Part1')}
          <Link
            href={`mailto:${t('privacyPolicy.content.1.paragraph4Link')}`}
            sx={[{ textDecoration: 'none', color: theme.palette.common.darkBlue7 }, FW600]}
          >
            {t('privacyPolicy.content.1.paragraph4Link')}
          </Link>
          {t('privacyPolicy.content.1.paragraph4Part2')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.1.paragraph5')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, FW600, MB20]} component='h3'>
          {t('privacyPolicy.content.2.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph]}>
          {t('privacyPolicy.content.2.paragraph1')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph]}>
          {t('privacyPolicy.content.2.paragraph2')}
        </Typography>
        <Box component='ul'>
          <Box component='li' sx={[MB10]}>
            {t('privacyPolicy.content.2.bullet1')}
          </Box>
          <Box component='li' sx={[MB10]}>
            {t('privacyPolicy.content.2.bullet2')}
          </Box>
          <Box component='li' sx={[MB10]}>
            {t('privacyPolicy.content.2.bullet3')}
          </Box>
          <Box component='li' sx={[MB10]}>
            {t('privacyPolicy.content.2.bullet4')}
          </Box>
          <Box component='li' sx={[MB10]}>
            {t('privacyPolicy.content.2.bullet5')}
          </Box>
          <Box component='li' sx={[MB20]}>
            {t('privacyPolicy.content.2.bullet6')}
          </Box>
        </Box>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.2.paragraph3')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, FW600, MB20]} component='h3'>
          {t('privacyPolicy.content.3.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB10]}>
          {t('privacyPolicy.content.3.paragraph1')}
        </Typography>
        <Box component='ul'>
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.3.bullet1') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.3.bullet2') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.3.bullet3') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.3.bullet4') }}
          />
        </Box>
        <Typography
          sx={[PrivacyPolicyContentParagraph, MB20]}
          dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.3.paragraph2') }}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, FW600, MB20]} component='h3'>
          {t('privacyPolicy.content.4.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.4.paragraph1')}
        </Typography>
        <Box component='ul'>
          <Box
            component='li'
            sx={[MB10]}
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.4.bullet1') }}
          />
          <Box
            component='li'
            sx={[MB10]}
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.4.bullet2') }}
          />
          <Box
            component='li'
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.4.bullet3') }}
          />
        </Box>
        <Typography
          sx={[PrivacyPolicyContentParagraph, MB20, PrivacyPolicyItalic]}
          dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.4.paragraph2') }}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, FW600, MB20]} component='h3'>
          {t('privacyPolicy.content.5.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.5.paragraph1')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.5.paragraph2')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, FW600, MB20]} component='h3'>
          {t('privacyPolicy.content.6.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, FW600, PrivacyPolicyItalic]}>
          {t('privacyPolicy.content.6.paragraph1')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.6.paragraph2')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, FW600, PrivacyPolicyItalic]}>
          {t('privacyPolicy.content.6.paragraph3')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.6.paragraph4')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, FW600, PrivacyPolicyItalic]}>
          {t('privacyPolicy.content.6.paragraph5')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.6.paragraph6')}
        </Typography>
        <Box component='ul'>
          <Box component='li'>{t('privacyPolicy.content.6.bullet1')}</Box>
          <Box component='li'>{t('privacyPolicy.content.6.bullet2')}</Box>
          <Box component='li'>{t('privacyPolicy.content.6.bullet3')}</Box>
          <Box component='li'>{t('privacyPolicy.content.6.bullet4')}</Box>
          <Box component='li'>{t('privacyPolicy.content.6.bullet5')}</Box>
        </Box>
        <Typography sx={[PrivacyPolicyContentParagraph, FW600, PrivacyPolicyItalic]}>
          {t('privacyPolicy.content.6.paragraph7')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.6.paragraph8')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.6.paragraph9')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, FW600, MB20]} component='h3'>
          {t('privacyPolicy.content.7.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.7.paragraph1')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.7.paragraph2')}
        </Typography>
        <Box sx={[Padding20, BorderLight]}>
          <Typography
            sx={[PrivacyPolicyContentParagraph]}
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.7.paragraph3') }}
          />
        </Box>
        <Box component='ul'>
          <Box
            component='li'
            sx={[MB10]}
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.7.bullet1') }}
          />
          <Box
            component='li'
            sx={[MB10]}
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.7.bullet2') }}
          />
          <Box
            component='li'
            sx={[MB10]}
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.7.bullet3') }}
          />
          <Box
            component='li'
            sx={[MB10]}
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.7.bullet4') }}
          />
          <Box
            component='li'
            sx={[MB10]}
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.7.bullet5') }}
          />
          <Box
            component='li'
            sx={[MB20]}
            dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content.7.bullet6') }}
          />
        </Box>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.7.paragraph4')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.7.paragraph5')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.7.paragraph6')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, FW600, MB20]} component='h3'>
          {t('privacyPolicy.content.8.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('privacyPolicy.content.8.paragraph1')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph]}>
          {t('privacyPolicy.content.8.paragraph2')}
        </Typography>
      </Box>
    </>
  );
};
