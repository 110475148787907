import { Box, Typography, SxProps, Theme } from '@mui/material';
import {
  triangle,
  triangleContainer,
  videoContainer,
  textsContainer,
  title as titleStyle,
  container,
  subtitleStyle
} from './GettingReadyPageVideoBox.styles';
import { FC } from 'react';
import { Img } from '../Primitive';

interface GettingReadyPageVideoBoxProps {
  handleClick: () => void;
  sx?: SxProps<Theme>;
  title?: string;
  subtitle?: string;
  image?: string;
}

export const GettingReadyPageVideoBox: FC<GettingReadyPageVideoBoxProps> = ({
  handleClick,
  sx = {},
  title,
  subtitle,
  image
}) => {
  return (
    <Box sx={container} onClick={handleClick}>
      <Box sx={{ ...sx, ...videoContainer }}>
        <Box sx={triangleContainer}>
          {image && <Img src={image} />}
          <Box sx={triangle} />
        </Box>
        <Box sx={textsContainer}>
          <Typography sx={titleStyle}>{title}</Typography>
          <Typography sx={subtitleStyle}>{subtitle}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
