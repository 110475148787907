import { FC } from 'react';
import {
  BasicInformationBoxContainer,
  BasicInformationBoxHeader,
  BasicInformationBoxSectionContainer
} from '../BasicInformationBox/BasicInformationBox.styles';
import { Typography, Box, Grid } from '@mui/material';
import { SecurityAndPrivacyBoxLink } from './SecurityAndPrivacyBox.styles';
import { useTranslation } from 'react-i18next';

interface SecurityAndPrivacyBoxProps {
  navigate: (to: string) => void;
  setChangePasswordOpened: (value: boolean) => void;
}
export const SecurityAndPrivacyBox: FC<SecurityAndPrivacyBoxProps> = ({
  navigate,
  setChangePasswordOpened
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ ...BasicInformationBoxContainer, paddingTop: 0 }}>
      <Box sx={{ ...BasicInformationBoxSectionContainer, position: 'relative' }}>
        <Typography sx={BasicInformationBoxHeader} component='h2'>
          {t('profilePage.securityAndPrivacy')}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid container spacing={0} sx={{ maxWidth: '782px' }}>
            <Grid item xs={12}>
              <Box
                sx={SecurityAndPrivacyBoxLink}
                role='link'
                onClick={() => setChangePasswordOpened(true)}
              >
                <Typography sx={{ fontWeight: 600 }}>
                  {t('profilePage.managePasswordHeader')}
                </Typography>
                <Typography>{t('profilePage.managePasswordText')}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={SecurityAndPrivacyBoxLink}
                role='link'
                onClick={() => navigate('privacy-policy')}
              >
                <Typography sx={{ fontWeight: 600 }}>
                  {t('profilePage.privacyPolicyHeader')}
                </Typography>
                <Typography>{t('profilePage.privacyPolicyText')}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={SecurityAndPrivacyBoxLink}
                role='link'
                onClick={() => navigate('terms-of-use')}
              >
                <Typography sx={{ fontWeight: 600 }}>
                  {t('profilePage.termsOfUseHeader')}
                </Typography>
                <Typography>{t('profilePage.termsOfUseText')}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
