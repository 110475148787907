import { FC } from 'react';
import {
  scoreReportViewCertificateHeader,
  scoreReportViewCertificateHeaderData,
  scoreReportViewCertificateHeaderImage,
  scoreReportViewCertificateHeaderImagePhoto,
  scoreReportViewPillStyles,
  scoreReportViewTestIdBox,
  scoreReportViewTestIdText,
  scoreReportViewTestIdValue,
  scoreReportViewUsernameText
} from './ScoreReportView.style';
import { Box, Stack, Typography } from '@mui/material';
import { DateFormat, formatDate } from '../../../../shared/src/utils/utils';
import { useTranslation } from 'react-i18next';
import { AccountPhoto } from '../../../../shared/src/components/AccountPhoto';
import SpeakingImage from '../../assets/images/speaking-cert.svg';
import ReadingImage from '../../assets/images/reading-cert.svg';
import { ScoreReportDTO, V2Certificate } from '@barracuda/shared/src';
import { ScoreReportMode } from './ScoreReportViewSkillScoresSection';
import { Img } from '@barracuda/shared/src/components/Primitive';

export interface ScoreReportViewOverallHeaderProps {
  reportMode: ScoreReportMode;
  certificateData: ScoreReportDTO | null;
  mtsData?: V2Certificate;
  testType: 'speaking' | 'reading' | null;
}
export const ScoreReportViewOverallHeader: FC<ScoreReportViewOverallHeaderProps> = ({
  reportMode,
  mtsData,
  certificateData,
  testType
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={scoreReportViewCertificateHeader(reportMode === 'mts')}>
      <Box sx={scoreReportViewCertificateHeaderImage}>
        <AccountPhoto
          sx={scoreReportViewCertificateHeaderImagePhoto}
          imageUri={certificateData?.testTaker?.imageUri}
          width='132px'
          height='143px'
          rectangular
        />
      </Box>
      <Box sx={[scoreReportViewCertificateHeaderData, reportMode === 'mts' ? { flex: 0.7 } : {}]}>
        <Typography sx={scoreReportViewUsernameText}>
          {certificateData?.testTaker?.firstName + ' ' + certificateData?.testTaker?.lastName}
        </Typography>
        <Box sx={scoreReportViewTestIdBox}>
          <Typography sx={scoreReportViewTestIdText}>{t('testTakerId')}:</Typography>
          <Typography sx={scoreReportViewTestIdValue}>
            {certificateData?.testTaker?.externalId}
          </Typography>
        </Box>
        <Box sx={scoreReportViewTestIdBox}>
          <Typography sx={scoreReportViewTestIdText}>{t('date')}:</Typography>
          <Typography sx={scoreReportViewTestIdValue}>
            {reportMode === 'two-skills'
              ? formatDate(certificateData?.license?.certificate?.issuedAt, DateFormat.DEFAULT)
              : formatDate(mtsData?.issuedAt, DateFormat.DEFAULT)}
          </Typography>
        </Box>
        <Box sx={scoreReportViewTestIdBox}>
          <Typography sx={scoreReportViewTestIdText}>{t('expirationDate')}:</Typography>
          <Typography sx={scoreReportViewTestIdValue}>
            {reportMode === 'two-skills'
              ? formatDate(certificateData?.license?.certificate?.validTo, DateFormat.DEFAULT)
              : formatDate(mtsData?.expiresAt, DateFormat.DEFAULT)}
          </Typography>
        </Box>
      </Box>
      <Stack sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
        {!!testType && (
          <Stack alignItems='center'>
            <Img
              sx={{ maxWidth: '135px' }}
              src={testType === 'speaking' ? SpeakingImage : ReadingImage}
            />
            <Box sx={scoreReportViewPillStyles}>
              {t(
                testType === 'speaking'
                  ? 'certificateTypes.speakingListening'
                  : 'certificateTypes.readingWriting'
              )}
            </Box>
          </Stack>
        )}
        {!testType && (
          <Img
            sx={{ maxWidth: '190px', marginLeft: 'auto', marginRight: 'auto' }}
            src={mtsData?.metadata?.CREDLY_URL}
          />
        )}
      </Stack>
    </Box>
  );
};
