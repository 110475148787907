import { FC } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from '@mui/material';
import {
  OrderHistoryAccordionContent,
  OrderHistoryAccordionContentName,
  OrderHistoryAccordionTableContainer,
  OrderHistoryAccordionTableTheadCell
} from './OrderHistoryAccordionTable.style';
import { OrderTransactionProduct } from '../../interface/transaction-product.interface';
import { formatCurrency } from '../../utils/utils';

interface OrderHistoryAccordionTableProps {
  tableHeaders: string[];
  data: OrderTransactionProduct[];
}

export const OrderHistoryAccordionTable: FC<OrderHistoryAccordionTableProps> = ({
  tableHeaders,
  data
}) => {
  const matchesMaxWidth = useMediaQuery('(max-width:834px)');
  return (
    <Box sx={OrderHistoryAccordionTableContainer}>
      <TableContainer sx={{ maxHeight: '300px' }}>
        <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell key={`${header}${index}`} sx={OrderHistoryAccordionTableTheadCell}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={OrderHistoryAccordionContent}>
            {data?.map((item, index) => (
              <TableRow key={`${item}${index}`} sx={OrderHistoryAccordionContent}>
                <TableCell>
                  <Typography sx={OrderHistoryAccordionContentName}>{item.product.name}</Typography>
                  {matchesMaxWidth && (
                    <>
                      <Typography sx={{ marginBottom: '.5rem' }}>
                        Qty: {item.quantity - item.quantityRefunded}
                      </Typography>
                    </>
                  )}
                </TableCell>
                {!matchesMaxWidth && (
                  <TableCell>
                    <Typography>{item.quantity - item.quantityRefunded}</Typography>
                  </TableCell>
                )}
                <TableCell>
                  <Typography>
                    {formatCurrency(
                      item.totalPrice,
                      item.currency.code,
                      item.currency.fractionDigits
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
