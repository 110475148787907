import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Img } from '../../../../shared/src/components/Primitive';
import NoAccountOAuth from '../../../../shared/src/assets/images/no-account-oauth.svg';
import { useTranslation } from 'react-i18next';
import { buttonOutlineDark } from '../../../../shared/src/styles/button';
import { oAuthFailedContainer, oAuthFailedTitle } from './OAuthFailedPopup.style';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';

export const OAuthFailedPopup: FC<{ close: () => void }> = ({ close }) => {
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();

  const handleClose = () => {
    navigate('join');
    close();
  };

  return (
    <Stack sx={oAuthFailedContainer}>
      <Img src={NoAccountOAuth} />
      <Typography sx={oAuthFailedTitle}>{t('oAuthFailed.title')}</Typography>
      <Typography sx={{ fontSize: '16px', textAlign: 'center' }}>
        {t('oAuthFailed.subtitle')}
      </Typography>
      <Button onClick={handleClose} sx={[buttonOutlineDark, { marginTop: '30px' }]}>
        {t('signUpText')}
      </Button>
    </Stack>
  );
};
