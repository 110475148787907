import { sopClient } from '../api/Auth';

export const setSopToken = (token: string) => {
  sopClient.session.setToken(token);
  localStorage.setItem('token', token);
};

// at first run it is not yet stored in sop client
export const getSopToken = () =>
  sopClient.session.getToken() || localStorage.getItem('token') || undefined;

export const removeSopToken = () => {
  sopClient.session.deleteToken();
  localStorage.removeItem('token');
};

export default {
  setSopToken,
  getSopToken,
  removeSopToken
};
