import { DashboardAccountsHeader, MyTopScore, sopClient } from '@barracuda/shared/src';
import { FC, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { AccountSharedScoresWrapper } from '../AccountSharedScoresWrapper/AccountSharedScoresWrapper';
import { ProfileWrapper } from '../ProfileWrapper/ProfileWrapper';
import { AppContext } from '../../context/App.context';
import { AccountOrderHistoryWrapper } from '../AccountOrderHistoryWrapper/AccountOrderHistoryWrapper';
import { useLocation } from 'react-router-dom';

export const DashboardAccounts: FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { user, setEmailPreviewData } = useContext(AppContext);
  const location = useLocation();
  const [myTopScore, setMyTopScore] = useState<MyTopScore>();

  useEffect(() => {
    setEmailPreviewData({
      company: '',
      email: ''
    });
    fetchMyTopScore();
  }, []);

  const fetchMyTopScore = async () => {
    sopClient
      .getMyTopScore(process.env.REACT_APP_MTS_CODE || '')
      .then((r) => {
        if (r?.data) {
          setMyTopScore(r.data);
        }
      })
      .catch((error) => console.error('There was an error during getting MyTopScore.', error));
  };
  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (location?.state?.activeTabIndex) {
      setActiveTab(location.state.activeTabIndex);
    }
  }, [location, setActiveTab]);

  return (
    <>
      <DashboardAccountsHeader
        user={user}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
      />
      {activeTab === 0 && <ProfileWrapper />}
      {activeTab === 1 && <AccountOrderHistoryWrapper />}
      {activeTab === 2 && <AccountSharedScoresWrapper myTopScore={myTopScore} />}
    </>
  );
};
