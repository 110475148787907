import { SetStateAction, useContext } from 'react';
import { ListItem, Typography } from '@mui/material';
import { ListItemStyle, Label, LinkWrapper } from './MobileList.styles';
import { Logout } from '../Logout/Logout';
import LogoutMobile from '../../assets/images/logout-mobile.svg';
import { Stack } from '@mui/material';
import { AccountPhoto } from '@barracuda/shared/src/components/AccountPhoto';
import { AppContext } from '../../context/App.context';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { useTranslation } from 'react-i18next';
import { LocalizedNavLink } from '../LocalizedNavLink/LocalizedNavLink';
import { theme } from '@theme';

export interface MobileListItemInterface {
  link: string;
  label: string;
  replace?: boolean;
  small?: boolean;
}

export interface MobileListProps {
  (
    items: MobileListItemInterface[],
    setDrawerOpen?: (value: SetStateAction<boolean>) => void
  ): JSX.Element[];
}

export interface MobileListItemProps {
  (
    item: MobileListItemInterface,
    index: number | string,
    setDrawerOpen?: (value: SetStateAction<boolean>) => void,
    style?: Record<string, any>
  ): JSX.Element;
}

export const MobileListItem: MobileListItemProps = (item, index, setDrawerOpen, style = {}) => (
  <LocalizedNavLink
    style={({ isActive }) => ({ ...LinkWrapper(isActive && !item.replace, item.small), ...style })}
    end
    to={item.replace ? '' : item.link}
    key={`navLink-${index}`}
    onClick={() => (item.replace ? window.open(item.link) : setDrawerOpen?.(false))}
  >
    <ListItem sx={ListItemStyle}>
      <Typography sx={Label(item.small)}>{item.label}</Typography>
    </ListItem>
  </LocalizedNavLink>
);

export const MobileList: MobileListProps = (items, setDrawerOpen) => {
  const { user, isLoggedIn } = useContext(AppContext);
  const { t } = useTranslation();

  const listItems = items.map((item, index) => MobileListItem(item, index, setDrawerOpen));

  if (isLoggedIn) {
    listItems.push(
      <ListItem key='navLink-account' style={{ marginTop: '30px' }}>
        <LocalizedNavLink
          to='/dashboard/account'
          style={{ textDecoration: 'none' }}
          onClick={() => setDrawerOpen?.(false)}
        >
          <Stack direction='row'>
            <AccountPhoto imageUri={user?.imageUri} width='24px' height='24px' />
            <Typography
              sx={{
                marginLeft: '5px',
                fontSize: '16px',
                color: theme.palette.common.darkGrey2,
                textDecoration: 'none'
              }}
            >
              {t('menuLabels.account')}
            </Typography>
          </Stack>
        </LocalizedNavLink>
      </ListItem>
    );

    listItems.push(
      <ListItem key='navLink-logout' onClick={() => setDrawerOpen?.(false)} sx={{ padding: 0 }}>
        <Logout sx={{ flexGrow: 1, padding: '8px 16px' }}>
          <Stack direction='row'>
            <Img src={LogoutMobile} alt='logout' />
            <Typography
              sx={{
                marginLeft: '5px',
                fontSize: '16px',
                color: theme.palette.common.darkGrey2,
                textDecoration: 'none'
              }}
            >
              {t('menuLabels.logOut')}
            </Typography>
          </Stack>
        </Logout>
      </ListItem>
    );
  } else {
    listItems.push(
      MobileListItem(
        { link: '/join', label: t('signUpText'), small: true },
        'signUp',
        setDrawerOpen,
        { marginTop: '30px' }
      )
    );
    listItems.push(
      MobileListItem(
        { link: '/login', label: t('signUp.signIn'), small: true },
        'signIn',
        setDrawerOpen
      )
    );
  }

  return listItems;
};
