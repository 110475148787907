import { FC } from 'react';
import { Theme } from '@mui/material';
import { Box, SxProps } from '@mui/material';
import { SoundWaveArea, SoundWaveData } from './SoundWaveArea';
import { PlayButton } from './PlayButton';
import { useTranslation } from 'react-i18next';
import { theme } from '../../styles/theme';
import { alpha } from '@mui/material';

export type ListeningState = 'DISABLED' | 'STANDBY' | 'ACTIVE' | 'FINISHED';

const SOUND_WAVE_DATA: SoundWaveData = [
  21,
  21,
  21,
  45,
  45,
  [60, -8],
  [60, 7],
  60,
  [35, 7],
  35,
  21,
  [21, 9],
  21,
  21,
  21
];

const soundbarColorByState = {
  ACTIVE: theme.palette.common.lightGrey2,
  STANDBY: theme.palette.common.darkGrey10,
  DISABLED: theme.palette.common.darkGrey10,
  FINISHED: theme.palette.common.darkBlue5
} as const;

const soundwaveBackgroundColorByState = {
  ACTIVE: theme.palette.common.lightBlue0,
  STANDBY: alpha(theme.palette.common.black, 0.1),
  DISABLED: alpha(theme.palette.common.black, 0.1),
  FINISHED: theme.palette.common.lightBlue0
} as const;

export type IListeningProps = { sx?: SxProps<Theme> } & (
  | {
      state: ListeningState;
      onPlayClick?: () => void;
      duration?: number;
      width?: number | string;
    }
  | {
      state: 'ACTIVE';
      onPlayClick: () => void;
      duration: number;
      width?: number | string;
    }
);

export const Listening: FC<IListeningProps> = ({
  state,
  onPlayClick,
  duration,
  width,
  sx = {}
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ position: 'relative', width, ...sx }}>
      {state === 'STANDBY' && onPlayClick && (
        <PlayButton onPlayClick={onPlayClick} id='listening-start' text={t('play')} />
      )}
      {(state === 'STANDBY' || state === 'DISABLED') && (
        <svg
          width={`${Number(40500 / 427).toPrecision(3)}%`}
          height={`${Number(12100 / 139).toPrecision(3)}%`}
          viewBox='0 0 405 121'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          style={{
            position: 'absolute',
            top: `${((139 - 121) / 2 / 155) * 100}%`,
            left: `${((427 - 405) / 2 / 405) * 100}%`
          }}
        >
          <path
            d='M26.5 10V56.5864C26.5 59.6881 25.0607 62.6142 22.6038 64.5075L1.65137 80.6529C0.139343 81.818 0.963257 84.2371 2.8721 84.2371H16.5C22.0229 84.2371 26.5 88.7143 26.5 94.2371V110.5C26.5 116.023 30.9771 120.5 36.5 120.5H395C400.523 120.5 405 116.023 405 110.5V10C405 4.47715 400.523 0 395 0H36.5C30.9771 0 26.5 4.47716 26.5 10Z'
            fill='black'
            fillOpacity='0.1'
          />
        </svg>
      )}
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 427 139'
        fill='none'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        {state === 'ACTIVE' && (
          <g filter='url(#filter0_f_6688_68196)' id='g5801'>
            <path
              d='m 38.5,19 v 46.6 c 0,3.1017 -1.4393,6 -3.9,7.9 L 9,93.2371 h 19.5 c 5.5,0 10,4.5 10,10 V 119.5 c 0,5.5 4.5,10 10,10 H 407 c 5.5,0 10,-4.5 10,-10 V 19 C 417,13.5 412.523,9 407,9 H 48.5 c -5.5,0 -10,4.5 -10,10 z'
              fill={theme.palette.common.darkBlue5}
            />
          </g>
        )}
        <path
          d='m 38.5,19 v 46.6 c 0,3.1 -1.44,6 -3.9,7.9 l -20.95,16.15 c -1.5,1.15 -0.7,3.6 1.2,3.6 h 13.6 c 5.5,0 10,4.5 10,10 v 16.25 c 0,5.5 4.5,10 10,10 h 358.5 c 5.5,0 10,-4.5 10,-10 v -100.5 c 0,-5.5 -4.5,-10 -10,-10 h -358.5 c -5.5,0 -10,4.5 -10,10 z'
          fill={theme.palette.common.lightBlue0}
        />

        <SoundWaveArea
          width={343}
          color={theme.palette.common.lightBlue0}
          leftPadding={31.5}
          soundbarColor={soundbarColorByState[state]}
          animateColor={state === 'ACTIVE' ? theme.palette.common.darkBlue5 : undefined}
          animateTimeMs={duration}
          left={56}
          soundWaveData={SOUND_WAVE_DATA}
          backgroundColor={soundwaveBackgroundColorByState[state]}
          id='listening'
        />
        <defs id='defs5810'>
          <filter
            id='filter0_f_6688_68196'
            x='0'
            y='0'
            width='426'
            height='138.5'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
            <feGaussianBlur stdDeviation='4.5' result='effect1_foregroundBlur_6688_68196' />
          </filter>
        </defs>
      </svg>
    </Box>
  );
};
