import { theme } from '@theme';
import { SignUpErrorMessage } from '@barracuda/shared/src/components/SignUp/SignUp.styles';

export const PopupDescription = {
  paddingBottom: '30px',
  color: theme.palette.common.darkGrey2,
  '> span': {
    color: theme.palette.common.darkBlue7,
    fontWeight: 600,
    cursor: 'pointer'
  }
};
export const FormInputContainer = {
  position: 'relative',
  marginBottom: '30px',
  width: '410px',
  '@media screen and (max-width: 500px)': {
    width: 'unset'
  }
};

export const ChangePasswordPopupContainer = {
  height: '100%',
  paddingTop: '40px'
};
export const InputLabel = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: 1.5,
  color: theme.palette.common.darkGrey3,
  paddingBottom: '8px'
};
export const InputStyles = {
  width: '100%'
};
export const ButtonStyles = {
  width: '80px',
  marginTop: '20px',
  marginBottom: 0
};
export const SendLinkStyles = {
  width: '120px',
  marginTop: '60px',
  marginBottom: 0,
  position: 'absolute',
  bottom: '40px',
  '@media screen and (max-width: 1100px)': {
    position: 'inherit'
  }
};
export const PasswordChangedStack = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  height: '100%'
};
export const SuccessText = {
  fontSize: '22px',
  fontWeight: 800,
  color: theme.palette.common.darkGrey1,
  marginTop: '25px'
};
export const ImgStyles = {
  maxWidth: { xs: '207px', sm: 'unset' }
};
export const HintContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '30px',
  marginTop: '6px',
  maxWidth: '340px',
  '@media screen and (max-width: 500px)': {
    maxWidth: 'calc(100% - 40px)'
  }
};
export const PasswordLengthStyles = (password: string) => ({
  position: 'absolute',
  right: '8px',
  bottom: '-25px',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0em',
  color: password.length > 8 ? theme.palette.common.darkGreen : theme.palette.common.darkGrey2
});
export const PasswordVisibilityIcon = {
  position: 'absolute',
  top: '45px',
  right: '15px',
  height: '20px',
  cursor: 'pointer',
  fill: '#555555'
};

export const changePasswordPopupErrorMessage = {
  ...SignUpErrorMessage,
  top: 'calc(100% + 2px)',
  bottom: 'unset'
};
