import { FC } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import {
  exceptionIcon,
  SimpleColorBarContainer,
  SimpleColorBarHeader,
  SimpleColorBarItem,
  SimpleColorBarItemFill,
  SimpleColorBarScore
} from './SimpleColorBar.styles';
import unableToCertifyIcon from '../../assets/images/unable-to-certify--icon.svg';
import { theme } from '../../styles/theme';

export interface ISimpleColorBarProps {
  label: string;
  score: number;
  max: number;
  color: string;
  errorIcon?: string;
  backgroundColor?: string;
  grayedOut?: boolean;
}

export const SimpleColorBar: FC<ISimpleColorBarProps> = ({
  color,
  max,
  score,
  label,
  errorIcon,
  backgroundColor,
  grayedOut
}) => {
  const calculateLeft = (value: number): number => {
    return (value / max) * 100;
  };

  return (
    <>
      <Box sx={SimpleColorBarContainer}>
        <Box sx={SimpleColorBarHeader}>
          <Box sx={{ display: 'flex' }}>
            <Typography
              sx={{
                fontWeight: 600,
                color: grayedOut ? '#717171 !important' : theme.palette.common.darkGrey2
              }}
            >
              {label}
            </Typography>
            {!!errorIcon && (
              <Tooltip placement='right' arrow title={errorIcon}>
                <img style={exceptionIcon} src={unableToCertifyIcon} />
              </Tooltip>
            )}
          </Box>
          {!grayedOut && (
            <Box sx={SimpleColorBarScore}>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: grayedOut ? '#717171 !important' : theme.palette.common.darkGrey2
                }}
              >
                {score}
              </Typography>
              /<Typography>{max}</Typography>
            </Box>
          )}
        </Box>
        <Box sx={[SimpleColorBarItem, backgroundColor ? { background: backgroundColor } : {}]}>
          <Box
            sx={[SimpleColorBarItemFill, { background: color, width: `${calculateLeft(score)}%` }]}
          />
        </Box>
      </Box>
    </>
  );
};
