import { FC } from 'react';
import { Box, CircularProgress, SxProps, Theme } from '@mui/material';
import { LoaderContainer } from './Loader.styles';

export interface LoaderProps {
  readonly loading: boolean;
  readonly small?: true;
  readonly size?: string;
  readonly color?: string;
  readonly sx?: SxProps<Theme>;
}

export const Loader: FC<LoaderProps> = ({ loading, small, sx, size, color }) => {
  if (!loading) {
    return null;
  }

  return small ? (
    <CircularProgress size={!size ? '70px' : size} sx={{ color, ...sx }} />
  ) : (
    <Box sx={{ ...LoaderContainer, ...sx }}>
      <CircularProgress size={!size ? '70px' : size} sx={{ color }} />
    </Box>
  );
};
