import { theme } from '../../styles/theme';

const alignCenter = {
  display: 'flex',
  alignItems: 'center'
};

export const SkillScoresTableHeader = {
  fontSize: '25px',
  fontWeight: 700,
  color: theme.palette.common.darkBlue1,
  marginBottom: '20px'
};

export const SkillScoresTableHeaderContainer = (insidePopup?: boolean) => ({
  ...alignCenter,
  justifyContent: 'space-between',
  '@media only screen and (min-width: 320px) and (max-width: 1024px)': {
    flexDirection: !insidePopup ? 'column' : 'row',
    alignItems: 'flex-start'
  }
});

export const SkillScoresTableHeaderLink = (insidePopup?: boolean) => ({
  fontSize: '16px',
  fontWeight: 600,
  ...alignCenter,
  color: theme.palette.common.darkBlue7,
  textDecoration: 'none',
  cursor: 'pointer',
  fontFamily: 'Open Sans, sans-serif',
  '> svg': {
    height: '15px'
  },
  '@media only screen and (min-width: 320px) and (max-width: 1024px)': {
    marginBottom: '15px'
  },
  '@media screen and (max-width: 800px)': {
    visibility: insidePopup ? 'hidden' : 'visible'
  }
});

export const SkillScoresTableUnderstandingSkillsPopup = {
  header: {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '5px'
  },
  paragraph: {
    fontSize: '17px',
    paddingBottom: '30px'
  },
  marginBetween: {
    marginBottom: '15px'
  }
};
