import { FC, useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CookiePolicyContent, ScalableWrapper } from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import {
  PrivacyPolicyHeaderWeb,
  PrivacyPolicyHeaderWebTitle
} from '../PrivacyPolicy/PrivacyPolicy.styles';
import { PrivacyPolicyItalic } from '@barracuda/shared/src/components/PrivacyPolicyContent/PrivacyPolicyContent.styles';
import { theme } from '@theme';

export const CookiePolicy: FC = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useContext(AppContext);
  const pathname = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0
    });
  }, [pathname]);

  return (
    <ScalableWrapper isLoggedIn={isLoggedIn}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={[PrivacyPolicyHeaderWeb, { paddingTop: isLoggedIn ? '100px' : '190px' }]}
        >
          <Typography sx={PrivacyPolicyHeaderWebTitle} component='h1'>
            {t('cookiePolicy.header')}
          </Typography>
          <Typography sx={PrivacyPolicyItalic}>{t('cookiePolicy.subheader')}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={[
            PrivacyPolicyHeaderWeb,
            { background: theme.palette.common.white, paddingTop: '100px' }
          ]}
        >
          <CookiePolicyContent isLoggedIn={isLoggedIn} />
        </Grid>
      </Grid>
    </ScalableWrapper>
  );
};
