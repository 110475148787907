import { AxiosResponse } from 'axios';
import { makeSOPClient, SOPClientApi } from './client';
import {
  SOPClientProtocol,
  SOPClientProtocolOptions,
  makeSOPClientProtocol,
  ParsedToken,
  SOPToken
} from './protocol';
import { SHARES_TABLE_PAGE_SIZE, TESTS_TABLE_PAGE_SIZE, TOKEN_DEADLINE } from './constants';

export type {
  SOPClientProtocol,
  SOPClientProtocolOptions,
  SOPClientApi,
  AxiosResponse,
  ParsedToken,
  SOPToken
};

export {
  makeSOPClient,
  makeSOPClientProtocol,
  SHARES_TABLE_PAGE_SIZE,
  TESTS_TABLE_PAGE_SIZE,
  TOKEN_DEADLINE
};
