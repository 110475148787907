import { changeLanguage } from '@i18n';
import { NavigateFunction } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../context/App.context';
import { Language } from '@barracuda/shared/src';
import { useOneTrust } from './useOneTrust';
import { IGNORE_PATHS } from '../app.utils';

export const useSwitchLanguage = (navigate: NavigateFunction): ((lang: Language) => void) => {
  const { language, setLanguage } = useContext(AppContext);
  const { mapOTLangCode, reloadOTBanner } = useOneTrust();

  return async (newLanguage: Language) => {
    await changeLanguage(newLanguage.toLowerCase()); // set language in i18n instance (within shared and web package)
    setLanguage?.(newLanguage); // set language in AppContext instance (within web package only)

    document.body.className = newLanguage.toLowerCase();

    localStorage.setItem('language', newLanguage);

    document.documentElement.setAttribute('lang', mapOTLangCode(newLanguage));

    const fullPath = `${location.pathname}${location.search}${location.hash}`;
    if (!IGNORE_PATHS.includes(location.pathname)) {
      navigate(`/${newLanguage}/${fullPath.slice(5)}`);
    }

    if (language !== newLanguage) reloadOTBanner();
  };
};
