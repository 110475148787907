import { theme } from '../../styles/theme';

export const link = {
  fontSize: '16px',
  color: theme.palette.common.darkBlue7,
  fontWeight: 600,
  textDecoration: 'none'
};

export const bottomLinkContainer = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  // to keep either this same vertical position and margin-top
  pt: {
    xs: 6,
    md: 3,
    xl: 6
  },
  marginTop: 'auto'
};

export const container = {
  padding: {
    xs: '37px 25px 25px',
    md: '75px 50px 50px'
  },
  border: '1px solid #727272',
  borderRadius: '6px',
  height: '100%'
};

export const header = {
  fontSize: '20px',
  fontWeight: 700
};

export const iconContainer = {
  padding: '25px',
  borderRadius: '50%',
  background: theme.palette.common.lightGrey5,
  width: '94px',
  height: '94px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: { xs: '20px', md: '25px' }
};

export const list = { lineHeight: 1.6, marginBottom: 0 };
