import { mediaQuery, theme } from '../../styles/theme';

export const LoginWebSection = {
  background: '#F9F9F9',
  height: '100%',
  minHeight: '100vh',
  position: 'relative',
  padding: '40px',
  '@media screen and (max-width: 899px)': {
    padding: '13px'
  }
};

export const LoginWebContainer = {
  height: 'calc(100% - 120px)',
  maxWidth: '1366px',
  justifyContent: 'center',
  gridGap: '20px',
  minHeight: '755px',
  display: 'flex',
  flexFlow: 'row',
  '@media screen and (max-width: 899px)': {
    marginTop: '90px',
    flexFlow: 'column'
  },
  marginLeft: { lg: 'auto' },
  marginRight: { lg: 'auto' },
  [mediaQuery.minHeight900]: {
    marginTop: '90px'
  }
};

export const LoginWebColumn = {
  height: 'auto',
  paddingLeft: '0 !important'
};

export const LoginWebColumnBox = {
  background: theme.palette.common.white,
  height: '100%',
  paddingBottom: '100px',
  margin: 'auto',
  button: {
    maxWidth: 'unset'
  }
};

export const LoginWebRightColumnBox = {
  display: 'flex',
  flexDirection: 'column',
  marginRight: { lg: '32px' }
};

export const LoginWebLeftColumn = {
  display: 'flex',
  paddingLeft: '60px',
  paddingRight: '60px',
  flex: '1',
  textAlign: 'left',
  justifyContent: 'center',
  alignItems: 'start',
  flexDirection: 'column',
  '@media screen and (max-width: 899px)': {
    display: 'none'
  }
};

export const LoginWebLeftColumnSubtitle = {
  color: theme.palette.common.violet,
  fontSize: '12px',
  textTransform: 'uppercase',
  fontWeight: 400,
  lineHeight: 1.3,
  letterSpacing: '3px',
  width: '100%',
  textAlign: 'center',
  padding: '30px 30px 0 30px',
  '@media screen and (min-width: 899px)': {
    display: 'none'
  }
};

export const LoginWebLeftColumnTitle = {
  fontSize: '72px',
  fontWeight: 800,
  color: theme.palette.common.darkGrey6,
  lineHeight: 1.1,
  letterSpacing: '-2px',
  '@media screen and (max-width: 980px)': {
    fontSize: '62px'
  }
};

export const LoginWebLeftColumnContent = {
  color: theme.palette.common.darkGrey2,
  fontSize: '16px',
  letterSpacing: 0,
  fontWeight: 400,
  lineHeight: 1.5,
  marginTop: '1rem'
};
export const LeftColumnProductName = {
  fontFamily: 'Open Sans !important',
  color: theme.palette.common.violet,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '3px',
  marginTop: '150px'
};

export const LoginWebPropsContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '2rem',
  width: '100%'
};

export const LoginWebProps = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem'
};

export const MobileColumnTitle = {
  fontSize: '35px',
  fontWeight: 800,
  color: theme.palette.common.darkGrey6,
  marginBottom: '70px',
  paddingLeft: '30px',
  paddingRight: '30px',
  lineHeight: 1,
  textAlign: 'center',
  '@media screen and (min-width: 899px)': {
    display: 'none'
  },
  '@media only screen and (max-width: 899px)': {
    marginTop: '40px',
    marginBottom: '40px'
  },
  '@media screen and (max-width: 428px)': {
    paddingLeft: '15px',
    paddingRight: '15px',
    fontSize: '30px'
  }
};

export const TabPanelInnerContainer = {
  p: 3,
  '@media screen and (max-width: 743px)': {
    p: 0
  }
};
