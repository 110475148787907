import { BaseSyntheticEvent, FC, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import {
  ResetPasswordButton,
  ResetPasswordContainer,
  ResetPasswordHeader,
  ResetPasswordInput,
  ResetPasswordParagraph
} from '../ResetPassword/ResetPassword.styles';
import { SignInButton, SignInTextFieldContainer } from '../SignIn/SignInStyles';
import {
  SetPasswordCounter,
  SetPasswordHints,
  SetPasswordHintsContainer,
  ValidationMessage
} from './SetPassword.styles';
import { PasswordVisibilityIcon, SignUpErrorMessage } from '../SignUp/SignUp.styles';
import resetPasswordSuccess from '../../assets/images/resetPassword--success.svg';
import { debounce } from 'lodash';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import { theme } from '../../styles/theme';
import { Img } from '../Primitive';

interface SetPasswordProps {
  submit?: (password: string) => void;
  setPasswordSuccess?: boolean;
  handleSignInRedirect?: () => void;
  handlePasswordValidation?: (password: string) => Promise<string[]>;
  clearError?: () => void;
  error?: string;
}

export const SetPassword: FC<SetPasswordProps> = ({
  submit,
  setPasswordSuccess,
  handleSignInRedirect,
  handlePasswordValidation,
  clearError,
  error
}) => {
  const [count, setCount] = useState(0);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [passwordVisible, setPasswordVisible] = useState({
    password: false,
    confirmPassword: false
  });
  const { t } = useTranslation();

  const validationCases = [
    t('resetPassword.oldPasswordNotMatch'),
    t('resetPassword.sameOldPassword'),
    t('resetPassword.chooseAnotherPassword')
  ];

  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    formState: { isValid, dirtyFields }
  } = useForm({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const isPasswordAndConfirmPasswordTheSame = useMemo(() => {
    return password === confirmPassword;
  }, [password, confirmPassword]);

  const submitHandler = (data: any) => {
    submit?.(data.password);
  };

  const checkPassword = useCallback(
    debounce(async (event: BaseSyntheticEvent) => {
      const value = event?.target.value;
      if (value) {
        const errors = await handlePasswordValidation?.(value);
        setErrors(errors || []);
      }
    }, 300),
    []
  );

  const handlePasswordChange = (event: BaseSyntheticEvent) => {
    clearError?.();
    setPassword(event?.target?.value);
    setValue('password', event?.target?.value);
    trigger('password');
    setCount(event?.target?.value.length);
    checkPassword(event);
  };

  const handleConfirmPasswordChange = (event: BaseSyntheticEvent) => {
    clearError?.();
    setConfirmPassword(event?.target.value);
    setValue('confirmPassword', event?.target?.value);
    trigger('confirmPassword');
  };

  const navigateToSignIn = () => {
    if (!handleSignInRedirect) return;
    handleSignInRedirect?.();
  };

  return (
    <>
      <Stack sx={ResetPasswordContainer} component='form' onSubmit={handleSubmit(submitHandler)}>
        {!setPasswordSuccess && (
          <>
            <Typography sx={ResetPasswordHeader} component='h2'>
              {t('resetPassword.createNewPassword')}
            </Typography>
            <Typography sx={ResetPasswordParagraph}>
              {t('resetPassword.settingNewPasswordDescription')}
            </Typography>
            <Box sx={[SignInTextFieldContainer, { marginBottom: 0, marginTop: '30px' }]}>
              <Box sx={ResetPasswordInput}>
                <TextField
                  {...register('password', {
                    required: t('thisIsRequired') as string
                  })}
                  type={passwordVisible.password ? 'text' : 'password'}
                  onChange={handlePasswordChange}
                  sx={{ width: '100%' }}
                  label={t('password')}
                />
                {passwordVisible.password && (
                  <VisibilityOffIcon
                    sx={[PasswordVisibilityIcon, { right: '10px' }]}
                    onClick={() => setPasswordVisible({ ...passwordVisible, password: false })}
                  />
                )}
                {!passwordVisible.password && (
                  <VisibilityIcon
                    sx={[PasswordVisibilityIcon, { right: '10px' }]}
                    onClick={() => setPasswordVisible({ ...passwordVisible, password: true })}
                  />
                )}
              </Box>
            </Box>

            <Box sx={SetPasswordHintsContainer}>
              <Typography sx={[SetPasswordHints, { textAlign: 'left' }]}>
                {t('atLeast')}
                <Typography
                  sx={[
                    SetPasswordHints,
                    {
                      color:
                        password.length && !errors.includes('NOT_LONG_ENOUGH')
                          ? theme.palette.common.darkGreen
                          : theme.palette.common.black
                    }
                  ]}
                  component='span'
                >
                  {' '}
                  {t('resetPassword.nCharacters', { n: 8 })}
                </Typography>
                ,
                <Typography
                  sx={[
                    SetPasswordHints,
                    {
                      color:
                        password.length && !errors.includes('NO_LOWERCASE_LETTER')
                          ? theme.palette.common.darkGreen
                          : theme.palette.common.black
                    }
                  ]}
                  component='span'
                >
                  {' '}
                  {t('oneLowercaseLetter')}
                </Typography>
                ,
                <Typography
                  sx={[
                    SetPasswordHints,
                    {
                      color:
                        password.length && !errors.includes('NO_UPPERCASE_CHARACTER')
                          ? theme.palette.common.darkGreen
                          : theme.palette.common.black
                    }
                  ]}
                  component='span'
                >
                  {' '}
                  {t('oneUppercaseLetter')}
                </Typography>
                ,
                <Typography
                  sx={[
                    SetPasswordHints,
                    {
                      color:
                        password.length && !errors.includes('NO_DIGIT_CHARACTER')
                          ? theme.palette.common.darkGreen
                          : theme.palette.common.black
                    }
                  ]}
                  component='span'
                >
                  {' '}
                  {t('oneNumber')}
                </Typography>{' '}
                {t('and')}
                <Typography
                  sx={[
                    SetPasswordHints,
                    {
                      color:
                        password.length && !errors.includes('NO_SPECIAL_CHARACTER')
                          ? theme.palette.common.darkGreen
                          : theme.palette.common.black
                    }
                  ]}
                  component='span'
                >
                  {' '}
                  {t('specialCharacter')}
                </Typography>
              </Typography>
              <Box
                sx={[
                  SetPasswordCounter,
                  {
                    color:
                      password.length && !errors.includes('NOT_LONG_ENOUGH')
                        ? theme.palette.common.darkGreen
                        : theme.palette.common.black
                  }
                ]}
              >
                {count} / 8
              </Box>
            </Box>
            <Box sx={[SignInTextFieldContainer, { marginBottom: '20px', marginTop: '30px' }]}>
              <Box sx={ResetPasswordInput}>
                <TextField
                  {...register('confirmPassword', {
                    required: t('thisIsRequired') as string
                  })}
                  type={passwordVisible.confirmPassword ? 'text' : 'password'}
                  sx={{ width: '100%' }}
                  onChange={handleConfirmPasswordChange}
                  label={t('resetPassword.confirmPassword')}
                />
                {passwordVisible.confirmPassword && (
                  <VisibilityOffIcon
                    sx={[PasswordVisibilityIcon, { right: '10px' }]}
                    onClick={() =>
                      setPasswordVisible({ ...passwordVisible, confirmPassword: false })
                    }
                  />
                )}
                {!passwordVisible.confirmPassword && (
                  <VisibilityIcon
                    sx={[PasswordVisibilityIcon, { right: '10px' }]}
                    onClick={() =>
                      setPasswordVisible({ ...passwordVisible, confirmPassword: true })
                    }
                  />
                )}
              </Box>
              {dirtyFields?.confirmPassword && !isPasswordAndConfirmPasswordTheSame && (
                <Typography sx={SignUpErrorMessage}>
                  {t('resetPassword.passwordsMustBeEqual')}
                </Typography>
              )}
              {error && (
                <Typography sx={ValidationMessage}>
                  {validationCases.find((item) => error.includes(item))}
                </Typography>
              )}
            </Box>
            <Button
              type='submit'
              disabled={!isValid || !isPasswordAndConfirmPasswordTheSame || !!errors.length}
              sx={[SignInButton, ResetPasswordButton]}
            >
              {t('resetPassword.updatePassword')}
            </Button>
          </>
        )}
        {setPasswordSuccess && (
          <>
            <Img src={resetPasswordSuccess} />
            <Typography sx={[ResetPasswordHeader, { marginTop: '20px' }]} component='h2'>
              {t('resetPassword.passwordHasBeenUpdated')}
            </Typography>
            <Typography
              sx={[ResetPasswordParagraph, { paddingLeft: '20px', paddingRight: '20px' }]}
            >
              {t('resetPassword.signInToAccount')}
            </Typography>
            <Button onClick={navigateToSignIn} sx={[SignInButton, { marginTop: '20px' }]}>
              {t('signInText')}
            </Button>
          </>
        )}
      </Stack>
    </>
  );
};
