import React, { FC, useMemo } from 'react';
import {
  Box,
  LabelDisplayedRowsArgs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery
} from '@mui/material';

import {
  OrderHistoryTableFooterStyle,
  SharedOrderHistoryTableCellWidth,
  SharedOrderHistoryTableContainer,
  SharedOrderHistoryTableContainerInner,
  SharedOrderHistoryTableHeader,
  SharedOrderHistoryTableThead,
  SharedOrderHistoryTableTHeadCell
} from './SharedOrderHistoryTable.style';
import { useTranslation } from 'react-i18next';
import { TransactionResults } from '../../interface/transaction-product.interface';
import { SharedOrdersSingleTableRow } from './SharedOrderHistorySingleTableRow';
import { SearchBar } from '../SearchBar/SearchBar';
import { TESTS_TABLE_PAGE_SIZE } from '@barracuda/sop-api-client';

interface SharedOrderHistoryProps {
  title: string;
  query?: string;
  setPage: (value: number) => void;
  handleInputChange?: (...args: any) => void;
  page: number;
  total: number;
  tableHeaders: string[];
  accordionHeaders: string[];
  handleChangePage: (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  orders: TransactionResults[];
  selectedProduct: (id: number) => void;
  downloadReceipt: (transactionId?: number) => void;
}

export const SharedOrderHistory: FC<SharedOrderHistoryProps> = ({
  title,
  page,
  total,
  tableHeaders,
  accordionHeaders,
  handleChangePage,
  selectedProduct,
  handleInputChange,
  orders,
  downloadReceipt
}) => {
  const { t } = useTranslation();
  const matchesMaxWidth = useMediaQuery('(max-width:834px)');
  const renderTableHeaderRegular = useMemo(() => {
    return (
      <TableHead sx={SharedOrderHistoryTableThead}>
        <TableRow>
          {tableHeaders.map((el, index) => (
            <TableCell
              key={`${el}${index}`}
              sx={{
                ...SharedOrderHistoryTableTHeadCell,
                ...SharedOrderHistoryTableCellWidth(matchesMaxWidth ? 0 : 1),
                ...(el === '' && { display: 'none' })
              }}
            >
              {el}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }, [tableHeaders]);

  const labelDisplayedRows = (props: LabelDisplayedRowsArgs): string => {
    return `${props.from}–${props.to} ${t('pagination.of')} ${props.count}`;
  };
  return (
    <>
      <Box sx={SharedOrderHistoryTableContainer}>
        <Box sx={SharedOrderHistoryTableContainerInner}>
          <Typography sx={SharedOrderHistoryTableHeader} component='h2'>
            {title}
          </Typography>
          <Box
            sx={{
              marginRight: '1rem'
            }}
          >
            <SearchBar handleInputChange={(event) => handleInputChange?.(event)} />
          </Box>
        </Box>

        <TableContainer>
          <Table>
            {renderTableHeaderRegular}
            {!orders.length && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9}>{t('profilePage.dontHaveAnyHistoryOrders')}</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          <SharedOrdersSingleTableRow
            orders={orders}
            selectedProduct={selectedProduct}
            accordionHeaders={accordionHeaders}
            downloadReceipt={downloadReceipt}
          />
        </TableContainer>
        <Table>
          <TableFooter sx={OrderHistoryTableFooterStyle}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[1]}
                rowsPerPage={TESTS_TABLE_PAGE_SIZE}
                labelDisplayedRows={labelDisplayedRows}
                colSpan={9}
                count={total}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': t('rowsPerPage') as string
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </>
  );
};
