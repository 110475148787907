import { FC, useContext, useEffect } from 'react';
import {
  HelpCenter,
  helpCenterContent,
  helpCenterContentJpn,
  helpCenterContentKor
} from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';

export const HelpCenterWrapper: FC = () => {
  const { isLoggedIn, setEmailPreviewData } = useContext(AppContext);
  const navigate = useLocalizedNavigate();
  useEffect(() => {
    setEmailPreviewData({
      company: '',
      email: ''
    });
  }, []);
  const { language } = useContext(AppContext);

  const handleContactUs = () => {
    window.open('https://pearson.tfaforms.net/3048');
  };

  const handleNavigate = (url: string) => {
    const link = isLoggedIn ? `/dashboard/${url}` : `/${url}`;
    navigate(link);
  };

  let helpCenterContentTranslated;
  switch (language) {
    case 'JPN':
      helpCenterContentTranslated = helpCenterContentJpn;
      break;

    case 'KOR':
      helpCenterContentTranslated = helpCenterContentKor;
      break;

    default:
      helpCenterContentTranslated = helpCenterContent;
  }

  return (
    <HelpCenter
      isLoggedIn={isLoggedIn}
      handleContactUs={handleContactUs}
      handleNavigate={handleNavigate}
      content={helpCenterContentTranslated}
    />
  );
};
