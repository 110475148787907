import { Box, Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { StatusBadge } from '../StatusBadge/StatusBadge';
import {
  BadgeCircle,
  BadgeContainer,
  BadgeContent,
  BadgeTitle,
  InsideStack,
  NoTopScoreDescription,
  PracticeButton,
  ScoreInsideShield,
  ValidKey,
  ValidValue
} from './TopScoreBadge.style';
import moment from 'moment';
import emptyScore from '../../assets/images/dashboard--ShareResults__emptyScore.svg';
import TopScoreShield from '../../assets/images/accordion-topscore-shield.svg';
import { Img } from '../Primitive';
import { useTranslation, Trans } from 'react-i18next';
import { MyTopScore } from '../../interface/my-top-score.interface';
import { NavigateFunction } from 'react-router-dom';

interface TopScoreBadgeProps {
  myTopScore: MyTopScore | undefined;
  navigate: NavigateFunction;
}

export const TopScoreBadge: FC<TopScoreBadgeProps> = ({ myTopScore, navigate }) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack sx={BadgeContainer}>
        <Typography sx={BadgeTitle}>{t('dashboardPage.badgeMyTopScore')}</Typography>
        {myTopScore && (
          <Stack sx={BadgeContent}>
            <Box sx={BadgeCircle}>
              <Img src={TopScoreShield} />
              <Box sx={ScoreInsideShield}>{myTopScore.score}</Box>
            </Box>
            <Stack sx={InsideStack}>
              <Typography sx={ValidKey} component='span'>
                {`${t('validThrough')}: `}
                <Typography component='span' sx={ValidValue}>
                  {moment(myTopScore.expiresAt).format('DD MMMM YYYY')}
                </Typography>
              </Typography>
              <StatusBadge type='CERTIFIED' />
              <Button onClick={() => navigate(`results/my-top-score`)} sx={PracticeButton}>
                {t('results.viewDetails')}
              </Button>
            </Stack>
          </Stack>
        )}

        {!myTopScore && (
          <Stack sx={[BadgeContent, { height: '100%' }]}>
            <Img src={emptyScore} />
            <Typography sx={{ marginTop: '25px' }}>
              <Trans
                sx={NoTopScoreDescription}
                i18nKey='yourTopScoreIsCalculatedFrom'
                components={{ boldItalic: <b style={{ fontStyle: 'italic' }} /> }}
              />
            </Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};
