import { FC, SetStateAction } from 'react';
import { Box, Link, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  SkillScoresTableHeader,
  SkillScoresTableHeaderContainer,
  SkillScoresTableHeaderLink,
  SkillScoresTableUnderstandingSkillsPopup
} from './SkillScoreTable.styles';
import { DialogWrapper } from '../DialogWrapper/DialogWrapper';
import { useTranslation, Trans } from 'react-i18next';

export const SkillScoreTable: FC<{
  open: boolean;
  setOpen: (value: SetStateAction<boolean>) => void;
  insidePopup?: boolean;
}> = ({ open, setOpen, insidePopup }) => {
  const { t } = useTranslation();

  return (
    <Box sx={SkillScoresTableHeaderContainer(insidePopup)}>
      <Typography sx={SkillScoresTableHeader} component='h2'>
        {t('results.skillScores')}
      </Typography>
      <Link onClick={() => setOpen(true)} sx={SkillScoresTableHeaderLink(insidePopup)}>
        {t('results.understandingYourSkills')}
        <ArrowForwardIosIcon />
      </Link>
      <DialogWrapper
        open={open}
        handleClose={() => setOpen(!open)}
        subtitle={t('results.skillScores') as string}
        title={t('results.understandingYourSkills')}
      >
        <Typography sx={SkillScoresTableUnderstandingSkillsPopup.header} component='h3'>
          {t('results.overallScore')}
        </Typography>
        <Typography
          sx={[
            SkillScoresTableUnderstandingSkillsPopup.paragraph,
            SkillScoresTableUnderstandingSkillsPopup.marginBetween
          ]}
        >
          {t('results.overallScoreDescription')}
        </Typography>
        <Typography sx={SkillScoresTableUnderstandingSkillsPopup.header} component='h3'>
          {t('results.gse')}
        </Typography>
        <Typography sx={SkillScoresTableUnderstandingSkillsPopup.paragraph}>
          <Trans
            i18nKey='results.gseDescription'
            components={{
              a: <a style={{ color: 'inherit' }} target='_blank' href='http://english.com/gse' />
            }}
          />
        </Typography>
      </DialogWrapper>
    </Box>
  );
};
