import { theme } from '../../styles/theme';

export const DialogWrapperContainer = {
  padding: '40px',
  width: '800px',
  height: 'auto',
  '@media only screen and (min-width: 320px) and (max-width: 900px)': {
    width: '100%',
    padding: '20px'
  },
  zIndex: '1301',
  position: 'relative'
};
export const DialogStyles = {
  '@media (orientation: landscape) and (min-width: 500px)': {
    '.MuiPaper-root': {
      maxHeight: 'unset',
      width: '70%'
    }
  }
};

export const DialogWrapperSubtitle = {
  color: theme.palette.common.violet,
  fontSize: '14px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  '@media only screen and (min-width: 320px) and (max-width: 500px)': {
    fontSize: '12px'
  },
  '@media only screen and (min-width: 501px) and (max-width: 900px)': {
    fontSize: '13px'
  }
};

export const DialogWrapperTitle = {
  fontSize: '36px',
  fontWeight: 800,
  color: theme.palette.common.darkGrey1,
  fontFamily: 'Epilogue, sans-serif;',
  '@media only screen and (min-width: 320px) and (max-width: 500px)': {
    fontSize: '24px',
    lineHeight: 1.2
  },
  '@media only screen and (max-width: 600px)': {
    fontSize: '28px',
    lineHeight: 1.2
  },
  marginTop: 0,
  paddingRight: '21px'
};
export const CenterAlign = {
  display: 'flex',
  alignItems: 'center'
};
export const CloseIconStyle = {
  position: 'absolute',
  top: '15px',
  right: '25px',
  cursor: 'pointer'
};
