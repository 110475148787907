import { FC } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import downloadBarracudaImage from '../../assets/images/download-barracuda-image.svg';
import { SignInButton } from '../SignIn/SignInStyles';
import { isMac } from '../../utils/utils';
import { DownloadButton, DownloadButtonContainer, DownloadImage } from './DownloadAppPopup.style';
import { useTranslation } from 'react-i18next';
import { Img } from '../Primitive';

interface DownloadAppPopupProps {
  closeDialog: (key: string) => void;
  MSI_URL: string | undefined;
  MAC_URL: string | undefined;
}

export const DownloadAppPopup: FC<DownloadAppPopupProps> = ({ MAC_URL, MSI_URL, closeDialog }) => {
  const downloadApp = (url: string | undefined) => {
    if (!url) return;
    window.open(url);
    closeDialog('download');
  };

  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography>
            {t('downloadPearsonEnglishSkillsCertificate', {
              productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
            })}
          </Typography>
          <Img sx={DownloadImage} src={downloadBarracudaImage} />
          <Box sx={DownloadButtonContainer}>
            <Button
              onClick={() => downloadApp(isMac() ? MAC_URL : MSI_URL)}
              sx={[SignInButton, DownloadButton]}
            >
              Download
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
