import { theme } from '@theme';
import { buttonOutlineDark } from '@barracuda/shared/dist/src/styles/button';

const pageNotFoundColumnCenter = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};

export const pageNotFoundWrapper = {
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  ...pageNotFoundColumnCenter,
  backgroundColor: theme.palette.common.white
};

export const pageNotFoundContainer = {
  padding: '50px',
  background: theme.palette.common.white,
  ...pageNotFoundColumnCenter,
  height: '100%',
  borderRadius: '8px'
};

export const pageNotFoundH2 = {
  fontSize: '22px',
  fontWeight: 600,
  marginBottom: '10px'
};

export const pageNotFoundH3 = { fontSize: '16px', marginBottom: '30px' };

export const pageNotFoundBtn = {
  ...buttonOutlineDark,
  marginBottom: 0,
  padding: '.5rem 1rem'
};

export const pageNotFountImage = { width: '340px', marginBottom: '20px' };
