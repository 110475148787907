import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  FlexCenter,
  ScoreData,
  ScoreTitle,
  ShieldContainer,
  ShieldScore,
  StatusDescription,
  TileBody,
  TileContainer,
  TileFooter,
  TileHead
} from './LatestTestTile.style';
import { License } from '../../interface/licenses.interfaces';
import { NavigateFunction } from 'react-router-dom';
import shield from '../../assets/images/purple-shield.svg';
import grayShield from '../../assets/images/shield-gray.svg';
import ArrowRight from '../../assets/images/arrow-right.svg';
import moment from 'moment';
import { Img } from '../Primitive';
import { StatusBadge } from '../StatusBadge/StatusBadge';
import CertFailedImage from '../../assets/images/cert-failed.svg';
import noTestsImage from '../../assets/images/no-tests-new-image.svg';
import TestLateHangupImage from '../../assets/images/late-hangup-new.svg';
import TestStartedImage from '../../assets/images/test-started-img.svg';
import RuleViolationIcon from '../../assets/images/rule-violation-icon.svg';
import ErrorTileIcon from '../../assets/images/error-small-tile.svg';
import PartiallyScoredIcon from '../../assets/images/partially-scored-small.svg';
import { SkillTypes } from '../../index';
import { theme } from '../../styles/theme';

export interface LatestTestTileProps {
  license: License | undefined;
  navigate: NavigateFunction;
}

export const LatestTestTile: FC<LatestTestTileProps> = ({ license, navigate }) => {
  const { t } = useTranslation();
  const getTakenOnLabel = () => {
    const validDate = license?.endTime || license?.startTime || undefined;
    return validDate ? `${t('takenOn')} ${moment(validDate).format('DD MMMM yyyy')}` : '';
  };

  const prepareBody = () => {
    switch (license?.status) {
      case 'CERTIFIED': {
        return (
          <Box sx={TileBody}>
            <Box sx={ShieldContainer}>
              <Img width={80} alt='Shield icon' src={shield} />
              <Typography sx={ShieldScore}>
                {(license?.scores &&
                  license?.scores?.find(
                    (el) => el?.component === SkillTypes.OVERALL_SCORE && el?.scale === 'GSE'
                  )?.score) ||
                  ''}
              </Typography>
            </Box>
            <Stack sx={ScoreData}>
              <Typography sx={ScoreTitle}>{license?.product?.name}</Typography>
              <StatusBadge type={license?.status} />
            </Stack>
          </Box>
        );
      }
      case 'SCORED': {
        return (
          <Box sx={TileBody}>
            <Box sx={ShieldContainer}>
              <Img width={80} alt='Shield icon' src={grayShield} />
              <Typography sx={ShieldScore}>
                {(license?.scores &&
                  license?.scores?.find(
                    (el) => el?.component === SkillTypes.OVERALL_SCORE && el?.scale === 'GSE'
                  )?.score) ||
                  ''}
              </Typography>
            </Box>
            <Stack sx={ScoreData}>
              <Typography sx={ScoreTitle}>{license?.product?.name}</Typography>
              <StatusBadge type={license?.status} />
            </Stack>
          </Box>
        );
      }
      case 'CERTIFICATION_FAILED': {
        return (
          <Box sx={[TileBody, { marginTop: '12px' }]}>
            <Stack sx={FlexCenter}>
              <Img width={88} sx={{ paddingBottom: '15px' }} src={CertFailedImage} />
              <Typography sx={[StatusDescription, { color: theme.palette.common.darkRed }]}>
                <Trans i18nKey='results.unableToCertifyScore' components={{ br: <br /> }} />
              </Typography>
              <StatusBadge type={license?.status} />
            </Stack>
          </Box>
        );
      }
      case 'PARTIALLY_SCORED': {
        return (
          <Box sx={[TileBody, { marginTop: '12px' }]}>
            <Stack sx={FlexCenter}>
              <Img width={88} sx={{ paddingBottom: '15px' }} src={PartiallyScoredIcon} />
              <Typography sx={[StatusDescription]}>
                <Trans i18nKey='results.yourSpeakingWasUnscorable' components={{ br: <br /> }} />
              </Typography>
              <StatusBadge type={license?.status} />
            </Stack>
          </Box>
        );
      }
      case 'ERROR': {
        return (
          <Box sx={[TileBody, { marginTop: '12px' }]}>
            <Stack sx={FlexCenter}>
              <Img width={81} sx={{ paddingBottom: '15px' }} src={ErrorTileIcon} />
              <Typography sx={[StatusDescription]}>
                <Trans i18nKey='results.somethingWentWrong' components={{ br: <br /> }} />
              </Typography>
              <StatusBadge type={license?.status} />
            </Stack>
          </Box>
        );
      }
      case 'LATE_HANGUP': {
        return (
          <Box sx={[TileBody, { marginTop: '9px', height: '100%' }]}>
            <Stack sx={FlexCenter}>
              <Img width={81} sx={{ paddingBottom: '15px' }} src={TestLateHangupImage} />
              <Typography sx={StatusDescription}>{t('results.testWasNotCompleted')}</Typography>
              <StatusBadge type={license?.status} />
            </Stack>
          </Box>
        );
      }
      case 'ABORTED': {
        return (
          <Box sx={[TileBody, { marginTop: '9px', height: '100%' }]}>
            <Stack sx={FlexCenter}>
              <Img width={43} sx={{ paddingBottom: '15px' }} src={RuleViolationIcon} />
              <Typography sx={StatusDescription}>{t('results.couldNotBeCertified')}</Typography>
              <StatusBadge type={license?.status} />
            </Stack>
          </Box>
        );
      }
      case 'STARTED':
      case 'COMPLETED':
      case 'UPLOADING': {
        return (
          <Box sx={[TileBody, { marginTop: '9px', height: '100%' }]}>
            <Stack sx={FlexCenter}>
              <Img width={81} sx={{ paddingBottom: '15px' }} src={TestStartedImage} />
              <Typography sx={StatusDescription}>{t('results.thisTestHasStarted')}</Typography>
              <StatusBadge type={license?.status} />
            </Stack>
          </Box>
        );
      }
      default: {
        return (
          <Box sx={[TileBody, { marginTop: '16px', height: '100%' }]}>
            <Stack sx={FlexCenter}>
              <Img width={135} sx={{ paddingBottom: '15px' }} src={noTestsImage} />
              <Typography sx={StatusDescription}>{t('results.noTestsTakenDescription')}</Typography>
              <StatusBadge type={license?.status} />
            </Stack>
          </Box>
        );
      }
    }
  };

  const showViewDetails = () => {
    if (license?.status) {
      return [
        'CERTIFICATION_FAILED',
        'PARTIALLY_SCORED',
        'ERROR',
        'SCORED',
        'CERTIFIED',
        'ABORTED',
        'LATE_HANGUP'
      ].includes(license.status);
    }
    return false;
  };

  return (
    <Stack sx={TileContainer}>
      <Box sx={TileHead}>
        <Typography component='span'>{t('results.latestTest')}</Typography>
      </Box>
      {prepareBody()}
      <Box
        sx={[TileFooter, !showViewDetails() ? { height: '18px' } : {}]}
        onClick={() =>
          showViewDetails() ? navigate(`/dashboard/results/test/${license?.id}`) : null
        }
      >
        {showViewDetails() && (
          <>
            <Typography>{getTakenOnLabel()}</Typography>
            <Img width={9} src={ArrowRight} />
          </>
        )}
      </Box>
    </Stack>
  );
};
