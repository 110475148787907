import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import {
  ActivationCodePopup,
  ActivationCodeStatus,
  sopClient,
  SopStatusResponse,
  sopUtils
} from '@barracuda/shared/src';

export interface ActivationCodePopupWrapperProps {
  closeDialog?: () => void;
  withSkipButton?: boolean;
  setActivationCloseable?: Dispatch<SetStateAction<boolean>>;
  setStatus: Dispatch<SetStateAction<ActivationCodeStatus>>;
  status: ActivationCodeStatus;
  checkRedeemBlocked?: boolean;
  handleTokenExpiredAction?: () => void;
}

export const ActivationCodePopupWrapper: FC<ActivationCodePopupWrapperProps> = ({
  closeDialog,
  withSkipButton,
  setActivationCloseable,
  status,
  setStatus,
  checkRedeemBlocked,
  handleTokenExpiredAction
}) => {
  const sopToken = sopUtils.getSopToken();

  useEffect(() => {
    setProperStatus();
  }, []);

  const setProperStatus = () => {
    if (checkRedeemBlocked) {
      sopClient.checkActivationCodeRedeemBlocked().then(
        (r) => {
          setStatus(r.data ? 'TOO_MANY_ATTEMPTS' : 'DEFAULT');
        },
        () => setStatus('DEFAULT')
      );
    } else {
      setStatus('DEFAULT');
    }
  };

  const redeemActivationCode = (activationCode: string) => {
    if (sopToken) {
      sopClient
        .redeemActivationCode(activationCode)
        .then(handleSuccessResponse, handleErrorResponse);
    }
  };
  const handleErrorResponse = (e: any) => {
    const {
      response: {
        data: { type }
      }
    } = e;

    switch (type) {
      case SopStatusResponse.VALIDATION_EXCEPTION: {
        setStatus('ALREADY_USED');
        break;
      }
      case SopStatusResponse.CODE_REDEEM_EXPIRED_EXCEPTION: {
        setStatus('EXPIRED');
        break;
      }
      case SopStatusResponse.CODE_REDEEM_BLOCKED: {
        setStatus('TOO_MANY_ATTEMPTS');
        setActivationCloseable?.(true);
        break;
      }
      case SopStatusResponse.AUTHENTICATION_EXPIRED: {
        handleTokenExpiredAction?.();
        break;
      }
      default:
        setStatus('INVALID');
    }
  };
  const handleSuccessResponse = () => setStatus('VALID');

  return (
    <ActivationCodePopup
      closeDialog={closeDialog}
      onSubmit={redeemActivationCode}
      status={status}
      setStatus={setStatus}
      withSkipButton={withSkipButton}
    />
  );
};
