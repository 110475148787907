import { FC, useContext } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { AppContext } from '../../context/App.context';

export const LocalizedNavLink: FC<NavLinkProps> = (props) => {
  const { language } = useContext(AppContext);
  return (
    <NavLink {...props} to={`/${language}${props.to}`}>
      {props.children}
    </NavLink>
  );
};
