import axios, { InternalAxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';

export interface SOPToken {
  readonly token: ParsedToken;
}

export interface ParsedToken {
  payload: string;
  exp: number;
  raw: string;
  expiresAt: Date;
}

export interface RefreshResponse {
  readonly token: string;
  readonly test: {
    readonly tin: number;
    readonly code: string;
    readonly licenseId: number;
  };
}

export interface BlockedApplicationDto {
  readonly name: string;
  readonly pattern: string;
  readonly blocked: boolean;
  readonly monitored: boolean;
}

function isExpired(token: ParsedToken): boolean {
  return new Date() > token.expiresAt;
}

const parseToken = (token: string): ParsedToken => {
  const decodedPayload = jwt_decode(token) as any;
  return {
    raw: token,
    expiresAt: new Date(decodedPayload.exp * 1000),
    ...decodedPayload
  };
};

const userAgentInterceptor = (userAgent: string) => (req: InternalAxiosRequestConfig) => {
  req.headers.set('User-Agent', userAgent);
  return req;
};

const authorizationInterceptor =
  (tokenRef: { current: SOPToken | null }, unauthorizedEndpoints?: (string | RegExp)[]) =>
  (req: InternalAxiosRequestConfig) => {
    const withoutToken = unauthorizedEndpoints?.reduce((acc, endpoint) => {
      const found =
        endpoint instanceof RegExp
          ? new RegExp(endpoint).test(req.url as string)
          : !!req.url?.includes(endpoint);

      return acc || found;
    }, false);
    if (withoutToken) return req;
    if (!tokenRef.current?.token) {
      console.log(`Attempt to call ${req.url} without AUTHORIZATION`);
    } else if (isExpired(tokenRef.current.token)) {
      console.log(`SOP token expired token=${tokenRef.current.token.raw}`);
    } else {
      req.headers.set('Authorization', `Bearer ${tokenRef.current.token.raw}`);
    }
    return req;
  };

export type SOPClientProtocolOptions = {
  userAgent?: string;
  token?: string;
  baseUrl: string;
  withXSRFToken?: boolean;
};

export const makeSOPClientProtocol = ({
  token,
  baseUrl,
  userAgent,
  withXSRFToken = true
}: SOPClientProtocolOptions) => {
  const tokenRef: { current: SOPToken | null } = { current: null };
  const httpProvider = axios.create({ withXSRFToken, withCredentials: true });

  const unauthorizedEndpoints = [
    '/api/licenses/id/verify',
    '/api/licenses/id/verify/status',
    '/api/authentication/login',
    '/api/testTaker/password/reset',
    '/api/authentication/password/validate',
    '/api/dictionaries/',
    '/api/products/BARRACUDA',
    /\/api\/shares\/(?!organizations)(?!revoke)/
  ];

  const setToken = (token: string) => {
    const parsedToken = parseToken(token);
    tokenRef.current = {
      token: parsedToken
    };
  };

  if (token) setToken(token);

  httpProvider.interceptors.request.use(authorizationInterceptor(tokenRef, unauthorizedEndpoints));

  if (userAgent) httpProvider.interceptors.request.use(userAgentInterceptor(userAgent));

  return {
    httpProvider,
    makeUrl: (url: string) => `${baseUrl}${url}`,
    deleteToken: () => {
      tokenRef.current = null;
    },
    getToken: () => tokenRef.current?.token.raw,
    setToken
  };
};

export type SOPClientProtocol = ReturnType<typeof makeSOPClientProtocol>;
