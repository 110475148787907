import { FC } from 'react';
import {
  dashboardUploadIdIconGreen,
  dashboardUploadIdIconRed,
  dashboardUploadIdSubheader,
  dashboardUploadIdTipsItemIcon,
  dashboardUploadIdTipsWrapper,
  dashboardUploadIdFourthImageStyles,
  dashboardUploadIdThirdImageStyles,
  dashboardUploadIdTipsContainer,
  dashboardUploadIdPutYourIdTextTipsRecommendationText
} from './DashboardUploadId.styles';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import cornersImage from '../../assets/images/idv-image-without-corners.png';
import CancelIcon from '@mui/icons-material/Cancel';
import blurryImage from '../../assets/images/idv-image-blurry.png';
import coveredImage from '../../assets/images/idv-image-covered.png';
import validImage from '../../assets/images/idv-image-valid.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Img } from '@barracuda/shared/src/components/Primitive';

export const DashboardUploadIdPhotoTips: FC = () => {
  const { t } = useTranslation();

  return (
    <Box sx={dashboardUploadIdTipsContainer}>
      <Typography
        sx={[dashboardUploadIdSubheader, dashboardUploadIdPutYourIdTextTipsRecommendationText]}
      >
        {t('mobileUploadId.guidelines')}
      </Typography>
      <Box sx={dashboardUploadIdTipsWrapper}>
        <Box>
          <Img src={cornersImage} />
          <CancelIcon sx={[dashboardUploadIdTipsItemIcon, dashboardUploadIdIconRed]} />
          <Typography>{t('mobileUploadId.cornersMissing')}</Typography>
        </Box>
        <Box>
          <Img src={blurryImage} />
          <CancelIcon sx={[dashboardUploadIdTipsItemIcon, dashboardUploadIdIconRed]} />
          <Typography>{t('mobileUploadId.blurry')}</Typography>
        </Box>
        <Box sx={dashboardUploadIdThirdImageStyles}>
          <Img src={coveredImage} />
          <CancelIcon sx={[dashboardUploadIdTipsItemIcon, dashboardUploadIdIconRed]} />
          <Typography>{t('mobileUploadId.covered')}</Typography>
        </Box>
        <Box sx={dashboardUploadIdFourthImageStyles}>
          <Img src={validImage} />
          <CheckCircleIcon sx={[dashboardUploadIdTipsItemIcon, dashboardUploadIdIconGreen]} />
          <Typography>{t('mobileUploadId.clear')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
