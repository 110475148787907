import background from '../../assets/images/whatCanDoNextTile--background.svg';
import { theme } from '../../styles/theme';

export const whatCanDoNextTileContainer = {
  background: `${theme.palette.common.darkBlue9} url(${background}) right bottom no-repeat`,
  color: theme.palette.common.darkGrey2,
  justifyContent: 'center',
  borderRadius: '10px',
  padding: '28px  30px'
};

export const whatCanWeDoNextTileLink = {
  color: theme.palette.common.darkTeal,
  textDecoration: 'underline',
  cursor: 'pointer',
  display: 'inline-block',
  fontWeight: 600
};

export const whatCanDoNextTileHeader = {
  fontSize: '18px',
  fontWeight: 400,
  color: theme.palette.common.white
};
