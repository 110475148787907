export const title = {
  fontSize: '25px',
  fontWeight: 600,
  paddingBottom: '5px'
};

export const slider = {
  marginTop: '30px',
  width: '100%',
  // prevent cutting children shadows
  '.swiper.swiper-initialized': {
    marginInline: '-10px',
    padding: '10px'
  }
};

export const sliderItemWrapper = {
  display: 'flex',
  cursor: 'pointer'
};
