import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// Client-Side Cookie Management: https://community.cookiepro.com/s/article/UUID-730ad441-6c4d-7877-7f85-36f1e801e8ca?language=en_US
export const OneTrustBanner: FC<{
  readonly apiKey: string;
  readonly consentUpdate: (value: boolean) => void;
}> = ({ apiKey, consentUpdate }) => {
  const isInTestMode = process.env.REACT_APP_ONETRUST_TESTING === 'true';

  useEffect(() => {
    // import style only when component is mounted
    // @ts-ignore
    import('./OneTrustBanner.styles.css');

    window.OptanonWrapper = consentUpdate;
  }, []);

  // @ts-ignore
  return (
    <Helmet>
      <script
        src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
        type='text/javascript'
        charSet='UTF-8'
        data-domain-script={`${apiKey}${isInTestMode ? '-test' : ''}`}
        data-document-language='true'
      ></script>
    </Helmet>
  );
};
