import { theme } from '../../styles/theme';

export const container = {
  cursor: 'pointer'
};

export const triangle = {
  borderStyle: 'solid',
  borderWidth: '17px 0 18px 35px',
  borderColor: 'transparent transparent transparent rgba(255, 255, 255, 0.75)',
  zIndex: '2',
  position: 'absolute'
};

export const triangleContainer = {
  background: '#EEEEEE',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  img: {
    width: '100%'
  }
};

export const videoContainer = {
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column'
} as const;

export const textsContainer = {
  padding: '20px',
  background: theme.palette.common.white,
  flex: '1'
};

export const title = {
  fontWeight: 600
};

export const subtitleStyle = {
  fontSize: '14px',
  color: theme.palette.common.darkBlue2
};
