import { FC } from 'react';
import { Box, Link, Stack, Typography, SxProps, Theme } from '@mui/material';
import arrowRightBlue from '../../assets/images/arrow-right-blue.svg';
import {
  bottomLinkContainer,
  link,
  container,
  header,
  iconContainer,
  list
} from './GettingReadyPageBoxWithIcon.styles';
import { Img } from '../Primitive';

interface GettingReadyPageProps {
  icon: string;
  title: string;
  subtitle?: string;
  description?: string;
  points?: string[];
  bottomLink?: any;
  linkAction: (value: string) => void;
  sx?: SxProps<Theme>;
}

export const GettingReadyPageBoxWithIcon: FC<GettingReadyPageProps> = ({
  icon,
  title,
  subtitle,
  points,
  bottomLink,
  linkAction,
  sx
}) => {
  return (
    <Stack sx={{ ...container, ...sx }}>
      <Box sx={subtitle ? { marginBottom: '20px' } : iconContainer}>
        <Img src={icon} />
      </Box>
      <Typography sx={header} component='h4'>
        {title}
      </Typography>
      {subtitle && <Typography sx={{ marginBottom: '5px' }}>{subtitle}</Typography>}
      <ul style={list}>
        {points?.map((point, index) => (
          <li key={`point-${index}`}>{point}</li>
        ))}
      </ul>
      {bottomLink && (
        <Box sx={bottomLinkContainer}>
          <Link onClick={() => linkAction(bottomLink.name)} sx={link}>
            {bottomLink.name}
          </Link>
          <Img sx={{ marginLeft: '10px' }} src={arrowRightBlue} />
        </Box>
      )}
    </Stack>
  );
};
