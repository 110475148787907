import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import {
  CellStyles,
  OrderRefundPopupAccordionTableTheadCell,
  OrderRefundPopupButtonContainer,
  OrderRefundPopupCheckboxContainer,
  OrderRefundPopupCloseIcon,
  OrderRefundPopupDialogWrapperContainer,
  OrderRefundPopupSubmit,
  OrderRefundPopupSubTitle,
  OrderRefundPopupTableCheckbox,
  OrderRefundPopupTableContainer,
  OrderRefundPopupTableRow,
  OrderRefundPopupTableRowCell,
  OrderRefundPopupTableRowFirstCell,
  OrderRefundPopupTableRowLastCell,
  OrderRefundPopupTableRowMiddleCell,
  OrderRefundPopupTitle,
  RefundPopupMain,
  SmallDateStyles,
  SuccessMobile
} from '../../OrderRefundPopup.style';
import CloseIcon from '@mui/icons-material/Close';
import Img from '@barracuda/shared/src/components/Primitive/Img';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import InfoIcon from '@sharedImages/info-icon.svg';
import { formatCurrency } from '@barracuda/shared/src';
import { LicenceOfProduct, TransactionProducts } from '../../interfaces/OrderRefundPopup.interface';
import { purpleTooltip } from '@barracuda/shared/src/styles/tooltip';

interface OrderRefundablePopupContentProps {
  readonly tableHeaders: string[];
  readonly transaction: TransactionProducts[];
  readonly handleClose: () => void;
  readonly handleRefund: (ids: number[]) => void;
}

export const OrderRefundablePopupContent: FC<OrderRefundablePopupContentProps> = ({
  tableHeaders,
  transaction,
  handleClose,
  handleRefund
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const matches700 = useMediaQuery('(max-width: 700px)');
  const { t } = useTranslation();

  useEffect(() => {
    setDefaultChecked(transaction);
  }, [transaction]);

  const isRefundable = (item: LicenceOfProduct): boolean =>
    ['VALIDATED', 'NEW'].includes(item.status);
  const isRefundableByCartCost = (cart: TransactionProducts) => cart.totalPrice !== 0;
  const markAsSelected = (id: number) => {
    if (selectedIds.find((select) => select === id)) {
      setSelectedIds(selectedIds.filter((select) => select !== id));
    } else {
      setSelectedIds([...new Set([...selectedIds, id])]);
    }
  };

  const setDefaultChecked = (products: TransactionProducts[]) => {
    const allValidLicenses = products
      .flatMap((item) => item.licenses)
      .filter((license) => isRefundable(license));

    if (allValidLicenses.length === 1) {
      markAsSelected(allValidLicenses[0].id);
    }
  };

  const checkIfDefaultChecked = (item: LicenceOfProduct) => {
    const allValidLicenses = transaction
      .flatMap((item) => item.licenses)
      .filter((license) => isRefundable(license));
    return allValidLicenses?.length === 1 && allValidLicenses[0] === item;
  };
  const combinedRefundStatement = (licence: LicenceOfProduct, cart: TransactionProducts) =>
    isRefundable(licence) && isRefundableByCartCost(cart);
  return (
    <Box sx={RefundPopupMain}>
      <Box sx={{ letterSpacing: 0 }}>
        <Typography component='h1' sx={OrderRefundPopupTitle}>
          {t('profilePage.requestARefund')}
        </Typography>
        <Typography sx={OrderRefundPopupSubTitle}>{t('profilePage.selectProducts')}</Typography>
      </Box>

      <IconButton aria-label='close' onClick={handleClose} sx={OrderRefundPopupCloseIcon}>
        <CloseIcon />
      </IconButton>
      <Grid container spacing={0} sx={[OrderRefundPopupDialogWrapperContainer, SuccessMobile]}>
        <Box sx={OrderRefundPopupTableContainer}>
          <TableContainer sx={{ maxHeight: '300px' }}>
            <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableCell
                      key={`${header}${index}`}
                      sx={OrderRefundPopupAccordionTableTheadCell}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {transaction?.map((item: TransactionProducts) => (
                  <>
                    {item.licenses.map((license: LicenceOfProduct, index: number) => (
                      <TableRow
                        key={`${license}${index}`}
                        sx={OrderRefundPopupTableRow(combinedRefundStatement(license, item))}
                      >
                        <TableCell sx={OrderRefundPopupTableRowCell}>
                          <Box
                            sx={[
                              OrderRefundPopupCheckboxContainer,
                              OrderRefundPopupTableRowFirstCell,
                              CellStyles(combinedRefundStatement(license, item))
                            ]}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <FormControlLabel
                                sx={{ marginRight: 0 }}
                                label=''
                                control={
                                  <Checkbox
                                    onChange={() => markAsSelected(license.id)}
                                    disabled={!combinedRefundStatement(license, item)}
                                    defaultChecked={checkIfDefaultChecked(license)}
                                    sx={OrderRefundPopupTableCheckbox}
                                  />
                                }
                              />
                              <Typography className='bold'>{item.product.name}</Typography>
                              {!combinedRefundStatement(license, item) && (
                                <Tooltip
                                  componentsProps={{ tooltip: { sx: purpleTooltip } }}
                                  placement='right'
                                  arrow
                                  title={
                                    license.status === 'REFUNDED'
                                      ? t('orderHistory.testAlreadyRefunded')
                                      : t('profilePage.jitNotice')
                                  }
                                >
                                  <Img sx={{ marginLeft: '10px' }} src={InfoIcon} alt='Info' />
                                </Tooltip>
                              )}
                            </Box>
                            {matches700 && (
                              <Box>
                                <Typography sx={SmallDateStyles}>
                                  {
                                    new Set(
                                      item.licenses.map((item: LicenceOfProduct) =>
                                        moment(item.createdAt).format('DD MMMM yyyy')
                                      )
                                    )
                                  }
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </TableCell>
                        {!matches700 && (
                          <TableCell sx={OrderRefundPopupTableRowCell}>
                            <Typography
                              sx={[
                                OrderRefundPopupTableRowMiddleCell,
                                CellStyles(combinedRefundStatement(license, item))
                              ]}
                            >
                              {
                                new Set(
                                  item.licenses.map((item: LicenceOfProduct) =>
                                    moment(item.createdAt).format('DD MMMM yyyy')
                                  )
                                )
                              }
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell sx={OrderRefundPopupTableRowCell}>
                          <Typography
                            sx={[
                              OrderRefundPopupTableRowLastCell,
                              CellStyles(combinedRefundStatement(license, item))
                            ]}
                          >
                            {formatCurrency(
                              item.price,
                              item.currency.code,
                              item.currency.fractionDigits
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Grid item xs={12} sx={OrderRefundPopupButtonContainer}>
          <Button
            disabled={!selectedIds.length}
            onClick={() => handleRefund(selectedIds)}
            tabIndex={0}
            type='submit'
            sx={OrderRefundPopupSubmit}
          >
            {t('profilePage.refund')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
