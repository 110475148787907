import { FC } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { SignInButton } from '../SignIn/SignInStyles';
import VecMobileScreen from '../../assets/images/barracuda-laptop.png';
import VecDesktopScreen from '../../assets/images/vec-desktop-screen.png';
import {
  DownloadButton,
  DownloadButtonContainer
} from '../DownloadAppPopup/DownloadAppPopup.style';
import { ImageStyle, ParagraphStyle } from './SoftProfileAllDone.style';
import { useTranslation, Trans } from 'react-i18next';
import { Img } from '../Primitive';

interface SoftProfileAllDoneProps {
  closeDialog: (key: string) => void;
  isMobile?: boolean;
}

export const SoftProfileAllDone: FC<SoftProfileAllDoneProps> = ({ closeDialog, isMobile }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography sx={ParagraphStyle}>
            <Trans
              i18nKey='dashboardPage.SoftProfileAllDoneText'
              components={{
                bold: <Typography component='span' sx={{ fontWeight: 600 }} />
              }}
            />
          </Typography>
          <Img sx={ImageStyle} src={isMobile ? VecMobileScreen : VecDesktopScreen} />
          <Box sx={[DownloadButtonContainer, { marginTop: '50px' }]}>
            <Button onClick={() => closeDialog('allDone')} sx={[SignInButton, DownloadButton]}>
              {t('dashboardPage.SoftProfileAllDoneButton')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
