import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { DotsContainer, progressDot } from './ProgressDots.styles';

export interface ProgressDotsProps {
  numberOfDots: number;
  activeIndex: number;
}
export const ProgressDots: FC<ProgressDotsProps> = ({ numberOfDots, activeIndex }) => {
  const dotsArray = new Array(numberOfDots).fill(0);

  return (
    <Stack alignItems='center' justifyContent='center' flexDirection='row' sx={DotsContainer}>
      {dotsArray.map((_, index) => (
        <Box sx={progressDot(index === activeIndex)} />
      ))}
    </Stack>
  );
};
