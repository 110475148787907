import { BaseSyntheticEvent, FC, useContext, useState } from 'react';
import { AppContext } from '../../context/App.context';
import { LanguageInterface, SopStatusResponse, User, sopClient } from '@barracuda/shared/src';
import { SignUpErrorMessage } from '@barracuda/shared/src/components/SignUp/SignUp.styles';
import { SignInButton } from '@barracuda/shared/src/components/SignIn/SignInStyles';
import { useForm } from 'react-hook-form';
import { Country } from '@barracuda/shared/src/interface/dictionary.interfaces';
import {
  ButtonStyles,
  FormInputContainer,
  InputLabel,
  LabelWithTooltip,
  UserDataFormInput
} from './TellUsMoreForm.style';
import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

interface TellUsMoreFormProps {
  closeDialog: (key: string, ageFailed?: boolean) => void;
  handleTokenExpiredAction: () => void;
}

export const TellUsMoreForm: FC<TellUsMoreFormProps> = ({
  closeDialog,
  handleTokenExpiredAction
}) => {
  const { user, setUser } = useContext(AppContext);
  const [cities, setCities] = useState<Country[] | []>([]);
  const [preferredName, setPreferredName] = useState<string | null>(user?.preferredName || null);
  const [countries, setCountries] = useState<Country[] | []>([]);
  const [city, setCity] = useState<Country | null>(user?.cityOfResidence || null);
  const [country, setCountry] = useState<Country | null>(user?.countryOfResidence || null);
  const {
    register,
    setValue,
    trigger,
    getValues,
    formState: { errors },
    formState
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });
  const { t } = useTranslation();
  const nonLetters = /[^\p{L} ]/u;
  const lettersOnly = /\p{L}/u;

  const handleSubmit = async (dto: Partial<User>) => {
    try {
      const response = await sopClient.updateProfile(dto);
      const { data } = response;
      setUser({ ...user, ...data });
      closeDialog('tellUsMore', data.registrationAge <= 14);
    } catch (error: any) {
      const {
        response: {
          data: { type }
        }
      } = error;
      if (type === SopStatusResponse.AUTHENTICATION_EXPIRED) {
        handleTokenExpiredAction();
      }
    }
  };

  const clearCity = () => {
    setCity(null);
    setValue('cityOfResidence', null);
    if (country) {
      trigger('cityOfResidence');
    }
  };

  const handleAutocompleteChange = (
    _: BaseSyntheticEvent,
    v: Country | LanguageInterface | null,
    formControlName: string
  ) => {
    switch (formControlName) {
      case 'countryOfResidence':
        setCountry(v as Country);
        clearCity();
        break;
      case 'cityOfResidence':
        setCity(v as Country);
        break;
    }
    setValue(formControlName, v || '');
    trigger(formControlName);
  };
  const getResources = async (query: string, resources: 'countries' | 'languages' | 'cities') => {
    switch (resources) {
      case 'countries': {
        const { data } = await sopClient.getCountries(query);
        setCountries(data?.results);
        break;
      }
      case 'cities': {
        const { data } = await sopClient.getCities(query, country?.id as number);
        setCities(data?.results);
        break;
      }
    }
  };
  const handleInputChange = (
    event: BaseSyntheticEvent,
    resources: 'countries' | 'languages' | 'cities'
  ) => {
    const value = event?.target.value;
    getResources(value, resources);
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item md={6} sm={6} xs={12} sx={FormInputContainer}>
          <Box sx={LabelWithTooltip}>
            <Typography sx={InputLabel}>{t('tellUsMoreForm.preferredName')}</Typography>
          </Box>
          <TextField
            {...register('preferredName', {
              required: true,
              pattern: lettersOnly
            })}
            inputProps={{ maxLength: 35 }}
            type='text'
            id='preferredName'
            variant='outlined'
            value={preferredName}
            onChange={(e) => setPreferredName(e.target.value.replace(nonLetters, ''))}
            sx={UserDataFormInput}
          />
          {errors?.preferredName?.type === 'required' && (
            <Typography sx={[SignUpErrorMessage]}>{t('tellUsMoreForm.fieldRequired')}</Typography>
          )}
        </Grid>
        <Grid item md={6} sm={6} xs={12} sx={FormInputContainer}>
          <Box sx={LabelWithTooltip}>
            <Typography sx={InputLabel}>{t('tellUsMoreForm.age')}</Typography>
          </Box>
          <TextField
            {...register('registrationAge', {
              required: true,
              valueAsNumber: true
            })}
            type='number'
            id='registrationAge'
            variant='outlined'
            sx={UserDataFormInput}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12} sx={FormInputContainer}>
          <Typography sx={InputLabel}>{t('tellUsMoreForm.country')}</Typography>
          <Autocomplete
            {...register('countryOfResidence', {
              required: true,
              value: country
            })}
            sx={UserDataFormInput}
            forcePopupIcon={false}
            disablePortal={false}
            id='countryInput'
            options={countries.length ? countries : []}
            getOptionLabel={(option) => option.name}
            onInputChange={debounce((e) => handleInputChange(e, 'countries'), 300)}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'countryOfResidence')
            }
            value={country}
            renderInput={(params) => (
              <TextField {...params} type='text' id='countryOfResidence' variant='outlined' />
            )}
          />
          {formState.errors?.countryOfResidence?.type === 'required' && (
            <Typography sx={[SignUpErrorMessage]}>{t('tellUsMoreForm.fieldRequired')}</Typography>
          )}
        </Grid>
        <Grid item md={6} sm={6} xs={12} sx={FormInputContainer}>
          <Typography sx={InputLabel}>{t('tellUsMoreForm.city')}</Typography>
          <Autocomplete
            {...register('cityOfResidence', {
              required: true,
              value: city
            })}
            sx={UserDataFormInput}
            forcePopupIcon={false}
            disablePortal={false}
            id='cityOfResidence'
            options={cities.length ? cities : city ? [city] : []}
            getOptionLabel={(option) => option.name}
            onInputChange={debounce((e) => handleInputChange(e, 'cities'), 300)}
            onChange={(event, value) => handleAutocompleteChange(event, value, 'cityOfResidence')}
            value={city}
            renderInput={(params) => (
              <TextField {...params} type='text' id='cityOfResidenceInput' variant='outlined' />
            )}
          />
          {formState.errors?.cityOfResidence?.type === 'required' && (
            <Typography sx={[SignUpErrorMessage]}>{t('tellUsMoreForm.fieldRequired')}</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            type='submit'
            onClick={() => handleSubmit(getValues())}
            disabled={Object.keys(errors).length > 0 || !formState.isValid}
            sx={[SignInButton, ButtonStyles]}
          >
            {t('tellUsMoreForm.next')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
