import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { BannerContainer, DescriptionStyle, OrderDetails } from './ProcessingOrderBanner.style';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const ProcessingOrderBanner: FC<{ recentOrder: { orderId: string; date: Date } | null }> = ({
  recentOrder
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={BannerContainer}>
      <Typography sx={DescriptionStyle}>{t('processingOrderBanner.description')}</Typography>
      <Box sx={OrderDetails}>
        <Typography>
          {t('processingOrderBanner.orderId')}
          <Typography component='span'>{recentOrder?.orderId}</Typography>
        </Typography>
        <Typography>
          {t('processingOrderBanner.dateOfPurchase')}
          <Typography component='span'>
            {moment(recentOrder?.date).format('DD MMMM YYYY')}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};
