import { FC } from 'react';
import {
  FS13,
  FW600,
  MB10,
  MB20,
  PrivacyPolicyContentParagraph,
  PrivacyPolicyItalic
} from '../PrivacyPolicyContent/PrivacyPolicyContent.styles';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { theme } from '../../styles/theme';

interface CookiePolicyContent {
  isLoggedIn?: boolean;
}

export const CookiePolicyContent: FC<CookiePolicyContent> = ({ isLoggedIn }) => {
  const { t } = useTranslation();
  const privacyPolicyLink = `${process.env.REACT_APP_PESC_URL}/${
    isLoggedIn ? 'dashboard/' : ''
  }privacy-policy`;

  return (
    <Box sx={{ color: theme.palette.common.darkBlue7 }}>
      <Typography
        dangerouslySetInnerHTML={{
          __html: t('cookiePolicy.preSection.paragraph1', { privacyPolicyLink: privacyPolicyLink })
        }}
        sx={[PrivacyPolicyContentParagraph]}
      ></Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: t('cookiePolicy.preSection.paragraph2') }}
        sx={[PrivacyPolicyContentParagraph, MB20]}
      />
      <Typography sx={[PrivacyPolicyContentParagraph, FW600]}>
        {t('cookiePolicy.content.paragraph1.header')}
      </Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
        {t('cookiePolicy.content.paragraph1.description')}
      </Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: t('cookiePolicy.content.paragraph2.description') }}
        sx={[PrivacyPolicyContentParagraph, MB20, FS13]}
      ></Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, FW600]}>
        {t('cookiePolicy.content.paragraph3.header')}
      </Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: t('cookiePolicy.content.paragraph3.description') }}
        sx={[PrivacyPolicyContentParagraph, MB20]}
      ></Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
        {t('cookiePolicy.content.paragraph4.description')}
      </Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
        {t('cookiePolicy.content.paragraph5.description')}
      </Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
        {t('cookiePolicy.content.paragraph6.description')}
      </Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: t('cookiePolicy.content.paragraph7.description') }}
        sx={[PrivacyPolicyContentParagraph, MB20, PrivacyPolicyItalic]}
      ></Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: t('cookiePolicy.content.paragraph8.description') }}
        sx={[PrivacyPolicyContentParagraph, MB20, PrivacyPolicyItalic]}
      ></Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: t('cookiePolicy.content.paragraph9.description') }}
        sx={[PrivacyPolicyContentParagraph, MB20, PrivacyPolicyItalic]}
      ></Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: t('cookiePolicy.content.paragraph10.description') }}
        sx={[PrivacyPolicyContentParagraph, MB20, PrivacyPolicyItalic]}
      ></Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: t('cookiePolicy.content.paragraph11.description') }}
        sx={[PrivacyPolicyContentParagraph, MB20, PrivacyPolicyItalic]}
      ></Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: t('cookiePolicy.content.paragraph12.description') }}
        sx={[PrivacyPolicyContentParagraph, MB20, PrivacyPolicyItalic]}
      ></Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: t('cookiePolicy.content.paragraph13.description') }}
        sx={[PrivacyPolicyContentParagraph, MB20, PrivacyPolicyItalic]}
      ></Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, FW600]}>
        {t('cookiePolicy.content.paragraph14.header')}
      </Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
        {t('cookiePolicy.content.paragraph14.description')}
      </Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
        {t('cookiePolicy.content.paragraph15.description')}
      </Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
        {t('cookiePolicy.content.paragraph16.description')}
      </Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, FW600]}>
        {t('cookiePolicy.content.paragraph17.header')}
      </Typography>
      <Box component='ul'>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph17.bullet1.description')}
          <Box component='ul'>
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet1.subbullet1')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet1.subbullet2')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet1.subbullet3')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet1.subbullet4')
              }}
            />
          </Box>
        </Box>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph17.bullet2.description')}
          <Box component='ul'>
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet2.subbullet1')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet2.subbullet2')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet2.subbullet3')
              }}
            />
          </Box>
        </Box>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph17.bullet3.description')}
          <Box component='ul'>
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet3.subbullet1')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet3.subbullet2')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet3.subbullet3')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet3.subbullet4')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet3.subbullet5')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet3.subbullet6')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet3.subbullet7')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet3.subbullet8')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet3.subbullet9')
              }}
            />
            <Box
              sx={[MB10]}
              component='li'
              dangerouslySetInnerHTML={{
                __html: t('cookiePolicy.content.paragraph17.bullet3.subbullet10')
              }}
            />
          </Box>
        </Box>
      </Box>
      <Typography sx={[PrivacyPolicyContentParagraph, FW600]}>
        {t('cookiePolicy.content.paragraph18.header')}
      </Typography>
      <Box component='ul'>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph18.bullet1.description')}
        </Box>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph18.bullet2.description')}
        </Box>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph18.bullet3.description')}
        </Box>
      </Box>
      <Typography sx={[PrivacyPolicyContentParagraph, FW600]}>
        {t('cookiePolicy.content.paragraph19.header')}
      </Typography>
      <Box component='ul'>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph19.bullet1.description')}
        </Box>
      </Box>
      <Typography sx={[PrivacyPolicyContentParagraph, FW600]}>
        {t('cookiePolicy.content.paragraph20.header')}
      </Typography>
      <Box component='ul'>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph20.bullet1.description')}
        </Box>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph20.bullet2.description')}
        </Box>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph20.bullet3.description')}
        </Box>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph20.bullet4.description')}
        </Box>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph20.bullet5.description')}
        </Box>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph20.bullet6.description')}
        </Box>
        <Box sx={[MB10]} component='li'>
          {t('cookiePolicy.content.paragraph20.bullet7.description')}
        </Box>
      </Box>
      <Typography sx={[PrivacyPolicyContentParagraph, FW600]}>
        {t('cookiePolicy.content.paragraph21.header')}
      </Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
        {t('cookiePolicy.content.paragraph21.description')}
      </Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, FW600]}>
        {t('cookiePolicy.content.paragraph22.header')}
      </Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: t('cookiePolicy.content.paragraph22.description') }}
        sx={[PrivacyPolicyContentParagraph, MB20]}
      ></Typography>
      <Typography sx={[PrivacyPolicyContentParagraph, FW600]}>
        {t('cookiePolicy.content.paragraph23.header')}
      </Typography>
    </Box>
  );
};
