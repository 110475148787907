import { SignInButton, SignInButtonOutline } from '../SignIn/SignInStyles';
import { theme } from '../../styles/theme';

export const MobileButtonStyle = {
  margin: 0,
  '@media only screen and (max-width: 770px)': {
    fontSize: '14px'
  }
};
export const typeOfTestContainer = (vertical: boolean) => ({
  padding: vertical ? '30px' : '40px 30px',
  '@media screen and (max-width: 1085px)': {
    padding: '20px'
  },
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.darkGrey0}`,
  borderRadius: '8px',
  '@media screen and (max-width: 830px)': {
    padding: '20px'
  },
  '@media screen and (max-width: 750px)': {
    display: 'grid'
  }
});

export const typeOfTestContainerInner = (vertical: boolean) => ({
  padding: vertical ? '2rem 0' : '1rem 2rem',
  display: 'flex',
  flexWrap: 'wrap',
  height: '100%',
  letterSpacing: 0,
  'media screen and (max-width: 1024px)': {
    paddingBottom: '60px'
  }
});
export const typeOfTestTextContainer = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  '@media screen and (max-width: 850px)': {
    flexFlow: 'column'
  }
};
export const typeOfTestTitle = {
  fontWeight: 700,
  lineHeight: '26px',
  '@media screen and (max-width: 900px)': {
    fontSize: '18px'
  },
  '@media screen and (max-width: 600px)': {
    fontSize: '16px',
    lineHeight: 1.3
  }
};

export const typeOfTestTags = {
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: 1.5,
  display: 'flex',
  '@media screen and (max-width: 900px)': {
    fontSize: '12px',
    margin: 0
  }
};
export const typeOfTestTime = {
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: 1.5,
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'start',
  minWidth: 'fit-content',
  img: {
    marginRight: '.25rem'
  },
  '@media screen and (max-width: 600px)': {
    fontSize: '12px',
    img: {
      width: '12px',
      height: '12px'
    }
  }
};

export const typeOfTestPrice = {
  fontSize: '25px',
  fontWeight: 700,
  lineHeight: '26px',
  margin: '1rem 0',
  paddingBottom: '47px'
};

export const typeOfTestButtonPractice = {
  ...SignInButtonOutline,
  ...MobileButtonStyle,
  marginBottom: 0,
  marginLeft: '1rem',
  textTransform: 'inherit',
  '@media screen and (max-width: 770px)': {
    marginLeft: '0.75rem'
  }
};
export const typeOfTestButtonBuyNow = {
  ...SignInButton,
  ...MobileButtonStyle,
  textTransform: 'inherit'
};
export const ImgSizeStyle = (vertical: boolean) => ({
  width: vertical ? '100%' : 'auto',
  height: vertical ? 'auto' : '100%',
  maxWidth: '100%',
  '@media screen and (max-width: 1024px)': {
    maxWidth: !vertical ? '100%' : 'unset',
    height: 'auto'
  },
  '@media screen and (max-width: 750px)': {
    width: '100%',
    height: 'auto'
  }
});
