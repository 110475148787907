import { FC } from 'react';

interface ISoundBarProps {
  xPos: number;
  height: number;
  offset: number;
}

export const SoundBar: FC<ISoundBarProps> = ({ xPos, height, offset }) => {
  const h = Math.max(height, 10);
  return (
    <path
      fill='black'
      d={`M ${xPos},${70.5 - h / 2 + offset} c -2.75,0 -5,2.25 -5,5 v ${
        h - 10
      } c 0,2.75 2.25,5 5,5 2.75,0 5,-2.25 5,-5 v -${h - 10} c 0,-2.75 -2.25,-5 -5,-5 z`}
    />
  );
};
