const getTokenFunction = () => {
  return `Bearer ${localStorage.getItem('token')}`;
};
export const ellCommerceInstanceConfig = {
  getTokenFunction: getTokenFunction,
  cartServiceUri: process.env.REACT_APP_PESC_ECOMMERCE_CARTS_API_URL || '',
  customerServiceUri: process.env.REACT_APP_PESC_ECOMMERCE_CUSTOMERS_API_URL || '',
  orderServiceUri: process.env.REACT_APP_PESC_ECOMMERCE_ORDERS_API_URL || '',
  productServiceUri: process.env.REACT_APP_PESC_ECOMMERCE_PRODUCTS_API_URL || '',
  applicationId: process.env.REACT_APP_PESC_ECOMMERCE_APPLICATION_ID || ''
};
