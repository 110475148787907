export const SoftProfileSurveyFormInput = {
  '@media only screen and (min-width: 320px) and (max-width: 510px)': {
    width: '100%'
  },
  '@media only screen and (min-width: 320px) and (max-width: 400px)': {
    marginLeft: 0
  }
};
export const FormContainer = {
  position: 'relative',
  paddingBottom: '50px',
  marginTop: '20px',
  '@media screen and (max-height: 600px)': {
    paddingBottom: '20px',
    marginTop: 0
  }
};
export const NextButtonAlign = {
  marginBottom: 0,
  marginTop: '20px',
  '@media screen and (max-height: 600px)': {
    marginTop: 0
  }
};
