import { theme } from '../../styles/theme';

export const accountContainerStyle = (width: string | undefined, height: string | undefined) => ({
  width: width || '50px',
  minWidth: width || '50px',
  height: height || '50px',
  background: '#EAEAEA',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    fill: theme.palette.common.white,
    height: calculateInnerSvgHeight(height),
    width: 'auto'
  }
});

export const accountImageStyle = (
  imageUri?: string,
  width?: string,
  height?: string,
  rectangular?: boolean,
  handlePhotoClick?: () => void
) => ({
  overflow: 'hidden',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  img: {
    opacity: 0
  },
  cursor: handlePhotoClick ? 'pointer' : 'unset',
  backgroundImage: `url('${imageUri}')`,
  width,
  height,
  minWidth: width,
  borderRadius: rectangular ? '4px' : '100px'
});

const calculateInnerSvgHeight = (height: string | undefined): string => {
  if (height) {
    const heightNumber = +height.split('px')[0];
    return heightNumber * 0.7 + 'px';
  }
  return 'auto';
};
