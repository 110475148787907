import { FC, useCallback, useState } from 'react';
import { Box, Button, Dialog, Stack, TextareaAutosize, Typography } from '@mui/material';
import { SignInButton } from '../SignIn/SignInStyles';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CloseIcon from '../../assets/images/close-circle-icon.svg';
import {
  PreviewContainer,
  PreviewFooter,
  PreviewHeader,
  PreviewHeaderContent,
  PreviewHeaderContentText,
  PreviewHeaderTitle
} from './SamplePreviewBox.style';
import { Listening, ListeningState } from '../Listening/Listening';
import { useTranslation } from 'react-i18next';

interface SamplePreviewBoxProps {
  mode: 'listen' | 'read';
  open: boolean;
  speakingResource: BlobPart | null;
  writingResource: string | null;
  handleClose: () => void;
  testTakerName: string;
}

export const SamplePreviewBox: FC<SamplePreviewBoxProps> = ({
  mode,
  open,
  handleClose,
  speakingResource,
  writingResource,
  testTakerName
}) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [audio, setAudio] = useState<HTMLAudioElement>();
  const [currentStage, setCurrentStage] = useState<ListeningState>('STANDBY');
  const { t } = useTranslation();

  const audioRef = useCallback(
    (node: HTMLAudioElement) => {
      if (node) {
        configureAudioElement(node);
      }
    },
    [speakingResource]
  );

  const playAudio = () => {
    setCurrentStage('ACTIVE');
    if (audio) {
      if (playing) {
        setPlaying(false);
        audio?.pause();
      } else {
        audio?.play().then((r) => console.log(r));
        setPlaying(true);
      }
      audio.onended = () => {
        setPlaying(false);
        setCurrentStage('STANDBY');
      };
    }
  };

  const configureAudioElement = (audio: HTMLAudioElement) => {
    if (speakingResource) {
      const blob = new Blob([speakingResource], { type: 'audio/wav' });
      audio.src = URL.createObjectURL(blob);
      setAudio(audio);
    }
  };

  const closePopup = () => {
    setCurrentStage('STANDBY');
    setPlaying(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={closePopup} maxWidth='md' disableEscapeKeyDown>
      <Stack sx={PreviewContainer}>
        <Box sx={PreviewHeader}>
          <Typography sx={PreviewHeaderTitle}>
            {mode === 'listen' ? t('results.speakingSample') : t('results.writingSample')}
          </Typography>
          <img style={{ cursor: 'pointer' }} onClick={() => closePopup()} src={CloseIcon} alt='' />
        </Box>
        <Box sx={PreviewHeaderContent}>
          <Typography sx={PreviewHeaderContentText}>
            {t('results.samplePreviewDescription', {
              fullName: testTakerName,
              skill: mode === 'listen' ? t('results.speakingSkill') : t('results.writingSkill')
            })}
          </Typography>
          {mode === 'listen' && (
            <>
              <audio id='AudioPlayerElement' ref={audioRef}></audio>
              <Listening
                state={currentStage}
                width='100%'
                duration={(audio?.duration || 0) * 1000}
                onPlayClick={playAudio}
              />
            </>
          )}
          {mode === 'read' && (
            <TextareaAutosize
              style={{
                resize: 'none',
                overflow: 'auto',
                width: '90%',
                fontSize: '16px',
                height: '380px'
              }}
              readOnly
              defaultValue={
                writingResource ? writingResource : (t('noSampleTextToPreview') as string)
              }
            />
          )}
        </Box>
        <Box sx={PreviewFooter}>
          <Button
            type='submit'
            onClick={() => closePopup()}
            sx={[SignInButton, { marginBottom: 0 }]}
          >
            {t('done')}
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};
