import {
  Box,
  Button,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  activationCodePopupBoxStyle,
  activationCodePopupImageStyle,
  activationCodePopupInputBoxStyle,
  activationCodePopupInputStyle,
  activationCodePopupSkipButtonStyle,
  activationCodePopupSmallScreenStyles,
  activationCodePopupValidationResultStyle
} from './ActivationCodePopup.styles';
import { useForm } from 'react-hook-form';
import { buttonDark } from '../../styles/button';
import InfoIcon from '../../assets/images/info-icon.svg';
import { Img } from '../Primitive';
import activationCodeImage from '../../assets/images/activation-code-popup-image.svg';
import checkedGreenIcon from '../../assets/images/checked-green-icon.svg';
import alreadyUsedImage from '../../assets/images/activation-code-already-used.svg';
import { ActivationCodeStatus } from '../../interface/activation-code.interface';
import { purpleTooltip } from '../../styles/tooltip';

export interface ActivationCodePopupProps {
  closeDialog?: () => void;
  onSubmit: (activationCode: string) => void;
  status: ActivationCodeStatus;
  setStatus: Dispatch<SetStateAction<ActivationCodeStatus>>;
  withSkipButton?: boolean;
}

export const ActivationCodePopup: FC<ActivationCodePopupProps> = ({
  closeDialog,
  onSubmit,
  status,
  setStatus,
  withSkipButton
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const showMessage = ['INVALID', 'VALID', 'ALREADY_USED', 'EXPIRED'].includes(status);
  const modifyStatusOnChange = ['INVALID', 'ALREADY_USED', 'EXPIRED'].includes(status);
  const showButton = ['DEFAULT', 'INVALID', 'ALREADY_USED', 'EXPIRED'].includes(status);
  const matchesSmallWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const { register, formState, getValues, setValue, trigger } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });

  const getStatusMessage = (status: ActivationCodeStatus) => {
    switch (status) {
      case 'ALREADY_USED':
        return t('activationCodePopup.usedMessage');
      case 'EXPIRED':
        return t('activationCodePopup.expiredMessage');
      case 'VALID':
        return t('activationCodePopup.validMessage');
      case 'INVALID':
        return t('activationCodePopup.invalidMessage');
      default:
        return null;
    }
  };

  const handleActivationCodeChange = (e: any) => {
    setValue('activationCode', e.target.value);
    trigger('activationCode');
    if (modifyStatusOnChange) {
      setStatus('DEFAULT');
    }
  };

  return status === 'TOO_MANY_ATTEMPTS' ? (
    <Stack>
      <Typography sx={{ paddingBottom: '30px' }}>
        {t('activationCodePopup.tooManyAttempts')}
      </Typography>
      <Img
        sx={{ alignSelf: 'center', width: { xs: '250px', sm: '350px' } }}
        src={alreadyUsedImage}
      />
    </Stack>
  ) : (
    <Stack alignItems='center'>
      <Typography>{t('activationCodePopup.description')}</Typography>
      <Box sx={activationCodePopupBoxStyle} justifyContent='center'>
        <Box sx={activationCodePopupInputBoxStyle}>
          <TextField
            {...register('activationCode', {
              required: true
            })}
            InputProps={{
              readOnly: status === 'VALID'
            }}
            onChange={handleActivationCodeChange}
            placeholder={t('activationCodePopup.inputPlaceholder')}
            type='text'
            id='activationCodeInput'
            sx={activationCodePopupInputStyle(status)}
          />
          {showMessage && (
            <Typography sx={activationCodePopupValidationResultStyle(status)}>
              {getStatusMessage(status)}
            </Typography>
          )}
        </Box>

        {showButton && !matchesSmallWidth && (
          <Button
            type='submit'
            disabled={
              !formState.isValid ||
              status === 'INVALID' ||
              status === 'ALREADY_USED' ||
              status === 'EXPIRED'
            }
            sx={buttonDark}
            id='activationCodeSubmit'
            onClick={() => onSubmit(getValues('activationCode'))}
          >
            {t('activationCodePopup.buttonText')}
          </Button>
        )}
        {status === 'VALID' && <Img src={checkedGreenIcon} />}
        <Tooltip
          componentsProps={{ tooltip: { sx: purpleTooltip } }}
          placement='bottom-end'
          open={tooltipOpened}
          arrow
          title={<Trans i18nKey='activationCodePopup.tooltip' components={{ bold: <strong /> }} />}
        >
          <Img
            width={24}
            src={InfoIcon}
            alt='Tooltip hint icon'
            onMouseLeave={() => setTooltipOpened(false)}
            onMouseOver={() => setTooltipOpened(true)}
            onClick={() => setTooltipOpened(!tooltipOpened)} //For mobile purposes, since there is tap instead of hover functionality.
          />
        </Tooltip>
      </Box>
      {showButton && matchesSmallWidth && (
        <Button
          type='submit'
          disabled={
            !formState.isValid ||
            status === 'INVALID' ||
            status === 'ALREADY_USED' ||
            status === 'EXPIRED'
          }
          sx={[buttonDark, activationCodePopupSmallScreenStyles]}
          id='activationCodeSubmit'
          onClick={() => onSubmit(getValues('activationCode'))}
        >
          {t('activationCodePopup.buttonText')}
        </Button>
      )}
      <Img src={activationCodeImage} sx={activationCodePopupImageStyle} />
      {withSkipButton && (
        <Typography
          sx={activationCodePopupSkipButtonStyle}
          id='activationCodeSkip'
          onClick={closeDialog}
        >
          {t(formState.isValid ? 'activationCodePopup.next' : 'activationCodePopup.skip')}
        </Typography>
      )}
    </Stack>
  );
};
