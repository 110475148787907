import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  scoreLookupExpiredViewContainer,
  scoreLookupExpiredViewTitle
} from './ScoreLookupExpiredView.style';
import LookupImage from '@barracuda/shared/src/assets/images/empty-table-image.svg';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { buttonOutlineDark } from '@barracuda/shared/src/styles/button';

export const ScoreLookupExpiredView: FC<{ backToHomepage: () => void }> = ({ backToHomepage }) => {
  const { t } = useTranslation();

  return (
    <Stack sx={scoreLookupExpiredViewContainer}>
      <Typography sx={scoreLookupExpiredViewTitle}>{t('scoreLookup.lookup')}</Typography>
      <Img sx={{ paddingBottom: '20px', maxWidth: '168px' }} src={LookupImage} />
      <Typography sx={{ paddingBottom: '20px' }}>{t('scoreLookup.expiredDescription')}</Typography>
      <Typography sx={{ paddingBottom: '70px' }}>
        <Trans
          i18nKey='scoreLookup.supportLink'
          components={{ a: <a target='_blank' href='https://pearson.tfaforms.net/3048' /> }}
        />
      </Typography>
      <Button onClick={backToHomepage} sx={buttonOutlineDark}>
        {t('scoreLookup.expiredButton')}
      </Button>
    </Stack>
  );
};
