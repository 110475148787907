import { theme } from '../../styles/theme';

export const SignUpErrorMessage = {
  position: 'absolute',
  bottom: '-20px',
  fontSize: '12px',
  '@media screen and (max-width: 400px)': {
    fontSize: '10px'
  },
  color: 'red',
  left: 0,
  paddingLeft: '20px'
};

export const SignUpTextFieldContainer = {
  marginBottom: '45px',
  paddingLeft: '20px',
  paddingRight: '20px',
  position: 'relative'
};

export const SignUpPasswordLength = (password: string) => ({
  position: 'absolute',
  right: '20px',
  bottom: '-25px',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: 1.5,
  letterSpacing: 0,
  color: password.length > 8 ? theme.palette.common.darkGreen : theme.palette.common.darkGrey2
});

export const SignUpTextField = {
  width: '100%'
};

export const ConsentData = {
  color: theme.palette.common.darkGrey2,
  lineHeight: 1.6,
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: 0,

  '> *': {
    fontSize: '14px',
    fontWeight: 400
  }
};

export const ConsentDataContainer = {
  width: '90%',
  padding: '10px 20px',
  margin: '0 auto',
  background: theme.palette.common.lightGrey4,
  borderRadius: '8px',
  border: `1px solid ${theme.palette.common.lightGrey1}`,
  'input:checked + svg': {
    fill: theme.palette.common.darkBlue7
  }
};

export const SignUpHintContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 auto',
  width: '100%',
  paddingLeft: '20px',
  paddingRight: '60px',
  marginBottom: '30px',
  marginTop: '5px'
};

export const ConsentLinkStyle = {
  cursor: 'pointer',
  textDecoration: 'underline',
  color: theme.palette.common.darkGrey2,
  fontWeight: 600
};

export const ConsentCheckboxStyle = {
  padding: '2px',
  margin: '0 7px 0 7px',
  '&.Mui-checked > svg': {
    fill: `${theme.palette.common.darkBlue7} !important`
  }
};

export const ConsentOffer = {
  marginTop: '15px',
  marginBottom: '10px'
};

export const PasswordVisibilityIcon = {
  position: 'absolute',
  top: '20px',
  right: '30px',
  height: '20px',
  cursor: 'pointer',
  fill: '#555555'
};
