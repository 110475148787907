export interface IRoute {
  label: string;
  main: boolean;
  redirectTo: string;
}

export enum SkillTypes {
  SPEAKING = 'Speaking',
  LISTENING = 'Listening',
  READING = 'Reading',
  WRITING = 'Writing',
  OVERALL_SCORE = 'Overall Score',
  CEFR = 'CEFR'
}

export type SkillType = 'Speaking' | 'Listening' | 'Reading' | 'Writing' | 'Overall Score';

export enum OrderStatuses {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  PAID = 'PAID',
  FULFILLED = 'FULFILLED',
  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  DENIED = 'DENIED'
}

export type OrderStatus =
  | 'PENDING'
  | 'REJECTED'
  | 'PAID'
  | 'FULFILLED'
  | 'REFUNDED'
  | 'CANCELLED'
  | 'ERROR'
  | 'PARTIALLY_REFUNDED'
  | 'DENIED';
