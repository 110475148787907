import { AxiosResponse } from 'axios';
import { FC } from 'react';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import {
  makeSOPClient,
  makeSOPClientProtocol,
  SOPClientApi,
  TOKEN_DEADLINE
} from '@barracuda/sop-api-client';
import sopUtils from '../utils/sopUtils';

interface GlobalInterceptorProps {
  callback: (value: boolean) => void;
  redirectToSignIn?: (value: boolean) => void;
  children?: any;
}

const sopToken = localStorage.getItem('token') || undefined;
export const sopClient: SOPClientApi = makeSOPClient(
  makeSOPClientProtocol({
    baseUrl: process.env.REACT_APP_PESC_URL as string,
    token: sopToken
  })
);

let refreshIssued = false;
let refreshTokenCallback: ((token: string) => void) | null = null;

export const setRefreshTokenCallback = (callback: ((token: string) => void) | null) => {
  refreshTokenCallback = callback;
};

const GlobalInterceptor: FC<GlobalInterceptorProps> = ({
  children,
  callback,
  redirectToSignIn
}) => {
  sopClient.httpProvider.interceptors.response.use(
    (response) => {
      if (isTokenCloseToExpired() && !response.data.token && !refreshIssued) {
        refreshToken();
      }
      return response;
    },
    (error) => {
      const status: number | undefined = error?.response?.status;
      if (status === 401 && !localStorage.getItem('token')) {
        // Unauthorized while doin' survey.
        if (redirectToSignIn) {
          redirectToSignIn(false);
        }
      }
      if (status === 401) {
        callback(false);
        localStorage.removeItem('token');
      }
      if (process.env.REACT_APP_MODE !== 'prod') {
        console.log('The response was rejected', error);
      }
      throw error;
    }
  );

  sopClient.httpProvider.interceptors.request.use((config) => {
    if (!config.url?.includes('refresh')) {
      refreshIssued = false;
    }
    return config;
  });

  return children;
};

export default GlobalInterceptor;

export const oAuthLogin = (
  verificationLicenseId: string | null = null,
  createAccount?: boolean
) => {
  const url = `${process.env.REACT_APP_PESC_URL}/api/authentication/oauth2/redirect/google`;
  const queryParams = verificationLicenseId
    ? `?verificationLicenseId=${verificationLicenseId}`
    : '';
  const params = {
    url: `${process.env.REACT_APP_PESC_URL}/callback${queryParams}`
  };

  window.location.href = `${url}?url=${encodeURIComponent(params.url)}${`&createAccount=${
    createAccount ? 'true' : 'false'
  }`}`;
};

export const signUp = async (email: string, password: string, captchaResult?: string) => {
  const response = await sopClient.signUp(email, password, captchaResult);

  if (response.status === 409) {
    return Promise.reject(response);
  }

  return response as AxiosResponse;
};

export const signIn = async (
  email: string,
  password: string,
  ignoreSavingToken?: boolean,
  captchaResult?: string
) => {
  const response = await sopClient.signIn(email, password, captchaResult);

  if (response.data.token && !ignoreSavingToken) {
    sopUtils.setSopToken(response.data.token);
  }

  return response;
};

export const logout = async (loggedBefore?: string) => {
  await addLoggedBefore(loggedBefore);
  await sopClient.logout();
  sopUtils.removeSopToken();
  localStorage.removeItem('selectedTab');
  localStorage.removeItem('recentOrder');
};

export const addLoggedBefore = async (loggedBefore?: string) => {
  const payload = {
    loggedBefore: 'true'
  };

  if (!loggedBefore) {
    await sopClient.addMetadataToProfile(payload);
  }
};

const refreshToken = async () => {
  refreshIssued = true;
  try {
    const response = await sopClient.refreshToken();
    const token = response?.data?.token;
    if (token) {
      sopUtils.setSopToken(token);
      refreshTokenCallback?.(token);
    }
  } catch (error) {
    console.log('There was an error during refreshing token', error);
  }
};

export const isTokenCloseToExpired = (): boolean => {
  const storedToken = sopUtils.getSopToken();
  if (storedToken && !isTokenExpired(storedToken)) {
    const decodedToken: { exp: number } = jwt_decode(storedToken);
    const timeLeft = moment(moment(decodedToken?.exp * 1000)).diff(Date.now(), 'seconds');
    return timeLeft < TOKEN_DEADLINE;
  }
  return false;
};

export const isTokenExpired = (token: string | null) => {
  if (token) {
    const storedToken: { exp: number } = jwt_decode(token);
    return moment(moment(storedToken?.exp * 1000)).isBefore(Date.now(), 'seconds');
  }
  return false;
};

export const getQrCodeExpirationTime = (token: string | null) => {
  if (token) {
    const decodedToken: { exp: number } = jwt_decode(token);
    return decodedToken.exp * 1000;
  }
  return null;
};
