import { buttonDark } from '../../styles/button';
import { theme } from '../../styles/theme';

export const SignInLoginWithGoogleButton = {
  alignItems: 'center',
  background: theme.palette.common.lightGrey6,
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
  color: theme.palette.common.darkGrey2,
  display: 'flex',
  fontSize: '16px',
  justifyContent: 'center',
  marginBottom: '60px',
  padding: '10px 20px',
  textTransform: 'capitalize',
  img: {
    marginRight: '10px'
  }
};

export const SignInDivider = {
  marginBottom: '60px',
  padding: '0 20px',
  position: 'relative',
  textWrap: 'nowrap',
  span: {
    background: theme.palette.common.white,
    color: theme.palette.common.darkGrey2,
    display: 'inline-block',
    fontSize: '19px',
    left: '50%',
    padding: '0 25px',
    position: 'absolute',
    top: '-13px',
    transform: 'translateX(-50%)'
  },
  '@media screen and (max-width: 428px)': {
    span: {
      padding: '0 10px'
    }
  }
};

export const SignInTextFieldContainer = {
  marginBottom: '45px',
  paddingLeft: '20px',
  paddingRight: '20px',
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
};

export const SignInTextField = {
  width: '100%'
};

export const SignUpLink = {
  fontSize: '14px',
  fontWeight: 700,
  textDecoration: 'underline',
  cursor: 'pointer'
};

export const SignInResetPasswordLink = {
  position: 'absolute',
  right: '20px',
  bottom: '-25px',
  '@media screen and (max-width: 400px)': {
    fontSize: '10px'
  }
};

export const SignInButtonContainer = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  flexFlow: 'column',
  marginTop: '80px',
  minWidth: 'fit-content'
};
export const ConsentsContainer = {
  ...SignInButtonContainer,
  paddingBottom: '20px',
  paddingLeft: '30px',
  paddingRight: '30px',
  fontSize: '14px'
};

export const DontHaveAccountBox = {
  '@media only screen and (min-width: 320px) and (max-width: 400px)': {
    flexDirection: 'column'
  }
};

export const SignInButton = {
  ...buttonDark,
  marginBottom: '60px'
};

export const SignInButtonOutline = {
  ...SignInButton,
  color: theme.palette.common.darkGrey3,
  background: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.common.white,
    background: theme.palette.common.darkGrey3
  },
  '&[disabled]': {
    color: theme.palette.common.white,
    border: '1px solid rgba(51, 51, 51, 0.1)',
    background: 'rgba(51, 51, 51, 0.3)'
  }
};

export const SignInAlreadyHaveAnAccountHeader = {
  fontSize: '22px',
  fontWeight: 700,
  textAlign: 'center',
  width: '80%',
  margin: '0 auto',
  marginBottom: '10px',
  marginTop: '30px'
};

export const SignInAlreadyHaveAnAccountParagraph = {
  textAlign: 'center'
};

export const SignInAlreadyHaveAnAccountImage = {
  margin: '0 auto',
  marginTop: '15px',
  marginBottom: '15px',
  display: 'block'
};

export const SignInAlreadyHaveAnAccountEmail = {
  fontSize: '16px',
  fontWeight: 600,
  textAlign: 'center',
  marginBottom: '50px'
};

export const IncorrectErrorAlign = {
  width: '50%',
  bottom: '-40px',
  top: '107%',
  left: 0,
  paddingLeft: '20px'
};

export const ErrorLinkStyle = {
  fontWeight: 600,
  textDecoration: 'underline',
  color: 'red',
  cursor: 'pointer'
};

export const RecaptchaBox = {
  marginTop: '20px',
  marginBottom: '20px'
};
