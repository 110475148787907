import { FC } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { SignInButton } from '../SignIn/SignInStyles';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AgeRestriction from '../../assets/images/age-restriction.svg';
import {
  AgeRestrictionContainer,
  AgeRestrictionDescription,
  AgeRestrictionTitle,
  ImageContainer,
  RestrictionNote
} from './AgeRestrictionPopup.style';
import { useTranslation } from 'react-i18next';

export const AgeRestrictionPopup: FC<{ handleClose: (key: string) => void }> = ({
  handleClose
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container sx={AgeRestrictionContainer}>
        <Typography sx={AgeRestrictionTitle}>{t('ageRestriction.title')}</Typography>
        <Typography sx={AgeRestrictionDescription}>
          {t('ageRestriction.thankYouForInterest', {
            productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
          })}
        </Typography>
        <Typography sx={RestrictionNote}>{t('ageRestriction.pleaseNote')}</Typography>
        <Grid item xs={12} sx={ImageContainer}>
          <img src={AgeRestriction} />
        </Grid>
        <Box>
          <Button
            onClick={() => handleClose('ageRestriction')}
            type='submit'
            sx={[SignInButton, { margin: 0 }]}
          >
            {t('ageRestriction.backToHomepage')}
          </Button>
        </Box>
      </Grid>
    </>
  );
};
