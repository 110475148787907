import { theme } from '@theme';

export const PrivacyPolicyHeaderWeb = {
  paddingLeft: '150px',
  paddingRight: '150px',
  paddingTop: '190px',
  paddingBottom: '100px',
  background: '#F9F9F9',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    paddingLeft: '30px',
    paddingRight: '30px'
  }
};

export const PrivacyPolicyHeaderWebTitle = {
  color: theme.palette.common.darkGrey2,
  fontSize: '50px',
  marginBottom: '20px',
  fontWeight: 700,
  width: 'calc(100% - 100px)',
  '@media screen and (max-width: 510px)': {
    fontSize: '44px'
  }
};
