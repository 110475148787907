import { Box, Button, Typography } from '@mui/material';
import { ELLCommerce, Order } from '@pearson-ell/commerce-sdk';
import { OrderSummary } from '@pearson-ell/commerce-ui';
import { FC, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from 'src/context/App.context';
import errorImage from '../../assets/images/orderError.png';
import {
  ButtonFilled,
  ButtonOutlined,
  ButtonsContainer,
  ErrorContainer,
  OrdersContainer,
  TextContent,
  TextHeader
} from './OrderWrapper.style';
import { Trans, useTranslation } from 'react-i18next';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { sopClient, sopUtils } from '@barracuda/shared/src';
import { ellCommerceInstanceConfig } from '../../utils/ell-commerce';

const OrderWrapper: FC = () => {
  const { user, setUser } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const [order, setOrder] = useState<Order>();
  const [dataGathered, setDataGathered] = useState(false);
  const orderNumber = searchParams.get('orderId');
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();

  const { eCommerceCountryISO2, setLoading } = useContext(AppContext);
  const setPurchaseMetaData = () =>
    sopClient
      .addMetadataToProfile({
        RESULTS_DETAILS_CERTIFIED_TEST_USER_FIRST_PURCHASE: 'true'
      })
      .then((response) => {
        const { data } = response;
        setUser(data);
      });

  useEffect(() => {
    setLoading?.(true);
    const eCommerce = ELLCommerce.getInstance({
      ...ellCommerceInstanceConfig,
      defaultCountryISO2: eCommerceCountryISO2 || 'US',
      getTokenFunction: () => `Bearer ${sopUtils.getSopToken()}`
    });
    eCommerce
      .OrderService()
      .getOrder(orderNumber || '')
      .then((r) => {
        setOrder(r);
        saveRecentOrderData(r);
        if (!user?.profile.RESULTS_DETAILS_CERTIFIED_TEST_USER_FIRST_PURCHASE) {
          setPurchaseMetaData();
        }
      })
      .finally(() => {
        setLoading?.(false);
        setDataGathered(true);
      });
  }, []);

  const handleViewOrderHistory = () => {
    navigate('/dashboard/account', { state: { activeTabIndex: 1 } });
  };
  const saveRecentOrderData = (order: Order) => {
    const dto = { orderId: order.id, date: order.dateCreated };
    localStorage.setItem('recentOrder', JSON.stringify(dto));
  };

  return (
    <Box sx={{ paddingLeft: '21px' }}>
      {dataGathered && (
        <Box sx={OrdersContainer}>
          <Box>
            {order ? (
              <Box>
                <Typography sx={TextHeader}>{t('orderConfirmation.thanksForPurchase')}</Typography>
                <Typography sx={{ ...TextContent, marginBottom: '32px' }}>
                  <Trans
                    components={{ bold: <strong /> }}
                    i18nKey='orderConfirmation.yourOrderNumberIs'
                    values={{ orderNumber }}
                  />
                </Typography>
                <Typography sx={{ ...TextContent, marginBottom: '32px' }}>
                  <Trans
                    i18nKey='orderConfirmation.weHaveSentYouConfirmationEmail'
                    components={{
                      a: <a href='https://pearson.tfaforms.net/3048' target='_blank' />
                    }}
                  />
                </Typography>
                <Typography sx={TextContent}>
                  {t(`orderConfirmation.fromDashboardYouCan`)}:
                  <ul>
                    <li>
                      <Trans
                        i18nKey='orderConfirmation.takeATest'
                        components={{ bold: <strong /> }}
                      />
                    </li>
                    <li>
                      <Trans
                        i18nKey='orderConfirmation.viewOrderHistory'
                        components={{ bold: <strong /> }}
                      />
                    </li>
                  </ul>
                </Typography>
              </Box>
            ) : (
              <Box sx={ErrorContainer}>
                <Typography sx={{ ...TextHeader, marginBottom: 0 }}>
                  {t('orderConfirmation.errorTitle')}
                </Typography>
                <Typography sx={{ ...TextContent, marginBottom: '32px' }}>
                  {t('orderConfirmation.errorMessage')}
                </Typography>
                <Img src={errorImage} />
              </Box>
            )}
            <Box sx={ButtonsContainer}>
              <Button variant='outlined' sx={ButtonFilled} onClick={() => navigate('/dashboard')}>
                {t('orderConfirmation.goToDashboard')}
              </Button>
              <Button sx={ButtonOutlined} onClick={handleViewOrderHistory}>
                {t('orderConfirmation.viewOrderHistoryButton')}
              </Button>
            </Box>
          </Box>
          {order && (
            <Box sx={{ minWidth: '400px' }}>
              <OrderSummary order={order} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default OrderWrapper;
