import { ParamParseKey, PathMatch, PathPattern } from '@remix-run/router';
import { AppContext } from '../context/App.context';
import { useContext } from 'react';
import { useMatch } from 'react-router-dom';

export const useLocalizedMatch = <ParamKey extends ParamParseKey<Path>, Path extends string>(
  pattern: PathPattern<Path> | Path
): PathMatch<ParamKey> | null => {
  const { language } = useContext(AppContext);

  return useMatch(`/${language}${pattern}`);
};
