import { theme } from '../../styles/theme';

export const DialogWrapperContainer = {
  padding: '40px',
  width: '580px',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@media screen and (max-width: 650px)': {
    width: 'unset'
  }
};

export const DialogWrapperTitle = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '40px',
  textAlign: 'center',
  color: theme.palette.common.darkBlue1
};
export const FlexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
export const DialogWrapperText = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.6,
  color: 'black',
  textAlign: 'center'
};
export const CancelButtonAlign = {
  marginLeft: '20px',
  marginBottom: 0
};
export const RevokeSharingButtonMobile = {
  '@media screen and (max-width: 395px)': {
    fontSize: '15px'
  }
};
