import { Box } from '@mui/material';
import { FC } from 'react';
import {
  AvailableTestsNotificationContainer,
  AvailableTestsNotificationPipe
} from './AvailableTestsNotification.style';
import GreenCheckIcon from '../../assets/images/green-check.svg';
import { Img } from '../Primitive';
import { Trans } from 'react-i18next';

export interface AvailableTestsNotificationProps {
  testsCount: number;
  scrollToElement: (id: string) => void;
}

export const AvailableTestsNotification: FC<AvailableTestsNotificationProps> = ({
  testsCount,
  scrollToElement
}) => {
  return (
    <Box sx={AvailableTestsNotificationContainer}>
      <Box sx={AvailableTestsNotificationPipe} />
      <Img sx={{ marginInline: '10px' }} src={GreenCheckIcon} />
      <Trans
        i18nKey='availableTestsNotification'
        components={{
          a: <a onClick={() => scrollToElement('#takeTest')} />
        }}
        values={{ testsCount }}
      />
    </Box>
  );
};
