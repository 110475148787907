import { FC } from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StatusBadge } from '../StatusBadge';
import { DialogWrapperTitle } from '../DialogWrapper/DialogWrapper.styles';
import {
  CloseIconStyles,
  DashboardTestDetailsContainer,
  FlexCenter,
  gseLabel,
  overallProficiencyLabel,
  ScoresPopupContainer,
  ScoresPopupDescription,
  ScoresPopupDialogWrapperTitle,
  ScoresPopupHeaderContainer,
  ScoresPopupHeaderShieldContainer,
  ScoresPopupHeaderShieldLeftContainer,
  ScoresPopupHeaderShieldRightContainer,
  ScoresPopupHeaderShieldScore,
  SkillScoresContainer,
  testDataLabel
} from './ScoresPopup.styles';
import { License } from '../../interface/licenses.interfaces';
import shieldGray from '../../assets/images/shield-gray.svg';
import moment from 'moment/moment';
import { SkillScoresTable } from '../SkillScoresTable/SkillScoresTable';
import { useTranslation } from 'react-i18next';
import { hasSkills } from '../../utils/utils';
import { V2CertificateSkill } from '../../interface/mts-report.interface';
import { SkillType, SkillTypes } from '../../index';
import { Img } from '../Primitive';

interface ScoresPopupProps {
  readonly open: boolean;
  readonly license: License;
  readonly skills: Record<string, V2CertificateSkill>;
  readonly findSkillByName: (name: SkillType) => V2CertificateSkill | undefined;
  readonly findExceptionByName: (name: string) => string;
  readonly closePopup: () => void;
  readonly findCefrScoreBySkill: (skillName: string) => string | undefined;
}

export const ScoresPopup: FC<ScoresPopupProps> = ({
  open,
  license,
  skills,
  findExceptionByName,
  closePopup,
  findSkillByName,
  findCefrScoreBySkill
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={open} sx={[{ '.MuiPaper-root': { maxWidth: '1000px !important' } }]}>
        <Box sx={ScoresPopupContainer}>
          <CloseIcon sx={CloseIconStyles} onClick={() => closePopup()} />
          <Box sx={ScoresPopupHeaderContainer}>
            <Typography
              sx={[DialogWrapperTitle, { marginBottom: 0 }, ScoresPopupDialogWrapperTitle]}
            >
              {license?.product?.name}
            </Typography>
            <StatusBadge type={license?.status} />
          </Box>
          <Box sx={ScoresPopupHeaderShieldContainer}>
            <Box sx={ScoresPopupHeaderShieldLeftContainer}>
              <Img alt='Shield icon' src={shieldGray} />
              <Typography sx={ScoresPopupHeaderShieldScore}>
                {(license?.scores &&
                  license?.scores?.find(
                    (el) => el?.component === SkillTypes.OVERALL_SCORE && el?.scale === 'GSE'
                  )?.score) ||
                  ''}
              </Typography>
              <Typography sx={gseLabel}>{t('gseScore')}</Typography>
            </Box>
            <Box sx={ScoresPopupHeaderShieldRightContainer}>
              <Typography sx={overallProficiencyLabel}>{t('myOverallProficiency')}</Typography>
              <Typography sx={ScoresPopupDescription}>
                {hasSkills(skills) && findSkillByName(SkillTypes.OVERALL_SCORE)?.description}
              </Typography>
              <Box sx={FlexCenter}>
                <Typography sx={testDataLabel}>{t('testID')}:</Typography>
                <Typography sx={{ marginLeft: '5px' }}>{license?.testCode}</Typography>
              </Box>
              <Box sx={FlexCenter}>
                <Typography sx={testDataLabel}>{t('date')}:</Typography>
                <Typography sx={{ marginLeft: '5px' }}>
                  {moment(license?.createdAt).format('DD MMMM yyyy')}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            className='scores-in-popup'
            sx={[DashboardTestDetailsContainer, SkillScoresContainer]}
          >
            <SkillScoresTable
              findExceptionByName={findExceptionByName}
              findCefrScoreBySkill={findCefrScoreBySkill}
              skills={skills}
              license={license}
              insidePopup
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
