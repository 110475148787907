import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import {
  AvailableTestsTitle,
  NoTestsBox,
  NoTestsDescription,
  NoTestsText,
  TestsCount
} from './TakeTestAccordionWrapper.style';
import { Img } from '@barracuda/shared/src/components/Primitive';
import QuestionSign from '../../assets/images/question-sign.svg';
import {
  AvailableProduct,
  DialogWrapper,
  EventTypes,
  LimitReachedModal,
  PracticeDownloadAppPopup,
  ProductMetadata,
  RedeemActivationCodeTile
} from '@barracuda/shared/src';
import { StartJourneyStepper } from '@barracuda/shared/src/components/StartJourneyStepper/StartJourneyStepper';
import { ProductTile } from '../ProductTile/ProductTile';
import { useAddCartItem } from '../ExploreTests/useAddCartItem';
import { AppContext } from 'src/context/App.context';
import { Trans, useTranslation } from 'react-i18next';
import { scrollToElement } from '@barracuda/shared/src/utils/utils';
import ReactGA from 'react-ga4';
import { useLaunchTest } from '../../hooks/useLaunchTest';
import { buttonDark } from '@barracuda/shared/src/styles/button';
import { theme } from '@theme';

interface TakeTestAccordionWrapperProps {
  setBuyTestExpanded: (value: boolean) => void;
  buyTestExpanded: boolean;
  setActivationCodeOpened: () => void;
  fetchAvailableProducts: () => void;
  availableProducts: AvailableProduct[];
}
export const TakeTestAccordionWrapper: FC<TakeTestAccordionWrapperProps> = ({
  setBuyTestExpanded,
  buyTestExpanded,
  setActivationCodeOpened,
  fetchAvailableProducts,
  availableProducts
}) => {
  const [downloadOpened, setDownloadOpened] = useState<boolean>(false);
  const [beforeStartOpenMobile, setBeforeStartOpenMobile] = useState<boolean>(false);
  const { eCommerceCountryISO2, availableTests } = useContext(AppContext);
  const [launchTest, limitReached, setLimitReached, message] = useLaunchTest(
    setBeforeStartOpenMobile,
    setDownloadOpened
  );
  const { t } = useTranslation();

  useEffect(() => {
    fetchAvailableProducts();
    const interval = setInterval(async () => {
      fetchAvailableProducts();
    }, 20_000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const parseProductMetadata = (metadata: ProductMetadata) => {
    const time = Number(metadata.TEST_DURATION);
    const skills = JSON.parse(metadata.TEST_SKILLS);
    return {
      skills,
      time
    };
  };

  const scrollToElementCustom = (id: string) => {
    if (!buyTestExpanded) {
      setBuyTestExpanded(true);
    }
    scrollToElement(id);
  };

  const addCartItem = useAddCartItem();

  const handleCloseDialog = (value?: string) => {
    if (value && value === 'download') {
      ReactGA.event(EventTypes.APP_DOWNLOADED);
    }
    setDownloadOpened(false);
  };

  const RedeemComponent = <RedeemActivationCodeTile handleRedeemCode={setActivationCodeOpened} />;

  return (
    <>
      <DialogWrapper
        gridModifications={{ width: '980px' }}
        key='formDownload'
        open={downloadOpened}
        handleClose={() => setDownloadOpened(false)}
        scrollable
        subtitle={t('dashboardPage.formDownloadAccordionSubtitle')}
        title={t('dashboardPage.formDownloadAccordionTitle')}
        subtitleColor={theme.palette.common.violet}
        closeable
      >
        <PracticeDownloadAppPopup
          MSI_URL={process.env.REACT_APP_PESC_DOWNLOAD_MSI_PATH}
          MAC_URL={process.env.REACT_APP_PESC_DOWNLOAD_DMG_MAC_PATH}
          closeDialog={(value) => handleCloseDialog(value)}
        />
      </DialogWrapper>
      <DialogWrapper
        gridModifications={{ maxWidth: '885px', width: 'unset' }}
        key='beforeStartDownloadMobile'
        open={beforeStartOpenMobile}
        handleClose={() => setBeforeStartOpenMobile(false)}
        scrollable
        subtitle={t('dashboardPage.formDownloadAccordionSubtitle')}
        title={t('dashboardPage.formDownloadAccordionTitle')}
      >
        <PracticeDownloadAppPopup
          mobile
          MSI_URL={process.env.REACT_APP_PESC_DOWNLOAD_MSI_PATH}
          MAC_URL={process.env.REACT_APP_PESC_DOWNLOAD_DMG_MAC_PATH}
          closeDialog={() => setBeforeStartOpenMobile(false)}
        />
      </DialogWrapper>
      <Stack
        direction='row'
        flexWrap='wrap'
        justifyContent='center'
        sx={{ px: { md: '16px', xl: '65px' }, pb: { md: '50px', xl: '58px' } }}
      >
        <Typography sx={AvailableTestsTitle}>
          <Trans
            i18nKey='dashboardPage.takeTestAccordionAvailableTestsTitle'
            values={{ availableTests }}
            components={{ span: <Typography component='span' sx={TestsCount} /> }}
          />
        </Typography>
        {!availableProducts?.length && (
          <Grid
            container
            columnSpacing={{ md: '20px', lg: '20px', xl: '25px' }}
            rowSpacing={{ xs: '15px', lg: '10px', xl: '25px' }}
            sx={{ marginBottom: { xs: '43px', md: '55px', xl: '50px ' } }}
          >
            <Grid item xs={12} md={6} xl={5}>
              <Box sx={NoTestsBox}>
                <Img src={QuestionSign} />
                <Stack sx={NoTestsDescription}>
                  <Typography sx={NoTestsText}>
                    {t('dashboardPage.takeTestAccordionNoTestsText')}
                  </Typography>
                  <Button
                    size='small'
                    onClick={() => scrollToElementCustom('#buyTest')}
                    sx={buttonDark}
                  >
                    {t('dashboardPage.takeTestAccordionSignInButton')}
                  </Button>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              {RedeemComponent}
            </Grid>
          </Grid>
        )}
        {!!availableProducts?.length && (
          <Grid
            container
            columnSpacing={{ md: '20px', lg: '20px', xl: '25px' }}
            rowSpacing={{ xs: '15px', lg: '10px', xl: '25px' }}
            sx={{ marginBottom: { xs: '43px', md: '55px', xl: '50px ' } }}
          >
            {availableProducts.map((item, index) => (
              <Grid key={`transaction-product-${index}`} item sm={12} md={6}>
                <ProductTile
                  numberOfItems={item.numberOfItems}
                  showCounter
                  startTestHandle={launchTest}
                  type='take'
                  data={parseProductMetadata(item.metadata)}
                  essId={`${item?.essId}`}
                  handleBuy={addCartItem}
                  eCommerceCountryISO2={eCommerceCountryISO2 || 'US'}
                  retake={item.retake}
                />
              </Grid>
            ))}
            <Grid item xs={12} md={6}>
              {RedeemComponent}
            </Grid>
          </Grid>
        )}
        <StartJourneyStepper />
      </Stack>
      <LimitReachedModal
        open={limitReached}
        handleClose={() => setLimitReached(false)}
        message={message}
      />
    </>
  );
};
