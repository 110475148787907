import { theme } from '../../styles/theme';
import { alpha } from '@mui/material';

export const sharedScoresDetailedTableOffCircle = {
  width: '10px',
  height: '10px',
  backgroundColor: theme.palette.common.darkRed,
  boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.25)}`,
  borderRadius: '100px'
};

export const sharedScoresDetailedTableOnCircle = {
  width: '10px',
  height: '10px',
  backgroundColor: theme.palette.common.darkGreen,
  boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.25)}`,
  borderRadius: '100px'
};

export const sharedScoresDetailedTableSharingCell = {
  display: 'flex',
  alignItems: 'center',
  gap: '5px'
};

export const sharedScoresDetailedTableOrderHistoryAccordionTableTheadCell = {
  fontWeight: 600,
  textAlign: 'left',
  color: `${theme.palette.common.lightGrey6} !important`,
  backgroundColor: theme.palette.common.violet,
  ':first-child': {
    borderTopLeftRadius: '8px'
  },
  ':last-child': {
    borderTopRightRadius: '8px'
  }
};

export const sharedScoresDetailedTableOrderHistoryAccordionTableContainer = {
  background: theme.palette.common.white,
  boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.25)}`,
  marginTop: '30px',
  cursor: 'pointer'
};

export const sharedScoresDetailedTableRow = {
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  fontSize: '16px',
  letterSpacing: '0em',
  fontWeight: 400
};

export const sharedScoresDetailedTableFooterStyle = {
  display: 'flex',
  flexFlow: 'row-reverse'
};

export const sharedScoresDetailedTableEmail = {
  whiteSpace: 'nowrap',
  maxWidth: '180px',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
};
