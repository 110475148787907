import { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  PagePadding,
  H3,
  AccordionContainer,
  BlueLink,
  SectionContainer,
  SectionMargin
} from '../GettingReadyPage/GettingReadyPage.styles';
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { theme } from '@theme';

export const GettingReadyPageFaq: FC<{
  readonly readMoreAction: () => void;
  readonly id?: string;
}> = ({ readMoreAction, id }) => {
  const { t } = useTranslation();

  const faqIAccordion1List1: string[] = Object.values(
    t('gettingReadyPage.faqIAccordion1List1', { returnObjects: true })
  );
  const faqIAccordion1List2: string[] = Object.values(
    t('gettingReadyPage.faqIAccordion1List2', { returnObjects: true })
  );
  const faqIAccordion1List3: string[] = Object.values(
    t('gettingReadyPage.faqIAccordion1List3', {
      returnObjects: true,
      productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
    })
  );
  const faqIAccordion2List1: string[] = Object.values(
    t('gettingReadyPage.faqIAccordion2List1', { returnObjects: true })
  );
  const faqIAccordion2List2: string[] = Object.values(
    t('gettingReadyPage.faqIAccordion2List2', { returnObjects: true })
  );

  return (
    <Grid container sx={[SectionContainer, SectionMargin, PagePadding]} id={id}>
      <Grid item xs={12}>
        <Typography component='h2' sx={[H3, { textAlign: 'center' }]}>
          {t('gettingReadyPage.faqHeader')}
        </Typography>
        <Box sx={AccordionContainer}>
          <Accordion sx={{ marginTop: '10px' }} key='accordion-1'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.common.darkGrey2 }} />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography sx={{ fontWeight: 600 }}>
                {t('gettingReadyPage.faqIAccordion1Title')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component='span'>
                {t('gettingReadyPage.faqIAccordion1Description')}
                <ul>
                  {faqIAccordion1List1.map((li, index) => (
                    <li key={`faqIAccordion1List1-${index}`}>{li}</li>
                  ))}
                </ul>
                <p style={{ fontWeight: 600 }}>
                  {t('gettingReadyPage.faqIAccordion1Description2')}
                </p>
                <ul>
                  {faqIAccordion1List2.map((li, index) => (
                    <li key={`faqIAccordion1List2-${index}`}>{li}</li>
                  ))}
                </ul>
                <p style={{ fontWeight: 600 }}>
                  {t('gettingReadyPage.faqIAccordion1Description3')}
                </p>
                <ul>
                  {faqIAccordion1List3.map((li, index) => (
                    <li key={`faqIAccordion1List3-${index}`}>{li}</li>
                  ))}
                </ul>
                <p>
                  {t('gettingReadyPage.faqIAccordion1Description4', {
                    productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
                  })}
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ marginTop: '10px' }} key='accordion-2'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.common.darkGrey2 }} />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography sx={{ fontWeight: 600 }}>
                {t('gettingReadyPage.faqIAccordion2Title')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component='span'>
                <p>{t('gettingReadyPage.faqIAccordion2Description')}</p>
                <p style={{ fontWeight: 600 }}>
                  {t('gettingReadyPage.faqIAccordion2Description2')}
                </p>
                <ul>
                  {faqIAccordion2List1.map((li, index) => (
                    <li key={`faqIAccordion2List1-${index}`}>{li}</li>
                  ))}
                </ul>
                <p style={{ fontWeight: 600 }}>
                  {t('gettingReadyPage.faqIAccordion2Description3')}
                </p>
                <ul>
                  {faqIAccordion2List2.map((li, index) => (
                    <li key={`faqIAccordion2List2-${index}`}>{li}</li>
                  ))}
                </ul>
                <p style={{ fontStyle: 'italic' }}>
                  {t('gettingReadyPage.faqIAccordion2Description4')}
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ marginTop: '10px' }} key='accordion-3'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.common.darkGrey2 }} />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography sx={{ fontWeight: 600 }}>
                {t('gettingReadyPage.faqIAccordion3Title')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component='span'>
                <p>{t('gettingReadyPage.faqIAccordion3Description')}</p>
                <p>
                  <Trans
                    i18nKey='gettingReadyPage.faqIAccordion3Description2'
                    components={{ bold: <strong /> }}
                  />
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Link onClick={readMoreAction} sx={[BlueLink, { marginTop: '30px' }]}>
          {t('gettingReadyPage.faqReadMore')} <ArrowForwardIosIcon />
        </Link>
      </Grid>
    </Grid>
  );
};
