import { theme } from '../../styles/theme';

export const StartJourneyContainer = {
  background: theme.palette.common.lightGrey4,
  border: `1px solid ${theme.palette.common.lightGrey3}`,
  borderRadius: '8px',
  padding: '50px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  '@media screen and (max-width: 600px)': {
    padding: '40px 10px 40px 10px'
  }
};
export const StartJourneyTitle = {
  fontSize: '20px',
  fontWeight: 600,
  paddingBottom: '30px',
  textAlign: 'center'
};
export const JourneySteps = {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  justifyContent: 'center'
};
export const JourneyStepImage = {
  height: '135px',
  '@media screen and (max-width: 600px)': {
    width: '70px',
    marginRight: '15px',
    height: 'unset'
  }
};
export const JourneyStepTime = {
  fontWeight: 600,
  wordBreak: 'break-word'
};
export const StepContainer = {
  textAlign: 'center',
  width: 'calc(25% - 20px)',
  '@media screen and (max-width: 1000px)': {
    width: 'calc(50% - 20px)'
  },
  '@media screen and (max-width: 600px)': {
    width: 'calc(100% - 20px)',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left'
  }
};
export const Description = {
  fontWeight: 400,
  color: theme.palette.common.darkBlue2,
  '@media screen and (max-width: 600px)': {
    '*': {
      fontSize: '14px'
    },
    fontWeight: 400,
    color: theme.palette.common.darkBlue2
  },
  '> span:first-of-type': {
    fontWeight: 600,
    '@media screen and (max-width: 600px)': {
      display: 'none'
    }
  }
};
export const HideFor600px = {
  '@media screen and (max-width: 600px)': {
    display: 'none'
  }
};
