import axios, { AxiosResponse } from 'axios';
import { BlockedApplicationDto, RefreshResponse, SOPClientProtocol } from './protocol';
import { SHARES_TABLE_PAGE_SIZE, TESTS_TABLE_PAGE_SIZE } from './constants';

const statusesWithoutNewAndValidated = [
  'STARTED',
  'COMPLETED',
  'LATE_HANGUP',
  'ERROR',
  'NOT_SCORABLE',
  'PARTIALLY_SCORED',
  'SCORED',
  'CERTIFICATION_FAILED',
  'CERTIFIED',
  'ABORTED'
];

const statuses = statusesWithoutNewAndValidated.reduce(
  (prev, current, index) => prev.concat(`${index === 0 ? '' : '&'}status=${current}`),
  '?'
);

type SerializeObj = Record<string, boolean | number | string | string[]>;

const serialize = function (obj: SerializeObj) {
  let encodedParts = [];
  for (const property in obj)
    if (obj.hasOwnProperty(property)) {
      const value = obj[property];
      Array.isArray(value)
        ? value.forEach((innerProp: string) =>
            encodedParts.push(encodeURIComponent(property) + '=' + encodeURIComponent(innerProp))
          )
        : encodedParts.push(
            encodeURIComponent(property) + '=' + encodeURIComponent(value as string)
          );
    }
  return encodedParts.join('&');
};

const attachSessionId = (event: any, sessionId: string) => {
  if (Array.isArray(event)) {
    return event.map((p) => ({ ...p, payload: { ...p.payload, sessionId } }));
  }
  return { ...event, payload: { ...event.payload, sessionId } };
};

export const makeSOPClient = ({
  httpProvider,
  makeUrl,
  getToken,
  setToken,
  deleteToken
}: SOPClientProtocol) => {
  return {
    session: {
      getToken,
      setToken,
      deleteToken
    },
    httpProvider,
    health: () => axios.get(makeUrl('/api/v1/health')),
    getAvailableProducts: () => httpProvider.get(makeUrl(`/api/products?practice=false`)),
    getAvailableProductsUnauthorized: (applicationId: string = '') =>
      httpProvider.get(makeUrl(`/api/products/${applicationId}?practice=false`)),
    getUserData: async () => httpProvider.get(makeUrl('/api/testTaker')),
    signIn: async (
      email: string,
      password: string,
      captchaResult?: string
    ): Promise<AxiosResponse> => {
      const body = {
        email,
        password,
        applicationCode: 'BARRACUDA',
        captchaResult: captchaResult || undefined
      };
      return httpProvider.post(makeUrl('/api/authentication/login'), body);
    },
    signUp: async (email: string, password: string, captchaResult?: string) => {
      const body = {
        email,
        password,
        captchaResult
      };
      return httpProvider.post(makeUrl('/api/testTaker'), body);
    },
    logout: async () => httpProvider.post(makeUrl('/api/authentication/logout')),
    refreshToken: async () => {
      const body = { token: getToken() };
      return httpProvider.post(makeUrl('/api/authentication/refresh'), body);
    },
    generateResetPasswordLink: async (email: string, captchaResult?: string) => {
      const body = captchaResult ? { email, captchaResult } : { email };
      return httpProvider.post(makeUrl('/api/testTaker/password/reset'), body);
    },
    validatePassword: async (password: string) =>
      httpProvider.post(makeUrl('/api/authentication/password/validate'), { password }),
    getUserBadges: async () => httpProvider.get(makeUrl('/api/testTaker/badges')),
    uploadSurvey: async (licenseId: string | undefined, payload: unknown) =>
      httpProvider.post(makeUrl(`/api/licenses/${licenseId}/metadata`), payload),
    sendLicenseSessionEvent: async (
      licenseId: string | undefined,
      payload:
        | {
            createdAt: Date;
            type: Object;
            payload: Object;
          }
        | {
            createdAt: Date;
            type: Object;
            payload: Object;
          }[],
      sessionId: string,
      sopToken: string
    ) =>
      httpProvider.post(
        makeUrl(`/api/licenses/${licenseId}/events`),
        attachSessionId(payload, sessionId),
        {
          headers: {
            Authorization: `Bearer ${sopToken}`
          }
        }
      ),
    getLanguages: async (query = '', pageSize = 7) =>
      httpProvider.get(makeUrl(`/api/dictionaries/languages?name=${query}&pageSize=${pageSize}`)),
    getCountries: async (query: string) =>
      httpProvider.get(makeUrl(`/api/dictionaries/countries?name=${query}&pageSize=7`)),
    getCities: async (query: string, countryId: number) =>
      httpProvider.get(
        makeUrl(`/api/dictionaries/cities?countryId=${countryId}&name=${query}&pageSize=7`)
      ),
    updateProfile: async (user: unknown) => httpProvider.patch(makeUrl('/api/testTaker'), user),
    addMetadataToProfile: async (meta: { [key: string]: string }) =>
      httpProvider.put(makeUrl('/api/testTaker/profile'), { set: meta }),
    changePassword: async (oldPassword: string, newPassword: string, confirmPassword: string) =>
      httpProvider.put(makeUrl('/api/testTaker/password/update'), {
        oldPassword,
        newPassword,
        confirmPassword
      }),
    setNewPassword: async (newPassword: string, token?: string) =>
      httpProvider.put(makeUrl('/api/testTaker/password'), {
        newPassword,
        token
      }),
    getUserTransactionProducts: async () =>
      httpProvider.get(makeUrl('/api/testTaker/transactions/products?transactionStatus=FULFILLED')),
    getUserIPCountry: async () => {
      let userCountry = 'US';
      try {
        const res = await httpProvider.get(makeUrl('/api/dictionaries/countries/guess'));
        if (res.data.isoCode) userCountry = res.data.isoCode;
      } catch (err) {
        console.log('Error trying to determine user location based on IP.', err);
      }
      return userCountry;
    },
    getPracticeTests: async () => httpProvider.get(makeUrl('/api/products?practice=true')),
    getMyTopScore: async (mtsCode = '') =>
      httpProvider.get(makeUrl(`/api/testTaker/topscores/${mtsCode}`)),
    getMyTopScoreDetails: async (id: number, lang: 'ENG' | 'JPN' | 'KOR' = 'ENG') =>
      httpProvider.get(
        makeUrl(
          `/api/testTaker/topscores/${id}/certificate?language=${lang !== 'KOR' ? lang : 'ENG'}`
        )
      ),
    getMyTopScoreShares: async (id: number) =>
      httpProvider.get(makeUrl(`/api/testTaker/topscores/${id}/shares`)),
    shareMyTopScore: async (data: unknown, id?: string) =>
      httpProvider.post(makeUrl(`/api/testTaker/topscores/${id}/shares`), data),
    myTopScoreEmailPreview: async (data: unknown, id: string) =>
      httpProvider.post(makeUrl(`/api/testTaker/topscores/${id}/shares/preview`), data),
    downloadMyTopScoreCertificate: async (
      id: number,
      language: 'ENG' | 'JPN' | 'KOR' = 'ENG'
    ): Promise<AxiosResponse<Blob>> =>
      httpProvider.get(
        makeUrl(
          `/api/testTaker/topscores/${id}/certificate.pdf?language=${
            language !== 'KOR' ? language : 'ENG'
          }`
        ),
        {
          responseType: 'blob'
        }
      ),
    downloadSharesCertificate: async (
      lang: 'ENG' | 'JPN' | 'KOR',
      code?: string,
      pin?: string | undefined
    ): Promise<AxiosResponse<Blob>> =>
      httpProvider.get(
        makeUrl(
          `/api/shares/${code}/${pin}/certificate.pdf?language=${lang !== 'KOR' ? lang : 'ENG'}`
        ),
        {
          responseType: 'blob'
        }
      ),
    getSentScores: async (page = 0, query?: string) =>
      httpProvider.get(
        makeUrl(`/api/shares?page=${page}&pageSize=${SHARES_TABLE_PAGE_SIZE}&name=${query || ''}`)
      ),
    getSharesOrganizations: async (search?: string) =>
      httpProvider.get(makeUrl(`/api/shares/organizations?search=${search}`)),
    getLicenseSharesById: async (id: string) => httpProvider.get(makeUrl(`/api/shares/${id}`)),
    revokeSharing: async (id: string) => httpProvider.get(makeUrl(`/api/shares/${id}/revoke`)),
    certificateLookup: async (code: string | undefined, data: unknown) =>
      httpProvider.post(makeUrl(`/api/shares/${code}/lookup`), data),
    displayCertificate: async (code?: string, pin?: string) =>
      httpProvider.get(makeUrl(`/api/shares/${code}/${pin}/responses`)),
    displayCertificateData: async (code?: string, pin?: string) =>
      httpProvider.get(makeUrl(`/api/shares/${code}/${pin}`)),
    loadCertificate: async (code?: string, pin?: string, lang?: string) =>
      httpProvider.get(
        makeUrl(`/api/shares/${code}/${pin}/certificate?language=${lang !== 'KOR' ? lang : 'ENG'}`)
      ),
    downloadResourceData: async (
      responseId: string,
      type: 'audio' | 'text',
      code: string | undefined,
      pin: string | undefined,
      resourceKey: string | undefined
    ) =>
      httpProvider.get(
        makeUrl(`/api/shares/${code}/${pin}/responses/${responseId}/${type}/${resourceKey}`),
        { responseType: type === 'audio' ? 'blob' : 'json' }
      ),
    revokeMultipleShares: async (dto: { shareIds: number[] }) =>
      httpProvider.put(makeUrl('/api/shares/revoke'), dto),
    getSharesById: async (
      page = 0,
      resourceId: number,
      resourceName: 'testTakerTopScoreId' | 'licenseId',
      query?: string
    ) =>
      httpProvider.get(
        makeUrl(
          `/api/shares?page=${page}&pageSize=${SHARES_TABLE_PAGE_SIZE}&${resourceName}=${resourceId}${
            query ? '&email=' + query : ''
          }`
        )
      ),
    getLicenses: (page = 0, name?: string) => {
      const params = {
        name: name || '',
        page: page,
        pageSize: TESTS_TABLE_PAGE_SIZE,
        practice: false,
        sort: 'endTime',
        order: 'desc'
      };
      return httpProvider.get(makeUrl(`/api/licenses${statuses}`), { params });
    },
    getPracticeLicenses: async (page = 0, name?: string) =>
      httpProvider.get(
        makeUrl(
          `/api/licenses?name=${
            name || ''
          }&sort=endTime&order=desc&page=${page}&pageSize=${TESTS_TABLE_PAGE_SIZE}&practice=true&status=COMPLETED&status=ERROR&status=NOT_SCORABLE&status=PARTIALLY_SCORED&status=SCORED&status=CERTIFICATION_FAILED&status=CERTIFIED`
        )
      ),
    getLatestLicense: async () =>
      httpProvider.get(makeUrl('/api/licenses?sort=endTime&order=desc&practice=false')),
    getLatestLicenses: async (size: number) => {
      const statusFilter =
        '&status=STARTED&status=COMPLETED&status=LATE_HANGUP&status=ERROR&status=NOT_SCORABLE&status=PARTIALLY_SCORED&status=SCORED&status=CERTIFICATION_FAILED&status=CERTIFIED&status=ABORTED';
      return httpProvider.get(
        makeUrl(
          `/api/licenses?pageSize=${size}&page=0&sort=endTime&order=desc&practice=false&${statusFilter}`
        )
      );
    },
    getLicenseDetails: async (id: number | undefined) =>
      httpProvider.get(makeUrl(`/api/licenses/${id}`)),
    getDataForScoreReport: async (id: string, lang = 'ENG') =>
      httpProvider.get(
        makeUrl(`/api/licenses/${id}/certificate?language=${lang !== 'KOR' ? lang : 'ENG'}`)
      ),
    downloadLicenseCertificate: async (
      id: string,
      language: 'ENG' | 'JPN' | 'KOR' = 'ENG'
    ): Promise<AxiosResponse<Blob>> =>
      httpProvider.get(
        makeUrl(
          `/api/licenses/${id}/certificate.pdf?language=${
            language !== 'KOR' ? language.toUpperCase() : 'ENG'
          }`
        ),
        {
          responseType: 'blob'
        }
      ),
    getLicensesEmailPreview: async (data: unknown, id: string) =>
      httpProvider.post(makeUrl(`/api/licenses/${id}/shares/preview`), data),
    sendShareEmail: async (data: unknown, licenseId?: string) =>
      httpProvider.post(makeUrl(`/api/licenses/${licenseId}/shares`), data),
    getOrderHistory: async (page?: number, search?: string) =>
      httpProvider.get(makeUrl(`/api/transactions?sort=createdAt&order=desc`), {
        params: {
          page,
          pageSize: SHARES_TABLE_PAGE_SIZE,
          ...(search && { search })
        }
      }),
    sendRefundOrder: async (licenseIds: number[]) =>
      httpProvider.post(makeUrl(`/api/licenses/refunds`), {
        licenseIds
      }),
    getOrderDetails: async (transactionId: number) =>
      httpProvider.get(makeUrl(`/api/transactions/${transactionId}`)),
    retakeTest: async (licenseId: number, systemInfo: unknown) =>
      httpProvider.post(makeUrl(`/api/licenses/${licenseId}/retake`), systemInfo),
    getLicenseResolutions: async (id: number | undefined) =>
      httpProvider.get(makeUrl(`/api/licenses/${id}/resolutions`)),
    uploadUserId: async (form: FormData, token: string | null) =>
      httpProvider.post(makeUrl(`/api/licenses/id/verify`), form, {
        headers: { Authorization: `Bearer ${token}` }
      }),
    getTokenFromCode: (code: string) => httpProvider.get(makeUrl(`/api/licenses/id/token/${code}`)),
    checkIfCanUploadId: (token: string | null) =>
      httpProvider.get(makeUrl(`/api/licenses/id/verify/status`), {
        headers: { Authorization: `Bearer ${token}` }
      }),
    hasAnyTestScored: async (): Promise<boolean> => {
      const statusFilter =
        '&status=COMPLETED&status=PARTIALLY_SCORED&status=SCORED&status=CERTIFICATION_FAILED&status=CERTIFIED';
      const { data } = await httpProvider.get(`/api/licenses?page=0&practice=false${statusFilter}`);
      return new Promise((resolve) =>
        resolve(data?.results.some((item: any) => item.scores?.length > 0))
      );
    },
    getBlueJourneyTileData: async () => httpProvider.get(makeUrl(`/api/licenses/latest`)),
    getLicensesByProductId: async (productId: number) => {
      const statusFilter = `&status=NEW&status=VALIDATED&practice=false&productId=${productId}`;
      const retakeFilter = `&retake=false`;
      return httpProvider.get(makeUrl(`/api/licenses?${statusFilter}${retakeFilter}`));
    },
    getRetakesByProductId: async (productId: number, filters?: SerializeObj) => {
      const serializedFilters = filters ? serialize(filters) : '';
      return httpProvider.get(makeUrl(`/api/licenses?${serializedFilters}&productId=${productId}`));
    },
    canStartAnotherTest: async (): Promise<AxiosResponse> =>
      httpProvider.get(makeUrl(`/api/licenses/can-validate`)),
    redeemActivationCode: async (activationCode: string) =>
      httpProvider.post(makeUrl(`/api/licenses/redeem`), { activationCode }),
    checkActivationCodeRedeemBlocked: async (): Promise<AxiosResponse<boolean>> =>
      httpProvider.get(makeUrl(`/api/licenses/redeem/blocked`)),
    downloadReceipt: async (transactionId: number): Promise<AxiosResponse<Blob>> =>
      httpProvider.get(makeUrl(`/api/transactions/${transactionId}/receipt`), {
        responseType: 'blob'
      }),
    getShareableLicenses: async (page = 0, query?: string): Promise<AxiosResponse> =>
      httpProvider.get(
        makeUrl(
          `/api/licenses?sort=endTime&order=desc&page=${page}&pageSize=${TESTS_TABLE_PAGE_SIZE}&practice=false&status=CERTIFIED&name=${
            query || ''
          }`
        )
      ),
    getNewOrValidatedLicenses: async (page = 0) => {
      return httpProvider.get(makeUrl(`/api/licenses?status=NEW&status=VALIDATED`), {
        params: { page }
      });
    },
    validateLicense: async (licenseId: number) => {
      return httpProvider.post(makeUrl(`/api/licenses/${licenseId}/validate`), {});
    },
    refreshLicense: async (licenseId: string) =>
      httpProvider.get<RefreshResponse>(makeUrl(`/api/licenses/${licenseId}/refresh`)),
    getBlockingApplications: async (): Promise<AxiosResponse<BlockedApplicationDto[]>> => {
      return httpProvider.get(makeUrl(`/api/dictionaries/applications/blocked`));
    },
    uploadUserAvatar: async (licenseId: number, form: FormData): Promise<AxiosResponse<void>> =>
      httpProvider.postForm(makeUrl(`/api/licenses/${licenseId}/image`), form)
  };
};

export type SOPClientApi = ReturnType<typeof makeSOPClient>;
