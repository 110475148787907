import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { DialogMainContainer } from './OrderRefundPopup.style';
import { AppContext } from '../../context/App.context';
import { TransactionProducts, LicenceOfProduct } from './interfaces/OrderRefundPopup.interface';
import { OrderRefundablePopupContent } from './components/OrderRefundablePopupContent/OrderRefundablePopupContent';
import { OrderRefundMessagePopupContent } from './components/OrderRefundMessagePopupContent/OrderRefundMessagePopupContent';
import { useTranslation } from 'react-i18next';
import NoTestForRefundImg from '@sharedImages/account--OrderRefundPopup__no-tests-for-refund.svg';
import RefundInProgress from '@sharedImages/account--OrderRefundPopup__refund-in-progress.svg';
import RefundSuccessImg from '@sharedImages/refund-success--image.svg';
import { extractAxiosErrorMessage, sopClient } from '@barracuda/shared/src';

interface OrderRefundPopupProps {
  readonly open: boolean;
  readonly handleClose: () => void;
  readonly tableHeaders: string[];
  readonly productID: any;
}

export const OrderRefundPopup: FC<PropsWithChildren<OrderRefundPopupProps>> = ({
  open,
  handleClose,
  productID,
  tableHeaders
}) => {
  const { t } = useTranslation();
  const { setError } = useContext(AppContext);
  const [transaction, setTransaction] = useState<TransactionProducts[]>([]);
  const [mode, setMode] = useState<
    | 'REFUNDABLE'
    | 'REFUNDED_SUCCESSFULLY'
    | 'REFUND_INPROGRESS'
    | 'ALREADY_REFUNDED'
    | 'TESTS_TAKEN_OR_EXPIRED'
    | null
  >(null);

  useEffect(() => {
    getLicencesTransactionProducts(productID);
  }, []);

  const handleRefund = async (selectedIds: number[]) => {
    try {
      await sopClient.sendRefundOrder(selectedIds);
      setMode('REFUNDED_SUCCESSFULLY');
    } catch (e: any) {
      setError({ open: true, message: extractAxiosErrorMessage(e) });
    }
  };

  const getLicencesTransactionProducts = async (id: number) => {
    const { data } = await sopClient.getOrderDetails(id);
    setTransaction(data.transactionProducts);

    const licenses: LicenceOfProduct[] = data.transactionProducts.flatMap(
      (t: TransactionProducts) => t.licenses
    );

    const isRefundInProgress = licenses.some((license) => license.status == 'REFUND_PENDING');
    const isRefundable = licenses.some((license) => ['VALIDATED', 'NEW'].includes(license.status));
    const isAllRefunded = licenses.every((license) => license.status == 'REFUNDED');

    setMode(
      isAllRefunded
        ? 'ALREADY_REFUNDED'
        : isRefundInProgress
        ? 'REFUND_INPROGRESS'
        : isRefundable
        ? 'REFUNDABLE'
        : 'TESTS_TAKEN_OR_EXPIRED'
    );
  };

  return (
    <Dialog
      sx={DialogMainContainer}
      open={open}
      onClose={handleClose}
      maxWidth='lg'
      disableEscapeKeyDown
    >
      {mode === 'REFUNDABLE' && (
        <OrderRefundablePopupContent
          tableHeaders={tableHeaders}
          transaction={transaction}
          handleRefund={handleRefund}
          handleClose={handleClose}
        />
      )}
      {mode === 'ALREADY_REFUNDED' && (
        <OrderRefundMessagePopupContent
          title={t('profilePage.noAvailableTestsForRefund')}
          description={t('profilePage.testsAlreadyRefundedDescription')}
          buttonLabel={t('profilePage.close')}
          img={NoTestForRefundImg}
          handleClose={handleClose}
        />
      )}
      {mode === 'REFUND_INPROGRESS' && (
        <OrderRefundMessagePopupContent
          title={t('profilePage.refundInProgressTitle')}
          description={t('profilePage.refundInProgressDescription')}
          buttonLabel={t('profilePage.close')}
          img={RefundInProgress}
          handleClose={handleClose}
        />
      )}
      {mode === 'TESTS_TAKEN_OR_EXPIRED' && (
        <OrderRefundMessagePopupContent
          title={t('profilePage.noAvailableTestsForRefund')}
          description={t('profilePage.testsExpiredForRefundDescription')}
          buttonLabel={t('profilePage.close')}
          img={NoTestForRefundImg}
          handleClose={handleClose}
        />
      )}
      {mode === 'REFUNDED_SUCCESSFULLY' && (
        <OrderRefundMessagePopupContent
          title={t('profilePage.youAreAllSet')}
          description={t('profilePage.weHaveInitiatedYourRefund')}
          buttonLabel={t('profilePage.done')}
          img={RefundSuccessImg}
          handleClose={handleClose}
        />
      )}
    </Dialog>
  );
};
