import { theme } from '../../styles/theme';

export const TestsTableContainer = {
  background: theme.palette.common.white,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
  marginTop: '30px',
  minHeight: '558px',
  display: 'flex',
  flexFlow: 'column'
};

export const TestsTableHeader = {
  fontSize: '20px',
  fontWeight: 700,
  paddingLeft: '24px',
  paddingTop: '36px',
  paddingBottom: '36px'
};

export const TestsTableTHead = {
  background: theme.palette.common.lightGrey5
};

export const TestTableCell = {
  padding: '16px',
  fontSize: '14px'
};

export const TestsTableTHeadCell = {
  ...TestTableCell,
  fontWeight: 600,
  whiteSpace: 'nowrap',
  '@media screen and (max-width:1024px)': {
    width: '33%'
  },
  width: '20%'
};

export const dynamicColumnsStyle = (el: string, skills: string[]) => {
  return {
    ...TestTableCell,
    fontWeight: 600,
    '@media screen and (max-width: 1024px)': {
      display: skills.includes(el) ? 'none !important' : 'table-cell'
    },
    '@media screen and (max-width: 1200px)': {
      display: el === 'Status' ? 'none !important' : 'table-cell'
    }
  };
};

export const HideMobileColumnStyle = {
  '@media screen and (max-width: 1024px)': {
    display: 'none'
  }
};

export const StatusHideCondition = {
  '@media screen and (max-width: 1200px)': {
    display: 'none'
  }
};

export const TitleAndDate = {
  '@media screen and (min-width: 1201px)': {
    display: 'none'
  },
  display: 'flex',
  flexFlow: 'column'
};

export const ProductNameOnly = {
  '@media screen and (max-width: 1200px)': {
    display: 'none'
  }
};

export const TestsTableTBody = {
  td: {
    height: '69px'
  }
};

export const TestsTableTestCell = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 600,
  img: {
    marginRight: '5px'
  }
};

export const TestsTableOverallCell = {
  fontSize: '16px',
  fontWeight: 600,
  width: '140px'
};

export const TestsTableArrowCellImage = {
  height: '15px'
};

export const scoreBadgeStyle = (mode: 'below' | 'at' | 'above' | undefined) => {
  if (!mode) {
    return;
  }
  switch (mode) {
    case 'at': {
      return {
        width: '9px',
        height: '9px',
        borderRadius: '50%',
        background: '#FF9C1A'
      };
    }
    case 'below': {
      return {
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '9px 6.5px 0 6.5px',
        borderTopColor: theme.palette.common.darkRed
      };
    }
    case 'above': {
      return {
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 6.5px 9px 6.5px',
        borderColor: 'transparent',
        borderBottomColor: theme.palette.common.darkGreen
      };
    }
  }
};

export const CellStyles = {
  verticalAlign: 'middle',
  fontSize: '13px',
  '> div': {
    display: 'inline-block',
    marginRight: '5px'
  }
};

export const NoTestsStack = {
  width: '100%',
  flex: 1
};

export const cellWidth = (index: number) => ({
  '@media screen and (min-width: 1200px)': {
    width: index === 0 ? '25%' : index === 1 ? '10%' : 'unset'
  }
});
