import {
  SignInButton,
  SignInButtonOutline,
  SignInTextField
} from '@barracuda/shared/src/components/SignIn/SignInStyles';
import { buttonOutlineDark } from '@barracuda/shared/src/styles/button';
import { theme } from '@theme';

export const homepageWrapperCallToActionTop = {
  display: 'flex',
  justifyContent: 'center'
};
export const homepageWrapperCertifyYourSkills = {
  ...homepageWrapperCallToActionTop,
  backgroundColor: theme.palette.common.lightGrey4,
  paddingTop: '10rem'
};
export const homepageWrapperCertifyYourSkillsTextFieldContainer = {
  position: 'relative',
  marginRight: '30px',
  width: '-webkit-fill-available'
};
export const homepageWrapperCertifyYourSkillsTextField = {
  ...SignInTextField,
  maxWidth: '439px',
  borderColor: 'red'
};
export const homepageWrapperCertifyYourSkillsButton = {
  marginBottom: 0,
  maxWidth: '439px !important',
  width: '100% !important',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  marginTop: '15px'
};
export const homepageWrapperCreateAccountButton = {
  ...SignInButton,
  ...homepageWrapperCertifyYourSkillsButton,
  marginTop: '20px'
};
export const homepageWrapperWatchVideoButton = (isLoggedIn?: boolean) => ({
  ...buttonOutlineDark,
  ...homepageWrapperCertifyYourSkillsButton,
  maxWidth: !isLoggedIn ? '439px' : '205px',
  '#arrow-icon': {
    marginLeft: !isLoggedIn ? '10px' : '5px'
  },
  '&:hover': {
    background: theme.palette.common.darkGrey2,
    color: theme.palette.common.white,
    '#arrow-icon': {
      filter: 'invert(100%)',
      '-webkit-filter': 'invert(100%)'
    }
  }
});
export const homepageWrapperExploreTestButton = {
  ...SignInButtonOutline,
  marginBottom: 0,
  textTransform: 'inherit',
  textDecoration: 'none'
};
export const homepageWrapperPadding = {
  padding: '10rem 0'
};
export const homepageWrapperPaddingSmall = {
  padding: '5rem 0'
};

export const homepageWrapperDiscoverCredlyButton = {
  ...SignInButtonOutline,
  marginBottom: 0,
  textTransform: 'inherit'
};
export const homepageWrapperDiscoverCredlyContainer = {
  ...homepageWrapperCallToActionTop,
  ...homepageWrapperPadding,
  backgroundColor: theme.palette.common.lightGrey4
};

export const homepageWrapperMyTopScoreButton = {
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: 1.5,
  letterSpacing: 0,
  color: theme.palette.common.darkBlue7
};

export const homepageWrapperSidePadding = {
  paddingLeft: '2rem',
  paddingRight: '2rem'
};

export const homepageWrapperErrorMessage = {
  position: 'absolute',
  bottom: '-20px',
  fontSize: '12px',
  color: 'red'
};

export const limitContainerWidth = {
  '@media screen and (min-width: 1367px)': {
    maxWidth: '1258px !important'
  }
};
