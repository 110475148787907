import { theme } from '../../styles/theme';

export const FlexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const TileContainer = {
  position: 'relative',
  background: theme.palette.common.white,
  height: '100%',
  border: `3px solid ${theme.palette.common.lightBlue0}`,
  borderRadius: '8px'
};
export const TileHead = {
  background: theme.palette.common.lightBlue0,
  borderWidth: '3px 0 0 3px',
  borderColor: theme.palette.common.lightBlue0,
  borderRadius: '8px 0',
  ...FlexCenter,
  alignSelf: 'start',
  '> span': {
    fontSize: '20px',
    fontWeight: 400,
    color: theme.palette.common.darkGrey2,
    padding: '8px',
    letterSpacing: '0.08em'
  }
};
export const TileBody = {
  marginTop: '30px',
  paddingLeft: '30px',
  paddingRight: '30px',
  display: 'flex',
  '@media screen and (max-width: 520px)': {
    paddingBottom: '50px'
  }
};
export const ShieldContainer = {
  position: 'relative',
  ...FlexCenter
};
export const ShieldScore = {
  fontSize: '42px',
  fontWeight: 700,
  color: theme.palette.common.darkGrey2,
  position: 'absolute',
  marginLeft: '12%',
  marginRight: '12%',
  left: 0,
  right: 0,
  textAlign: 'center',
  top: '16%'
};
export const ScoreData = {
  marginTop: '30px',
  display: 'flex',
  '> div': {
    marginLeft: '15px'
  },
  paddingLeft: '30px',
  paddingRight: '30px',
  '@media screen and (max-width: 520px)': {
    flexFlow: 'column',
    paddingTop: '30px',
    '> div': {
      marginLeft: 0,
      marginTop: '10px'
    }
  }
};

export const TileFooter = {
  background: theme.palette.common.lightBlue0,
  borderWidth: '0 3px 3px 3px',
  borderColor: theme.palette.common.lightBlue0,
  borderRadius: '0 0 8px 8px',
  width: '100%',
  marginTop: 'auto',
  padding: '25px',
  ...FlexCenter,
  '@media screen and (max-width: 520px)': {
    display: 'none'
  }
};

export const OverallProficiency = {
  marginLeft: '24px'
};
export const OverallProficiencyTitle = {
  fontSize: '16px',
  fontWeight: 600,
  paddingBottom: '5px'
};
export const OverallProficiencyDesc = {
  fontSize: '14px',
  fontWeight: 400,
  paddingBottom: '15px',
  maxHeight: '160px',
  overflow: 'auto'
};
export const GSEScoreStyle = {
  fontSize: '14px',
  textAlign: 'center'
};
export const TakenOnStyle = {
  marginTop: '5px',
  fontSize: '14px',
  fontWeight: 600,
  '> span': {
    fontWeight: 400,
    fontSize: '14px'
  }
};
