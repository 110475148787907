import { FC, useEffect, useMemo, useRef } from 'react';
import { SoundBar } from './SoundBar';

export type SoundWaveData = (number | [number, number])[];

interface ISoundWaveAreaProps {
  readonly width: number;
  readonly leftPadding: number;
  readonly soundbarColor?: string;
  readonly animateColor?: string;
  readonly animateTimeMs?: number;
  readonly color?: string;
  readonly left: number;
  readonly soundWaveData: SoundWaveData;
  readonly backgroundColor?: string;
  readonly id: string;
}

const rectanglePath = (left: number, width: number) =>
  `M ${left},35 v 70 c 0,5.5 4.5,10 10,10 h ${
    width - 20
  } c 5.5,0 10,-4.5 10,-10 V 35 c 0,-5.5 -4.5,-10 -10,-10 h ${-(
    width - 20
  )} c -5.5,0 -10,4.5 -10,10 z`;

export const SoundWaveArea: FC<ISoundWaveAreaProps> = ({
  width,
  leftPadding,
  color = 'white',
  left,
  soundWaveData,
  soundbarColor,
  animateColor,
  animateTimeMs,
  id
}) => {
  const animate = useRef<SVGAnimateElement>(null);

  useEffect(() => {
    animate.current?.beginElement();
  }, [animateColor, animateTimeMs]);

  const animationWidth = useMemo(() => {
    const w = (soundWaveData.length - 1) * 18 + 10;
    return w > 0 ? w : 0;
  }, [soundWaveData]);

  return (
    <g>
      {soundbarColor && (
        <rect
          x={left + leftPadding}
          y={31}
          width={animationWidth}
          height={78}
          fill={soundbarColor}
        />
      )}
      {animateColor && animateTimeMs && (
        <rect x={left + leftPadding} y={31} width={animationWidth} height={78} fill={animateColor}>
          <animate
            ref={animate}
            attributeName='width'
            from={0}
            to={animationWidth}
            dur={`${animateTimeMs}ms`}
            restart='always'
          />
        </rect>
      )}
      <mask id={`mask${id}`}>
        <path fill='white' d={rectanglePath(left, width)} />
        {soundWaveData
          .map((h) => [h, 0].flat())
          .map(([h, o], i) => (
            <SoundBar key={i} xPos={left + leftPadding + 5 + i * 18} height={h} offset={o} />
          ))}
      </mask>
      <path mask={`url(#mask${id})`} fill={color} d={rectanglePath(left, width)} />
    </g>
  );
};
