import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Auth,
  countryISO3ToISO2,
  DialogWrapper,
  EventTypes,
  extractAxiosErrorMessage,
  LoginPage,
  LoginWrapperMode,
  sopClient,
  sopUtils,
  User
} from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { SoftProfile } from '../SoftProfile/SoftProfile';
import ReactGA from 'react-ga4';
import { useAddCartItem } from '../ExploreTests/useAddCartItem';
import { RecaptchaWeb } from '@barracuda/shared/src/components/Recaptcha/RecaptchaWeb';
import { RecaptchaChangeHandler } from '@barracuda/shared/src/interface/recaptcha.interface';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { useLocalizedMatch } from '../../hooks/useLocalizedMatch';
import { OAuthFailedPopup } from '../OAuthFailedPopup/OAuthFailedPopup';
import { ELLCommerce, PasswordChangeReason, PasswordChangeStatus } from '@pearson-ell/commerce-sdk';
import { ellCommerceInstanceConfig } from '../../utils/ell-commerce';

interface LoginWrapperProps {
  forceAccountExistMode?: boolean;
  setForceAccountExistMode?: (value: boolean) => void;
}

export const LoginWrapper: FC<LoginWrapperProps> = ({
  forceAccountExistMode,
  setForceAccountExistMode
}) => {
  const navigate = useLocalizedNavigate();
  const [signUpSuccess, setSignUpSuccess] = useState({
    state: false,
    email: ''
  });
  const [sendLinkSuccess, setSendLinkSuccess] = useState(false);
  const [setPasswordSuccess, setSetPasswordSuccess] = useState(false);
  const { setIsLoggedIn, setUser, setError, user, error, isLoggedIn, eCommerceCountryISO2 } =
    useContext(AppContext);
  const [mode, setMode] = useState<LoginWrapperMode>('signin');
  const [captchaVisible, setCaptchaVisible] = useState(false);
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');
  const isResetPassword = useLocalizedMatch('/reset-password');
  const isSignIn = useLocalizedMatch('/login');
  const isSetPassword = isResetPassword && !!searchParams.get('token');
  const addCartItem = useAddCartItem();
  const captchaRef = useRef<ReCAPTCHA>();
  const [oAuthFailed, setoAuthFailed] = useState(false);

  const eCommerce = ELLCommerce.getInstance({
    ...ellCommerceInstanceConfig,
    defaultCountryISO2: eCommerceCountryISO2 || 'US',
    getTokenFunction: () => `Bearer ${sopUtils.getSopToken()}`
  });

  const location = useLocation();
  useEffect(() => {
    if (location) {
      const { state } = location;
      if (state) console.log('cartItemId', state.cartItemId);
      if (state?.error) setError({ open: true });
    }
  }, [location]);

  useEffect(() => {
    if (redirectUrl && isLoggedIn) {
      navigate(redirectUrl);
    }
    if (searchParams.get('failed') === 'true') {
      setoAuthFailed(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      setUser(null); // clear user info at first
    }
  });

  useEffect(() => {
    if (isResetPassword && !isSetPassword) {
      localStorage.setItem('selectedTab', '1');
      setMode('email_form');
      tabChangedAction();
    }

    if (isSetPassword) {
      setMode('password_form');
      tabChangedAction();
    }

    if (isSignIn) {
      setMode('signin');
    }
  }, [isResetPassword, isSignIn, isSetPassword, forceAccountExistMode]);

  const clearError = () => {
    setError({ open: false, message: '' });
  };

  const handleSSO = async (mode: 'join' | 'signin') => {
    try {
      await Auth.oAuthLogin(searchParams.get('verificationLicenseId'));
      ReactGA.event(mode === 'join' ? EventTypes.SIGN_UP_OAUTH : EventTypes.SIGN_IN_OAUTH);
    } catch (err) {
      console.error(`Error: token request; ${err}`);
    }
  };

  const handleNavigate = (url: string) => {
    navigate(`/${url}`);
  };

  const handleSignIn = async (email: string, password: string, captchaResult?: string | null) => {
    try {
      captchaResult = captchaResult || '';
      const response = await Auth.sopClient.signIn(email, password, captchaResult);

      if (response.data.token) {
        sopUtils.setSopToken(response.data.token);
      }

      const { data: captchaData } = response;
      setCaptchaVisible(captchaData?.showCaptcha);
      if (captchaData.error) {
        setError({ open: false, message: captchaData.error });
        captchaRef?.current?.reset();
        return;
      }

      const user: AxiosResponse<User> = await Auth.sopClient.getUserData();
      const { data } = user;
      if (data) {
        setUser(data);
        setIsLoggedIn(true);
        ReactGA.set({ userId: data.externalId });
        ReactGA.event(EventTypes.SIGN_IN_APP);
        eCommerce
          .CustomerService()
          .postLoginEvent(data.externalId || '', data.countryOfResidence?.isoCode);
        const IPCountry = await sopClient.getUserIPCountry();
        if (redirectUrl) {
          navigate(redirectUrl);
          return;
        }
        if (searchParams.get('verificationLicenseId')) {
          navigate(`/dashboard/results/test/${searchParams.get('verificationLicenseId')}/upload`);
        } else {
          if (location?.state?.cartItemId) {
            const pId = location?.state?.cartItemId;
            addCartItem(
              pId,
              data,
              false,
              countryISO3ToISO2(data.countryOfResidence.isoCode || IPCountry)
            );
          } else {
            navigate(`/dashboard${location?.state?.hash || ''}`);
          }
        }
      }
    } catch (error: any) {
      if (error.response?.data?.message) {
        setError({ open: false, message: error.response?.data?.message });
      } else {
        setError({ open: false, message: error.message });
      }
    }
  };

  const handleResetPassword = () => {
    navigate('/reset-password');
  };

  const handleSignUp = () => {
    if (location?.state?.cartItemId) {
      navigate('/join', { state: { cartItemId: location.state.cartItemId } });
    } else {
      navigate('/join');
    }
  };

  const handleSignInRedirect = () => {
    navigate('/login');
    setMode('signin');
    setSendLinkSuccess(false);
    setSignUpSuccess({
      state: false,
      email: ''
    });
  };

  const handleSignUpRedirect = () => {
    navigate('/join');
  };

  const tabChangedAction = () => {
    setForceAccountExistMode?.(false);
    sopUtils.removeSopToken();
    setUser(null);
  };

  const handleSendLink = async (email: string, captchaResult = '') => {
    try {
      await Auth.sopClient.generateResetPasswordLink(email, captchaResult);
    } catch (error: any) {
      console.log('There was an error during generating reset password link.', error);
      console.log(error);
    } finally {
      setSendLinkSuccess(true);
    }
  };

  const handleSetPasswordSubmit = async (password: string) => {
    const token = searchParams.get('token');
    if (!token) return;
    try {
      await sopClient.setNewPassword(password, token);
      setSetPasswordSuccess(true);
      eCommerce.CustomerService().postPasswordChangeEvent(
        user?.externalId || '',
        {
          passwordChangeReason: PasswordChangeReason.forgot_password,
          passwordChangeStatus: PasswordChangeStatus.success
        },
        user?.countryOfResidence?.isoCode
      );
    } catch (e) {
      setError({ open: true, message: extractAxiosErrorMessage(e) });
      eCommerce.CustomerService().postPasswordChangeEvent(
        user?.externalId || '',
        {
          passwordChangeReason: PasswordChangeReason.forgot_password,
          passwordChangeStatus: PasswordChangeStatus.failure
        },
        user?.countryOfResidence?.isoCode
      );
    }
  };

  const handlePasswordValidation = async (password: string): Promise<string[]> => {
    try {
      const response = await sopClient.validatePassword(password);
      const { data } = response;
      return data?.validationErrors;
    } catch (error) {
      console.log('There was an error during password validation', error);
      return [];
    }
  };

  const handleSoftProfileComplete = () => {
    if (sopUtils.getSopToken()) {
      setIsLoggedIn(true);
      navigate('../dashboard');
    }
  };

  const recaptchaComponent = useCallback(
    (onChange: RecaptchaChangeHandler) => <RecaptchaWeb ref={captchaRef} onChange={onChange} />,
    []
  );
  const emailFromSignUp = useMemo(() => {
    if (location?.state?.email) {
      return location?.state?.email;
    }
  }, []);
  const loginProps = {
    signUpHidden: false,
    handleSSO,
    handleSignIn,
    handleSignUp,
    signUpSuccess,
    handleResetPassword,
    mode,
    handleSignInRedirect,
    handleSignUpRedirect,
    handleSendLink,
    handleSetPasswordSubmit,
    sendLinkSuccess,
    setPasswordSuccess,
    handlePasswordValidation,
    error: error?.message,
    navigate: navigate,
    clearError,
    tabChangedAction,
    handleNavigate,
    emailFromSignUp,
    captchaVisible: { visible: captchaVisible, index: 0 },
    recaptchaComponent
  };

  const oAuthFailedClose = () => {
    navigate('/join');
    setoAuthFailed(false);
  };

  return (
    <>
      <LoginPage {...loginProps} />
      {user && sopUtils.getSopToken() && !forceAccountExistMode && (
        <SoftProfile
          setForceAccountExistMode={setForceAccountExistMode}
          user={user}
          softProfileComplete={handleSoftProfileComplete}
        />
      )}
      {oAuthFailed && (
        <DialogWrapper key='accountDoesntExistPopup' open scrollable subtitle='' title=''>
          <OAuthFailedPopup close={oAuthFailedClose} />
        </DialogWrapper>
      )}
    </>
  );
};
