import { Theme } from '@mui/material';
import { SignInButton } from '@barracuda/shared/src/components/SignIn/SignInStyles';
const isWindows = /Win/i.test(navigator.userAgent);
import { theme } from '@theme';

export const DialogMainContainer = {
  '@media screen and (max-width: 500px)': {
    '.MuiPaper-root': {
      margin: '10px !important'
    }
  },
  '> div > div': {
    '@media screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx)': {
      scale: isWindows ? '0.8' : 'unset',
      maxHeight: 'unset'
    },
    '@media screen and (min-resolution: 1.5dppx)': {
      scale: isWindows ? '0.67' : 'unset',
      maxHeight: 'unset'
    }
  }
};

export const RefundPopupMain = {
  padding: '3rem',
  minWidth: '980px',

  '@media screen and (max-width: 500px)': {
    padding: '1.5rem',
    minWidth: 'unset'
  }
};

export const OrderRefundPopupTitle = {
  fontSize: '36px',
  '@media screen and (max-width: 740px)': {
    fontSize: '30px'
  },
  fontWeight: 800,
  lineHeight: '43px',
  paddingBottom: '10px'
};

export const OrderRefundPopupSubTitle = {
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: 1.5,
  width: '80%',
  '@media screen and (max-width: 740px)': {
    width: '100%'
  }
};

export const OrderRefundPopupCloseIcon = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: (theme: Theme) => theme.palette.grey[500]
};

export const OrderRefundPopupDialogWrapperContainer = {
  marginTop: '40px',
  '@media screen and (max-width: 740px)': {
    marginTop: '30px'
  },
  width: '100%',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@media screen and (max-width: 650px)': {
    width: 'unset'
  }
};

export const OrderRefundPopupTableHeadCell = {
  fontWeight: 600,
  textAlign: 'left',
  color: `${theme.palette.common.lightGrey4} !important`,
  ':first-child': {
    borderTopLeftRadius: '8px'
  },
  ':last-child': {
    borderTopRightRadius: '8px'
  }
};

export const OrderRefundPopupAccordionContent = {
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px'
};

export const OrderRefundPopupAccordionTableTheadCell = {
  fontWeight: 600,
  textAlign: 'left',
  color: theme.palette.common.darkGrey3,
  width: '33%',
  backgroundColor: theme.palette.common.lightGrey5,
  lineHeight: '18px',
  fontSize: '15px',
  letterSpacing: 0
};

export const OrderRefundPopupButtonContainer = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  marginTop: '25px',
  '@media screen and (max-width: 740px)': {
    marginTop: 0
  }
};

export const OrderRefundPopupButtonMobile = {
  '@media screen and (max-width: 395px)': {
    fontSize: '15px'
  }
};

export const OrderRefundPopupTableRow = (isRefundable: boolean) => ({
  cursor: isRefundable ? 'inherit' : 'default',

  '&.MuiTableRow-root': {
    paddingBottom: '2rem'
  }
});

export const OrderRefundPopupTableRowCell = {
  margin: '1rem',
  border: 'none',
  padding: '.5rem 0'
};
export const OrderRefundPopupTableRowCellInner = {
  fontSize: '16px',
  paddingLeft: '16px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #EDEDED',
  '@media screen and (max-width: 700px)': {
    minHeight: '80px'
  }
};

export const OrderRefundPopupTableRowFirstCell = {
  ...OrderRefundPopupTableRowCellInner,
  borderRight: 'none',
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
  '@media screen and (max-width: 700px)': {
    flexFlow: 'column',
    minHeight: '80px',
    alignItems: 'start',
    justifyContent: 'center'
  }
};
export const SmallDateStyles = {
  fontSize: '12px',
  fontWeight: '400 !important',
  color: theme.palette.common.black,
  marginLeft: '30px'
};
export const OrderRefundPopupTableRowMiddleCell = {
  ...OrderRefundPopupTableRowCellInner,
  borderLeft: 'none',
  borderRight: 'none'
};
export const OrderRefundPopupTableRowLastCell = {
  ...OrderRefundPopupTableRowCellInner,
  borderLeft: 'none',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px'
};

export const OrderRefundPopupCheckboxContainer = {
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center'
};

export const OrderRefundPopupTableCheckbox = {
  color: theme.palette.common.grey,
  marginRight: 0,
  '&.Mui-checked': {
    color: theme.palette.common.violet
  }
};

export const OrderRefundPopupSubmit = {
  ...SignInButton,
  marginTop: '2rem',
  marginBottom: 0,
  ...OrderRefundPopupButtonMobile
};

export const OrderRefundPopupTableContainer = {
  ...OrderRefundPopupTableHeadCell,
  cursor: 'pointer'
};

export const CellStyles = (value: boolean) => ({
  background: !value ? theme.palette.common.lightGrey9 : 'inherit',
  color: !value ? theme.palette.common.darkBlue2 : theme.palette.common.darkGrey11,
  '.bold': {
    fontWeight: !value ? '400' : '600',
    color: !value ? theme.palette.common.darkBlue2 : theme.palette.common.darkGrey11
  }
});

export const NoRefundableStyles = {
  marginTop: '60px',
  '@media screen and (max-width: 740px)': {
    marginTop: 0,
    width: '230px'
  }
};

export const SuccessMobile = {
  '@media screen and (max-width: 740px)': {
    marginTop: '0 !important'
  }
};
