import { theme } from '../../styles/theme';

export const BadgeContainer = {
  padding: '40px',
  backgroundColor: theme.palette.common.lightGrey4,
  borderRadius: '8px',
  border: `1px solid ${theme.palette.common.lightGrey3}`,
  height: '100%',
  '@media screen and (max-width: 1270px)': {
    height: '540px'
  },
  '@media screen and (max-width: 880px)': {
    height: 'unset'
  }
};
export const BadgeTitle = {
  fontWeight: 700,
  fontSize: '25px',
  color: theme.palette.common.darkGrey2,
  paddingBottom: '25px'
};
export const BadgeCircle = {
  position: 'relative'
};
export const ScoreInsideShield = {
  position: 'absolute',
  top: '47px',
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'center',
  fontWeight: 700,
  fontSize: '64px',
  color: theme.palette.common.darkGrey2
};
export const BadgeContent = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  '@media screen and (min-width: 650px) and (max-width: 880px)': {
    flexFlow: 'row',
    justifyContent: 'flex-start'
  }
};
export const PracticeButton = {
  backgroundColor: theme.palette.common.white,
  width: '130px',
  color: theme.palette.common.black,
  fontWeight: 600,
  height: '32px',
  fontSize: '14px',
  textTransform: 'none',
  marginTop: '35px',
  border: `2px solid ${theme.palette.common.darkGrey2}`,
  borderRadius: '40px',
  '&:hover': {
    backgroundColor: theme.palette.common.black,
    border: `1px solid ${theme.palette.common.white}`,
    color: theme.palette.common.white
  }
};
export const ValidValue = {
  color: theme.palette.common.black,
  fontSize: '14px',
  fontWeight: 600
};
export const ValidKey = {
  paddingBottom: '10px',
  fontSize: '14px'
};
export const NoTopScoreDescription = {
  textAlign: 'center',
  paddingTop: '36px'
};
export const InsideStack = {
  alignItems: 'center',
  justifyContent: 'center',
  '@media screen and (min-width: 650px) and (max-width: 880px)': {
    marginLeft: '30px',
    justifyContent: 'flex-start',
    paddingBottom: '20px',
    alignItems: 'flex-start'
  }
};
