import { Box, Stack, Typography } from '@mui/material';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { FC } from 'react';
import QRCode from 'react-qr-code';
import { ErrorContainer, ErrorDescription, ErrorTitle, qrCode } from './UploadIdErrorScreen.style';
import { useTranslation } from 'react-i18next';
import qrcodePreview from '../../assets/images/verification--uploadId__qrcodePreview.svg';
import { theme } from '@theme';

export const UploadIdErrorScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Stack sx={ErrorContainer}>
        <Box sx={{ position: 'relative' }}>
          <QRCode style={qrCode} size={185} value={window.location.href} />
          <Img src={qrcodePreview} />
        </Box>
        <Typography sx={ErrorTitle}>{t('mobileUploadId.errorHeader')}</Typography>
        <Typography sx={ErrorDescription}>{t('mobileUploadId.errorDescription')}</Typography>
        <Typography sx={{ color: theme.palette.common.darkGrey11, marginTop: '10px' }}>
          {t('mobileUploadId.useQrCode')}
        </Typography>
      </Stack>
    </>
  );
};
