import {
  Box,
  Grid,
  Pagination,
  Stack,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  Typography,
  useMediaQuery
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { DashboardResultsTabLabel } from '../DashboardResults/DashboardResults.styles';
import { AxiosResponse } from 'axios';
import {
  License,
  MobileTestTile,
  PageableResponse,
  Score,
  SkillType,
  SkillTypes,
  sopClient,
  statusesWithoutDetailsPage,
  TestsTable
} from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import {
  HideMobileColumnStyle,
  LoginRightColumnTab,
  LoginRightColumnTabs,
  MobileTestTileDate,
  NoTestsMobile,
  PaginationPosition,
  PanelBox,
  ScalableFix,
  StackMobileCondition,
  StackNextButton,
  StackTitle,
  TableMobileCondition,
  TestsStack
} from './ResultsTablesWrapper.style';
import moment from 'moment';
import { Dictionary, groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../app.utils';
import { useLocalizedNavigate } from 'src/hooks/useLocalizedNavigate';
import { TESTS_TABLE_PAGE_SIZE } from '@barracuda/sop-api-client';

const findScoreByName = (
  name: string,
  scale: string,
  scores: Score[],
  retakeRedeemed?: boolean
): string | null => {
  if (typeof scores === undefined || retakeRedeemed) return null;
  const score = scores.find((el) => el.component === name && el.scale === scale);
  if (!score) {
    return null;
  }
  switch (scale) {
    case 'LEVEL':
    case 'CEFR': {
      return score.label || null;
    }
    default: {
      return score.score || null;
    }
  }
};

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ marginTop: '20px' }}>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const ResultsTablesWrapper: FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [licenses, setLicenses] = useState<License[]>([]);
  const [practiceLicenses, setPracticeLicenses] = useState<License[]>([]);
  const [page, setPage] = useState(0);
  const [practicePage, setPracticePage] = useState(0);
  const [total, setTotal] = useState(0);
  const [datesDictionary, setDatesDictionary] = useState<Dictionary<License[]> | null>(null);
  const [practiceDatesDictionary, setPracticeDatesDictionary] = useState<Dictionary<
    License[]
  > | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPracticePages, setTotalPracticePages] = useState(0);
  const navigate = useLocalizedNavigate();
  const { setError } = useContext(AppContext);
  const { t } = useTranslation();
  const maxWidth700px = useMediaQuery('(max-width: 700px)');
  const isWindows = /Win/i.test(navigator.userAgent);
  const skillTypes = [t('speaking'), t('listening'), t('reading'), t('writing')];

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setPage(0);
    setActiveTab(newValue);
  };
  useEffect(() => {
    if (isMobile() || maxWidth700px) {
      loadMobileVersionData(page);
    }
  }, []);

  const loadMobileVersionData = async (page: number, query?: string) => {
    await getTests(page, query);
    await getPracticeTestsMobileVariation(page, query);
  };

  const getTests = async (page: number, query?: string) => {
    const method = activeTab === 0 ? sopClient.getLicenses : sopClient.getPracticeLicenses;
    try {
      const response = (await method(page, query)) as AxiosResponse;
      const data = response.data as PageableResponse<License>;
      setTotal(data.total);
      setTotalPages(data.totalPages);
      const results = data.results;
      setLicenses(results);
      distinctDatesByDay(results, setDatesDictionary);
    } catch (e: any) {
      const {
        response: {
          data: { message }
        }
      } = e;
      setError(true, message);
    }
  };
  const LEVEL_SCALE_ARR = Object.values(t('results.levelScale', { returnObjects: true }));

  const getLabelForSkill = (skillname: SkillType, scale: string, scores: Score[]) => {
    const overallScore = findScoreByName(SkillTypes.OVERALL_SCORE, scale, scores);
    const overallScoreIndex = overallScore ? LEVEL_SCALE_ARR.indexOf(overallScore) : undefined;
    const skillnameScore = findScoreByName(skillname, scale, scores);
    const skillnameScoreIndex = skillnameScore
      ? LEVEL_SCALE_ARR.indexOf(skillnameScore)
      : undefined;

    if (
      (!skillnameScoreIndex && skillnameScoreIndex !== 0) ||
      (!overallScoreIndex && overallScoreIndex !== 0)
    )
      return '';

    if (skillnameScoreIndex < overallScoreIndex) {
      return t('results.belowLevel');
    }

    if (skillnameScoreIndex === overallScoreIndex) {
      return t('results.atLevel');
    }

    return t('results.aboveLevel');
  };

  const getPracticeTestsMobileVariation = async (page: number, query?: string) => {
    try {
      const response = (await sopClient.getPracticeLicenses(page, query)) as AxiosResponse;
      const data = response.data as PageableResponse<License>;
      setTotalPracticePages(data.totalPages);
      const results = data.results;
      distinctDatesByDay(results, setPracticeDatesDictionary);
      setPracticeLicenses(results);
    } catch (e: any) {
      const {
        response: {
          data: { message }
        }
      } = e;
      setError(true, message);
    }
  };

  const handleRowClick = (t: License) => {
    if (statusesWithoutDetailsPage.includes(t.status)) {
      return;
    }
    navigate(`test/${t.id}`);
  };

  const handleChangePage = async (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    getTests(newPage);
  };

  const loadLicensePage = async (page: number) => {
    setPage(page);
    await getTests(page, undefined);
  };
  const loadPracticePage = async (page: number) => {
    setPracticePage(page);
    await getPracticeTestsMobileVariation(page, undefined);
  };
  const distinctDatesByDay = (array: License[], setter: (value: Dictionary<License[]>) => void) => {
    const dateDictionary = groupBy(array, (item) => {
      return moment(item.endTime).startOf('day');
    });
    setter(dateDictionary);
  };

  const tableHeaders = Object.values(
    t('results.resultsTablesHeaders', { returnObjects: true })
  ).map((header) => header as string);

  const practiceTableHeaders = Object.values(
    t('results.resultsPracticeTableHeaders', { returnObjects: true })
  ).map((header) => header as string);

  const generateEmptyRows = (practice: boolean): JSX.Element[] => {
    const rows = [];
    const headersSource = practice ? practiceTableHeaders : tableHeaders;
    if (licenses.length === TESTS_TABLE_PAGE_SIZE) return [];

    for (let i = 0; i <= TESTS_TABLE_PAGE_SIZE - licenses.length - 1; i++) {
      rows.push(
        <TableRow key={Math.random() * 1000}>
          {headersSource.map((element, index) => (
            <TableCell
              key={`dynamicHeader-${index}`}
              sx={[HideMobileColumnStyle(element, skillTypes), { height: '69px' }]}
            />
          ))}
        </TableRow>
      );
    }

    return rows;
  };

  return (
    <>
      <Grid item xs={12} sx={[TableMobileCondition, { marginTop: '70px' }]}>
        <Tabs
          sx={[LoginRightColumnTabs, { marginBottom: 0 }, isWindows ? ScalableFix : {}]}
          value={activeTab}
          onChange={handleTabChange}
        >
          <Tab
            sx={[LoginRightColumnTab, DashboardResultsTabLabel]}
            label={t('results.yourTests')}
          />
          <Tab
            sx={[LoginRightColumnTab, DashboardResultsTabLabel]}
            label={t('results.yourPracticeTests')}
          />
        </Tabs>
        <Box sx={PanelBox} />
        <TabPanel value={activeTab} index={0}>
          <TestsTable
            getLabelForSkill={getLabelForSkill}
            getTests={getTests}
            title={t('results.allYourTests')}
            findScoreByName={findScoreByName}
            generateEmptyRows={() => generateEmptyRows(false)}
            handleChangePage={handleChangePage}
            handleRowClick={handleRowClick}
            page={page}
            setPage={setPage}
            tableHeaders={tableHeaders}
            licenses={licenses}
            total={total}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <TestsTable
            getLabelForSkill={getLabelForSkill}
            title={t('results.allYourPracticeTests')}
            findScoreByName={findScoreByName}
            generateEmptyRows={() => generateEmptyRows(true)}
            handleChangePage={handleChangePage}
            handleRowClick={handleRowClick}
            page={page}
            setPage={setPage}
            tableHeaders={practiceTableHeaders}
            licenses={licenses}
            total={total}
            getTests={getTests}
            practice
          />
        </TabPanel>
      </Grid>
      <Stack sx={StackMobileCondition}>
        <Typography sx={StackTitle}>{t('results.yourTests')}</Typography>
        <Stack sx={TestsStack}>
          {!!licenses.length &&
            datesDictionary &&
            Object.keys(datesDictionary).map((item, index) => (
              <Stack key={`DictStack-${index}`}>
                <Typography sx={MobileTestTileDate}>
                  {moment(item).format('dddd, DD MMMM yyyy')}
                </Typography>
                {Object.values(datesDictionary[item]).map((value) => (
                  <MobileTestTile
                    key={`MobileTile-${index}`}
                    handleRowClick={handleRowClick}
                    license={value}
                    practice={false}
                  />
                ))}
              </Stack>
            ))}
          {!!licenses.length && (
            <Box sx={StackNextButton}>
              <Pagination
                size='small'
                sx={PaginationPosition}
                onChange={(_, value) => loadLicensePage(value - 1)}
                count={totalPages}
              />
            </Box>
          )}
          {!licenses.length && <Typography sx={NoTestsMobile}>{t('dontHaveAnyTests')}</Typography>}
        </Stack>
        <Typography sx={{ ...StackTitle, marginTop: '47px' }}>Your Practice Tests</Typography>
        <Stack sx={TestsStack}>
          {!!practiceLicenses.length &&
            !!practiceDatesDictionary &&
            Object.keys(practiceDatesDictionary).map((item, index) => (
              <Stack key={`PracticeDictStack-${index}`}>
                <Typography sx={MobileTestTileDate}>
                  {moment(item).format('dddd, DD MMMM yyyy')}
                </Typography>
                {Object.values(practiceDatesDictionary[item]).map((value, index) => (
                  <MobileTestTile
                    key={`PracticeMobileTile-${index}`}
                    license={value}
                    getLabelForSkill={findScoreByName}
                    practice
                  />
                ))}
              </Stack>
            ))}
          {!!practiceLicenses.length && (
            <Box sx={StackNextButton}>
              <Pagination
                size='small'
                page={practicePage}
                sx={PaginationPosition}
                onChange={(_, value) => loadPracticePage(value)}
                count={totalPracticePages - 1}
              />
            </Box>
          )}
          {!practiceLicenses.length && (
            <Typography sx={NoTestsMobile}>{t('dontHaveAnyTests')}</Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
};
