import { FC, useContext, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  ReasonRow,
  ReasonSectionSubtitle,
  ResultsModifications,
  ScaleStyles
} from './DashboardTestDetails.styles';
import {
  DashboardTestDetailsCertificationFailed,
  DashboardTestDetailsCertified,
  DialogWrapper,
  IRoute,
  Language,
  License,
  LicenseResolution,
  ScoresPopup,
  SkillType,
  sopClient,
  V2Certificate,
  V2CertificateSkill
} from '@barracuda/shared/src';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { AxiosError, AxiosResponse } from 'axios';
import { AppContext } from '../../context/App.context';
import { Trans, useTranslation } from 'react-i18next';
import {
  NavbarRouteBoxContainer,
  NavbarRouteBoxLinks,
  NavbarRouteBoxStyle,
  RouteLink
} from '../HeaderNavbar/HeaderNavbar.style';
import ArrowRight from '../../assets/images/arrow-right.svg';
import { changeLanguage } from '@i18n';
import {
  certifiedSurveyKey,
  incompleteSurveyKey,
  ResultsSurveyVariant,
  uncertifiedSurveyKey
} from '../ResultsSurvey/ResultsSurvey';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { DownloadCertificatePopup } from '../DownloadCertificatePopup/DownloadCertificatePopup';
import { saveAxiosResponseFile } from '../../app.utils';

const getDownloadCertificatePopupSubtitle = (license?: License) => {
  return license?.product.code === 'BCUDA_2S_RW'
    ? 'downloadCertificatePopup.readingWriting'
    : 'downloadCertificatePopup.speakingListening';
};

export const DashboardTestDetails: FC = () => {
  const [license, setLicense] = useState<License>();
  const [daysToExpire, setDaysToExpire] = useState<number>();
  const [skills, setSkills] = useState<Record<string, V2CertificateSkill>>({});
  const [resolutions, setResolutions] = useState<LicenseResolution[]>([]);
  const navigate = useLocalizedNavigate();
  const { id } = useParams();
  const {
    setError,
    setResultsSurveyOpen,
    setResultsSurveyVariant,
    setResultsSurveyLicenseId,
    user
  } = useContext(AppContext);
  const [scorePopup, setScorePopup] = useState(false);
  const [downloadCertificateOpened, setDownloadCertificateOpened] = useState(false);
  const { t } = useTranslation();
  const { language } = useContext(AppContext);
  const scoredStatuses = ['CERTIFIED', 'SCORED', 'PARTIALLY_SCORED', 'CERTIFICATION_FAILED'];

  useEffect(() => {
    if (license && scoredStatuses.includes(license.status)) {
      getScoreReportDetails(language);
    }
  }, [language, license]);
  const getLicenseDetails = async () => {
    if (id) {
      const response = (await sopClient.getLicenseDetails(+id)) as AxiosResponse;
      const { data } = response;
      setLicense(data);
      setDaysToExpire(calculateDaysToExpiration(data?.retakeExpiresAt));
      if (data.status === 'CERTIFICATION_FAILED' && id) {
        const { data: resolutionsResponse } = await sopClient.getLicenseResolutions(+id);
        setResolutions(resolutionsResponse);
      }
    }
  };

  const calculateDaysToExpiration = (expirationDate: Date) => {
    const daysToExpire = Math.ceil(moment(expirationDate).diff(Date.now(), 'days', true));
    return daysToExpire < 0 ? 0 : daysToExpire;
  };

  useEffect(() => {
    return () => {
      if (license && setResultsSurveyOpen && setResultsSurveyVariant && setResultsSurveyLicenseId) {
        let surveyVariant = undefined;
        if (license.status.toString() === 'CERTIFIED') {
          if (user && certifiedSurveyKey in user.profile) {
            const data = JSON.parse(user.profile[certifiedSurveyKey]);
            if (!data.includes(license.id.toString())) {
              surveyVariant = 'certified' as ResultsSurveyVariant;
            }
          } else {
            surveyVariant = 'certified' as ResultsSurveyVariant;
          }
        } else if (license.status.toString() === 'LATE_HANGUP') {
          if (user && incompleteSurveyKey in user.profile) {
            const data = JSON.parse(user.profile[incompleteSurveyKey]);
            if (!data.includes(license.id.toString())) {
              surveyVariant = 'incomplete' as ResultsSurveyVariant;
            }
          } else {
            surveyVariant = 'incomplete' as ResultsSurveyVariant;
          }
        } else {
          if (user && uncertifiedSurveyKey in user.profile) {
            const data = JSON.parse(user.profile[uncertifiedSurveyKey]);
            if (!data.includes(license.id.toString())) {
              surveyVariant = 'uncertified' as ResultsSurveyVariant;
            }
          } else {
            surveyVariant = 'uncertified' as ResultsSurveyVariant;
          }
        }
        if (surveyVariant) {
          setResultsSurveyOpen(
            license?.status.toString() !== 'SCORED' && license?.status.toString() !== 'ABORTED'
          );
          setResultsSurveyVariant(surveyVariant);
          setResultsSurveyLicenseId(license.id.toString());
        }
      }
    };
  }, [license]);

  const getScoreReportDetails = async (lang = 'ENG') => {
    try {
      const response = (await sopClient.getDataForScoreReport(id as string, lang)) as AxiosResponse;
      await changeLanguage(lang.toLowerCase());
      const { data }: { data: V2Certificate } = response;
      setSkills(data.skills);
    } catch (e: any) {
      const {
        response: {
          data: { message }
        }
      } = e;
      setError(true, message);
    }
  };

  const findCefrScoreBySkill = (skillName: string) =>
    license?.scores.find((score) => score.component === skillName && score.scale === 'CEFR')?.label;

  const findScoreByName = (name: string): number => {
    const score = license?.scores?.find((el) => el?.component === name && el?.scale === 'GSE');
    return !score ? 0 : +score?.score;
  };
  const findExceptionByName = (name: string): string =>
    (skills[name]?.exceptionCode && skills[name]?.exceptionMessage) || '';
  const findSkillByName = (name: SkillType): V2CertificateSkill | undefined => skills[name];

  const handleDownloadCertificate = async (lang: Language) => {
    try {
      if (id) {
        const response = await sopClient.downloadLicenseCertificate(id as string, lang);
        saveAxiosResponseFile(response);
        setDownloadCertificateOpened(false);
      }
    } catch (e: any) {
      if (e.response.status === 403) {
        const message = <Trans i18nKey='toast.refreshMTSError' components={{ a: <a href='' /> }} />;
        setError({ open: true, message });
        return;
      }
      const responseAsText = await e.response.data.text();
      const { message } = JSON.parse(responseAsText);
      setError({ open: e.response?.status !== 401, message });
    }
  };

  useEffect(() => {
    getLicenseDetails();
  }, []);

  const retakeTest = () => {
    if (license?.id) {
      // TODO: Systeminfo is not available in web. Should be optional.
      sopClient
        .retakeTest(license.id, {})
        .then((_result: AxiosResponse) => {
          const anchor = document.createElement('a');
          anchor.href = `${process.env.REACT_APP_DEEPLINK_ID}://token=${localStorage.getItem(
            'token'
          )}`;
          anchor.click();
        })
        .catch((error: AxiosError) =>
          console.error('There was an error during retake license.', error)
        );
    }
  };

  const resolutionsList = resolutions.map((resolution, index) => {
    return (
      <Stack key={`resolution-${index}`}>
        <Typography sx={ReasonSectionSubtitle}>
          {t('results.reason', { number: index + 1 })}
        </Typography>
        <Typography sx={ReasonRow}>{resolution?.value}</Typography>
      </Stack>
    );
  });

  const handleOpenScorePopup = () => {
    setScorePopup(true);
  };

  const handleClosePopup = () => {
    setScorePopup(false);
  };

  const showDetailsWithoutScores = (): boolean => {
    return (
      license?.status === 'LATE_HANGUP' ||
      license?.status === 'ABORTED' ||
      license?.status === 'COMPLETED' ||
      license?.status === 'ERROR' ||
      license?.status === 'NOT_SCORABLE' ||
      license?.status === 'PARTIALLY_SCORED'
    );
  };

  const defaultRoutes: IRoute[] = [
    { label: t('results.allResults'), main: false, redirectTo: `../results` },
    { label: t('results.resultDetails'), main: true, redirectTo: `` }
  ];

  return (
    <>
      <Box sx={[NavbarRouteBoxStyle, ResultsModifications]}>
        <Box sx={NavbarRouteBoxContainer}>
          {defaultRoutes.map((item, index) => {
            return (
              <Box sx={NavbarRouteBoxLinks} key={`routeLink-${index}`}>
                <Typography
                  key={index}
                  onClick={() => navigate?.(item.redirectTo)}
                  sx={{
                    ...RouteLink,
                    fontWeight: item.main ? '600' : '400',
                    cursor: 'pointer',
                    textDecoration: index === 0 ? 'underline' : 'none'
                  }}
                >
                  {item.label}
                </Typography>
                {index !== defaultRoutes.length - 1 && <img width={7} src={ArrowRight} />}
              </Box>
            );
          })}
        </Box>
      </Box>

      {license &&
        (license.status.toString() === 'SCORED' || license.status.toString() === 'CERTIFIED') && (
          <>
            <DashboardTestDetailsCertified
              navigate={navigate}
              findCefrScoreBySkill={findCefrScoreBySkill}
              license={license}
              skills={skills}
              findScoreByName={findScoreByName}
              handleDownloadCertificate={() => setDownloadCertificateOpened(true)}
              findSkillByName={findSkillByName}
              t={t}
            />
          </>
        )}
      {/*HANDLE UNABLE TO CERTIFY*/}
      {license && license.status.toString() === 'CERTIFICATION_FAILED' && (
        <>
          <DashboardTestDetailsCertificationFailed
            navigate={navigate}
            license={license as License}
            skills={skills}
            findSkillByName={findSkillByName}
            retakeTest={retakeTest}
            daysToExpire={daysToExpire}
            resolutionsList={resolutionsList}
            openScorePopup={handleOpenScorePopup}
          />
        </>
      )}
      {showDetailsWithoutScores() && (
        <>
          <DashboardTestDetailsCertificationFailed
            navigate={navigate}
            license={license as License}
            skills={skills}
            findSkillByName={findSkillByName}
            retakeTest={retakeTest}
            daysToExpire={daysToExpire}
            resolutionsList={resolutionsList}
            openScorePopup={handleOpenScorePopup}
          />
        </>
      )}
      {scorePopup && license && license.status.toString() !== 'ABORTED' && (
        <ScoresPopup
          findCefrScoreBySkill={findCefrScoreBySkill}
          open={scorePopup}
          license={license as License}
          skills={skills}
          findSkillByName={findSkillByName}
          findExceptionByName={findExceptionByName}
          closePopup={handleClosePopup}
        />
      )}
      <DialogWrapper
        key='downloadCertificatePopup'
        title={t('downloadCertificatePopup.wrapperTitle')}
        subtitle={t(getDownloadCertificatePopupSubtitle(license))}
        open={downloadCertificateOpened}
        paperRootModifications={ScaleStyles}
        handleClose={() => setDownloadCertificateOpened(false)}
        closeable
      >
        <DownloadCertificatePopup handleDownload={handleDownloadCertificate} />
      </DialogWrapper>
    </>
  );
};
