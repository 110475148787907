import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Typography, Stack } from '@mui/material';
import { SimpleColorBar } from '../SimpleColorBar/SimpleColorBar';
import { NoScoresStack, SkillsTileContainer, SkillsTileTitle } from './SkillsTile.style';
import UnableToCertifyImage from '../../assets/images/unable-to-certify.svg';
import { Img } from '../Primitive';
import { SignInButton, SignInButtonOutline } from '../SignIn/SignInStyles';
import { theme } from '../../styles/theme';

interface SkillsTileProps {
  findScoreByName?: (value: string) => number;
  removeBorder?: boolean;
  mode?: 'NO_SCORES' | 'IMG_ONLY' | undefined;
  openScorePopup?: () => void;
  image?: any;
}

export const SkillsTile: FC<SkillsTileProps> = ({
  findScoreByName,
  removeBorder,
  mode,
  openScorePopup,
  image
}) => {
  const { t } = useTranslation();
  const skills = [
    {
      label: t('speaking'),
      score: findScoreByName?.('Speaking'),
      color: theme.palette.common.darkBlue9
    },
    {
      label: t('listening'),
      score: findScoreByName?.('Listening'),
      color: theme.palette.common.violet
    },
    {
      label: t('reading'),
      score: findScoreByName?.('Reading'),
      color: theme.palette.common.lightBlue3
    },
    {
      label: t('writing'),
      score: findScoreByName?.('Writing'),
      color: theme.palette.common.darkBlue4
    }
  ];

  return (
    <Box sx={[SkillsTileContainer, removeBorder ? { border: 'unset' } : {}]}>
      {mode !== 'IMG_ONLY' && (
        <Typography sx={[SkillsTileTitle, mode === 'NO_SCORES' ? { paddingBottom: '9px' } : {}]}>
          {t('skillsLabel')}
        </Typography>
      )}
      {mode !== 'NO_SCORES' &&
        mode !== 'IMG_ONLY' &&
        skills
          .filter((i) => !!i.score)
          .map((skill) => (
            <SimpleColorBar
              grayedOut={!skill.score}
              label={skill.label}
              score={skill.score || 0}
              max={90}
              color={skill.color}
              backgroundColor='#EAEAEA'
            />
          ))}
      {mode === 'NO_SCORES' && (
        <Stack sx={NoScoresStack}>
          <Img sx={{ maxWidth: '264px' }} alt='Unable to certify icon' src={UnableToCertifyImage} />
          <Typography sx={{ color: theme.palette.common.darkBlue2 }}>
            <Trans i18nKey='skillsTile.weWereUnable' components={{ bold: <strong /> }} />
          </Typography>
          <Button
            sx={[SignInButton, SignInButtonOutline, { margin: 0 }]}
            onClick={() => openScorePopup?.()}
          >
            {t('results.viewMyScores')}
          </Button>
        </Stack>
      )}
      {mode === 'IMG_ONLY' && image && (
        <Stack sx={NoScoresStack}>
          <Img height={316} sx={{ maxWidth: '100%' }} src={image} />
        </Stack>
      )}
    </Box>
  );
};
