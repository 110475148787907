import { BaseSyntheticEvent, FC } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  PurpleCircle,
  SearchBarContainer,
  SearchBarInput,
  SearchBarSearchIcon
} from './SearchBar.styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ISearchBarProps {
  readonly handleInputChange: (event: BaseSyntheticEvent) => void;
}

export const SearchBar: FC<ISearchBarProps> = ({ handleInputChange }) => {
  const { t } = useTranslation();

  return (
    <Box sx={SearchBarContainer}>
      <Box
        onChange={handleInputChange}
        component='input'
        placeholder={t('profilePage.searchPlaceholder')}
        sx={SearchBarInput}
        type='text'
        id='searchInput'
      />
      <Box sx={PurpleCircle}>
        <SearchIcon sx={SearchBarSearchIcon} />
      </Box>
    </Box>
  );
};
