import { FC } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import {
  NoRefundableStyles,
  OrderRefundPopupButtonContainer,
  OrderRefundPopupCloseIcon,
  OrderRefundPopupDialogWrapperContainer,
  OrderRefundPopupSubmit,
  OrderRefundPopupSubTitle,
  OrderRefundPopupTitle,
  RefundPopupMain
} from '../../OrderRefundPopup.style';
import CloseIcon from '@mui/icons-material/Close';
import Img from '@barracuda/shared/src/components/Primitive/Img';

interface OrderRefundMessagePopupContentProps {
  readonly title: string;
  readonly description: string;
  readonly img: string;
  readonly buttonLabel: string;
  readonly handleClose: () => void;
}

export const OrderRefundMessagePopupContent: FC<OrderRefundMessagePopupContentProps> = ({
  title,
  description,
  img,
  buttonLabel,
  handleClose
}) => {
  return (
    <Box sx={RefundPopupMain}>
      <Box sx={{ letterSpacing: 0 }}>
        <Typography component='h1' sx={OrderRefundPopupTitle}>
          {title}
        </Typography>
        <Typography sx={OrderRefundPopupSubTitle}>{description}</Typography>
      </Box>
      <IconButton aria-label='close' onClick={handleClose} sx={OrderRefundPopupCloseIcon}>
        <CloseIcon />
      </IconButton>
      <Grid container spacing={0} sx={OrderRefundPopupDialogWrapperContainer}>
        <Img src={img} sx={NoRefundableStyles} />
        <Grid item xs={12} sx={OrderRefundPopupButtonContainer}>
          <Button onClick={handleClose} tabIndex={0} type='submit' sx={OrderRefundPopupSubmit}>
            {buttonLabel}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
