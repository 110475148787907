import { theme } from '../../styles/theme';

export const DashboardTestDetailsShareLink = {
  color: theme.palette.common.black,
  fontSize: '16px',
  fontWeight: 700,
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  cursor: 'pointer',
  borderRadius: '30px',
  background: theme.palette.common.lightGrey4,
  '@media only screen and (max-width: 540px)': {
    borderRadius: '50px',
    padding: 0,
    height: '48px',
    width: '48px',
    minWidth: 0
  }
};
export const MobileVisibility = {
  '@media only screen and (max-width: 540px)': {
    display: 'none'
  }
};
