import { theme } from '../../styles/theme';

export const myTopScoreTableContainer = {
  width: '100%',
  maxWidth: '1318px',
  background: theme.palette.common.white,
  padding: '35px',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    padding: '30px 20px',
    background: theme.palette.common.lightGrey6,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px'
  },
  '@media only screen and (min-width: 746px) and (max-width: 1023px)': {
    padding: '30px 20px'
  }
};

export const myTopScoreTableMobileTableVersion = {
  '@media screen and (min-width: 680px)': {
    display: 'none'
  }
};

export const myTopScoreTableCell = {
  padding: '16px 8px',
  fontSize: '12px',
  '@media screen and (min-width: 1512px)': {
    fontSize: '14px',
    padding: '16px'
  }
};

export const myTopScoreTableTable = {
  '@media screen and (max-width: 680px)': {
    display: 'none'
  }
};

export const myTopScoreTableTableRow = {
  background: theme.palette.common.lightBlue0,
  borderRadius: '4px',
  borderBottom: `0 solid ${theme.palette.common.white}`,
  borderTop: `10px solid ${theme.palette.common.white}`,
  cursor: 'pointer'
};

export const myTopScoreTableSkillHighlight = {
  '> div': {
    width: '29px',
    height: '29px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.violet,
    borderRadius: '100px',
    color: theme.palette.common.lightGrey6,
    fontWeight: 700
  }
};
