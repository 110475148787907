import { Box, Stack } from '@mui/material';
import { Cart, CouponCode, Product } from '@pearson-ell/commerce-sdk';
import {
  CartOrderSummary,
  CartProductHeader,
  CartQty,
  EmptyCart,
  PolicieLink
} from '@pearson-ell/commerce-ui';
import React, { FC, useMemo } from 'react';
import {
  CartMainContainer,
  CartMainHeader,
  CartSummaryStyle,
  FlexCenter,
  ShopContainer
} from './ShoppingCart.styles';

interface ShoppingCartProps {
  cart?: Cart;
  cartProducts?: JSX.Element[];
  redirectToCheckout: () => void;
  onContinueShoppingClick: () => void;
  products?: Product[];
  policiesLinks?: PolicieLink[];
  policiesLinksCallback?: (
    e: React.MouseEvent<HTMLLinkElement> | React.KeyboardEvent<HTMLLinkElement>,
    linkName: string
  ) => void;
  applyCouponCode: (cartId: string, coupon: CouponCode) => Promise<Cart>;
  withdrawCouponCode: (cartId: string, coupon: string) => Promise<Cart>;
}

export const ShoppingCart: FC<ShoppingCartProps> = ({
  cart,
  cartProducts,
  redirectToCheckout,
  policiesLinksCallback,
  policiesLinks,
  onContinueShoppingClick,
  applyCouponCode,
  withdrawCouponCode
}) => {
  const cartQuantityCalculate = () => {
    return cart?.items.reduce((prev, current) => {
      return prev + current.quantity;
    }, 0);
  };
  const cartQuantity = useMemo(cartQuantityCalculate, [cart]);
  return (
    <>
      <Stack sx={ShopContainer}>
        <Box sx={FlexCenter}>
          {!cart?.items.length && <EmptyCart onBackButtonClick={onContinueShoppingClick} />}
          {!!cart?.items.length && (
            <Stack>
              <Box sx={CartMainHeader}>
                <CartQty itemsCount={cartQuantity || 0} />
              </Box>
              <Box sx={CartMainContainer}>
                <Box sx={{ width: '100%', maxWidth: '796px' }}>
                  <CartProductHeader>{cartProducts}</CartProductHeader>
                </Box>
                <Box>
                  <Box sx={[FlexCenter, { marginTop: '40px' }, CartSummaryStyle]}>
                    <CartOrderSummary
                      policiesLinksCallback={policiesLinksCallback}
                      policiesLinks={policiesLinks || []}
                      cart={cart as Cart}
                      onCheckoutClick={() => redirectToCheckout()}
                      onContinueShoppingClick={onContinueShoppingClick}
                      applyCouponCode={applyCouponCode}
                      withdrawCouponCode={withdrawCouponCode}
                      isDisplayedCouponInput
                    />
                  </Box>
                </Box>
              </Box>
            </Stack>
          )}
        </Box>
      </Stack>
    </>
  );
};
