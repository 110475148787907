import { theme } from '../../styles/theme';

export const HeaderMainContainer = {
  paddingTop: '50px',
  height: '350px',
  backgroundColor: 'white',
  width: '100%',
  '@media screen and (max-width: 1200px)': {
    marginTop: '90px'
  }
};

export const AccountDataContainer = {
  width: '70%',
  borderBottom: '4px solid #B0BBD5',
  paddingBottom: '30px'
};

export const FlexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const AccountName = {
  fontWeight: 600,
  fontSize: '36px',
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
};

export const AccountTabs = {
  marginBottom: '35px',
  '.MuiButtonBase-root': {
    '@media screen and (max-width: 495px)': {
      paddingLeft: 0,
      paddingRight: 0,
      margin: '0 8px'
    }
  },
  '.MuiTabs-indicator': {
    backgroundColor: theme.palette.common.violet,
    height: '3px',
    borderRadius: '4px'
  },
  '.Mui-selected': {
    color: `${theme.palette.common.darkBlue7} !important`
  },
  '.MuiTabs-flexContainer': {
    justifyContent: 'center'
  }
};

export const AccountTabsScaled = {
  '@media screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx)': {
    scale: '1.25',
    width: 'calc(100% * (1/1.25))'
  },
  '@media screen and (min-resolution: 1.5dppx)': {
    scale: '1.5',
    width: 'calc(100% * (1/1.5))'
  },
  marginBottom: '35px',
  '.MuiButtonBase-root': {
    '@media screen and (max-width: 495px)': {
      paddingLeft: 0,
      paddingRight: 0,
      margin: '0 8px'
    },
    '@media screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx)': {
      scale: '0.8',
      width: 'calc(100% * (1/0.8))',
      transformOrigin: 'left'
    },
    '@media screen and (min-resolution: 1.5dppx)': {
      scale: '0.67',
      width: 'calc(100% * (1/0.67))',
      transformOrigin: 'center'
    }
  },

  '.MuiTabs-indicator': {
    backgroundColor: theme.palette.common.violet,
    height: '3px',
    borderRadius: '4px'
  },
  '.Mui-selected': {
    color: `${theme.palette.common.darkBlue7} !important`
  },
  '.MuiTabs-flexContainer': {
    justifyContent: 'center'
  }
};

export const AccountSingleTab = {
  flex: '1',
  borderBottom: `1px solid ${theme.palette.common.grey}`,
  paddingTop: '20px',
  paddingBottom: '20px',
  fontSize: '18px',
  fontWeight: 700,
  color: `${theme.palette.common.darkGrey8} !important`,
  maxWidth: '200px',
  textTransform: 'capitalize',
  '@media screen and (max-width: 495px)': {
    fontSize: '14px'
  }
};
