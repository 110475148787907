import { theme } from '@theme';

export const exploreTestsWrapperJustifyCenter = {
  display: 'flex',
  justifyContent: 'center'
};

export const exploreTestsExploreResources = {
  ...exploreTestsWrapperJustifyCenter,
  padding: '5rem 0',
  maxWidth: '1212px',
  marginLeft: 'auto',
  marginRight: 'auto'
};

export const exploreTestsWrapperHorizontalLineText = {
  padding: '1rem',
  maxWidth: '450px',
  textAlign: 'center',
  fontSize: '25px',
  fontWeight: 400,
  lineHeight: 1.2,
  letterSpacing: 0,
  flexShrink: 0
};

export const exploreTestsWrapperCallToActionTop = {
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '90px',
  paddingBottom: '70px'
};

export const exploreTestsWrapperTypeOfTests = {
  backgroundColor: theme.palette.common.lightBlue00,
  padding: '4rem',
  display: 'flex',
  flexDirection: 'column',
  '@media screen and (min-resolution: 1.5dppx) and (min-width: 1200px)': {
    maxWidth: '1212px !important'
  },
  '@media screen and (min-width: 1300px)': {
    maxWidth: '1212px !important'
  },
  '@media screen and (max-width: 1300px)': {
    padding: '2rem'
  },
  '@media screen and (max-width: 1100px)': {
    padding: '0.5rem'
  },
  '@media screen and (max-width: 1024px)': {
    padding: '0.5rem'
  }
};

export const exploreTestsWrapperHorizontalLineContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2rem 0'
};

export const exploreTestsWrapperHorizontalLine = {
  height: '1px',
  backgroundColor: theme.palette.common.lightGrey1,
  width: '100%'
};

export const exploreTestsWrapperCheckYourTestAndRequirements = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.lightGrey4
};

export const exploreTestsWrapperSidePadding = {
  paddingLeft: '2rem',
  paddingRight: '2rem',
  '@media screen and (max-width: 1024px)': {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem'
  }
};

export const exploreTestsWrapperChooseTestContainer = {
  textAlign: 'center',
  letterSpacing: 0
};

export const exploreTestsWrapperChooseTestTitle = {
  fontSize: '45px',
  fontWeight: 700,
  lineHeight: 1.5,
  letterSpacing: 0,
  padding: '1rem 0',
  '@media screen and (max-width: 1366px)': {
    fontSize: '40px'
  },
  '@media screen and (max-width: 428px)': {
    fontSize: '35px'
  }
};

export const exploreTestsWrapperChooseTestDesc = {
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: 1.5,
  textAlign: 'center'
};

export const exploreTestsWrapperTypeOfTestsHeader = {
  fontSize: '24px',
  textAlign: 'center'
};

export const sectionWrapper = {
  '@media screen and (min-width: 1920px)': {
    maxWidth: '1212px !important'
  },
  '@media screen and (min-resolution: 1.5dppx) and (min-width: 1200px)': {
    maxWidth: '1212px !important'
  },
  '@media screen and (max-width: 900px)': {
    padding: '0 30px'
  },
  '@media screen and (max-width: 1200px)': {
    flexBasis: '92% !important',
    maxWidth: '92% !important',
    paddingBottom: '80px'
  },
  '@media screen and (max-width: 740px)': {
    flexBasis: '100% !important',
    maxWidth: '100% !important'
  }
};

export const SmallTestsAlignEnd = {
  display: 'flex',
  justifyContent: 'flex-end',
  '@media screen and (max-width: 750px)': {
    justifyContent: 'center'
  }
};

export const SmallTestsAlignStart = {
  display: 'flex',
  justifyContent: 'flex-start',
  '@media screen and (max-width: 750px)': {
    justifyContent: 'center'
  }
};
