export const ScalableDefault = {
  '@media screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx)': {
    scale: '0.8',
    transformOrigin: '0 0',
    width: 'calc(100% * (1/0.8))',
    height: '100vh'
  },
  '@media screen and (min-resolution: 1.5dppx)': {
    scale: '0.67',
    transformOrigin: '0 0',
    width: 'calc(100% * (1/0.67))',
    height: '100vh'
  }
};
