import { theme } from '../../styles/theme';

export const DashboardTestDetailsContainer = {
  padding: '70px',
  display: 'flex',
  gap: '20px',
  background: theme.palette.common.lightGrey4,
  justifyContent: 'center',
  '@media screen and (max-width: 1245px)': {
    padding: '30px'
  },
  '@media screen and (max-width: 745px)': {
    flexFlow: 'column'
  },
  '@media screen and (max-width: 430px)': {
    paddingLeft: '10px',
    paddingRight: '10px'
  }
};

export const DashboardTestDetailsShareLink = {
  color: theme.palette.common.black,
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  marginRight: '20px',
  cursor: 'pointer',
  '> svg': {
    height: '15px'
  }
};

export const DashboardTestDetailsSkillScoresTableMobileContainer = {
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    marginTop: '0 !important'
  }
};

export const ReasonSectionTitle = {
  fontWeight: 700,
  fontSize: '25px',
  lineHeight: 1.2,
  color: theme.palette.common.darkBlue1,
  paddingBottom: '24px'
};
export const ExpirationStyles = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: 1.5,
  color: theme.palette.common.darkBlue2
};
export const ExpirationStylesExpired = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: 1.5,
  color: theme.palette.common.darkRed
};

export const RetakeCompletedContainerStyles = {
  display: 'flex',
  fontSize: '14px',
  color: theme.palette.common.darkGreen
};

export const RetakeCompletedStyles = {
  fontWeight: 600,
  lineHeight: 1.5,
  marginLeft: '4px'
};

export const HoverColorChange = {
  ':hover': {
    '> svg path': {
      stroke: theme.palette.common.black
    }
  }
};
export const ReasonBox = {
  background: theme.palette.common.lightGrey6,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  height: '348px',
  overflow: 'auto',
  padding: '33px',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    width: '100%'
  }
};

export const RetakeTestButtonContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  flexFlow: 'wrap'
};

export const RetakeTestBtn = {
  margin: 0,
  fontSize: '14px',
  width: '160px'
};
export const DownloadButton = {
  marginBottom: 0,
  height: '36px',
  fontSize: '14px',
  padding: '13px 20px'
};

export const ClockIconStyle = {
  paddingRight: '4px'
};

export const ReasonBackground = {
  paddingTop: '10px',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    marginTop: 0,
    padding: '30px 20px',
    marginLeft: 0,
    width: '100%'
  },

  '@media only screen and (min-width: 320px) and (max-width: 1023px)': {
    marginTop: 0
  }
};

export const DashboardTestDetailsReasonContainer = {
  '@media only screen and (min-width: 320px) and (max-width: 1023px)': {
    marginTop: 0
  },
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    paddingLeft: '0 !important',
    paddingTop: '0 !important'
  }
};

export const DownloadButtonAlignMobile = {
  marginBottom: 0,
  display: 'none',
  position: 'fixed',
  bottom: '75px',
  right: '17px',
  borderRadius: '100%',
  width: '48px !important',
  height: '48px',
  zIndex: '100',
  padding: '5px 10px',
  '@media screen and (max-width: 520px)': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ':hover > svg > path': {
    fill: theme.palette.common.darkGrey3
  }
};
