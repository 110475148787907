import { Box, Tooltip } from '@mui/material';
import { FC, PropsWithChildren, useRef, useState } from 'react';
import { purpleTooltip } from '../../styles/tooltip';

export interface TooltipWrapperProps {
  sx?: { [p: string]: string };
}

export const TooltipWrapper: FC<TooltipWrapperProps & PropsWithChildren> = ({ children, sx }) => {
  const [open, setOpen] = useState(false);
  const elementRef = useRef<HTMLElement>(null);

  const tooltipOverflowOnly = (el: HTMLElement | null, func: () => void) => {
    if (el && el.offsetWidth < el.scrollWidth) {
      func();
    }
  };

  return (
    <Tooltip
      placement='top'
      open={open}
      componentsProps={{ tooltip: { sx: purpleTooltip } }}
      arrow
      title={elementRef?.current?.firstElementChild?.innerHTML || ''}
    >
      <Box
        sx={sx}
        ref={elementRef}
        onMouseLeave={() =>
          tooltipOverflowOnly(elementRef?.current?.firstElementChild as HTMLElement, () =>
            setOpen(false)
          )
        }
        onMouseOver={() =>
          tooltipOverflowOnly(elementRef?.current?.firstElementChild as HTMLElement, () =>
            setOpen(true)
          )
        }
        onClick={() =>
          tooltipOverflowOnly(elementRef?.current?.firstElementChild as HTMLElement, () =>
            setOpen(!open)
          )
        }
      >
        {children}
      </Box>
    </Tooltip>
  );
};
