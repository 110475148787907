export const FlexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexFlow: 'column'
};
export const ImageStyle = {
  display: 'block',
  margin: '0 auto',
  width: '85%',
  maxWidth: '585px'
};
export const ParagraphStyle = {
  marginBottom: '40px',
  fontSize: '17px'
};
