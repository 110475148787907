import { FC, useEffect, useState } from 'react';
import {
  SkillScoreGraphAchievedScorePointer,
  SkillScoreGraphAchievedScorePointerContent,
  SkillScoreGraphAchievedScorePointerContentPointer,
  SkillScoreGraphAchievedScorePointerContentText,
  SkillScoreGraphFirstSpan,
  SkillScoreGraphLabel,
  SkillScoreGraphRuler,
  SkillScoreGraphRulerPointer,
  SkillScoreGraphScaleContainer,
  SkillScoreGraphScalePointer,
  SkillScoreGraphScalePoints,
  SkillScoreGraphSecondSpan
} from './SkillScoreGraps.styles';
import { useTranslation } from 'react-i18next';

export interface ISkillScoreGraphProps {
  min: number;
  max: number;
  score: number;
}

export const SkillScoreGraph: FC<ISkillScoreGraphProps> = ({ score, min, max }) => {
  const valueInterval = 10;
  const [scalePoints, setScalePoints] = useState<number[]>([]);
  const [rulerPoints, setRulerPoints] = useState<number[]>([]);
  const scalePointers: number[] = [];
  const rulerPointers: number[] = [];
  const percentageOffset = 1.5;

  const { t } = useTranslation();

  useEffect(() => {
    createPointersArray();
    dropClosestPointerToTheScore(score);
  }, [score]);

  const createPointersArray = () => {
    for (let i = min; i <= max; i++) {
      rulerPointers.push(i);
      if (i % valueInterval === 0) {
        scalePointers.push(i);
      }
    }
    setRulerPoints([...rulerPointers]);
  };

  const dropClosestPointerToTheScore = (value: number): void => {
    const clonedArray = [...scalePointers];
    const closestPointer = clonedArray.sort((a, b) => Math.abs(value - a) - Math.abs(value - b))[0];
    const closestPointerIndex = scalePointers.indexOf(closestPointer);
    scalePointers.splice(closestPointerIndex, 1);
    setScalePoints([...scalePointers]);
  };

  const generateScalePointers = () => {
    return scalePoints.map((el, index) => (
      <span
        key={`scale${el}${index}`}
        style={{
          ...SkillScoreGraphScalePointer,
          left: ((el - min) / (max - min)) * 100 - percentageOffset + '%'
        }}
      >
        {el}
      </span>
    ));
  };

  const generateRulerPointers = () => {
    return rulerPoints.map((el, index) => (
      <div
        key={`ruler${el}${index}`}
        style={{
          ...SkillScoreGraphRulerPointer,
          left: ((el - min) / (max - min)) * 100 + '%',
          backgroundColor: el % valueInterval === 0 ? '#E5BCDD' : '#F3DFEF',
          height: (el % valueInterval === 0 ? 80 : el % 5 === 0 ? 65 : 45) + '%'
        }}
      />
    ));
  };

  return (
    <>
      <div style={{ height: '150px', maxWidth: '500px', marginTop: '20px' }}>
        <div style={SkillScoreGraphScaleContainer}>
          <div style={SkillScoreGraphLabel}>{t('results.gse')}</div>
          <div style={SkillScoreGraphScalePoints}>{generateScalePointers()}</div>
          <div style={SkillScoreGraphRuler}>{generateRulerPointers()}</div>
          <div
            style={{
              ...SkillScoreGraphAchievedScorePointer,
              left: score >= min ? ((score - min) / (max - min)) * 100 + '%' : 0 + '%'
            }}
          >
            <div style={SkillScoreGraphAchievedScorePointerContent}>
              <div style={SkillScoreGraphAchievedScorePointerContentText}>
                <span style={SkillScoreGraphFirstSpan}>{t('results.yourScore')}</span>
                <span style={SkillScoreGraphSecondSpan}>{score}</span>
              </div>
              <div style={SkillScoreGraphAchievedScorePointerContentPointer} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
