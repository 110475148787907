import { theme } from '../../styles/theme';

export const ShopContainer = {
  width: '100%',
  margin: '0 auto',
  paddingLeft: '60px',
  paddingRight: '60px',
  marginTop: '80px',
  '> h1': {
    color: theme.palette.common.darkGrey3,
    fontWeight: 700,
    fontSize: '30px',
    paddingBottom: '30px'
  },
  '@media screen and (max-width: 1200px)': {
    marginTop: '130px'
  },
  '@media screen and (max-width: 440px)': {
    paddingLeft: '15px',
    paddingRight: '15px'
  }
};

export const FlexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const CartMainContainer = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  gap: { xl: '54px', lg: '15px' },
  '@media screen and (max-width: 1200px)': {
    gap: '15px'
  },
  '@media screen and (max-width: 1024px)': {
    flexFlow: 'column',
    gap: 0,
    '> *': {
      width: '100% !important'
    }
  },
  '.productPriceContainer button': { whiteSpace: 'nowrap' }
};

export const CartSummaryStyle = {
  marginBottom: '30px',
  width: '429px',
  '@media screen and (max-width: 1024px)': {
    display: 'block',
    width: '100%',
    marginTop: 0,
    '> *': {
      maxWidth: 'unset !important'
    }
  },
  '.order-summary-block': {
    padding: '30px !important'
  }
};

export const CartMainHeader = {
  paddingBottom: '55px'
};
