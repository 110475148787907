import { theme } from '../../styles/theme';

export const container = (language: string) => ({
  fontFamily:
    language === 'jpn'
      ? '"Noto Sans JP", sans-serif !important'
      : '"Noto Sans", sans-serif !important',
  '> *': {
    fontFamily:
      language === 'jpn'
        ? '"Noto Sans JP", sans-serif !important'
        : '"Noto Sans", sans-serif !important'
  },
  a: {
    color: theme.palette.common.darkBlue7
  }
});

export const lowerAlphaBrace = {
  li: {
    position: 'relative',
    counterIncrement: 'count 1'
  },
  '> li::marker': {
    content: "counter(count, lower-alpha) ') '",
    counterIncrement: 'list',
    position: 'absolute',
    left: '-17px'
  }
};
