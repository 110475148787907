export const success = {
  v: '5.5.9',
  fr: 30,
  ip: 0,
  op: 40,
  w: 80,
  h: 80,
  nm: 'Success Checkmark',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Check Mark',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [40, 40, 0], ix: 2 },
        a: { a: 0, k: [-1.312, 6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-15.75, 8],
                    [-8, 16],
                    [13.125, -4]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 25, s: [0] },
                  {
                    t: 33,
                    s: [100]
                  }
                ],
                ix: 1
              },
              e: { a: 0, k: 0, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Circle Flash',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 25,
              s: [0]
            },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 30, s: [98] },
            {
              t: 38,
              s: [0]
            }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [40, 40, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 25,
              s: [0, 0, 100]
            },
            { t: 30, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [64, 64], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false
        },
        {
          ty: 'gf',
          o: { a: 0, k: 100, ix: 10 },
          r: 1,
          bm: 0,
          g: {
            p: 3,
            k: {
              a: 0,
              k: [0, 0.342, 0.671, 0.974, 0.5, 0.198, 0.509, 0.902, 1, 0.054, 0.347, 0.83],
              ix: 9
            }
          },
          s: { a: 0, k: [0, 0], ix: 5 },
          e: { a: 0, k: [100, 0], ix: 6 },
          t: 1,
          nm: 'Gradient Fill 1',
          mn: 'ADBE Vector Graphic - G-Fill',
          hd: false
        }
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Circle Stroke',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 36,
              s: [100]
            },
            { t: 39, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [39.022, 39.022, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 16,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 22,
              s: [80, 80, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 25,
              s: [120, 120, 100]
            },
            { t: 29, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [60, 60], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                  {
                    t: 16,
                    s: [100]
                  }
                ],
                ix: 1
              },
              e: { a: 0, k: 0, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.309803921569, 0.58431372549, 0.929411764706, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0.978, 0.978], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Circle Green Fill',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 21,
              s: [0]
            },
            { t: 28, s: [98] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [40, 40, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 21,
              s: [0, 0, 100]
            },
            { t: 28, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [64, 64], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false
        },
        {
          ty: 'gf',
          o: { a: 0, k: 100, ix: 10 },
          r: 1,
          bm: 0,
          g: {
            p: 3,
            k: {
              a: 0,
              k: [0, 0.4, 0.69, 0.957, 0.5, 0.268, 0.563, 0.942, 1, 0.136, 0.435, 0.928],
              ix: 9
            }
          },
          s: { a: 0, k: [0, 0], ix: 5 },
          e: { a: 0, k: [100, 0], ix: 6 },
          t: 1,
          nm: 'Gradient Fill 1',
          mn: 'ADBE Vector Graphic - G-Fill',
          hd: false
        }
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
