import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import {
  whatCanDoNextTileContainer,
  whatCanDoNextTileHeader,
  whatCanWeDoNextTileLink
} from './WhatCanDoNextTile.style';
import { useTranslation, Trans } from 'react-i18next';
import { WhatCanDoNextTileStep } from '../WhatCanDoNextTileStep/WhatCanDoNextTileStep';
import TakePracticeIcon from '../../assets/images/take-practice-icon.svg';
import BuyTestIcon from '../../assets/images/buy-test-icon.svg';
import StartTestIcon from '../../assets/images/start-test-icon.svg';
import ViewResultsIcon from '../../assets/images/view-results-icon.svg';
import ShareResultsIcon from '../../assets/images/share-results-icon.svg';
import { theme } from '../../styles/theme';

export type TileMode = 'practice/buy' | 'start/practice' | 'results/buy' | 'share/buy';

interface WhatCanDoNextTileProps {
  tileMode: TileMode;
  buyTestAction: () => void;
  startTestAction: (id: string) => void;
  viewResultsShareScoreAction: () => void;
  handleRedeemCode: () => void;
}

export const WhatCanDoNextTile: FC<WhatCanDoNextTileProps> = ({
  tileMode,
  startTestAction,
  buyTestAction,
  viewResultsShareScoreAction,
  handleRedeemCode
}) => {
  const { t } = useTranslation();

  const modeData = {
    'practice/buy': {
      icons: [TakePracticeIcon, BuyTestIcon],
      titles: [
        t('dashboardPage.whatsNextTile.takePracticeTest'),
        t('dashboardPage.whatsNextTile.buyATest')
      ],
      actions: [() => startTestAction('#startPractice'), buyTestAction]
    },
    'start/practice': {
      icons: [StartTestIcon, TakePracticeIcon],
      titles: [
        t('dashboardPage.whatsNextTile.startTest'),
        t('dashboardPage.whatsNextTile.takePracticeTest')
      ],
      actions: [() => startTestAction('#takeTest'), () => startTestAction('#startPractice')]
    },
    'results/buy': {
      icons: [ViewResultsIcon, BuyTestIcon],
      titles: [
        t('dashboardPage.whatsNextTile.viewResults'),
        t('dashboardPage.whatsNextTile.buyATest')
      ],
      actions: [viewResultsShareScoreAction, buyTestAction]
    },
    'share/buy': {
      icons: [ShareResultsIcon, BuyTestIcon],
      titles: [
        t('dashboardPage.whatsNextTile.shareResults'),
        t('dashboardPage.whatsNextTile.buyATest')
      ],
      actions: [viewResultsShareScoreAction, buyTestAction]
    }
  };

  return (
    <>
      <Box sx={whatCanDoNextTileContainer}>
        <Typography sx={whatCanDoNextTileHeader}>
          {t('dashboardPage.whatsNextTile.whatYouCanDoNext')}
        </Typography>
        <WhatCanDoNextTileStep
          icon={modeData[tileMode].icons[0]}
          title={modeData[tileMode].titles[0]}
          action={modeData[tileMode].actions[0]}
        />
        <WhatCanDoNextTileStep
          icon={modeData[tileMode].icons[1]}
          title={modeData[tileMode].titles[1]}
          action={modeData[tileMode].actions[1]}
        />
        <Typography sx={{ color: theme.palette.common.white, mt: '20px' }}>
          <Trans
            i18nKey='dashboardPage.whatsNextTile.redeemCode'
            components={{
              action: <Typography onClick={handleRedeemCode} sx={whatCanWeDoNextTileLink} />
            }}
          />
        </Typography>
      </Box>
    </>
  );
};
