import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import {
  ProductFeaturesSingle,
  ProductFeaturesSingleDesc,
  ProductFeaturesSingleTitle,
  ProductFeaturesTitle
} from './ProductFeatures.styles';
import Img from '../Primitive/Img';

type FeatureType = {
  title: string;
  desc: string;
  image: string;
};
type TrustedUsTypes = {
  title: string;
  features: FeatureType[];
};

export const ProductFeatures: FC<TrustedUsTypes> = ({ title, features }) => (
  <Grid container>
    <Grid item xs={12} justifyContent='center'>
      <Typography component='h1' sx={ProductFeaturesTitle}>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Grid container justifyContent='space-between'>
        {features.map(({ title, desc, image }, index: number) => (
          <Grid
            key={`${title.replace(' ', '')}-${index}`}
            item
            xs={12}
            sm={4}
            sx={{
              marginBottom: '2rem'
            }}
          >
            <Box sx={ProductFeaturesSingle}>
              <Img src={image} sx={{ maxWidth: '100%' }} />
              <Box>
                <Typography component='h2' sx={ProductFeaturesSingleTitle}>
                  {title}
                </Typography>
                <Typography component='h4' sx={ProductFeaturesSingleDesc}>
                  {desc}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
);
