import { theme } from '@theme';

export const ListItemStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  color: theme.palette.common.darkGrey7,
  alignItems: 'flex-start',
  padding: '20px 20px 15px'
};

export const Label = (small?: boolean) => ({
  lineHeight: 1.1,
  color: theme.palette.common.darkGrey2,
  fontWeight: small ? 400 : 600,
  fontSize: small ? '16px' : '18px'
});

export const LinkWrapper = (active?: boolean, small?: boolean) => ({
  textDecoration: 'none',
  display: 'flex',
  backgroundColor: active ? '#ECEFF5' : 'unset',
  borderBottom: small ? 'none' : `1px solid ${theme.palette.common.lightGrey1}`
});
