import { theme } from '../../styles/theme';

export const SearchBarContainer = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '350px',
  '@media screen and (max-width: 860px)': {
    width: '200px'
  },
  '@media screen and (max-width: 645px)': {
    width: '140px'
  },
  '@media screen and (max-width: 570px)': {
    width: '40px'
  },
  '@media screen and (max-width: 475px)': {
    display: 'none'
  }
};

export const SearchBarInput = {
  height: '35px',
  borderRadius: '20px',
  padding: '10px 18px 10px 15px',
  width: '350px',
  '@media screen and (max-width: 860px)': {
    width: '200px'
  },
  '@media screen and (max-width: 645px)': {
    width: '140px'
  },
  '@media screen and (max-width: 570px)': {
    width: '40px',
    display: 'none'
  },
  border: `1px solid ${theme.palette.common.darkGrey8}`,
  '&::placeholder': {
    fontStyle: 'italic'
  }
};

export const SearchBarSearchIcon = {
  fill: theme.palette.common.white,
  height: '20px'
};
export const PurpleCircle = {
  position: 'absolute',
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '35px',
  height: '35px',
  borderRadius: '100px',
  background: theme.palette.common.violet
};
