import { FC, useEffect, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, Link, Stack, Tooltip, Typography } from '@mui/material';
import {
  ConsentCheckboxStyle,
  ConsentData,
  ConsentLinkStyle,
  ConsentOffer,
  mandatoryLabelStyle
} from './ConsentsBox.style';
import { Trans, useTranslation } from 'react-i18next';
import { theme } from '../../styles/theme';
import Img from '../Primitive/Img';
import InfoIcon from '../../assets/images/info-icon-gray.svg';
import { whiteTooltip } from '../../styles/tooltip';
import { generateConsentRedirectionLink, koConsentsList } from './utils';
import { Language } from '../../interface/language.interface';

interface ConsentBoxProps {
  readonly type: 'sk' | 'default';
  readonly onConsentsStatusChange: (
    accepted: boolean,
    consentsDto: { [p: string]: boolean }
  ) => void;
  readonly handleNavigate?: (url: string) => void;
  readonly onCheckboxChanged: (consentsDto: { [p: string]: boolean }) => void;
  readonly language?: Language;
}
export const ConsentsBox: FC<ConsentBoxProps> = ({
  type,
  onConsentsStatusChange,
  handleNavigate,
  onCheckboxChanged,
  language
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<{ [p: string]: boolean }>(
    type === 'sk'
      ? koConsentsList.reduce((prev, curr) => {
          prev = { ...prev, [curr.formControlName]: false };
          return prev;
        }, {})
      : { consent: false, emailReceiveConsent: false }
  );

  const checkAllConsents = (value: boolean) => {
    const modifiedState = { ...state };
    Object.keys(modifiedState).forEach((key) => {
      modifiedState[key] = value;
    });
    setState(modifiedState);
    onConsentsStatusChange(allSelected, state);
  };

  useEffect(() => {
    onCheckboxChanged(state);
  }, [state]);

  const allSelected = Object.values(state).every((item) => item);
  const allRequiredSelected = useMemo(() => {
    const mandatoryKeys = koConsentsList
      .filter((item) => item.mandatory)
      .map((item) => item.formControlName);
    return type === 'sk' ? mandatoryKeys.every((item) => state[item]) : state['consent'];
  }, [state]);

  useEffect(() => {
    onConsentsStatusChange(allRequiredSelected, state);
  }, [allRequiredSelected]);

  return (
    <Stack>
      {type === 'sk' && (
        <Stack>
          <FormControlLabel
            sx={[ConsentData, { alignItems: 'flex-start' }]}
            label={t('signUp.agreeToAll')}
            control={
              <Checkbox
                defaultChecked={false}
                checked={allSelected}
                sx={ConsentCheckboxStyle}
                size='small'
                color='default'
                id='agreeToAllCheckbox'
                onChange={(e) => checkAllConsents(e.target.checked)}
              />
            }
          />
          <Stack>
            <Stack style={{ paddingLeft: '16px', marginTop: '20px', gap: '10px' }}>
              {koConsentsList.map((item, index) => (
                <FormControlLabel
                  value={true}
                  key={`consentCheckbox-${index}`}
                  sx={[ConsentData, { alignItems: 'flex-start', pointerEvents: 'none' }]}
                  label={
                    <>
                      <Trans
                        i18nKey={item.label}
                        components={{
                          consentLink: (
                            <Link
                              target='_blank'
                              href={generateConsentRedirectionLink(item.formControlName, language)}
                              sx={ConsentLinkStyle}
                            />
                          )
                        }}
                      />
                      {item.mandatory && (
                        <Typography component='span' style={{ color: theme.palette.common.violet }}>
                          *
                        </Typography>
                      )}
                      {item.tooltipMessage && (
                        <Tooltip
                          componentsProps={{ tooltip: { sx: whiteTooltip } }}
                          style={{ pointerEvents: 'auto' }}
                          arrow
                          placement='bottom-end'
                          title={t(`signUp.${item.tooltipMessage}`)}
                        >
                          <Img
                            style={{ pointerEvents: 'auto' }}
                            sx={{ width: '12px' }}
                            src={InfoIcon}
                            alt='jit notice'
                          />
                        </Tooltip>
                      )}
                    </>
                  }
                  control={
                    <Checkbox
                      style={{ pointerEvents: 'auto' }}
                      defaultChecked={false}
                      checked={state[item.formControlName]}
                      onChange={(e) => {
                        setState({ ...state, [item.formControlName]: e.target.checked as boolean });
                      }}
                      sx={ConsentCheckboxStyle}
                      size='small'
                      color='default'
                      id={item.formControlName}
                    />
                  }
                />
              ))}
            </Stack>
            <Typography sx={mandatoryLabelStyle}>*{t('signUp.consentIsMandatory')}</Typography>
          </Stack>
        </Stack>
      )}
      {type === 'default' && (
        <Stack>
          <Typography sx={[ConsentData, { marginBottom: '10px' }]}>
            <Trans
              i18nKey='signUp.agreementsDescription'
              components={{
                termsOfUseComponent: (
                  <Link onClick={() => handleNavigate?.('terms-of-use')} sx={ConsentLinkStyle} />
                ),
                privacyPolicyComponent: (
                  <Link onClick={() => handleNavigate?.('privacy-policy')} sx={ConsentLinkStyle} />
                )
              }}
            />
          </Typography>
          <FormControlLabel
            sx={[ConsentData, { alignItems: 'flex-start' }]}
            label={t('signUp.consentLabel')}
            checked={state['consent']}
            control={
              <Checkbox
                defaultChecked={false}
                onChange={(e) => setState({ ...state, consent: e.target.checked })}
                sx={ConsentCheckboxStyle}
                size='small'
                color='default'
                id='ConsentCheckbox'
              />
            }
          />

          <Typography sx={[ConsentData, ConsentOffer]}>{t('signUp.offersDescription')}</Typography>
          <FormControlLabel
            sx={[ConsentData, { alignItems: 'flex-start' }]}
            label={t('signUp.offersConsentLabel')}
            checked={state['emailReceiveConsent']}
            control={
              <Checkbox
                defaultChecked={false}
                onChange={(e) => setState({ ...state, emailReceiveConsent: e.target.checked })}
                sx={ConsentCheckboxStyle}
                size='small'
                color='default'
                id='emailReceiveCheckbox'
              />
            }
          />
          <Typography sx={{ ...ConsentData, marginTop: '15px' }}>
            {t('signUp.consentSummaryDescription')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
