import { FC } from 'react';
import {
  scoreReportViewDownloadButtonAlign,
  scoreReportViewDownloadButtonAlignMobile,
  scoreReportViewLinksHide,
  scoreReportViewViewTextStyle,
  scoreReportViewTitle,
  scoreReportViewTitleContainer,
  scoreReportViewTitleLink,
  scoreReportViewTitleActions
} from './ScoreReportView.style';
import { Box, Button, Link, Typography } from '@mui/material';
import { scrollToElement } from '../../../../shared/src/utils/utils';
import { DownloadAnchorSvg, SignInStyles } from '@barracuda/shared/src';
import { useTranslation } from 'react-i18next';

export const ScoreReportViewHeader: FC<{ downloadCertificate: () => void }> = ({
  downloadCertificate
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={scoreReportViewTitleContainer}>
      <Typography component='h1' sx={scoreReportViewTitle}>
        {t('score.scoreReportHeader')}
      </Typography>
      <Box sx={scoreReportViewTitleActions}>
        <Link
          sx={[scoreReportViewLinksHide, scoreReportViewTitleLink]}
          onClick={() => scrollToElement('SampleResponseSection')}
        >
          <Typography sx={scoreReportViewViewTextStyle} component='span'>
            {t('view')}{' '}
          </Typography>{' '}
          {t('results.sampleResponses')}
        </Link>
        <Button
          onClick={() => downloadCertificate()}
          sx={[SignInStyles.SignInButton, scoreReportViewDownloadButtonAlign]}
        >
          {t('results.downloadCertificate')}
          <DownloadAnchorSvg />
        </Button>
        <Box
          onClick={() => downloadCertificate()}
          sx={[SignInStyles.SignInButton, scoreReportViewDownloadButtonAlignMobile]}
        >
          <DownloadAnchorSvg />
        </Box>
      </Box>
    </Box>
  );
};
