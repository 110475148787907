import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GSEScoreStyle,
  OverallProficiency,
  OverallProficiencyDesc,
  OverallProficiencyTitle,
  ShieldContainer,
  ShieldScore,
  TakenOnStyle,
  TileBody,
  TileContainer,
  TileFooter,
  TileHead
} from './MyTopScoreDetailsTile.style';
import shield from '../../assets/images/score-shield--icon.svg';
import { MyTopScore, SignInStyles, V2Certificate } from '../../index';
import {
  DashboardTestDetailsShareLink,
  DownloadButton
} from '../DashboardTestDetails/DashboardTestDetails.styles';
import moment from 'moment';
import ShareIcon from '@mui/icons-material/Share';
import { NavigateFunction } from 'react-router-dom';
import { LinkVisibility } from '../LatestTestDetailsTile/LatestTestDetailsTile.style';
import { SkillTypes } from '../../index';
import { theme } from '../../styles/theme';
import { Img } from '../Primitive';

export interface MyTopScoreDetailsTileProps {
  myTopScore: MyTopScore | undefined;
  handleDownloadCertificate: () => void;
  navigate: NavigateFunction;
  mtsDetails: V2Certificate | null;
}

export const MyTopScoreDetailsTile: FC<MyTopScoreDetailsTileProps> = ({
  myTopScore,
  handleDownloadCertificate,
  navigate,
  mtsDetails
}) => {
  const { t } = useTranslation();
  return (
    <Stack sx={TileContainer}>
      <Link
        onClick={() => navigate(`./${myTopScore?.id}/share`)}
        sx={{
          ...DashboardTestDetailsShareLink,
          ...LinkVisibility
        }}
      >
        <ShareIcon />
        {t('results.shareResults')}
      </Link>
      <Box sx={TileHead}>
        <Typography component='span'>{t('results.myTopScoreHeader')}</Typography>
      </Box>
      <Box sx={TileBody}>
        <Box sx={ShieldContainer}>
          <Box sx={{ position: 'relative' }}>
            <Img width={100} alt='Shield icon' src={shield} />
            <Typography sx={ShieldScore}>{myTopScore?.score}</Typography>
            <Typography sx={GSEScoreStyle}>{t('gseScore')}</Typography>
          </Box>
        </Box>
        <Stack sx={OverallProficiency}>
          <Typography sx={OverallProficiencyTitle}>{t('myOverallProficiency')}</Typography>
          <Typography sx={OverallProficiencyDesc}>
            {mtsDetails?.skills[SkillTypes.OVERALL_SCORE]?.description}
          </Typography>
          <Typography sx={TakenOnStyle}>
            {`${t('generatedOn')}: `}
            <Typography component='span'>{`${moment(myTopScore?.issuedAt).format(
              'DD MMMM yyyy'
            )}`}</Typography>
          </Typography>
          <Typography sx={[TakenOnStyle, { paddingBottom: '20px' }]}>
            {`${t('validThrough')}: `}
            <Typography component='span'>{`${moment(myTopScore?.expiresAt).format(
              'DD MMMM yyyy'
            )}`}</Typography>
          </Typography>
        </Stack>
      </Box>
      <Box sx={TileFooter}>
        <Link
          onClick={() => navigate(`./${myTopScore?.id}/share`)}
          sx={{
            ...DashboardTestDetailsShareLink
          }}
        >
          <ShareIcon />
          {t('results.shareResults')}
        </Link>
        <Button
          onClick={handleDownloadCertificate}
          sx={[SignInStyles.SignInButton, DownloadButton, { height: '36px' }]}
        >
          {t('results.downloadCertificate')}
          <svg
            style={{ marginLeft: '7px' }}
            width='15'
            height='15'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M16.5 11.75C16.9142 11.75 17.25 12.0858 17.25 12.5V15.5C17.25 16.7426 16.2426 17.75 15 17.75H3C1.75736 17.75 0.75 16.7426 0.75 15.5V12.5C0.75 12.0858 1.08579 11.75 1.5 11.75C1.91421 11.75 2.25 12.0858 2.25 12.5V15.5C2.25 15.9142 2.58579 16.25 3 16.25H15C15.4142 16.25 15.75 15.9142 15.75 15.5V12.5C15.75 12.0858 16.0858 11.75 16.5 11.75ZM9 0.5C9.41421 0.5 9.75 0.835786 9.75 1.25V9.93934L12.2197 7.46967C12.5126 7.17678 12.9874 7.17678 13.2803 7.46967C13.5732 7.76256 13.5732 8.23744 13.2803 8.53033L9.53033 12.2803C9.38388 12.4268 9.19194 12.5 9 12.5C8.80806 12.5 8.61612 12.4268 8.46967 12.2803L4.71967 8.53033C4.42678 8.23744 4.42678 7.76256 4.71967 7.46967C5.01256 7.17678 5.48744 7.17678 5.78033 7.46967L8.25 9.93934V1.25C8.25 0.835786 8.58579 0.5 9 0.5Z'
              fill={theme.palette.common.lightBlue00}
            />
          </svg>
        </Button>
      </Box>
    </Stack>
  );
};
