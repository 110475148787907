import { theme } from './theme';

export const buttonDark = {
  backgroundColor: theme.palette.common.darkGrey2,
  color: theme.palette.common.lightGrey6,
  width: 'fit-content',
  minWidth: 'fit-content',
  fontWeight: 600,
  borderRadius: '40px',
  '&:hover': {
    backgroundColor: theme.palette.common.darkBlue2
  },
  '&[disabled]': {
    color: theme.palette.common.darkGrey8,
    backgroundColor: theme.palette.common.lightGrey9,
    border: 'none'
  },
  '&:focus': {
    border: '3px solid  #4FA8FF'
  }
};

export const buttonOutlineDark = {
  backgroundColor: theme.palette.common.white,
  border: `2px solid ${theme.palette.common.darkGrey2}`,
  color: theme.palette.common.darkGrey2,
  '&:hover': {
    color: theme.palette.common.lightGrey6,
    backgroundColor: theme.palette.common.darkGrey2,
    border: `2px solid ${theme.palette.common.darkGrey2}`
  },
  '&[disabled]': {
    color: theme.palette.common.darkGrey8,
    border: `2px solid ${theme.palette.common.lightGrey9}`,
    backgroundColor: theme.palette.common.white
  },
  '&:focus': {
    border: '2px solid  #4FA8FF'
  }
};

export const buttonOutlineLight = {
  backgroundColor: theme.palette.common.darkGrey3,
  border: `2px solid ${theme.palette.common.white}`,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.darkGrey3,
    border: `2px solid ${theme.palette.common.white}`
  },
  '&[disabled]': {
    color: theme.palette.common.white
  }
};
