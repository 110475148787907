import { FC, FormEvent, useContext, useState } from 'react';
import { AppContext } from '../../context/App.context';
import { SoftProfileSurveyForm, SopStatusResponse, User, sopClient } from '@barracuda/shared/src';

export interface SoftProfileSurveyFormWrapperProps {
  closeDialog: (key: string) => void;
  handleTokenExpiredAction: () => void;
}

const FORM_KEY = 'SURVEY_WHY_ARE_YOU_INTERESTED_IN_BARRACUDA';

const initialState = (user: User | undefined): { stateValue: string; otherValue: string } => {
  let stateValue = '';
  let otherValue = '';
  if (!user) {
    return {
      stateValue,
      otherValue
    };
  }
  if (user) {
    const value = user.profile[FORM_KEY];
    if (!value)
      return {
        stateValue,
        otherValue
      };
    if (value === 'job' || value === 'university') {
      stateValue = value;
    } else {
      stateValue = 'other';
      otherValue = user.profile[FORM_KEY];
    }
  }

  return {
    stateValue,
    otherValue
  };
};

export const SoftProfileSurveyFormWrapper: FC<SoftProfileSurveyFormWrapperProps> = ({
  closeDialog,
  handleTokenExpiredAction
}) => {
  const { user, setUser } = useContext(AppContext);
  const { otherValue, stateValue } = initialState(user);
  const [radioValue, setRadioValue] = useState(stateValue || null);
  const [other, setOther] = useState(otherValue || '');

  const handleSubmit = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      const value = radioValue === 'other' ? other : radioValue;
      const payload = {
        SURVEY_WHICH_ONE_BEST_DESCRIBES_YOU: value as string
      };
      const response = await sopClient.addMetadataToProfile(payload);
      const { data } = response;
      setUser({ ...data });
      closeDialog('survey');
    } catch (error: any) {
      const {
        response: {
          data: { type }
        }
      } = error;
      if (type === SopStatusResponse.AUTHENTICATION_EXPIRED) {
        handleTokenExpiredAction();
      }
    }
  };
  return (
    <>
      <SoftProfileSurveyForm
        handleSubmit={handleSubmit}
        setRadioValue={setRadioValue}
        radioValue={radioValue}
        setOther={setOther}
        other={other}
      />
    </>
  );
};
