import { buttonDark } from '../../styles/button';

export const icon = {
  marginRight: '10px',
  height: {
    xs: '25px',
    md: 'initial'
  }
};

export const iconRow = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  img: {
    height: {
      xs: '25px',
      md: 'initial'
    }
  },
  '> p': {
    fontSize: {
      xs: '14px',
      md: 'initial'
    }
  }
};

export const button = {
  ...buttonDark,
  marginTop: {
    xs: '40px',
    md: '100px'
  }
};

export const imageContainer = {
  display: {
    xs: 'none',
    sm: 'flex'
  },
  alignItems: 'center',
  justifyContent: 'center'
};

export const closeIcon = {
  position: 'absolute',
  top: '40px',
  right: '40px',
  cursor: 'pointer',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    top: '15px',
    right: '15px'
  }
};
