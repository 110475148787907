import { FC, useCallback } from 'react';
import { Box } from '@mui/material';

interface VideoPopupProps {
  url: string;
  onEnded: () => void;
}

export const VideoPopup: FC<VideoPopupProps> = ({ url, onEnded }) => {
  const videoElement = useCallback(
    (node: HTMLElement) => {
      if (node) {
        node.addEventListener('ended', onEnded);
      }
    },
    [url]
  );
  return (
    <Box
      component='video'
      ref={videoElement}
      style={{ width: '100%' }}
      autoPlay
      controls
      src={url}
    ></Box>
  );
};
