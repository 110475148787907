export const PopupContent = {
  display: 'flex',
  justifyContent: 'center',
  gap: '44px',
  alignItems: 'center',
  flexFlow: { md: 'row', xs: 'column' }
};
export const DownloadButton = {
  marginBottom: 0,
  marginTop: '50px',
  width: '210px'
};
export const Description = {
  fontSize: '14px',
  lineHeight: '29px',
  fontWeight: 400,
  paddingBottom: '23px'
};
export const FlexCenter = {
  display: 'flex',
  alignItems: 'center'
};
export const ListPoint = { marginLeft: '3px' };
