export const oAuthFailedContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export const oAuthFailedTitle = {
  marginTop: '25px',
  fontWeight: 800,
  fontSize: '36px'
};
