import { theme } from '../../styles/theme';

export const ProgressBar = {
  width: '200px',
  position: 'relative'
};

export const ProgressBarPipe = {
  width: '100%',
  background: theme.palette.common.lightGrey9,
  borderRadius: '7px',
  height: '9px'
};

export const GSELabel = {
  fontWeight: 700,
  fontSize: '16px',
  color: theme.palette.common.darkGrey2,
  position: 'absolute',
  bottom: '27px',
  left: 0
};

export const PipeLabel = {
  position: 'absolute',
  fontWeight: 600,
  fontSize: '14px',
  color: theme.palette.common.darkGrey2,
  bottom: '12px'
};

export const ScoreLabel = {
  top: '15px',
  fontSize: '14px',
  fontWeight: 700
};

export const ScoreAnchor = {
  fill: theme.palette.common.white,
  strokeWidth: '1px',
  stroke: '#D1D3D6',
  filter: 'drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25))',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  width: '26px',
  height: '26px',
  background: theme.palette.common.white,
  borderRadius: '4px',
  border: '1px solid #D1D3D6'
};

export const AnchorStyle = {
  position: 'absolute',
  webkitTextStroke: '1px #D1D3D6',
  color: theme.palette.common.white,
  fontSize: '8px',
  top: '-8px',
  '::after': {
    content: '""',
    position: 'absolute',
    display: 'block',
    background: theme.palette.common.white,
    width: '9px',
    top: '8px',
    height: '2px',
    left: 0
  }
};

export const FillStyle = {
  borderRadius: '7px',
  height: '9px'
};

export const CefrAnchor = (isMts: boolean) => ({
  marginTop: '5px',
  position: 'absolute',
  minWidth: '75px',
  left: '-17px',
  backgroundColor: isMts ? theme.palette.common.lightPurple : theme.palette.common.lightPink,
  borderRadius: '40px',
  padding: '0 8px'
});

export const CefrLabel = (isMts: boolean) => ({
  fontWeight: 700,
  fontSize: '12px',
  color: isMts ? theme.palette.common.darkBlue10 : theme.palette.common.violet
});
