import { theme } from '@theme';

export const TextHeader = {
  fontWeight: 700,
  fontSize: '30px',
  lineHeight: 1.045,
  marginBottom: '63px'
};

export const TextContent = {
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: 1.6,
  '> a': {
    color: theme.palette.common.darkBlue7,
    fontWeight: 600,
    textDecoration: 'none'
  },
  whiteSpace: 'pre-line'
};

export const ButtonsContainer = {
  display: 'flex',
  flexDirection: 'row',
  gap: '30px',
  marginTop: '60px',
  justifyContent: 'center',
  '@media only screen and (max-width: 740px)': {
    flexDirection: 'column'
  }
};

export const ButtonFilled = {
  backgroundColor: theme.palette.common.darkGrey2,
  borderRadius: '40px',
  color: theme.palette.common.white,
  textTransform: 'none',
  borderWidth: 0,
  fontWeight: 600,
  width: 'max-content',
  '&:hover': {
    backgroundColor: theme.palette.common.darkGrey2,
    borderRadius: '40px',
    color: theme.palette.common.white,
    textTransform: 'none',
    borderWidth: 0,
    fontWeight: 600
  }
};

export const ButtonOutlined = {
  backgroundColor: 'transparent',
  borderRadius: '40px',
  color: theme.palette.common.darkGrey2,
  textTransform: 'none',
  border: `2px solid ${theme.palette.common.darkGrey2}`,
  fontWeight: 600,
  width: 'max-content',
  '&:hover': {
    backgroundColor: 'transparent',
    borderRadius: '40px',
    color: theme.palette.common.darkGrey2,
    textTransform: 'none',
    border: `2px solid ${theme.palette.common.darkGrey2}`,
    fontWeight: 600
  }
};

export const OrdersContainer = {
  maxWidth: '1181px',
  display: 'flex',
  flexDirection: 'row',
  gap: '70px',
  margin: 'auto',
  justifyContent: 'center',
  '@media only screen and (min-width: 1513px)': {
    paddingTop: '135px'
  },
  '@media only screen and (max-width: 1512px) and (min-width: 1200px)': {
    paddingTop: '80px'
  },
  '@media only screen and (max-width: 1199px)': {
    paddingTop: '169px'
  },
  '@media only screen and (max-width: 1023px)': {
    flexDirection: 'column'
  },
  paddingLeft: '48px',
  paddingRight: '48px',
  '@media only screen and (max-width: 740px)': {
    paddingLeft: '23px',
    paddingRight: '23px',
    paddingBottom: '80px'
  }
};

export const ErrorContainer = { display: 'flex', flexDirection: 'column', alignItems: 'center' };
