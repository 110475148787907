export const productsListWrapperContainer = {
  padding: '0 65px 65px 65px',
  '@media screen and (max-width: 1650px)': {
    padding: '0 10px 65px 30px'
  },
  '@media screen and (max-width: 770px)': {
    padding: '0 10px 65px 30px',
    gap: '15px'
  },
  gap: '30px',
  justifyContent: 'center'
};

export const productsListWrapperProduct = {
  maxWidth: '390px',
  width: 'calc(33.33% - 30px)',
  '@media screen and (max-width: 770px)': {
    width: 'calc(50% - 15px)'
  }
};
