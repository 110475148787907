import { FC, ReactElement, useState, useRef, useEffect } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide, SwiperProps, SwiperClass } from 'swiper/react';
import 'swiper/css';
import { Box, SxProps, Theme } from '@mui/material';
import {
  beginSliderShadow,
  buttonIcon,
  container,
  endSliderShadow,
  nextButton,
  // navigationStyle,
  prevButton,
  sliderShadow,
  controls,
  paginationStyle
} from './Slider.styles';
import { Button } from '../Primitive';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { theme } from '../../styles/theme';

// Slider API: https://swiperjs.com/swiper-api
export interface SliderProps extends SwiperProps {
  readonly items: ReactElement[];
  readonly sx?: SxProps<Theme>;
  readonly navigation?: boolean;
  readonly pagination?: boolean;
  readonly shadowColor?: string;
}
export const Slider: FC<SliderProps> = ({
  items,
  sx,
  navigation = true,
  pagination = true,
  shadowColor = theme.palette.common.white,
  ...rest
}) => {
  const containerRef = useRef<typeof Box | any>(null);
  const customPaginationRef = useRef<typeof Box | any>(null);
  const [isBeginning, setIsBeginning] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const navigationNextRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const swiperRef = useRef<typeof Swiper | any>();
  const [allItemsVisible, setAllItemsVisible] = useState(false);

  const handleSlideChange = (swiperInstance: SwiperClass) => {
    setIsBeginning(swiperInstance.isBeginning);
    setIsEnd(swiperInstance.isEnd);
    setAllItemsVisible(swiperInstance.isBeginning && swiperInstance.isEnd);
  };

  const handleSliderMove = () => {
    setIsMoving(true);
  };

  const handleTransitionEnd = () => setIsMoving(false);

  const paginationOptions = pagination ? { clickable: true } : undefined;

  const navigationOptions = navigation
    ? {
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current
      }
    : undefined;

  const movePagination = () => {
    // some refs could be null
    if (pagination && containerRef?.current && customPaginationRef.current) {
      const $pagination = swiperRef.current.el.querySelector('.swiper-pagination');
      if ($pagination) customPaginationRef.current.append($pagination);
    }
  };

  useEffect(movePagination, [containerRef]);

  return (
    <Box ref={containerRef} sx={{ '--shadow-color': shadowColor, ...sx }}>
      <Box sx={container}>
        <Swiper
          onSliderMove={handleSliderMove}
          onSlideChange={handleSlideChange}
          onTransitionEnd={handleTransitionEnd}
          onResize={handleSlideChange}
          onSwiper={(swiper: SwiperClass) => {
            handleSlideChange(swiper);
            swiperRef.current = swiper;
          }}
          modules={[Navigation, Pagination]}
          navigation={navigationOptions}
          pagination={paginationOptions}
          {...rest}
        >
          {/* show shadows only when not all items are visible */}
          {!allItemsVisible && (
            <>
              <Box
                slot='container-start'
                sx={[beginSliderShadow(!isBeginning || isMoving), sliderShadow]}
              ></Box>
              <Box
                slot='container-end'
                sx={[endSliderShadow(!isEnd || isMoving), sliderShadow]}
              ></Box>
            </>
          )}

          {items.map((item, index) => (
            <SwiperSlide key={`swiperSlide-${index}`}>{item}</SwiperSlide>
          ))}
        </Swiper>
        {(pagination || navigation) && (
          <Box sx={controls(allItemsVisible)}>
            {/* pagination has to be outside slider because of overflowing arrows */}
            {/* visibility controlled by style - this element should be always present in DOM */}
            {pagination && <Box ref={customPaginationRef} sx={paginationStyle} />}
            {/* show navigation only when there is at least one not visible item and navigationVisible parameter is true */}
            {navigation && !allItemsVisible && (
              <>
                <Button
                  sx={prevButton}
                  disabled={isBeginning}
                  onClick={() => swiperRef.current?.slidePrev()}
                >
                  <NavigateBeforeIcon sx={buttonIcon} />
                </Button>
                <Button
                  sx={nextButton}
                  disabled={isEnd}
                  onClick={() => swiperRef.current?.slideNext()}
                >
                  <NavigateNextIcon sx={buttonIcon} />
                </Button>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
