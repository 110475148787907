import { theme } from '@theme';

export const ProductTileContainer = {
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.common.lightGrey4,
  boxShadow: '0 0 6px 0 rgba(4, 37, 87, 0.10);',
  borderRadius: '8px',
  border: '0.8px solid #727272',
  padding: '17px !important',
  '@media screen and (max-width: 675px)': {
    padding: '40px !important'
  },
  height: '100%'
};
export const LoaderContainer = {
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.common.lightGrey4,
  boxShadow: '0 1px 6px rgba(4, 37, 87, 0.1)',
  borderRadius: '8px',
  padding: '17px',
  width: '294px',
  height: '437px'
};
export const ProductTileRowContainer = (retake?: boolean) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.common.lightGrey4,
  border: retake ? '2px solid #8EE0E0' : '',
  boxShadow: '0 0 6px 0 rgba(4, 37, 87, 0.15)',
  borderRadius: '8px',
  padding: '17px',
  height: '100%',
  position: 'relative'
});

export const ProductTileTitle = {
  fontSize: '18px',
  '@media screen and (max-width: 770px)': {
    fontSize: '16px'
  },
  fontWeight: 600,
  color: theme.palette.common.darkGrey2,
  marginTop: '15px',
  textAlign: 'center'
};
export const ProductTileRow = {
  fontSize: '14px',
  '@media screen and (max-width: 770px)': {
    fontSize: '12px'
  },
  fontWeight: 600,
  color: theme.palette.common.darkGrey2,
  marginTop: '15px'
};
export const FitWithCounter = {
  maxWidth: 'calc(100% - 30px)'
};
export const ProductTileSkills = {
  marginTop: '5px',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'center'
};
export const ProductTileTime = {
  color: theme.palette.common.darkBlue2,
  flex: 1,
  '> p': {
    fontWeight: 600,
    fontSize: '12px'
  },
  display: 'flex',
  alignItems: 'center'
};
export const ProductTilePrice = {
  color: theme.palette.common.darkBlue7,
  fontWeight: 700,
  fontSize: '25px',
  marginTop: '20px'
};
export const SkillWrapper = {
  display: 'flex',
  alignItems: 'center',
  '> p': {
    color: theme.palette.common.darkBlue2,
    fontSize: '12px',
    fontWeight: 400
  }
};
export const ReadyToStartBtn = {
  marginTop: '25px',
  marginBottom: '15px',
  width: '145px',
  height: '32px',
  fontSize: '14px',
  '@media screen and (max-width: 400px)': {
    width: '138px',
    fontSize: '13px'
  }
};
export const CounterBox = {
  height: '20px',
  background: 'rgba(158, 0, 126, 0.1)',
  borderRadius: '4px',
  padding: '2px 5px',
  color: theme.palette.common.violet,
  fontSize: '12px',
  fontWeight: 700,
  position: 'absolute',
  right: '15px',
  top: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};
export const RetakeIndicatorBox = {
  position: 'absolute',
  right: '15px',
  top: '10px'
};
