import { theme } from '../../styles/theme';

export const ResetPasswordContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '80px',
  padding: '10px'
};

export const ResetPasswordHeader = {
  fontSize: '22px',
  fontWeight: 700,
  marginBottom: '23px',
  color: theme.palette.common.darkGrey1
};

export const ResetPasswordParagraph = {
  fontSize: '16px',
  textAlign: 'center',
  color: theme.palette.common.darkGrey2
};

export const ResetPasswordInput = {
  width: '100%',
  maxWidth: '370px',
  '@media only screen and (min-width: 320px) and (max-width: 400px)': {
    width: '280px'
  },
  position: 'relative'
};

export const ResetPasswordButton = {
  marginTop: '30px',
  marginBottom: '30px'
};

export const ResetPasswordSignInLink = {
  color: theme.palette.common.darkGrey2,
  marginLeft: '5px',
  fontWeight: 700,
  cursor: 'pointer'
};

export const LinkStyle = {
  color: 'inherit',
  fontWeight: 600,
  cursor: 'pointer'
};
