import { theme } from '@theme';

export const UserDataFormInput = {
  width: '90%',
  'input:disabled': {
    color: 'rgba(0, 0, 0, 0.38) !important',
    WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)'
  },
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  }
};
export const DatePickerAlign = {
  display: 'flex',
  alignItems: 'center'
};
export const LabelWithTooltip = {
  display: 'flex',
  alignItems: 'center'
};
export const InputLabel = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: 1.5,
  color: theme.palette.common.darkGrey3,
  paddingBottom: '8px'
};
export const FormInput = {
  borderRadius: '4px',
  border: '1px solid #91919',
  width: '90%',
  '.MuiInputBase-input': {
    height: '15px'
  },
  height: '48px'
};
export const FormInputContainer = {
  position: 'relative',
  marginBottom: '30px'
};
export const ButtonStyles = {
  marginBottom: 0,
  marginTop: '40px',
  '@media screen and (max-height: 1000px)': {
    marginTop: 0
  }
};
