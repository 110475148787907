import { FC, useContext, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { AppContext } from '../../context/App.context';
import { Auth, sopClient, sopUtils, User } from '@barracuda/shared/src';
import ReactGA from 'react-ga4';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { ELLCommerce } from '@pearson-ell/commerce-sdk';
import { ellCommerceInstanceConfig } from '../../utils/ell-commerce';

export const Callback: FC = () => {
  const { hash } = useLocation();
  const navigate = useLocalizedNavigate();
  const { setIsLoggedIn, setUser, eCommerceCountryISO2 } = useContext(AppContext);
  const [searchParams] = useSearchParams();

  const eCommerce = ELLCommerce.getInstance({
    ...ellCommerceInstanceConfig,
    defaultCountryISO2: eCommerceCountryISO2 || 'US',
    getTokenFunction: () => `Bearer ${sopUtils.getSopToken()}`
  });

  const addConsentsMetadata = async () => {
    const acceptedConsents = localStorage.getItem('koConsents');
    if (acceptedConsents) {
      const parsedConsents = JSON.parse(acceptedConsents);
      await sopClient.addMetadataToProfile(parsedConsents);
      localStorage.removeItem('koConsents');
    }
  };

  useEffect(() => {
    const decodedHash = decodeURIComponent(hash);
    if (decodedHash && decodedHash.includes('failed=true')) {
      navigate('/login?failed=true');
      return;
    }
    if (hash) {
      const token = hash.split('=')[1];
      if (token) {
        sopUtils.setSopToken(token);
      }

      if (sopUtils.getSopToken()) {
        addConsentsMetadata();
        const fetchUser = async () => {
          const user: AxiosResponse<User> = await Auth.sopClient.getUserData();
          const { data } = user;
          if (data) {
            setUser(data);
            setIsLoggedIn(true);
            eCommerce
              .CustomerService()
              .postLoginEvent(data?.externalId || '', data?.countryOfResidence?.isoCode);
            ReactGA.set({ userId: data.externalId });
            if (searchParams.get('verificationLicenseId')) {
              navigate(
                `/dashboard/results/test/${searchParams.get('verificationLicenseId')}/upload`
              );
            } else {
              navigate('/dashboard');
            }
          }
        };

        fetchUser().catch(console.error);
      } else {
        navigate('/login');
      }
      return;
    }

    navigate('login');
  }, []);

  return <></>;
};
