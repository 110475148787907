import { FC } from 'react';
import { ConsentsBox } from '../ConsentsBox/ConsentsBox';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  consentSignUpContainer,
  consentSignUpTitle,
  consentsSignUpButtons
} from './JoinWeb.styles';
import { buttonDark, buttonOutlineDark } from '../../styles/button';
import { theme } from '../../styles/theme';
import { Language } from '../../interface/language.interface';

interface SignUpConsentProps {
  onSignUpClick: () => void;
  onCancelClick: () => void;
  readonly onConsentsStatusChange: (
    accepted: boolean,
    consentsDto: { [p: string]: boolean }
  ) => void;
  readonly onCheckboxChanged: (consentsDto: { [p: string]: boolean }) => void;
  readonly consentsAccepted: boolean;
  readonly language?: Language;
}

export const SignUpConsent: FC<SignUpConsentProps> = ({
  onSignUpClick,
  onCancelClick,
  onCheckboxChanged,
  onConsentsStatusChange,
  consentsAccepted,
  language
}) => {
  const { t } = useTranslation();
  return (
    <Stack sx={consentSignUpContainer}>
      <Stack sx={{ padding: '60px', backgroundColor: theme.palette.common.white }}>
        <Typography sx={consentSignUpTitle}>{t('signUp.agreementTitle')}</Typography>
        <ConsentsBox
          language={language}
          onCheckboxChanged={onCheckboxChanged}
          type='sk'
          onConsentsStatusChange={onConsentsStatusChange}
        />
        <Box sx={consentsSignUpButtons}>
          <Button onClick={onCancelClick} sx={buttonOutlineDark}>
            {t('account.cancel')}
          </Button>
          <Button disabled={!consentsAccepted} onClick={onSignUpClick} sx={buttonDark}>
            {t('signUpText')}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
